import { axiosAuthed as axios } from '../axios-config';
import { UserPreferences } from '../../types/user-preferences';
import { AxiosResponse } from 'axios';
import { updateDefaultUserPreferences } from '../../utils/user-preferences';

const URL = '/integration/timeline/preferences';

export const getPreferences = () =>
  axios
    .get(URL)
    .then(response => {
      let data: UserPreferences = response.data;
      (response as AxiosResponse<
        UserPreferences
      >).data = updateDefaultUserPreferences(data);
      return response;
    })
    .catch(e => {
      throw e;
    });

export const savePreferences = (preferences: string) =>
  axios.post(URL, preferences).catch(e => {
    throw e;
  });

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../reducers';
import { ReducerShape } from '../../../../reducers/ui';
import { getOperatingCompaniesDividersData } from '../../../../selectors';
import { OperatingCompanyLabelData } from '../../../../types/aircraft';

interface StateProps {
  companyGroups: OperatingCompanyLabelData[];
  showLabels: boolean;
  rowHeight: number;
  transform: ReducerShape['transform'];
}

export class OperatingCompaniesTitle extends PureComponent<StateProps> {
  render() {
    const {
      companyGroups,
      rowHeight,
      showLabels,
      transform: { ky, translateY },
    } = this.props;
    const verticalPadding = 4;
    const textLabelHeight = 18;
    if (!showLabels) {
      return null;
    }
    return (
      <div className="company-label-group">
        {companyGroups.map((com, i) => {
          const topCurrent = com.aircraftIndex * rowHeight * ky + translateY;
          const topNext =
            companyGroups[i + 1] &&
            companyGroups[i + 1].aircraftIndex * rowHeight * ky + translateY;
          const top =
            (companyGroups[i + 1] &&
              companyGroups[i + 1].y + translateY >
                textLabelHeight + verticalPadding &&
              (topCurrent < 0 || (topNext && topNext < verticalPadding))) ||
            (!companyGroups[i + 1] && topCurrent < verticalPadding) || // last company scrolled
            (companyGroups.length === 1 && topCurrent < verticalPadding) // only one company and scrolled
              ? 0
              : topCurrent;
          return (
            <div
              className="company-label"
              key={`${com.companyName}_${com.y}`}
              style={{
                top: `${top + verticalPadding}px`,
                padding: `${verticalPadding}px 8px`,
              }}
            >
              {`${com.companyName} Fleet`}
            </div>
          );
        })}
      </div>
    );
  }
}
export const OperatingCompaniesTitleConnected = connect(
  (state: RootState): StateProps => ({
    companyGroups: getOperatingCompaniesDividersData(state),
    rowHeight: state.ui.rowHeight,
    showLabels: state.aircraft.showLabels,
    transform: state.ui.transform,
  })
)(OperatingCompaniesTitle);

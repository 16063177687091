import { SUPPORT_URL } from '../constants/environment';

const ContactSupportTeamMessage = (msg = 'Server is unavailable') => {
  return (
    <>
      {msg}. Please{' '}
      <a target="_blank" href={SUPPORT_URL}>
        contact support team
      </a>
    </>
  );
};
export default ContactSupportTeamMessage;

import { PureComponent } from 'react';
import { utc } from 'moment';
import MaintenanceItem from '../../../types/maintenance-item';
import WarningIcon from '../../svg-icons/aircraft/WarningIcon';
import { CENTRED_DOT_SYMBOL } from '../../../constants';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { getAircraftByIdMap, getMELsByAircraftId } from '../../../selectors';
import { getHoursInMinutesFormattedToHHmm } from '../../../utils/time';
import {
  getMelBackgroundColor,
  getMelZoneColor,
} from '../../../common/mels/mel-colorize';
import Aircraft from '../../../types/aircraft';

interface StateProps {
  aircraft: Aircraft;
  mels: MaintenanceItem[];
  today: number;
}
class MelTooltipContent extends PureComponent<StateProps> {
  render() {
    const { aircraft, mels, today } = this.props;
    return mels.map(item => {
      const hours = getHoursInMinutesFormattedToHHmm(item.hoursInMinutes);
      const zone = getMelZoneColor(aircraft, item, today);
      const backgroundColor = getMelBackgroundColor(zone);
      return (
        <div
          className="mel-warning"
          key={item.id}
          style={{
            backgroundColor,
            borderColor: zone,
          }}
        >
          <div className="mel-warning-bold">Description</div>
          <div className="mel-warning-text">{item.description}</div>
          <div className="mel-warning-bold">Operation restrictions</div>
          <div className="mel-warning-text">
            {item.opDescription ? `${item.opDescription}` : 'N/A'}
          </div>
          <header className="mel-warning-header">
            <div className="mel-warning-icon">
              <WarningIcon />
            </div>
            <h1 className="mel-warning-h1">
              {`MEL ${CENTRED_DOT_SYMBOL} ${item.maintenanceItemType}`}
            </h1>
            {item.isAogDriver && (
              <div className="mel-warning-aog-label">AOG</div>
            )}
          </header>
          <div className="mel-warning-bold">
            {`MEL Ref: ${item.maintenanceItemRef || 'N/A'}`}
          </div>
          <div className="mel-warning-bold">
            {`Due Date: ${
              item.dueDate
                ? `${utc(item.dueDate).format(' DD MMM,’YY, HH:mm[Z]')}`
                : 'N/A'
            }`}
          </div>
          <div className="mel-warning-bold">{`Due Hours: ${hours}`}</div>
          <div className="mel-warning-bold">{`Due Cycles: ${
            item.cycles ? ` ${item.cycles}` : 'N/A'
          }`}</div>
        </div>
      );
    });
  }
}

const mapStateToProps = (state: RootState) => ({
  aircraft: getAircraftByIdMap(state)[state.ui.hoveredAircraftId],
  mels: getMELsByAircraftId(state, state.ui.hoveredAircraftId),
  today: state.time.today,
});

const MelTooltipContentConnected = connect(mapStateToProps)(MelTooltipContent);

export default MelTooltipContentConnected;

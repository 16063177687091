import { PureComponent } from 'react';
import { ConnectedEditOneWayIframeWrapper } from './gv-iframe-wrapper';

export class IframeWrappers extends PureComponent {
  render() {
    // TODO type connect wrapper
    // @ts-ignore
    return <ConnectedEditOneWayIframeWrapper />;
  }
}

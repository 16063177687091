import { Checkbox, Tooltip } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { flatten } from 'lodash';
import { FC } from 'react';
import {
  FilterByAircraftType,
  FilterByOperatingCompany,
} from '../../../types/aircraft';
import { setDataTestEntityId } from '../../../utils';

export const OperatingCompanyItem: FC<{
  filterByAircraftType: FilterByAircraftType[];
  classNamePrefix: string;
  disabled: boolean;
  onSelectOperatingCompany: (e: CheckboxChangeEvent, companyId: number) => void;
  company: FilterByOperatingCompany;
}> = ({
  classNamePrefix,
  filterByAircraftType,
  disabled,
  onSelectOperatingCompany,
  company,
}) => {
  const checked =
    company.checked &&
    filterByAircraftType.every(s => s.checked) &&
    flatten(filterByAircraftType.map(t => t.tails)).every(t => t.checked);
  const indeterminate =
    company.checked &&
    (filterByAircraftType.some(s => !s.checked) ||
      flatten(filterByAircraftType.map(t => t.tails)).some(t => !t.checked));

  return (
    <div className={`${classNamePrefix}actypes-content-companies-column-name`}>
      <Checkbox
        disabled={disabled}
        checked={checked}
        onChange={e => onSelectOperatingCompany(e, company.id)}
        id={`company-${company.name}`}
        indeterminate={indeterminate}
        {...setDataTestEntityId(`ac-type-filter-${company.name}`)}
      />
      <Tooltip title={company.name}>
        <label
          className={`${classNamePrefix}actypes-content-companies-column-name-label`}
          htmlFor={`company-${company.name}`}
          style={{ color: checked ? '#1677FF' : 'inherit' }}
        >
          {company.name}
        </label>
      </Tooltip>
    </div>
  );
};

import { AirportMismatch } from './airport-mismatch';
import { GroundTime } from './ground-time';

type HasFboMismatch = {
  hasFBOMismatch: boolean;
};

export type FboMismatch = (AirportMismatch | GroundTime) & HasFboMismatch;

export const isGroundTimeFbo = (
  el: FboMismatch
): el is GroundTime & HasFboMismatch =>
  typeof (el as GroundTime & HasFboMismatch)?.baseAirportId !== 'undefined';

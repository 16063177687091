import { PureComponent } from 'react';
import Aircraft from '../../../types/aircraft';
import { StopOutlined } from '@ant-design/icons';
import SeatingManIcon from '../../svg-icons/aircraft/SeatingManIcon';
import { setDataTestEntityId } from '../../../utils';

interface Props {
  aircraft: Aircraft;
  isUnavailableForAdvertisement: boolean;
}

export class PrimaryInfo extends PureComponent<Props> {
  render() {
    const { aircraft, isUnavailableForAdvertisement } = this.props;
    return (
      <div className="aircraft-content-primary-info">
        <div className="aircraft-content-primary-info-tail">
          <span
            className="aircraft-content-primary-info-tail-number"
            {...setDataTestEntityId(
              'aircraft-content-primary-info-tail-number'
            )}
          >
            {aircraft.tailNumber}
          </span>
          {isUnavailableForAdvertisement && (
            <span
              className="aircraft-content-primary-info-tail-unavailable-for-advertisement"
              {...setDataTestEntityId(
                'aircraft-content-primary-info-tail-unavailable-for-advertisement'
              )}
            >
              <StopOutlined
                style={{
                  color: '#839CA8',
                }}
              />
            </span>
          )}
        </div>
        <div className="aircraft-content-primary-info-seats">
          <span className="aircraft-content-primary-info-seats-icon">
            <SeatingManIcon />
          </span>
          <span
            className="aircraft-content-primary-info-seats-number"
            {...setDataTestEntityId(
              'aircraft-content-primary-info-seats-number'
            )}
          >
            {aircraft.numberOfSeats}
          </span>
        </div>
      </div>
    );
  }
}

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { FilterACTypes } from './aircraft';
import { FilterByElementsConnected } from './elements';
import { FilterTopBarLeftConnected } from './left-bar';
import { FilterByOneWayAndEmptyLegConnected } from './one-way-empty-leg';
import { FilterByOpStatusesConnected } from './op-statuses';
import { ResetPreset } from './reset-preset';
import { SavePreset } from './save-preset';
import './styles.scss';
import { FilterBarRightStickedStyled } from './styled';

interface StateProps {
  height: number;
  isVerticalMode: boolean;
}

export class FilterTopbar extends PureComponent<StateProps> {
  render() {
    const { height, isVerticalMode } = this.props;
    return (
      <div
        className="filter-bar unselectable"
        style={
          height > 0
            ? {
                height,
                zIndex: 0,
                flexWrap: isVerticalMode ? 'wrap' : 'nowrap',
                padding: isVerticalMode ? '12px 5px 5px 5px' : '0 15px',
              }
            : { height: 0, display: 'none' }
        }
      >
        <div
          className="filter-bar-left-sticked"
          style={{
            paddingBottom: isVerticalMode ? '10px' : '0px',
            fontSize: isVerticalMode ? '12px' : '14px',
          }}
        >
          <FilterTopBarLeftConnected />
        </div>

        <FilterBarRightStickedStyled isVerticalMode={isVerticalMode}>
          <FilterACTypes />
          <FilterByElementsConnected />
          <FilterByOneWayAndEmptyLegConnected />
          <FilterByOpStatusesConnected />
          {!isVerticalMode && (
            <>
              <ResetPreset />
              <SavePreset />
            </>
          )}
        </FilterBarRightStickedStyled>
      </div>
    );
  }
}

const advancedMap = createDelayedForScrollConnect((state: RootState) => ({
  height: state.ui.filterBarHeight,
  isVerticalMode: state.ui.isVerticalMode,
}));

export const FilterTopbarConnected = connect(advancedMap)(FilterTopbar);

import { utc } from 'moment';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import {
  isHoldAircraft,
  isAircraftForStats,
  isAirHamburgAircraft,
  isRWAOperatedAircraft,
  isSubCharter,
  isVJOperatedAircraft,
  isXOOperatedAircraft,
  isJetEdgeAircraft,
} from '../common/aircraft/aircraft-check-status';
import { RootState } from '../reducers';
import Aircraft, { MaintenanceStatus } from '../types/aircraft';
import { MaintenanceStatusForStats, RequiredDay } from '../types/ui';
import { getMaintenancesStatusType } from '../utils';
import { getAircraftByIdMapWithHold, getBaseCompaniesIds } from '.';
import { DAY_IN_MILLISECONDS } from '../constants';
import { Pool } from '../data-processing/pool-structure';
import Flight, { OperationalStatus } from '../types/flight';
import { ReducerShape, StatisticsMode } from '../reducers/ui';
import {
  isFerryFlight,
  isLiveFlight,
} from '../common/flight/flight-check-status';
import { assertUnreachable } from '../utils/compiler-utils';

export const isInOperationalDate = (a: Aircraft, today: number): boolean => {
  return (
    a.operationStartDate &&
    a.operationStartDate <=
      utc(today)
        .startOf('day')
        .valueOf() &&
    (!a.operationEndDate ||
      a.operationEndDate >=
        utc(today)
          .endOf('day')
          .valueOf())
  );
};

export const filterTailsByStatMode = (
  statisticsMode: StatisticsMode,
  aircraftList: Aircraft[]
): Aircraft[] => {
  switch (statisticsMode) {
    case 'RWA': {
      return aircraftList.filter(ac => isRWAOperatedAircraft(ac));
    }
    case 'XOJET': {
      return aircraftList.filter(ac => isXOOperatedAircraft(ac));
    }
    case 'VJ': {
      return aircraftList.filter(ac => isVJOperatedAircraft(ac));
    }
    case 'JE': {
      return aircraftList.filter(ac => isJetEdgeAircraft(ac));
    }
    case 'AH': {
      return aircraftList.filter(ac => isAirHamburgAircraft(ac));
    }
    case 'VG': {
      return aircraftList;
    }
    default:
      return assertUnreachable(statisticsMode);
  }
};
export const getTailsForStats = createSelector<
  RootState,
  Aircraft[],
  ReducerShape['statisticsMode'],
  number,
  Aircraft[]
>(
  state => state.aircraft.aircraft,
  state => state.ui.statisticsMode,
  state => state.time.today,
  (aircraft, statisticsMode, today) => {
    const aircraftList = aircraft.filter(
      a =>
        isInOperationalDate(a, today) &&
        isAircraftForStats(a) &&
        !isHoldAircraft(a)
    );
    return filterTailsByStatMode(statisticsMode, aircraftList);
  }
);

export const getStatsByTypeMaintenance = createCachedSelector(
  getTailsForStats,
  (state: RootState, type) => type,
  (tails, type: MaintenanceStatus) =>
    tails.length === 0
      ? 0
      : (tails.filter(a => a.maintenanceStatusId === type).length * 100) /
        tails.length
)((_state, key) => key);

export const getStatsForFlightAvailable = createSelector<
  RootState,
  Aircraft[],
  number
>(getTailsForStats, tails =>
  tails.length === 0
    ? 0
    : ((tails.length -
        tails.filter(
          t =>
            t.maintenanceStatusId === MaintenanceStatus.AOG ||
            t.maintenanceStatusId === MaintenanceStatus.UMX ||
            t.maintenanceStatusId === MaintenanceStatus.SMX
        ).length) /
        tails.length) *
      100
);

export const getStatsTextByTypeMaintenance = createCachedSelector(
  (state: RootState, type: MaintenanceStatusForStats) => {
    return `${getStatsByTypeMaintenance(
      state,
      getMaintenancesStatusType(type)
    ).toFixed()}%`;
  },
  maintenanceStatistics => maintenanceStatistics
)((_state, key) => key);

export const getStatsTextByTotalTails = createSelector<
  RootState,
  string,
  string
>(
  state => `${getTailsForStats(state).length}`,
  totalTails => totalTails
);

export const getStatsTextByFlightAvailable = createSelector<
  RootState,
  string,
  string
>(
  state => `${getStatsForFlightAvailable(state).toFixed()}%`,
  flightStatistics => flightStatistics
);

interface LiveTotalFlights {
  live: number;
  total: number;
}

const reduceToLiveTotalFlights = (
  acc: LiveTotalFlights,
  fl: Flight
): LiveTotalFlights => {
  acc.live = isLiveFlight(fl) ? acc.live + 1 : acc.live;
  acc.total =
    isLiveFlight(fl) || isFerryFlight(fl.legBusinessTypeId)
      ? acc.total + 1
      : acc.total;
  return acc;
};

const getFlightsCountFromState = (
  flightPool: Pool<Flight>,
  visibleAircraftMap: { [id: string]: Aircraft },
  baseCompanyIds: number[],
  date: number,
  statMode: StatisticsMode
): { live: number; total: number } => {
  if (flightPool.length === 0) return { live: 0, total: 0 };
  const thisDay = flightPool.find(
    pool => pool.day <= date && pool.day > date - DAY_IN_MILLISECONDS
  );
  if (!thisDay) return { live: 0, total: 0 };
  const flights = thisDay.pool;
  const visibleFlights = flights
    .filter(
      f =>
        f.departureUtcEstimated &&
        f.departureUtcScheduled &&
        f.legOperationalStatusId !== OperationalStatus.NO_SHOW &&
        f.legOperationalStatusId !== OperationalStatus.QUOTED &&
        f.legOperationalStatusId !== OperationalStatus.CANCELLED
    )
    .filter(
      f =>
        f.departureUtcScheduled >= date &&
        f.departureUtcScheduled <
          utc(date)
            .endOf('d')
            .valueOf()
    )
    .filter(
      f =>
        !!visibleAircraftMap[f.aircraftId] &&
        !isSubCharter(visibleAircraftMap[f.aircraftId], baseCompanyIds)
    );
  const { live, total } = visibleFlights.reduce<LiveTotalFlights>(
    (acc, fl) => {
      const aircraft = visibleAircraftMap[fl.aircraftId];
      switch (statMode) {
        case 'VJ': {
          if (aircraft && isVJOperatedAircraft(aircraft)) {
            reduceToLiveTotalFlights(acc, fl);
          }
          return acc;
        }
        case 'RWA': {
          if (aircraft && isRWAOperatedAircraft(aircraft)) {
            reduceToLiveTotalFlights(acc, fl);
          }
          return acc;
        }
        case 'XOJET': {
          if (aircraft && isXOOperatedAircraft(aircraft)) {
            reduceToLiveTotalFlights(acc, fl);
          }
          return acc;
        }
        case 'JE': {
          if (aircraft && isJetEdgeAircraft(aircraft)) {
            reduceToLiveTotalFlights(acc, fl);
          }
          return acc;
        }
        case 'AH': {
          if (aircraft && isAirHamburgAircraft(aircraft)) {
            reduceToLiveTotalFlights(acc, fl);
          }
          return acc;
        }
        case 'VG': {
          if (aircraft && isAircraftForStats(aircraft)) {
            reduceToLiveTotalFlights(acc, fl);
          }
          return acc;
        }
        default:
          reduceToLiveTotalFlights(acc, fl);
      }
      return acc;
    },
    { live: 0, total: 0 }
  );
  return { live, total };
};

const getFlightStatistics = createCachedSelector(
  (state: RootState) => state.timelineEvents.flights,
  getAircraftByIdMapWithHold,
  getBaseCompaniesIds,
  (state: RootState, type: RequiredDay) =>
    utc(state.time.now + (type === 'Tomorrow' ? DAY_IN_MILLISECONDS : 0))
      .startOf('day')
      .valueOf(),
  (state: RootState) => state.ui.statisticsMode,
  getFlightsCountFromState
)((_state, key) => key);

export const getStatsTextFlightsByRequiredDay = createCachedSelector(
  (state: RootState, type: RequiredDay) => getFlightStatistics(state, type),
  flightStatistics => flightStatistics
)((_state, key) => key);

export const getOperatingCompanyOptionsMap = createSelector<
  RootState,
  Aircraft[],
  { [key in StatisticsMode]: string }
>(
  state => state.aircraft.aircraft,
  aircraft => {
    return {
      VG: 'Vista Global',
      VJ:
        aircraft.find(ac => isVJOperatedAircraft(ac))?.operatingCompanyName ||
        'VistaJet Limited',
      XOJET:
        aircraft.find(ac => isXOOperatedAircraft(ac))?.operatingCompanyName ||
        'XOJET Aviation LLC',
      RWA:
        aircraft.find(ac => isRWAOperatedAircraft(ac))?.operatingCompanyName ||
        'Red Wing Aviation',
      AH:
        aircraft.find(ac => isAirHamburgAircraft(ac))?.operatingCompanyName ||
        'Air Hamburg',
      JE: 'Jet Edge', //  Jet Edge stats contains Jet Edge and Western Air Charter Inc consolidated under one Jet Edge umbrella
    };
  }
);

import './../styles.scss';
import { FC } from 'react';
import { RootState } from '../../../reducers';
import { useSelector } from 'react-redux';
import {
  FlightGhostConnected,
  HoldFlightGhostConnected,
} from './ghosts-render';
import Aircraft from '../../../types/aircraft';
import { getIndexByYPosition } from '../../../reducers/ui';
import { getAircraftByIndex, getVisibleHoldAircraft } from '../../../selectors';
import { isEqual } from 'lodash';

export const FlightsGhosts: FC = () => {
  const selectedFlights = useSelector<RootState, number[]>(
    state => state.ui.selectedFlights,
    isEqual
  );
  const { selectedAircraft, index } = useSelector<
    RootState,
    { selectedAircraft: Aircraft; index: number }
  >(state => {
    const index = getIndexByYPosition(
      state.ui,
      state.flightDetails.draggingPosition.y,
      getVisibleHoldAircraft(state)
    );
    return {
      selectedAircraft: getAircraftByIndex(state, index),
      index,
    };
  }, isEqual);
  if (!selectedFlights.length || !selectedAircraft) return null;
  return (
    <>
      {selectedFlights.map(id => (
        <FlightGhostConnected
          key={id}
          id={id}
          selectedAircraft={selectedAircraft}
          index={index}
        />
      ))}
    </>
  );
};
export const HoldLineFlightsGhosts: FC = () => {
  const selectedFlights = useSelector<RootState, number[]>(
    state => state.ui.selectedFlights,
    isEqual
  );
  const { selectedAircraft } = useSelector<
    RootState,
    { selectedAircraft: Aircraft }
  >(state => {
    const index = getIndexByYPosition(
      state.ui,
      state.flightDetails.draggingPosition.y,
      getVisibleHoldAircraft(state)
    );
    return {
      selectedAircraft: getAircraftByIndex(state, index),
    };
  }, isEqual);
  if (!selectedFlights.length || !selectedAircraft) return null;
  return (
    <>
      {selectedFlights.map(id => (
        <HoldFlightGhostConnected
          key={id}
          id={id}
          selectedAircraft={selectedAircraft}
        />
      ))}
    </>
  );
};

import { capitalize } from 'lodash';
import { PureComponent } from 'react';
import Airport from '../../types/airport';

interface Props {
  departureAirport: Airport;
  arrivalAirport: Airport;
}

class AirportRow extends PureComponent<Props> {
  render() {
    const { arrivalAirport, departureAirport } = this.props;
    return (
      <div className="ft-airport-row">
        {departureAirport && departureAirport.name && (
          <span className="ft-airport-row-full">
            {capitalize(departureAirport.name)}
          </span>
        )}
        {arrivalAirport && arrivalAirport.name && (
          <span className="ft-airport-row-full-right">
            {capitalize(arrivalAirport.name)}
          </span>
        )}
      </div>
    );
  }
}

export default AirportRow;

import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { ChequeredPattern } from './chequered-pattern';

const notReadyForPreparationFlightsInfoArray = [
  {
    name: 'PROGRAM',
    color: '#00c853',
  },
  {
    name: 'ON_DEMAND',
    color: '#ffde03',
  },
  {
    name: 'PRIVATE',
    color: '#612600',
  },
  {
    name: 'DEMO',
    color: '#ffc1e3',
  },
  {
    name: 'SENSITIVE',
    color: '#ff26ff',
  },
  {
    name: 'MAINTENANCE',
    color: '#645452',
  },
  {
    name: 'MANUFACTURER',
    color: '#A52A2A',
  },
  {
    name: 'OWNER',
    color: '#ea80fc',
  },
  {
    name: 'AMBULANCE',
    color: '#a7c0cd',
  },
  {
    name: 'MARKETING',
    color: '#8e24aa',
  },
  {
    name: 'OWNER_RELEASE',
    color: '#a1887f',
  },
  {
    name: 'LEASE',
    color: '#90caf9',
  },
  {
    name: 'OTHER_MISC',
    color: '#616161',
  },
  {
    name: 'VJ_DIRECT',
    color: '#80d8ff',
  },
  {
    name: 'B2S',
    color: '#bcaaa4',
  },
  {
    name: 'RETAIL',
    color: '#FF7F50',
  },
  {
    name: 'WHOLESALE',
    color: '#4dd0e1',
  },
  {
    name: 'ELITE',
    color: '#2196f3',
  },
  {
    name: 'DEFAULT',
    color: '#808080',
  },
];

interface StateProps {
  kx: number;
  ky: number;
}

const AllNotReadyForPreparationFlightPatterns = (props: StateProps) => (
  <>
    {notReadyForPreparationFlightsInfoArray.map(obj => (
      <ChequeredPattern
        kx={props.kx}
        ky={props.ky}
        patternIdName={`notReadyForPreparationFlight${obj.name}`}
        color={obj.color}
        key={obj.name}
      />
    ))}
  </>
);

const mapStateToProps = (state: RootState): StateProps => ({
  kx: state.ui.transform.kx,
  ky: state.ui.transform.ky,
});
const advancedMap = createDelayedForScrollConnect(mapStateToProps);

export const AllNotReadyForPreparationFlightPatternsConnected = connect(
  advancedMap
)(AllNotReadyForPreparationFlightPatterns);

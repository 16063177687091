import { isEqual } from 'lodash';
import { FC, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { ReducerShape } from '../../../reducers/ui';
import { Crew } from '../../../types/crew-roster';
import { EmptyLegRoute, OfferStatus } from '../../../types/empty-leg-offer';
import EventElement from '../../../types/event-element';
import { FlightLabelTimeType } from '../../../types/flight';
import { GroundTimeType } from '../../../types/ground-time';
import { MaintenanceType } from '../../../types/maintenance';
import { MaintenanceItemType } from '../../../types/maintenance-item';
import { FlightsGhosts } from '../../flight-label/flights-change-tail/dragging-ghosts-container';
import { OperatingCompaniesTitleConnected } from './operating-companies-title';
import { getMergedAvailabilityNotesD3creator } from './vistajet/d3-creators/merged-availability-notes';
import { getMergedGeneralNotesD3creator } from './vistajet/d3-creators/merged-general-notes';
import { getMergedMaintenanceItemsD3creator } from './vistajet/d3-creators/merged-maintenance-items';
import { getOverlapFlightELOWD3creator } from './vistajet/d3-creators/overlapped-flight-el-ow';
import { getOverlappedFlightMxPairsD3creator } from './vistajet/d3-creators/overlapped-flight-with-maintenances';
import { getOverlappedFlightsD3creator } from './vistajet/d3-creators/overlapped-flights';
import { getOverlappedMainenancesD3creator } from './vistajet/d3-creators/overlapped-maintenances';
import { crewElements, elementTypes } from './vistajet/element-types';
import { GroupOfTextLabels } from './vistajet/group-of-text-labels';

export interface ExtendedEventElement extends EventElement {
  x: number;
  width: number;
  ky: number;
  y: number;
  rh: number;
  airportsDataLoaded: boolean;
  laneKoef: number;
  timeLabelFilterId?: FlightLabelTimeType;
  team?: Crew[];
  offerStatus?: OfferStatus;
  maintenanceType?: MaintenanceType;
  maintenanceItemType?: MaintenanceItemType;
  message?: string;
  description?: string[];
  airportId?: number;
  departureAirportId?: number;
  emptyLegRoutes?: EmptyLegRoute[];
  groundTimeType?: GroundTimeType;
}
interface StateProps {
  width: number;
  height: number;
  textLabelAbility: boolean;
  isScrolling: boolean;
  top: number;
  segmentsVisibility: ReducerShape['segmentsVisibility'];
  aircraftLoadingComplete: boolean;
}

interface Props {
  onMouseMove: (e: MouseEvent<HTMLOrSVGElement>) => void;
  onMouseDown: (e: MouseEvent<HTMLOrSVGElement>) => void;
  onContextMenu: (e: MouseEvent<HTMLOrSVGElement>) => void;
}

export const TimelineTextLabels: FC<Props> = ({
  onContextMenu,
  onMouseDown,
  onMouseMove,
}) => {
  const {
    aircraftLoadingComplete,
    height,
    isScrolling,
    segmentsVisibility,
    textLabelAbility,
    top,
    width,
  } = useSelector<RootState, StateProps>(
    state => ({
      width: state.ui.width,
      height: state.ui.height,
      textLabelAbility: state.ui.textLabelAbility,
      isScrolling:
        state.ui.isScrollingVertically || state.ui.isScrollingHorizontally,
      top: state.ui.marginTop + state.ui.holdLineHeight,
      segmentsVisibility: state.ui.segmentsVisibility,
      aircraftLoadingComplete: state.aircraft.statusLoading.vistajet,
    }),
    isEqual
  );

  return (
    <div
      className="labels"
      onDragOver={ev => {
        ev.preventDefault();
        ev.dataTransfer.dropEffect = 'move';
      }}
      onDrop={ev => ev.preventDefault()}
      style={{
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`,
        display: !isScrolling && textLabelAbility ? 'block' : 'none',
        willChange: 'display',
      }}
      onMouseMove={onMouseMove}
      onMouseDown={onMouseDown}
      onContextMenu={onContextMenu}
    >
      {aircraftLoadingComplete && (
        <>
          {segmentsVisibility.availabilityNotes && (
            <GroupOfTextLabels
              elementName="mergedAvailabilityNotes"
              componentD3creator={getMergedAvailabilityNotesD3creator}
            />
          )}
          {elementTypes
            .filter(el => segmentsVisibility[el.elementName])
            .concat(crewElements)
            .map(e => (
              <GroupOfTextLabels key={e.elementName} {...e} />
            ))}
          <GroupOfTextLabels
            elementName="mergedGeneralNotes"
            componentD3creator={getMergedGeneralNotesD3creator}
          />
          {segmentsVisibility.maintenances && (
            <GroupOfTextLabels
              elementName="overlappedMaintenances"
              componentD3creator={getOverlappedMainenancesD3creator}
            />
          )}
          <GroupOfTextLabels
            elementName="overlap-flight-el-ow"
            componentD3creator={getOverlapFlightELOWD3creator}
          />
          {segmentsVisibility.flights && segmentsVisibility.maintenances && (
            <GroupOfTextLabels
              elementName="overlappedFlightWithMaintenances"
              componentD3creator={getOverlappedFlightMxPairsD3creator}
            />
          )}
          {segmentsVisibility.flights && (
            <GroupOfTextLabels
              elementName="overlappedFlights"
              componentD3creator={getOverlappedFlightsD3creator}
            />
          )}
          {segmentsVisibility.maintenanceItems && (
            <GroupOfTextLabels
              elementName="mergedMaintenanceItems"
              componentD3creator={getMergedMaintenanceItemsD3creator}
            />
          )}
          <FlightsGhosts />

          <OperatingCompaniesTitleConnected />
        </>
      )}
    </div>
  );
};

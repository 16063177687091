import { ChangeEvent, PureComponent } from 'react';
import { Input } from 'antd';

export class TimeInput extends PureComponent<
  {
    value: moment.Moment;
    onChange: (m: moment.Moment) => void;
  },
  { isEditing: boolean; value: string }
> {
  mask: any;
  momentFormat: string;
  element: HTMLInputElement;
  constructor(props) {
    super(props);
    this.momentFormat = 'HH:mm';
    this.state = { isEditing: false, value: props.value };
  }
  getRef = ref => (this.element = ref);
  onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      value: ev.target.value,
    });
  };
  onFocus = () => {
    this.setState((_prevState, props) => ({
      isEditing: true,
      value: props.value.format('HHmm'),
    }));
  };
  onBlur = () => {
    const hours = Number(this.state.value.slice(0, 2));
    const minutes = Number(this.state.value.slice(2));
    const newValue = this.props.value
      .clone()
      .set('m', minutes)
      .set('h', hours);
    this.setState({ isEditing: false });
    this.props.onChange(newValue);
  };
  onEnter = () => {
    this.element.blur();
  };
  render() {
    return (
      <Input
        onChange={this.onChange}
        value={
          this.state.isEditing
            ? this.state.value
            : this.props.value.format(this.momentFormat)
        }
        onPressEnter={this.onEnter}
        onFocus={this.onFocus}
        maxLength={4}
        onBlur={this.onBlur}
        style={{ width: 70 }}
        ref={this.getRef}
      />
    );
  }
}

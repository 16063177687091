import { Aircraft, MaintenanceStatus, WifiType } from '../../types/aircraft';

export interface AircraftGVModel {
  aircraft: {
    aircraftId: {
      key: number;
    };
    tailNumber: string;
    serialNumber: string;
    operationStartDate: number;
    operationEndDate: number;
    aircraftTypeId: {
      key: number;
    };
    aircraftTypeName: string;
    numberOfSeats: number;
    operatingCompanyId: {
      key: number;
    };
    owningCompanyId: {
      key: number;
    };
    prevLandings: number | null;
    prevFlightMinutes: number | null;
    aircraftTypeCode: string;
    aircraftTypeRank: number;
    aircraftTypeColour: string;
    lastRefurbishmentDate: number;
    manufactureDate: number;
    manufacturerId: number | null;
    wifi: WifiType | null;
    airTimeBeforeMX: number;
    landingsBeforeMX: number;
    refurbishmentNotes: string;
    nextDueDateMs: number;
  };
  aircraftAvailability: {
    startDate: number;
    endDate: number | null;
    desctiption: string;
  } | null;
  aircraftLocation: {
    updatedTimestamp: number;
    location: string;
    airportName: string;
    airportId: {
      key: number;
    };
  };
  statistics: {
    totalLandings: number;
    minutesFlown: number;
  } | null;
  maintenanceStatusId: {
    key: number;
  };
  operatingCompany: {
    aocCountryCode: string;
    aocName: string;
  } | null;
  aircraftDefects: string[] | null;
  hasMelDefects: boolean;
  hasAogDrivers: boolean;
  aogDescriptions: string[] | null;
  hasPrimaryWiFiDefects: boolean;
  hasSwiftWiFiDefects: boolean;
  customerAffectingNotes: string[] | null;
  hasPaxAffecting: boolean;
}

export function transformToFE(a: AircraftGVModel): Aircraft {
  return {
    id: a.aircraft.aircraftId.key,
    availabilityStartDate:
      a.aircraftAvailability && a.aircraftAvailability.startDate,
    availabilityEndDate:
      a.aircraftAvailability && a.aircraftAvailability.endDate,
    availabilityDesctiption:
      a.aircraftAvailability && a.aircraftAvailability.desctiption,
    aircraftDefects: a.aircraftDefects || [],
    aircraftTypeCode: a.aircraft.aircraftTypeCode,
    aircraftTypeColour: a.aircraft.aircraftTypeColour
      ? `#${a.aircraft.aircraftTypeColour}`
      : a.aircraft.aircraftTypeColour,
    aircraftTypeId: a.aircraft.aircraftTypeId.key,
    aircraftTypeRank: a.aircraft.aircraftTypeRank || Number.MAX_SAFE_INTEGER,
    aircraftTypeName: a.aircraft.aircraftTypeName,
    location: a.aircraftLocation && a.aircraftLocation.location,
    airportName: a.aircraftLocation && a.aircraftLocation.airportName,
    airportId:
      a.aircraftLocation &&
      a.aircraftLocation.airportId &&
      a.aircraftLocation.airportId.key,
    maintenanceStatusId:
      a.maintenanceStatusId === null
        ? MaintenanceStatus.RTS
        : a.maintenanceStatusId.key,
    numberOfSeats: a.aircraft.numberOfSeats,
    operatingCompanyId: a.aircraft.operatingCompanyId.key,
    operatingCompanyCountryCode:
      a.operatingCompany && a.operatingCompany.aocCountryCode,
    operatingCompanyName: a.operatingCompany && a.operatingCompany.aocName,
    operationEndDate: a.aircraft.operationEndDate,
    operationStartDate: a.aircraft.operationStartDate,
    owningCompanyId: a.aircraft.owningCompanyId.key,
    tailNumber: a.aircraft.tailNumber,
    totalLandings: a.statistics && a.statistics.totalLandings,
    minutesFlown: a.statistics && a.statistics.minutesFlown,
    hasMelDefects: a.hasMelDefects,
    serialNumber: a.aircraft.serialNumber,
    hasAogDrivers: a.hasAogDrivers,
    aogDescriptions: a.aogDescriptions || [],
    lastRefurbishmentDate: a.aircraft.lastRefurbishmentDate,
    manufactureDate: a.aircraft.manufactureDate,
    manufacturerId: a.aircraft.manufacturerId,
    wifi: a.aircraft.wifi,
    hasPrimaryWiFiDefects: a.hasPrimaryWiFiDefects,
    hasSwiftWiFiDefects: a.hasSwiftWiFiDefects,
    customerAffectingNotes: a.customerAffectingNotes,
    airTimeBeforeMX: a.aircraft.airTimeBeforeMX,
    landingsBeforeMX: a.aircraft.landingsBeforeMX,
    refurbishmentNotes: a.aircraft.refurbishmentNotes,
    hasPaxAffecting: a.hasPaxAffecting,
    nextDueDateMs: a.aircraft.nextDueDateMs,
  };
}

import { chain, maxBy, minBy } from 'lodash';
import EventElement, { OverlappedElements } from '../types/event-element';
import Flight from '../types/flight';

interface OverlapAcc {
  presentingElements: EventElement[];
  overlapsAcc: OverlappedElements[];
}
interface SimplefiedElementsBoundaries {
  type: 'start' | 'end';
  element: EventElement;
}

export const getOverlappedElementsForAircraft = (elements: EventElement[]) => {
  return chain(elements)
    .flatMap(e => [
      { type: 'start', element: e },
      { type: 'end', element: e },
    ])
    .orderBy(
      [(s: SimplefiedElementsBoundaries) => s.element[s.type], 'type'],
      ['asc', 'desc']
    )
    .reduce(
      (acc: OverlapAcc, n: SimplefiedElementsBoundaries, index, list) => {
        if (n.type === 'start') {
          switch (acc.presentingElements.length) {
            case 0:
              return { ...acc, presentingElements: [n.element] };
            case 1:
              return {
                ...acc,
                presentingElements: [...acc.presentingElements, n.element],
              };
            default:
              return {
                overlapsAcc: [
                  ...acc.overlapsAcc,
                  createOverlappedElements(
                    acc.presentingElements,
                    list[index - 1] &&
                      list[index - 1].element[list[index - 1].type],
                    n.element.start
                  ),
                ],
                presentingElements: [...acc.presentingElements, n.element],
              };
          }
        }
        switch (acc.presentingElements.length) {
          case 0:
            return acc;
          case 1:
            return {
              ...acc,
              presentingElements: acc.presentingElements.filter(
                e => e.id !== n.element.id
              ),
            };
          default:
            return {
              overlapsAcc: [
                ...acc.overlapsAcc,
                createOverlappedElements(
                  acc.presentingElements,
                  list[index - 1] &&
                    list[index - 1].element[list[index - 1].type],
                  n.element.end
                ),
              ],
              presentingElements: acc.presentingElements.filter(
                e => e.id !== n.element.id
              ),
            };
        }
      },
      { presentingElements: [], overlapsAcc: [] } as OverlapAcc
    )
    .value().overlapsAcc;
};

export const getPrioirtizedTypeTimeForFlight = (
  f: Flight,
  type: 'start' | 'end'
) => {
  if (
    f.departureUtcBlock &&
    f.arrivalUtcBlock &&
    f.departureUtcBlock < f.arrivalUtcBlock
  )
    return type == 'start' ? f.departureUtcBlock : f.arrivalUtcBlock;
  if (
    f.departureUtcEstimated &&
    f.arrivalUtcEstimated &&
    f.departureUtcEstimated < f.arrivalUtcEstimated
  )
    return type == 'start' ? f.departureUtcEstimated : f.arrivalUtcEstimated;
  if (
    f.departureUtcScheduled &&
    f.arrivalUtcScheduled &&
    f.departureUtcScheduled < f.arrivalUtcScheduled
  )
    return type == 'start' ? f.departureUtcScheduled : f.arrivalUtcScheduled;
};

export const getOverlappedFlightForAircraft = (
  elements: EventElement[]
): OverlappedElements[] => {
  return chain(elements)
    .flatMap(e => [
      { type: 'start', element: e },
      { type: 'end', element: e },
    ])
    .sortBy((s: SimplefiedElementsBoundaries) =>
      getPrioirtizedTypeTimeForFlight(s.element as Flight, s.type)
    )
    .reduce(
      (acc: OverlapAcc, n: SimplefiedElementsBoundaries) => {
        if (n.type === 'start') {
          switch (acc.presentingElements.length) {
            case 0:
              return { ...acc, presentingElements: [n.element] };
            case 1:
              return {
                overlapsAcc: [
                  ...acc.overlapsAcc,
                  createOverlappedFlights(
                    [...acc.presentingElements, n.element],
                    getPrioirtizedTypeTimeForFlight(
                      n.element as Flight,
                      'start'
                    )
                  ),
                ],
                presentingElements: [...acc.presentingElements, n.element],
              };
            default: {
              const lastOverlap = acc.overlapsAcc[acc.overlapsAcc.length - 1];
              lastOverlap.elements.push(n.element);
              return {
                ...acc,
                presentingElements: [...acc.presentingElements, n.element],
              };
            }
          }
        }
        switch (acc.presentingElements.length) {
          case 0:
            return acc;
          case 2: {
            const lastOverlap = acc.overlapsAcc[acc.overlapsAcc.length - 1];
            lastOverlap.end = getPrioirtizedTypeTimeForFlight(
              n.element as Flight,
              'end'
            );
            return {
              ...acc,
              presentingElements: acc.presentingElements.filter(
                e => e.id !== n.element.id
              ),
            };
          }
          default:
            return {
              ...acc,
              presentingElements: acc.presentingElements.filter(
                e => e.id !== n.element.id
              ),
            };
        }
      },
      { presentingElements: [], overlapsAcc: [] } as OverlapAcc
    )
    .value().overlapsAcc;
};

export function getOverlappedElements(
  elements: EventElement[]
): OverlappedElements[] {
  return chain(elements)
    .groupBy('aircraftId')
    .flatMap(getOverlappedElementsForAircraft)
    .value();
}
export function getOverlappedFlights(
  elements: EventElement[]
): OverlappedElements[] {
  return chain(elements)
    .groupBy('aircraftId')
    .flatMap(getOverlappedFlightForAircraft)
    .value();
}

export function createOverlappedElements(
  elements: EventElement[],
  start?: number,
  end?: number
): OverlappedElements {
  return {
    id: elements[0].id,
    start: start || maxBy(elements, 'start').start,
    end: end || minBy(elements, 'end').end,
    aircraftId: elements[0].aircraftId,
    elements: elements,
  };
}
export function createOverlappedFlights(
  elements: EventElement[],
  start: number
): OverlappedElements {
  return {
    id: elements[0].id,
    start: start,
    end: null,
    aircraftId: elements[0].aircraftId,
    elements: elements,
  };
}

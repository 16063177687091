import { Component } from 'react';
import EventElement from '../../../../types/event-element';
import * as actions from '../../../../actions';
import {
  getVerticalOffsetToElementsNode,
  ReducerShape,
} from '../../../../reducers/ui';
import { connect } from 'react-redux';
import { RootState } from '../../../../reducers';
import { OfferStatus } from '../../../../types/empty-leg-offer';
import { ActionCreator } from 'typescript-fsa';
import { SubscriptionFunction } from '../../../../data-processing/subscribe-middleware';
import { EventGroup } from './event-group';
import { NoteGroup } from './note';
import { EmptyLegGroup } from './empty-leg';
import { OneWayGroup } from './one-way';
import { MaintenanceEventGroup } from './maintenance';
import { FlightEventGroup } from './flight';
import { elementTypes, crewElements } from './element-types';
import {
  hoverNoteStateChanger,
  hoverOutNoteStateChanger,
  hoverMergedNotes,
  hoverNoteTooltip,
  unhoverNoteTooltip,
} from './note/subscriptionFunctions';
import { OverlappedMaintenances } from './overlapped-maintenances';
import { OverlappedFlightWithElOrOWOffer as OverlapFlightELOW } from './overlapped-flight-el-ow';
import { MelGroup } from './mel-items';

export interface ExtendedEventElement extends EventElement {
  y: number;
  now: number;
  canvasWidth: number;
  offerStatus?: OfferStatus;
}
export type SubscriptionTuple<P = any> = [
  ActionCreator<P>,
  SubscriptionFunction<P>
];

interface StateProps {
  offsetY: number;
  segmentsVisibility: ReducerShape['segmentsVisibility'];
  transform: ReducerShape['transform'];
}
class Segments extends Component<StateProps> {
  groupRef: SVGGElement;
  getGroupRef = ref => (this.groupRef = ref);
  render() {
    const { offsetY, segmentsVisibility, transform } = this.props;
    const { kx, ky, translateX } = transform;
    return (
      <g
        className="events-node"
        ref={this.getGroupRef}
        clipPath="url(#eventsSVGClip)"
      >
        <g
          transform={`translate(${translateX}, ${offsetY})scale(${kx}, ${ky})`}
          style={{ willChange: 'transform' }}
        >
          {elementTypes
            .filter(el => segmentsVisibility[el.elementName])
            .concat(crewElements)
            .map(el => (
              <EventGroup {...el} key={el.elementName} />
            ))}
          <MelGroup elementName="maintenanceItems" />
          {segmentsVisibility.availabilityNotes && (
            <NoteGroup
              elementName="availabilityNotes"
              ownSubscriptions={[
                [actions.userHoverSegment, hoverNoteStateChanger],
                [actions.userCloseTooltipSegment, hoverOutNoteStateChanger],
                [actions.userUnhoverOvlElement, hoverOutNoteStateChanger],
                [actions.userHoverMergedNotes, hoverMergedNotes],
                [actions.userHoverTooltipNote, hoverNoteTooltip],
                [actions.userUnhoverTooltipNote, unhoverNoteTooltip],
              ]}
            />
          )}
          <NoteGroup elementName="generalNotes" />
          {segmentsVisibility.emptyLegOffers && (
            <EmptyLegGroup elementName="emptyLegOffers" />
          )}
          {segmentsVisibility.oneWayOffers && (
            <OneWayGroup elementName="oneWayOffers" />
          )}
          {segmentsVisibility.maintenances && (
            <>
              <MaintenanceEventGroup elementName="maintenances" />
              <OverlappedMaintenances elementName="overlappedMxs" />
            </>
          )}
          <OverlapFlightELOW elementName="overlap-flight-el-ow" />
          {segmentsVisibility.flights && (
            <FlightEventGroup elementName="flights" />
          )}
        </g>
      </g>
    );
  }
}
export const TimelineSegments = connect(
  (state: RootState): StateProps => {
    const {
      holdLineHeight,
      marginTop,
      segmentsVisibility,
      transform,
    } = state.ui;
    return {
      offsetY: getVerticalOffsetToElementsNode(
        marginTop,
        holdLineHeight,
        transform.translateY
      ),
      segmentsVisibility,
      transform,
    };
  }
)(Segments);

import { PureComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import {
  BellOutlined,
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button, Popover, Badge } from 'antd';
import Flight from '../../types/flight';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import {
  DashboardWidgetList,
  DashboardWidgetType,
  getDelayedFlights,
} from '../../reducers/dashboard';
import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { AnyAction } from 'redux';

const WIDGET_HEIGHT = 40;
const overallHeight = WIDGET_HEIGHT * (DashboardWidgetList.length + 1);

interface WidgetProps {
  onClick: () => void;
  isSelected?: boolean;
  onClickNext: (n: number) => void;
  onClickPrev: (n: number) => void;
  onClose: () => void;
  currentPosition?: number;
  quantity?: number;
}
class DashboardWidget extends PureComponent<WidgetProps> {
  clickNext = () => this.props.onClickNext(this.props.quantity);
  clickPrev = () => this.props.onClickPrev(this.props.quantity);
  leftButtonstyle = {
    margin: '0 5px',
  };
  rightButtonstyle = {
    margin: '0 5px',
  };
  render() {
    const {
      isSelected,
      onClick,
      quantity = 0,
      currentPosition = 1,
    } = this.props;
    return (
      <div className="timelinebar-widget" onClick={isSelected ? null : onClick}>
        {this.props.children}
        {isSelected && (
          <>
            <Button
              shape="circle"
              type="default"
              size="small"
              icon={<LeftOutlined />}
              onClick={this.clickPrev}
              style={this.leftButtonstyle}
            />
            {currentPosition + 1}
            <Button
              shape="circle"
              type="default"
              size="small"
              icon={<RightOutlined />}
              onClick={this.clickNext}
              style={this.rightButtonstyle}
            />
          </>
        )}
        {' ' + quantity}
        {isSelected && (
          <Button
            shape="circle"
            type="default"
            size="small"
            icon={<CloseOutlined />}
            onClick={this.props.onClose}
          />
        )}
      </div>
    );
  }
}

interface StateProps {
  delayedFlights: Flight[];
  // actualMismatches: AirportMismatch[];
  selectedWidget: DashboardWidgetType;
  currentPosition: number;
}
export class Dashboard extends PureComponent<
  StateProps & DispatchProp<AnyAction>
> {
  dashboardIconStyle = {
    fontSize: '30px',
    lineHeight: '40px',
    marginRight: '10px',
    marginTop: '5px',
  };
  blinkingIconStyle = {
    fontSize: '20px',
    margin: '10px 10px 10px 5px',
    lineHeight: '20px',
  };
  dashboardPanelStyle = {
    maxHeight: `${overallHeight}px`,
  };
  activeDashboardPanelStyle = {
    minHeight: `${overallHeight}px`,
    maxHeight: `${overallHeight}px`,
  };
  selectDashboardWidget = (widgetType: DashboardWidgetType) => () =>
    this.props.dispatch(
      actions.userSelectDashboardWidget({ type: widgetType })
    );
  selectDelayFlightWidget = this.selectDashboardWidget('Delayed Flights');
  // selectMismatchWidget = this.selectDashboardWidget('Airport Mismatch');
  closeWidget = () => this.props.dispatch(actions.userCloseDashboardWidget());
  nextItemOnWidget = (n: number) =>
    this.props.dispatch(actions.userWidgetNext(n));
  prevItemOnWidget = (n: number) =>
    this.props.dispatch(actions.userWidgetPrev(n));
  render() {
    return (
      <Popover
        trigger="click"
        content={
          <div>
            <DashboardWidget
              quantity={this.props.delayedFlights.length}
              onClickNext={this.nextItemOnWidget}
              onClickPrev={this.prevItemOnWidget}
              onClose={this.closeWidget}
              onClick={
                this.props.delayedFlights.length
                  ? this.selectDelayFlightWidget
                  : null
              }
              isSelected={this.props.selectedWidget === 'Delayed Flights'}
              currentPosition={this.props.currentPosition}
            >
              Delayed Flights
            </DashboardWidget>
          </div>
        }
      >
        <div className="timelinebar-bell">
          <Badge
            count={this.props.delayedFlights.length}
            style={{ backgroundColor: '#ffc107', zIndex: 0 }}
          >
            <BellOutlined style={{ fontSize: '20px', color: 'white' }} />
          </Badge>
        </div>
      </Popover>
    );
  }
}

const advancedMap = createDelayedForScrollConnect((state: RootState) => {
  return {
    delayedFlights: getDelayedFlights(state),
    selectedWidget: state.dashboard.widgetActive,
    currentPosition: state.dashboard.focusedElement,
  };
});

export const DashboardConnected = connect(advancedMap)(Dashboard);

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers';
import { getRects, getFoundElements } from '../../../selectors';

interface ClippedRect {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

class SearchWindow extends PureComponent<{
  width: number;
  height: number;
  marginTop: number;
  rects: ClippedRect[];
  isVisible: boolean;
}> {
  static getWindowString(w, h, marginTop) {
    return `M 0 ${marginTop} h ${w} v ${h} h -${w} z`;
  }
  static getHoleString(r: ClippedRect, marginTop) {
    // if (isNaN(r.x1) || isNaN(r.x2) || isNaN(r.y1) || isNaN(r.y2)) {
    //   debugger;
    // }
    const { x1, x2 } = r;
    const y1 = Math.max(r.y1, marginTop);
    const y2 = Math.max(r.y2, marginTop);
    return `M${x1} ${y1} h ${x2 - x1} v ${y2 - y1} h ${x1 - x2}z`;
  }
  render() {
    const { width, height, marginTop, rects, isVisible } = this.props;
    let string = SearchWindow.getWindowString(width, height, marginTop);
    string += rects
      .map(d => SearchWindow.getHoleString(d, this.props.marginTop))
      .join(' ');
    return (
      isVisible && (
        <g
          className="search-clip"
          fill="white"
          fillOpacity={0.85}
          fillRule="evenodd"
        >
          <path d={string} />
        </g>
      )
    );
  }
}
export const SearchWindowConnected = connect((state: RootState) => {
  const { searchQueryAllFilters, searchType } = state.search;
  return {
    width: state.ui.width,
    height: state.ui.height,
    marginTop: state.ui.marginTop,
    rects:
      state.ui.isScrollingVertically ||
      state.ui.isScrollingHorizontally ||
      (!state.search.searchQuery && !searchQueryAllFilters[searchType])
        ? []
        : getRects(state),
    isVisible: !!getFoundElements(state).length,
  };
})(SearchWindow);

import { utc } from 'moment';
import { isHoldAircraft } from '../common/aircraft/aircraft-check-status';
import { getOrderTypeColor } from '../common/flight/flight-colorization';
import {
  DARK_TEXT_COLOR,
  HOLD_FLIGHT_HEIGHT,
  HOLD_FLIGHT_MARGIN,
  HOLD_FLIGHT_MIN_WIDTH,
  TRIP_PROFILE_MAX_LENGTH,
} from '../constants';
import { ZoomLevelForHoldFlights } from '../d3/components/zoom';
import { RootState } from '../reducers';
import { checkIfHoldFlightIsHiddenBySearch } from '../selectors';
import { ReducerShape } from '../reducers/ui';
import Aircraft from '../types/aircraft';
import EventElement from '../types/event-element';
import Flight, { HoursFlight, TimeFlightType } from '../types/flight';

export const getSvgWidthForHoldFlight = (props: {
  start: number;
  scaleX: ReducerShape['transform']['scaleX'];
  zoomLevel: ZoomLevelForHoldFlights;
  flightTimeStart: number;
  kx: number;
}): number => {
  const { flightTimeStart, kx, scaleX, start, zoomLevel } = props;
  if (zoomLevel === 'fullView') {
    return (
      scaleX(
        utc(start)
          .hours(flightTimeStart + 6)
          .minutes(0)
      ) -
      scaleX(
        utc(start)
          .hours(flightTimeStart)
          .minutes(0)
          .valueOf()
      )
    );
  }
  return HOLD_FLIGHT_MIN_WIDTH / kx;
};

export const getHoldFlightTimeStart = (fl: Flight): TimeFlightType => {
  const time = utc(fl.start).hour();
  if (time >= HoursFlight.Midnight && time < HoursFlight.SixMorning) {
    return TimeFlightType.Midnight;
  }

  if (time >= HoursFlight.SixMorning && time < HoursFlight.Midday) {
    return TimeFlightType.SixMorning;
  }

  if (time >= HoursFlight.Midday && time < HoursFlight.SixEvening) {
    return TimeFlightType.Midday;
  }

  if (time >= HoursFlight.SixEvening) {
    return TimeFlightType.SixEvening;
  }
  return TimeFlightType.Midnight;
};

export const formatDepArrTime = (time: number): string => {
  if (!time) return 'N/A';
  return utc(time)
    .format('HH:mm')
    .replace(':', '');
};
export const formatDuration = (time: number): string => {
  if (!time) return 'N/A';
  const stringed = utc(time)
    .format('HH:mm')
    .replace(':', 'h');
  const zeroed = stringed.charAt(0) === '0' ? stringed.slice(1) : stringed;
  return zeroed;
};

export const getTripProfile = (flight: Flight): string => {
  if (!flight.tripProfile) return '';
  if (flight.tripProfile.length > TRIP_PROFILE_MAX_LENGTH) {
    return flight.tripProfile.slice(0, TRIP_PROFILE_MAX_LENGTH);
  }
  return flight.tripProfile;
};

export const getNextDaySign = (depTime: number, arrTime: number): string => {
  if (!arrTime || !depTime) return ' ';
  const isNextDay = utc(arrTime).startOf('day') > utc(depTime).startOf('day');
  return isNextDay ? '+1' : ' ';
};

export const getHoursOffsetForHoldFlight = (
  timeType: TimeFlightType
): HoursFlight => {
  switch (timeType) {
    case TimeFlightType.Midnight: {
      return HoursFlight.Midnight;
    }
    case TimeFlightType.SixMorning: {
      return HoursFlight.SixMorning;
    }
    case TimeFlightType.Midday: {
      return HoursFlight.Midday;
    }
    case TimeFlightType.SixEvening: {
      return HoursFlight.SixEvening;
    }
    default:
      return null;
  }
};

export const getX = (
  transform: ReducerShape['transform'],
  start: number,
  time: HoursFlight
): number => {
  return transform.scaleX(
    utc(start)
      .hours(time)
      .minutes(0)
      .valueOf()
  );
};

export const getY = (
  aircraftId: number,
  holdAircraftPositionMap: {
    [id: number]: {
      y1: number;
      y2: number;
    };
  },
  index: number
): number => {
  const offset = holdAircraftPositionMap[aircraftId]?.y1;
  return (HOLD_FLIGHT_MARGIN + HOLD_FLIGHT_HEIGHT) * (index ?? 0) + offset;
};

export const getDay = (start: number): number => {
  if (!start) return 0;
  return utc(start)
    .startOf('day')
    .valueOf();
};

export function sortFlightsByStart(a: Flight, b: Flight) {
  return a.start - b.start;
}

export const getHoldFlightTextColor = (state: RootState, f: Flight): string => {
  if (checkIfHoldFlightIsHiddenBySearch(state, f.id)) return 'transparent';
  return f.demoFlight || f.sensitive
    ? DARK_TEXT_COLOR
    : getOrderTypeColor(f.orderBusinessTypeId).contrastTextColor;
};

export const collectPoolForTimeFlight = (flight: {
  day: number;
  pool: Flight[];
}) => {
  return flight.pool.reduce(
    (acc, fl) => {
      const timeStart = getHoldFlightTimeStart(fl);
      if (timeStart) {
        acc[timeStart] = acc[timeStart].concat(fl).sort(sortFlightsByStart);
      }
      return acc;
    },
    {
      [TimeFlightType.Midnight]: [],
      [TimeFlightType.SixMorning]: [],
      [TimeFlightType.Midday]: [],
      [TimeFlightType.SixEvening]: [],
    }
  );
};

export const isOnHoldAircraft = (
  flight: EventElement,
  aircraftById: { [aircraftId: number]: Aircraft }
) => {
  const aircraft = aircraftById[flight.aircraftId];
  return aircraft && !isHoldAircraft(aircraft);
};

export const getHoldFlightTextLabelLeft = (
  start: number,
  zoomLevel: ZoomLevelForHoldFlights,
  transform: ReducerShape['transform'],
  time: HoursFlight
): number => {
  const { scaleX } = transform;
  if (zoomLevel === 'weeklyView') {
    const today = getDay(start);
    const tomorrow = utc(today)
      .add(1, 'day')
      .toDate()
      .valueOf();
    const center = scaleX((today + tomorrow) / 2);
    const halfWidth = HOLD_FLIGHT_MIN_WIDTH / 2;
    const leftX = center - halfWidth;
    return leftX;
  }
  return getX(transform, start, time);
};

export const getHoldFlightTextLabelWidth = (
  zoomLevel: ZoomLevelForHoldFlights,
  scaleX: ReducerShape['transform']['scaleX']
): number => {
  const today = utc().toDate();
  const yesterday = utc()
    .subtract(1, 'day')
    .toDate();
  const hoursDivider = 4;
  const rightMargin = 2;
  switch (zoomLevel) {
    case 'fullView': {
      return (scaleX(today) - scaleX(yesterday)) / hoursDivider - rightMargin;
    }
    case 'weeklyView': {
      return HOLD_FLIGHT_MIN_WIDTH;
    }
    default:
      return scaleX(today) - scaleX(yesterday);
  }
};

import { Modal } from 'antd';
import { Component, ErrorInfo } from 'react';
import { sendErrorToRaygun } from '../../utils/raygun-middleware';
import { TimelineBar } from '../timeline-bar';
import { ErrorDialog } from './error-dialog';

interface State {
  hasError: boolean;
  error: Error;
}
export class ErrorBoundary extends Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      error,
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    sendErrorToRaygun(error, errorInfo.componentStack);
  }

  reloadPage = () => window.location.reload();

  render() {
    if (this.state.hasError) {
      return (
        <>
          <TimelineBar isNotPermitted />
          <ErrorDialog
            content={this.state.error.stack}
            title={this.state.error.message}
            visible
          />
        </>
      );
    }
    return this.props.children;
  }
}

import { PureComponent } from 'react';
import * as d3Scale from 'd3-scale';
import FlightPreview from './flight/';
import ElementPreview from './element/';
import { isFlight } from '../../../../common/flight/flight-check-status';
import EventElement from '../../../../types/event-element';

interface Props {
  element: EventElement;
  isFocused: boolean;
  y: number;
  scale: d3Scale.ScaleTime<number, number>;
  onFocus: () => void;
  onBlur: () => void;
}

export default class Preview extends PureComponent<Props> {
  render() {
    const { element } = this.props;
    if (isFlight(element))
      return <FlightPreview {...this.props} element={element} />;
    return <ElementPreview {...this.props} />;
  }
}

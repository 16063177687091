import { RootState } from '../reducers';
import { utc } from 'moment';
import { includes, isNumber, sortBy, uniqBy } from 'lodash';
import createCachedSelector from 're-reselect';
import { SegmentType } from '../types/segment-types';
import { getSelectedStatusesIds } from '../reducers/operational-statuses';
import {
  getSelectedEmptyLegIds,
  getSelectedOneWayIds,
} from '../reducers/empty-legs';
import EventElement from '../types/event-element';
import OneWayOffer from '../types/one-way-offer';
import Flight, { OperationalStatus } from '../types/flight';
import EmptyLegOffer from '../types/empty-leg-offer';
import { CrewDuty } from '../types/crew-roster';
import MaintenanceItem from '../types/maintenance-item';
import { getAirportAffectingElementsByType } from './airport-mismatch';
import { createSelector } from 'reselect';
import { Pool } from '../data-processing/pool-structure';
import { getRosterFromCrewArray } from '../utils/crew-roster';

export const getCrewRosterFromCrewDuties = createCachedSelector(
  (duties: CrewDuty[], key) => duties,
  crewDuties => getRosterFromCrewArray(crewDuties)
)((state, key) => key);

export const getElementsInVisibleTimeRange = createCachedSelector(
  (state: RootState) => state.ui.transform.scaleX.domain()[0].valueOf(),
  (state: RootState) => state.ui.transform.scaleX.domain()[1].valueOf(),
  (_state: RootState, type: SegmentType) => type,
  (state: RootState, type: SegmentType) =>
    type === 'groundTime' ||
    type === 'airportMismatches' ||
    type === 'fboMismatches'
      ? getAirportAffectingElementsByType(state, type)
      : state.timelineEvents[type],
  (firstVisibleTime, lastVisibleTime, type, days) => {
    const firstVisibleDay = utc(firstVisibleTime)
      .startOf('d')
      .valueOf();
    const lastVisibleDay = utc(lastVisibleTime)
      .endOf('d')
      .valueOf();
    if (
      type === 'groundTime' ||
      type === 'airportMismatches' ||
      type === 'fboMismatches' ||
      type === 'crewAssignment'
    ) {
      const data = days as EventElement[];
      return data.filter(
        d => d.start < lastVisibleDay && d.end > firstVisibleDay
      );
    }
    if (type === 'crewRoster') {
      const data = days as CrewDuty[];
      return getCrewRosterFromCrewDuties(data, '').filter(
        d => d.start < lastVisibleDay && d.end > firstVisibleDay
      );
    }
    if (type == 'maintenanceItems') {
      return (days as MaintenanceItem[]).filter(
        mel => mel.start <= lastVisibleDay && mel.end >= firstVisibleDay
      );
    }

    return uniqBy(
      (days as Pool<EventElement>)
        .filter(day => {
          return day.day >= firstVisibleDay && day.day <= lastVisibleDay;
        })
        .reduce((acc, el) => acc.concat(el.pool), []),

      (el: EventElement) => el.id
    );
  }
)((state, type) => type);

export const elementsSelector = createCachedSelector(
  (state: RootState) => state.ui.transform.scaleX.domain()[0].valueOf(),
  (state: RootState) => state.ui.transform.scaleX.domain()[1].valueOf(),
  (_state: RootState, type: SegmentType) => type,
  (state: RootState, type: SegmentType) =>
    type === 'groundTime' ||
    type === 'airportMismatches' ||
    type === 'fboMismatches'
      ? getAirportAffectingElementsByType(state, type)
      : state.timelineEvents[type],
  getSelectedStatusesIds,
  getSelectedEmptyLegIds,
  getSelectedOneWayIds,
  (
    firstVisibleTime,
    lastVisibleTime,
    type,
    days,
    visibleOpStatuses,
    visibleEmptyLegs,
    visibleOneWay
  ) => {
    let finalResult = [];
    const firstVisibleDay = utc(firstVisibleTime)
      .startOf('d')
      .valueOf();
    const lastVisibleDay = utc(lastVisibleTime)
      .endOf('d')
      .valueOf();
    if (
      type === 'groundTime' ||
      type === 'airportMismatches' ||
      type === 'fboMismatches' ||
      type === 'crewAssignment'
    ) {
      const data = days as EventElement[];
      finalResult = data.filter(
        d => d.start < lastVisibleDay && d.end > firstVisibleDay
      );
      return finalResult;
    }
    if (type === 'crewRoster') {
      const data = days as CrewDuty[];
      return getCrewRosterFromCrewDuties(data, '').filter(
        d => d.start < lastVisibleDay && d.end > firstVisibleDay
      );
    }
    if (type == 'maintenanceItems') {
      return (days as MaintenanceItem[]).filter(
        mel =>
          mel.isActive &&
          mel.clearedTimestamp === null &&
          mel.start <= lastVisibleDay &&
          mel.end >= firstVisibleDay
      );
    }
    const result = uniqBy(
      (days as Pool<EventElement>)
        .filter(day => {
          return day.day >= firstVisibleDay && day.day <= lastVisibleDay;
        })
        .reduce((acc, el) => acc.concat(el.pool), []),

      (el: EventElement) => el.id
    );

    if (type === 'emptyLegOffers') {
      finalResult = result.filter((element: EmptyLegOffer) => {
        return includes(visibleEmptyLegs, element.offerStatus);
      });
      return finalResult;
    }
    if (type === 'oneWayOffers') {
      finalResult = result.filter((element: OneWayOffer) => {
        return includes(visibleOneWay, element.offerStatus);
      });
      return finalResult;
    }
    if (type === 'maintenances') {
      finalResult = sortBy(result, m => m.start);
      return finalResult;
    }
    if (type !== 'flights') {
      finalResult = result;
      return finalResult;
    }
    if (visibleOpStatuses.length === 0) return [];

    finalResult = result.filter((element: Flight) => {
      return includes(visibleOpStatuses, element.legOperationalStatusId);
    });
    return finalResult;
  }
)((state, type) => type);

export const getTouchedByFlightAircraftMap = createSelector(
  (state: RootState) => elementsSelector(state, 'flights'),
  (flights: Flight[] = []) =>
    flights.reduce<{ [aircraftId: number]: boolean }>((acc, f) => {
      acc[f.aircraftId] = true;
      return acc;
    }, {})
);

export const isFlightVisible = <
  F extends Flight,
  O extends OperationalStatus
>(props: {
  f: F;
  visibleOperationalStatuses: O[];
  aircraftIndexMap: { [aircraftId: number]: number };
}) => {
  const { f, aircraftIndexMap, visibleOperationalStatuses } = props;
  if (!visibleOperationalStatuses.some(st => f.legOperationalStatusId === st))
    return false;
  if (!isNumber(aircraftIndexMap[f.aircraftId])) return false;
  return true;
};
export const isCrewElementVisible = (
  aircraftIndexMap: { [aircraftId: number]: number },
  aircraftId: number
): boolean => {
  if (!isNumber(aircraftIndexMap[aircraftId])) {
    return false;
  }
  return true;
};

import * as d3S from 'd3-selection';
import { store } from '../../../../../root';
import { EventGroup } from '../event-group';
import { getInitialScale, getLaneHeightKoef } from '../../../../../reducers/ui';
import Maintenance, {
  MaintenanceCategoryType,
} from '../../../../../types/maintenance';
import { ExtendedEventElement } from '..';
import {
  getMaintenanceSegmentColor,
  getMaintenanceBorderColor,
  getMaintenanceIsDispatchedGradient,
} from '../../../../../common/maintenance/maintenance-colorization';

export class MaintenanceEventGroup extends EventGroup {
  renderEntered(
    entered: d3S.Selection<
      d3S.EnterElement,
      ExtendedEventElement & Maintenance,
      SVGGElement,
      {}
    >
  ) {
    const state = store.getState();
    const { ui, aircraft } = state;
    const { segmentsVisibility, positionMap, width, planeBlockWidth } = ui;
    const { togglersState } = aircraft;
    const scaleX = getInitialScale(width - planeBlockWidth);
    const maintenanceEntered = entered;
    const rectGroup = maintenanceEntered.append('g').classed('event', true);
    rectGroup
      .append('rect')
      .attr('fill', d =>
        getMaintenanceSegmentColor(
          (d as Maintenance).maintenanceOrderType,
          (d as Maintenance).maintenanceType,
          d.isDispatchable
        )
      )
      .attr('x', d => scaleX(d.start))
      .attr('y', d => d.y)
      .attr('width', d => this.rectWidthCalculator(d, scaleX))
      .attr(
        'height',
        m =>
          positionMap[this.props.elementName] /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[m.aircraftId],
            positionMap
          )
      )
      .attr('stroke-width', d =>
        d.categoryId === MaintenanceCategoryType.NORMAL ? 1 : 2
      )
      .attr('stroke-dasharray', d =>
        d.categoryId === MaintenanceCategoryType.PROVISIONAL ? 4 : 0
      )
      .attr('stroke', d =>
        getMaintenanceBorderColor(
          d.categoryId,
          (d as Maintenance).maintenanceOrderType,
          (d as Maintenance).maintenanceType,
          d.isDispatchable
        )
      )
      .attr('vector-effect', 'non-scaling-stroke');
    rectGroup
      .filter(d => d.isDispatchable)
      .append('rect')
      .attr('fill', d => getMaintenanceIsDispatchedGradient(d))
      .attr('x', d => scaleX(d.start))
      .attr('y', d => d.y)
      .attr('width', d => this.rectWidthCalculator(d, scaleX))
      .attr(
        'height',
        m =>
          positionMap[this.props.elementName] /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[m.aircraftId],
            positionMap
          )
      );
    return rectGroup;
  }
}

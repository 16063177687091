import EventElement from './event-element';

export enum OfferStatus {
  TRANSIENT = 1,
  NEW,
  ADVERTISED,
  CANCELLED,
  RESERVED,
  SOLD,
}
export interface EmptyLegRoute {
  id: number;
  departureAirportId: number;
  arrivalAirportId: number;
  amountCents?: number;
  createdTime: number;
  validFrom: number;
  validTo: number;
  fspValidFrom: number;
  fspValidTo: number;
  avinodeValidFrom: number;
  avinodeValidTo: number;
  creator: {
    id: number;
    firstName: string;
    lastName: string;
  };
}
export default interface EmptyLegOffer extends EventElement {
  offerStatus: OfferStatus;
  departureAirportId: number;
  arrivalAirportId: number;
  emptyLegRoutes: EmptyLegRoute[];
  cancellationReason: string;
}

import { capitalize } from 'lodash';
import { utc } from 'moment';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getTimezoneOffset } from '../../common/flight/flight-labels';
import { RootState } from '../../reducers';
import { getTimezonesByAirportIdSelector } from '../../selectors/timezones';
import Timezone from '../../types/timezone';
import { universalDateAndTimeFormatter } from '../../utils/time';

interface Props {
  departureUtc: number;
  arrivalUtc: number;
  departureAirportId: number;
  arrivalAirportId: number;
  durationString: string;
  title: 'scheduled' | 'estimated' | 'block';
}

interface ConnectedProps {
  depTimezones: Timezone[];
  arrTimezones: Timezone[];
}

class TimeGroup extends PureComponent<Props & ConnectedProps> {
  getUtcDate(time: number) {
    return time ? universalDateAndTimeFormatter(utc(time), 'date') : 'N/A';
  }
  getUtcTime(time: number) {
    return time && universalDateAndTimeFormatter(utc(time), 'timeUtc');
  }
  getLocalDate(time: number, timezones: Timezone[]) {
    if (!time) {
      return 'N/A';
    }
    return timezones
      ? universalDateAndTimeFormatter(
          utc(time).utcOffset(getTimezoneOffset(timezones, time)),
          'date'
        )
      : 'No Timezone';
  }
  getLocalTime(time: number, timezones: Timezone[]) {
    return timezones && time
      ? universalDateAndTimeFormatter(
          utc(time).utcOffset(getTimezoneOffset(timezones, time)),
          'timeLocal'
        )
      : '';
  }
  render() {
    const {
      arrivalUtc,
      departureUtc,
      depTimezones,
      arrTimezones,
      title,
    } = this.props;
    return (
      <div
        data-test-entity={`ft-${title}-time-group`}
        className={`ft-${title}-time-group`}
      >
        <div className="ft-time-group">
          <div className="ft-date-and-time-left">
            <div>{this.getUtcDate(departureUtc)}</div>
            <div className="ft-time-bold">{this.getUtcTime(departureUtc)}</div>
          </div>
          <div className="ft-title">{capitalize(title)}</div>
          <div className="ft-date-and-time-right">
            <div>{this.getUtcDate(arrivalUtc)}</div>
            <div className="ft-time-bold">{this.getUtcTime(arrivalUtc)}</div>
          </div>
        </div>

        <div className="ft-time-group">
          <div className="ft-date-and-time-left">
            <div>{this.getLocalDate(departureUtc, depTimezones)}</div>
            <div className="ft-time-bold">
              {this.getLocalTime(departureUtc, depTimezones)}
            </div>
          </div>

          <div className="ft-duration">{this.props.durationString}</div>

          <div className="ft-date-and-time-right">
            <div>{this.getLocalDate(arrivalUtc, arrTimezones)}</div>
            <div className="ft-time-bold">
              {this.getLocalTime(arrivalUtc, arrTimezones)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const TimeGroupConnected = connect(
  (state: RootState, { departureAirportId, arrivalAirportId }: Props) => ({
    depTimezones: getTimezonesByAirportIdSelector(state, departureAirportId),
    arrTimezones: getTimezonesByAirportIdSelector(state, arrivalAirportId),
  })
)(TimeGroup);

export default TimeGroupConnected;

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import { each } from 'lodash';
import Airport from '../types/airport';

export interface AirportsState {
  airportsByIcao: { [ICAO: string]: Airport };
  airportsById: { [id: number]: Airport };
  loading: boolean;
  airportsLoadingComplete: boolean;
}
export const initialState: AirportsState = {
  airportsByIcao: {},
  airportsById: {},
  loading: false,
  airportsLoadingComplete: false,
};

export default reducerWithInitialState(initialState)
  .case(actions.doAirportsMappersFetch.started, state => ({
    ...state,
    loading: true,
  }))
  .case(actions.doAirportsMappersFetch.done, (state, { result }) => ({
    ...state,
    loading: false,
    airportsLoadingComplete: true,
    airportsByIcao: result.reduce((acc, e) => {
      acc[e.ICAO] = e;
      return acc;
    }, {}),
    airportsById: result.reduce((acc, e) => {
      acc[e.id] = e;
      return acc;
    }, {}),
  }))
  .case(actions.doAirportsMappersFetch.failed, state => ({
    ...state,
    loading: false,
  }))
  .case(actions.wsUpdateBatch, (state, payload) => {
    if (!payload.data.airport) return state;
    const byICAO = { ...state.airportsByIcao };
    each(payload.data.airport, airport => {
      if (airport) {
        byICAO[airport.ICAO] = airport;
      }
    });
    return {
      ...state,
      airportsByIcao: byICAO,
      airportsById: { ...state.airportsById, ...payload.data.airport },
    };
  });

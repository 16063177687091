import { Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AIRCRAFT_PROFILE_URL } from '../../../constants/environment';
import { NearestAircraftTableData } from '../../../types/nearest-aircraft';
import { formatNearestAcDuration, formatNearestAcFerryTime } from '../utils';
import { ActionButtonsConnected } from './action-buttons';

export const NEAREST_AIRCRAFT_COLUMNS = (
  flightTime: number,
  isVerticalMode: boolean
): Array<ColumnProps<NearestAircraftTableData>> => [
  {
    dataIndex: 'tailNumber',
    key: 'tailNumber',
    title: 'Tail#',
    width: isVerticalMode ? 76 : 130,
    render: (
      tailNumber: NearestAircraftTableData['tailNumber'],
      { aircraftId }
    ) => (
      <a
        href={`${AIRCRAFT_PROFILE_URL}/aircraft-details/gv/${aircraftId}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {tailNumber}
      </a>
    ),
  },
  {
    dataIndex: 'ferryTimeMs',
    key: 'ferryTimeMs',
    title: 'Ferry Time',
    width: isVerticalMode ? 64 : 130,
    render: (ferryTimeMs: NearestAircraftTableData['ferryTimeMs']) => (
      <>{ferryTimeMs !== null ? formatNearestAcFerryTime(ferryTimeMs) : ''}</>
    ),
  },
  {
    dataIndex: 'lastFlightTimeMs',
    key: 'lastFlightTimeMs',
    title: 'Last',
    width: isVerticalMode ? 105 : 130,
    render: (
      lastFlightTimeMs: NearestAircraftTableData['lastFlightTimeMs']
    ) => (
      <>
        {formatNearestAcDuration({
          date: lastFlightTimeMs,
          key: 'lastFlightTimeMs',
          flightTime,
        })}
      </>
    ),
  },
  {
    dataIndex: 'location',
    key: 'location',
    title: 'Location',
    width: isVerticalMode ? 67 : 130,
    render: (location: NearestAircraftTableData['location']) => <>{location}</>,
  },
  {
    dataIndex: 'nextFlightTimeMs',
    key: 'nextFlightTimeMs',
    title: 'Next',
    width: isVerticalMode ? 100 : 130,
    render: (
      nextFlightTimeMs: NearestAircraftTableData['nextFlightTimeMs']
    ) => (
      <>
        {formatNearestAcDuration({
          date: nextFlightTimeMs,
          key: 'nextFlightTimeMs',
          flightTime,
        })}
      </>
    ),
  },
  {
    dataIndex: 'nextRouting',
    key: 'nextRouting',
    title: 'Next Routing',
    width: isVerticalMode ? 100 : 130,
    render: (nextRouting: NearestAircraftTableData['nextRouting']) => (
      <>{nextRouting}</>
    ),
  },
  {
    dataIndex: 'customerName',
    key: 'customerName',
    title: 'Customer',
    width: isVerticalMode ? 100 : 130,
    render: (customerName: NearestAircraftTableData['customerName']) => (
      <Tooltip title={customerName} placement="topLeft">
        <div className="nearest-ac-modal-body-table-cell-ellipsis">
          {customerName}
        </div>
      </Tooltip>
    ),
  },
  {
    dataIndex: 'flightLegId',
    key: 'flightLegId',
    title: 'Flight ID',
    width: isVerticalMode ? 120 : 200,
    render: (flightLegId: NearestAircraftTableData['flightLegId'], record) => (
      <>{flightLegId && <ActionButtonsConnected record={record} />}</>
    ),
  },
  {
    dataIndex: 'notes',
    key: 'notes',
    title: 'Notes',
    width: 750,
    ellipsis: true,
    render: (notes: NearestAircraftTableData['notes']) => (
      <Tooltip title={notes} placement="topLeft">
        <div className="nearest-ac-modal-body-table-cell-ellipsis">{notes}</div>
      </Tooltip>
    ),
  },
];

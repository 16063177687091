import { useQuery } from '@apollo/client';
import { Spin } from 'antd';
import { FC } from 'react';
import { isProgramFlight } from '../../common/flight/flight-check-status';
import { getVJ25Label } from '../../common/flight/flight-labels';
import { GET_CONTRACT_SUB_PROGRAM_TYPE } from '../../services/sub-program-type-service/get-sub-program-type';
import { BookedBy, OrderBusinessTypeId } from '../../types/flight';
import {
  GetContractSubProgramType,
  GetContractSubProgramTypeVariables,
} from '../../services/sub-program-type-service/__generated__/get-sub-program-type';

interface Props {
  flightId: number;
  requestedAircraftTypeName: string;
  bookedBy: BookedBy;
  contractMsId: string;
  orderBusinessTypeId: OrderBusinessTypeId;
}

const AdditionalInfoRow: FC<Props> = props => {
  const {
    requestedAircraftTypeName,
    bookedBy,
    contractMsId,
    orderBusinessTypeId,
    flightId,
  } = props;

  const { data, loading } = useQuery<
    GetContractSubProgramType,
    GetContractSubProgramTypeVariables
  >(GET_CONTRACT_SUB_PROGRAM_TYPE, {
    variables: {
      contractId: contractMsId,
      flightId,
    },
    skip: !isProgramFlight(orderBusinessTypeId),
  });
  const { subProgramType, flightId: responseFlightId } =
    data?.getContractSubProgramType || {};

  return (
    <div className="ft-box-group">
      <div className="ft-box">
        <div className="ft-box-label">Requested A/C</div>
        <div>{requestedAircraftTypeName}</div>
      </div>

      <div className="ft-box-centered">
        <div>
          {loading ? (
            <Spin />
          ) : (
            getVJ25Label(flightId === responseFlightId ? subProgramType : null)
          )}
        </div>
      </div>

      <div className="ft-box-wide">
        <div className="ft-box-label">Booked by</div>
        <div>
          {bookedBy ? `${bookedBy.firstName} ${bookedBy.lastName}` : 'N/A'}
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfoRow;

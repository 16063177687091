import { Fragment, PureComponent, ReactNode } from 'react';
import { utc } from 'moment';
import EmptyLegOffer from '../../../../types/empty-leg-offer';
import Airport from '../../../../types/airport';
import { EmptyLegRoute } from '../../../../types/empty-leg-offer';
import TooltipDates from '../../dates';
import TooltipCreationLogTime from '../../creation-log-time';
import TooltipHeader from '../../header/';
import { getBackgroundColorToEmptyLeg } from '../../../../common/empty-leg-and-one-way/empty-leg-one-way-colorization';

import TooltipCancellationReason from '../../cancellation-reason';
import { setDataTestEntityId } from '../../../../utils';
import { getICAOLabelFromAirport } from '../../../../utils/airport';
import { getOfferStatusName } from '../../../../utils/empty-leg-offer';

interface ConnectedProps {}
interface OwnProps {
  segmentData: EmptyLegOffer;
  airportsById: { [id: number]: Airport };
}
interface Props extends ConnectedProps, OwnProps {}

export default class TooltipEmptyLegCommon extends PureComponent<Props> {
  getLabel(route: EmptyLegRoute, timeFrom: number, timeTo: number) {
    if (route && timeFrom && timeTo) {
      return `${utc(timeFrom).format('DD MMM, HH:mm')} — ${utc(timeTo).format(
        'DD MMM, HH:mm'
      )}`;
    } else return 'Not included';
  }

  getValidLabel(route) {
    return this.getLabel(route, route.validFrom, route.validTo);
  }

  getFspLabel(route) {
    return this.getLabel(route, route.fspValidFrom, route.fspValidTo);
  }

  getAvinodeLabel(route) {
    return this.getLabel(route, route.avinodeValidFrom, route.avinodeValidTo);
  }

  getAmountString(amount: number): string {
    if (amount) return `$${(amount / 100).toFixed(2)}`;
    return '';
  }

  getAdditionalPermitElements(sortedEmptyLegOffers): ReactNode {
    const { airportsById } = this.props;
    return sortedEmptyLegOffers.map((r: EmptyLegRoute, index: number) => (
      <Fragment key={index}>
        <div className="tooltip-routes-values">
          <div
            className="tooltip-routes-values-group"
            {...setDataTestEntityId('tooltip-routes-values-group')}
          >
            <div
              className="tooltip-icao-dep"
              {...setDataTestEntityId('tooltip-routes-icao-dep')}
            >
              {getICAOLabelFromAirport(airportsById[r.departureAirportId])}
            </div>
            <div className="tooltip-line">&mdash;</div>
            <div
              className="tooltip-icao-arr"
              {...setDataTestEntityId('tooltip-routes-icao-arr')}
            >
              {getICAOLabelFromAirport(airportsById[r.arrivalAirportId])}
            </div>
          </div>
          <div
            className="tooltip-amount"
            {...setDataTestEntityId('tooltip-routes-amount')}
          >
            {this.getAmountString(r.amountCents)}
          </div>
        </div>
      </Fragment>
    ));
  }

  render() {
    const { segmentData } = this.props;
    const {
      start,
      end,
      emptyLegRoutes,
      id,
      offerStatus,
      cancellationReason,
    } = segmentData;
    const firstEmptyLegRoute = emptyLegRoutes[0];
    const { creator, createdTime } = firstEmptyLegRoute;
    const updatedBy = creator && `${creator.firstName} ${creator.lastName}`;
    return (
      <>
        <TooltipHeader
          id={id}
          color="rgba(0, 0, 0, 0.85)"
          backgroundColor={getBackgroundColorToEmptyLeg(offerStatus)}
          name={`Empty Leg Offer • ${getOfferStatusName(offerStatus)}`}
        />
        <div className="tooltip-common">
          <TooltipCreationLogTime
            isUpdated={false}
            lastUpdated={createdTime}
            updatedBy={updatedBy}
          />
          <div className="tooltip-routes">Routes</div>
          {emptyLegRoutes.length > 0 &&
            this.getAdditionalPermitElements(emptyLegRoutes)}
          <div className="tooltip-vj-direct">
            <div className="tooltip-vj-direct-label">VJ Direct</div>
            <div className="tooltip-vj-direct-value">
              {this.getValidLabel(firstEmptyLegRoute)}
            </div>
          </div>
          <div className="tooltip-fsp">
            <div className="tooltip-fsp-label">FSP</div>
            <div className="tooltip-fsp-value">
              {this.getFspLabel(firstEmptyLegRoute)}
            </div>
          </div>
          <div className="tooltip-avinode">
            <div className="tooltip-avinode-label">Avinode</div>
            <div className="tooltip-avinode-value">
              {this.getAvinodeLabel(firstEmptyLegRoute)}
            </div>
          </div>
          {cancellationReason && (
            <TooltipCancellationReason text={cancellationReason} />
          )}
          <TooltipDates start={start} end={end} />
        </div>
      </>
    );
  }
}

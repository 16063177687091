import Popper from 'popper.js';
import { MouseEvent, ReactNode, PureComponent, CSSProperties } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import { TOP_BAR_HEIGHT } from '../../../constants';
import { RootState } from '../../../reducers';
import { subMiddle } from '../../../root';
import LocalTimeTooltipContentConnected from './local-time-tooltip-content';
import MainTooltipContentConnected from './main-tooltip-content';
import MelTooltipContentConnected from './mel-tooltip-content';
import MxDueTooltipContentConnected from './mx-due-tooltip-content';
import PaxTooltipContentConnected from './pax-tooltip';
import {
  tooltipMediumWidthStyles,
  tooltipMxDueStyles,
  tooltipNarrowWidthStyles,
  tooltipWideStyles,
} from './styles';

export type HoveredTailPart = 'mel' | 'local-time' | 'main' | 'mx-due' | 'pax';

class TooltipTailComponent extends PureComponent<
  {
    hoveredTailPart: HoveredTailPart;
  },
  { timer: number }
> {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
    };
  }
  popper: Popper;
  createPopper = action => {
    clearTimeout(this.state.timer);
    const tooltipWrapper = document.getElementsByClassName(
      'custom-tooltip'
    )[0] as HTMLDivElement;
    const actionRef = action.payload.ref;
    if (tooltipWrapper && actionRef) {
      this.popper = new Popper(actionRef, tooltipWrapper, {
        placement: 'right-start',
        modifiers: {
          preventOverflow: { enabled: true },
          computeStyle: { gpuAcceleration: false },
        },
        positionFixed: true,
      });
    }
  };
  onMouseOver = (e: MouseEvent) => {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
      this.setState({ timer: null });
    }
  };
  removePopper = () => {
    if (this.popper) {
      this.popper.destroy();
      delete this.popper;
    }
  };
  removePopperWithTimeout = () => {
    this.setState({
      timer: window.setTimeout(() => this.removePopper(), 500),
    });
  };
  getTooltipByHoveredPart(): ReactNode {
    switch (this.props.hoveredTailPart) {
      case 'main': {
        return <MainTooltipContentConnected />;
      }
      case 'mel': {
        return <MelTooltipContentConnected />;
      }
      case 'local-time': {
        return <LocalTimeTooltipContentConnected />;
      }
      case 'mx-due': {
        return <MxDueTooltipContentConnected />;
      }
      case 'pax': {
        return <PaxTooltipContentConnected />;
      }
      default:
        return null;
    }
  }
  getStyle(): CSSProperties {
    const windowHeight = window.innerHeight - TOP_BAR_HEIGHT;
    switch (this.props.hoveredTailPart) {
      case 'local-time': {
        return tooltipMediumWidthStyles;
      }
      case 'mel': {
        return { ...tooltipWideStyles, maxHeight: `${windowHeight}px` };
      }
      case 'mx-due': {
        return tooltipMxDueStyles;
      }
      case 'main': {
        return {
          ...tooltipNarrowWidthStyles,
          maxHeight: `${windowHeight}px`,
        };
      }
      default:
        return tooltipNarrowWidthStyles;
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.removePopper);
    subMiddle.on(actions.userHoverTailBlock, this.createPopper);
    subMiddle.on(actions.userUnhoverTailTooltip, this.removePopper);
    subMiddle.on(
      actions.userLeaveTailTooltipWithDebounce,
      this.removePopperWithTimeout
    );
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.removePopper);
  }
  render() {
    return (
      <div
        className="custom-tooltip"
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.removePopper}
        style={this.getStyle()}
      >
        {this.getTooltipByHoveredPart()}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  hoveredTailPart: state.ui.hoveredAircraftPart,
});
const TooltipAircraft = connect(mapStateToProps)(TooltipTailComponent);

export default TooltipAircraft;

import DatePicker from './date-picker';
import { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import { Moment } from 'moment';
import { forwardRef } from 'react';

export interface TimePickerProps
  extends Omit<PickerTimeProps<Moment>, 'picker'> {}

const TimePickerOnMoment = forwardRef<any, TimePickerProps>((props, ref) => (
  <DatePicker {...props} picker="time" ref={ref} />
));

TimePickerOnMoment.displayName = 'TimePicker';

export default TimePickerOnMoment;

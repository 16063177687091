import { PureComponent } from 'react';
import { setDataTestEntityId } from '../../utils';
interface Props {
  onClickCreateMxEvent: () => void;
  onClickCreateNote: () => void;
  onClickCreateOrder: () => void;
  onClickCreateEmptyLeg?: () => void;
  onClickSendArincMessage?: () => void;
  onClickCreatePeakDay?: () => void;
  onClickHandover?: () => void;
  hasCreateOrderPermission: boolean;
  hasCreateNotePermission: boolean;
  hasCreateMxEventPermission: boolean;
  hasCreateEmptyLegPermission?: boolean;
  hasCreatePeakDayPermission?: boolean;
  createFromTopBar?: boolean;
  hasArincPermission?: boolean;
  hasHandoverViewPermission?: boolean;
  isHoldAircraft?: boolean;
}
export class CreateEventsMenuCard extends PureComponent<Props> {
  render() {
    const names = getItemName(this.props.createFromTopBar);
    const {
      hasCreateMxEventPermission,
      hasCreateNotePermission,
      hasCreateOrderPermission,
      onClickCreateMxEvent,
      onClickCreateNote,
      onClickCreateOrder,
      onClickCreateEmptyLeg,
      onClickSendArincMessage,
      onClickCreatePeakDay,
      onClickHandover,
      hasCreatePeakDayPermission,
      hasCreateEmptyLegPermission,
      createFromTopBar,
      hasArincPermission,
      hasHandoverViewPermission,
      isHoldAircraft,
    } = this.props;
    return (
      <ul className="context-menu">
        {hasCreateOrderPermission && (
          <li
            onClick={onClickCreateOrder}
            {...setDataTestEntityId(names.order)}
          >
            <span className="context-menu-item-name">{names.order}</span>
          </li>
        )}
        {!isHoldAircraft && (
          <>
            {hasCreateNotePermission && (
              <li
                onClick={onClickCreateNote}
                {...setDataTestEntityId(names.note)}
              >
                <span className="context-menu-item-name">{names.note}</span>
              </li>
            )}
            {hasCreateMxEventPermission && (
              <li
                onClick={onClickCreateMxEvent}
                {...setDataTestEntityId(names.mx)}
              >
                <span className="context-menu-item-name">{names.mx}</span>
              </li>
            )}
            {hasCreateEmptyLegPermission && (
              <li
                onClick={onClickCreateEmptyLeg}
                {...setDataTestEntityId(names.emptyLeg)}
              >
                <span className="context-menu-item-name">{names.emptyLeg}</span>
              </li>
            )}
            {!createFromTopBar && hasArincPermission && (
              <li
                onClick={onClickSendArincMessage}
                {...setDataTestEntityId('arinc-email')}
              >
                <span className="context-menu-item-name">
                  Send ARINC Message
                </span>
              </li>
            )}
            {hasCreatePeakDayPermission && (
              <li
                onClick={onClickCreatePeakDay}
                {...setDataTestEntityId('peak-day')}
              >
                <span className="context-menu-item-name">Peak day</span>
              </li>
            )}
          </>
        )}
        {hasHandoverViewPermission && (
          <li
            onClick={onClickHandover}
            {...setDataTestEntityId('tail-handover')}
          >
            <span className="context-menu-item-name">Tail Ops Handover</span>
          </li>
        )}
      </ul>
    );
  }
}

interface ItemsNames {
  order: string;
  note: string;
  mx: string;
  emptyLeg: string;
}

function getItemName(isFromTopBar: boolean): ItemsNames {
  const itemsNames: ItemsNames = {
    order: 'Order',
    note: 'Note',
    mx: 'Maintenance Event',
    emptyLeg: 'Empty Leg',
  };
  if (!isFromTopBar) {
    return Object.keys(itemsNames).reduce((acc, item) => {
      acc[item] = 'Create ' + itemsNames[item];
      return acc;
    }, {} as ItemsNames);
  }
  return itemsNames;
}

import { PureComponent, FC } from 'react';
import * as d3Scale from 'd3-scale';
import { connect, DispatchProp } from 'react-redux';
import { OverlapMap } from './overlap-map';
import EventElement, { OverlappedElements } from '../../types/event-element';
import { maxBy, minBy } from 'lodash';
import { RootState } from '../../reducers';
import Cards from './cards/';
import { duration } from 'moment';
import * as actions from '../../actions';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { isFlight } from '../../common/flight/flight-check-status';
import { AnyAction } from 'redux';

const Header: FC = () => (
  <div
    style={{
      color: 'rgba(0, 0, 0, 0.85)',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '20px',
    }}
  >
    Overlapped Elements
  </div>
);
interface TooltipProps {
  placement: TooltipPlacement;
  flightTooltipWidth: number;
  isVerticalMode: boolean;
  boundingRect?: DOMRect;
}
interface StateProps {
  start: number;
  end: number;
  overlappedElements: EventElement[];
}
interface State {
  focused: number;
}
class OverlapTooltip extends PureComponent<
  StateProps & DispatchProp<AnyAction> & TooltipProps,
  State
> {
  state = {
    focused: null,
  };
  onFocus = (index: number) => () => {
    this.setState({
      focused: index,
    });
    this.props.dispatch(actions.userFocusOverlapCards());
  };
  onBlur = () => {
    this.setState({
      focused: null,
    });
  };
  render() {
    let { start, end } = this.props;
    const {
      overlappedElements,
      placement,
      flightTooltipWidth,
      isVerticalMode,
      boundingRect,
    } = this.props;
    const outOfAxisRangeValue = duration(end - start).asDays() - 7;
    if (outOfAxisRangeValue > 0) {
      start += outOfAxisRangeValue * 12 * 3600 * 1000;
      end -= outOfAxisRangeValue * 12 * 3600 * 1000;
    }
    const { focused } = this.state;
    const scale = d3Scale
      .scaleTime<number, number>()
      .domain([start, end])
      .range([100, 300]);
    return (
      <div
        style={{
          userSelect: 'none',
          MozUserSelect: '-moz-none',
          WebkitUserSelect: 'none',
        }}
      >
        <Header />
        <OverlapMap
          overlappedElements={overlappedElements}
          scale={scale}
          lineHeight={22}
          mapWidth={420}
          focused={focused}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          placement={placement}
        />
        <Cards
          overlappedElements={overlappedElements}
          focused={focused}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          placement={placement}
          flightTooltipWidth={flightTooltipWidth}
          isVerticalMode={isVerticalMode}
          boundingRect={boundingRect}
        />
      </div>
    );
  }
}

const getTimeFrameForOverlap = (ovEl: OverlappedElements) => {
  if (!ovEl) return [0, 1];
  const flights = ovEl.elements.filter(el => isFlight(el));

  let min, max;

  if (flights.length) {
    min = minBy(flights, 'start').start;
    max = maxBy(flights, 'end').end;
  } else {
    min = maxBy(ovEl.elements, 'start').start;
    max = minBy(ovEl.elements, 'end').end;
  }

  return [min, max];
};

export const TooltipConnected = connect((state: RootState) => {
  const elements = state.ui.openedOverlappedElement
    ? state.ui.openedOverlappedElement.elements
    : [];
  const [start, end] = getTimeFrameForOverlap(state.ui.openedOverlappedElement);
  return {
    start,
    end,
    overlappedElements: elements,
  };
})(OverlapTooltip);

import { CSSProperties, MouseEvent, PureComponent } from 'react';

interface Props {
  style?: CSSProperties;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}
interface StateProps {
  isDragging: boolean;
  drag: (x: number, y: number) => void;
  dragEnd: (x: number, y: number) => void;
}

export class DraggableArea extends PureComponent<Props & StateProps> {
  onDrag = (e: React.MouseEvent<HTMLDivElement>) => {
    this.props.isDragging && this.props.drag(e.clientX, e.clientY);
  };
  onDragEnd = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.isDragging) {
      this.props.dragEnd(e.clientX, e.clientY);
      this.props.onClick(e);
    } else {
      this.props.onClick(e);
    }
  };
  render() {
    return (
      <div
        style={this.props.style ? { ...this.props.style } : null}
        // onClick={this.props.onClick}
        onMouseMove={this.onDrag}
        onMouseUp={this.onDragEnd}
      >
        {this.props.children}
      </div>
    );
  }
}

import { PureComponent } from 'react';
import Preview from '../wrapper';
import Content from './content';
import * as d3Scale from 'd3-scale';
import Flight from '../../../../../types/flight';

interface Props {
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  scale: d3Scale.ScaleTime<number, number>;
  y: number;
  element: Flight;
}

class FlightPreview extends PureComponent<Props> {
  render() {
    const { element, scale, y, onFocus, onBlur, isFocused } = this.props;
    return (
      <Preview onBlur={onBlur} onFocus={onFocus} isFocused={isFocused}>
        <Content flight={element} scale={scale} y={y} />
      </Preview>
    );
  }
}

export default FlightPreview;

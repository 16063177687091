import { FC, useState } from 'react';
import { FieldInputProps, FormikProps } from 'formik';
import { PeakDayFormFieldValues } from '../helpers';
import { MultiSelectComponent } from '../../../../common/components/multi-select/multi-select-component';
import { OptionsShape } from '../../../../common/components/multi-select/helper';

export const SelectComponent: FC<FormikProps<PeakDayFormFieldValues> &
  FieldInputProps<
    PeakDayFormFieldValues['regions'] | PeakDayFormFieldValues['types']
  > & {
    options: OptionsShape[];
  }> = ({ value, name, errors, options, setFieldValue, touched }) => {
  const [pickedIds, setPickedIds] = useState(value);

  const onBlur = () => {
    setFieldValue(name, pickedIds);
  };

  const onChange = (ids: number[]) => {
    setPickedIds(ids);
  };

  const onClickClearIcon = () => setFieldValue(name, []);
  const hasError = errors[name] && touched[name];
  return (
    <div>
      <MultiSelectComponent
        filterOption={false}
        onBlur={onBlur}
        onChange={onChange}
        onClickClearIcon={onClickClearIcon}
        allItems={options}
        placeholder={`Please select ${name}`}
        value={pickedIds}
        style={{
          border: hasError ? '1px solid red' : 'none',
          borderRadius: '4px',
          width: '372px',
        }}
      />
      {hasError ? (
        <div className="edit-event-form-body-error-message">{errors[name]}</div>
      ) : null}
    </div>
  );
};

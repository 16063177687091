import * as actions from '../actions';
import { put, call, spawn, all } from 'redux-saga/effects';
import { getAirports } from '../services/airport-service/service';
import { getTimezones } from '../services/timezone-service/service';

export function* getAirportsSaga() {
  yield put(actions.doAirportsMappersFetch.started(null));
  try {
    const startFetchingAirports = new Date().valueOf();
    const result = yield call(getAirports);
    if (!result) {
      throw new Error(`Failed to fetch Airports list. Response is ${result}`);
    }
    const endFetchingAirports = new Date().valueOf();
    yield put(
      actions.doAirportsMappersFetch.done(
        { params: null, result },
        {
          airportsLoadingTime: endFetchingAirports - startFetchingAirports,
        }
      )
    );
  } catch (e) {
    yield put(actions.doAirportsMappersFetch.failed(e));
  }
}

export function* getTimezonesSaga() {
  yield put(actions.doTimezonesMappersFetch.started(null));
  try {
    const startFetchingTimeZones = new Date().valueOf();
    const result = yield call(getTimezones);
    if (!result) {
      throw new Error(`Failed to fetch Timezones. Response is ${result}`);
    }
    const endFetchingTimeZones = new Date().valueOf();
    yield put(
      actions.doTimezonesMappersFetch.done(
        {
          params: null,
          result,
        },
        {
          timeZonesLoadingTime: endFetchingTimeZones - startFetchingTimeZones,
        }
      )
    );
  } catch (e) {
    yield put(actions.doTimezonesMappersFetch.failed(e));
  }
}

export function* fetchCommonDataMappers() {
  yield all([spawn(getAirportsSaga), spawn(getTimezonesSaga)]);
}

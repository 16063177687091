import { minBy } from 'lodash';

import EmptyLegOffer, {
  EmptyLegRoute as FERoute,
} from '../../types/empty-leg-offer';

interface EmptyLegRoute {
  emptyLegRouteId: {
    key: number;
  };
  departureAirportId: {
    key: number;
  };
  arrivalAirportId: {
    key: number;
  };
  amountCents: number;
  creator: {
    personId: {
      key: number;
    };
    firstName: string;
    lastName: string;
    active: boolean;
  };
  createdTime: number;
  validFrom: number;
  validTo: number;
  fspValidFrom: number;
  fspValidTo: number;
  avinodeValidFrom: number;
  avinodeValidTo: number;
  minDeparture: number;
  maxDeparture: number;
  emptyLegOfferId: {
    key: number;
  };
}
const getRouteWithMinId = (elo: EmptyLegOfferGVModel): EmptyLegRoute => {
  return minBy(elo.emptyLegRoutes, r => r.emptyLegRouteId.key);
};
const getELOStart = (
  elo: EmptyLegOfferGVModel,
  routeWithMinId: EmptyLegRoute
): number => {
  return routeWithMinId.minDeparture || elo.segmentStart;
};
const getELOEnd = (
  elo: EmptyLegOfferGVModel,
  routeWithMinId: EmptyLegRoute
): number => {
  return routeWithMinId.maxDeparture || elo.segmentEnd;
};
const getDepartureAirport = (
  elo: EmptyLegOfferGVModel,
  routeWithMaxId: EmptyLegRoute
): number => {
  return (
    (routeWithMaxId.departureAirportId &&
      routeWithMaxId.departureAirportId.key) ||
    elo.segmentDepartureAirportId.key
  );
};
const getArrivalAirport = (
  elo: EmptyLegOfferGVModel,
  routeWithMaxId: EmptyLegRoute
): number => {
  return (
    (routeWithMaxId.arrivalAirportId && routeWithMaxId.arrivalAirportId.key) ||
    elo.segmentArrivalAirportId.key
  );
};
export interface EmptyLegOfferGVModel {
  emptyLegOfferId: {
    key: number;
  };
  offerStatus: {
    offerStatusId: {
      key: number;
    };
    name: string;
    description: string;
  };
  segmentDepartureAirportId: {
    key: number;
  };
  segmentArrivalAirportId: {
    key: number;
  };
  aircraftId: {
    key: number;
  };
  segmentStart: number;
  segmentEnd: number;
  emptyLegRoutes: EmptyLegRoute[];
  cancellationReason: string;
}

const transformRoutes = (r: EmptyLegRoute): FERoute => ({
  id: r.emptyLegRouteId.key,
  amountCents: r.amountCents,
  arrivalAirportId: r.arrivalAirportId.key,
  departureAirportId: r.departureAirportId.key,
  avinodeValidFrom: r.avinodeValidFrom,
  avinodeValidTo: r.avinodeValidTo,
  fspValidFrom: r.fspValidFrom,
  fspValidTo: r.fspValidTo,
  createdTime: r.createdTime,
  creator: {
    id: r.creator.personId.key,
    firstName: r.creator.firstName,
    lastName: r.creator.lastName,
  },
  validFrom: r.validFrom,
  validTo: r.validTo,
});

export const transformToFE = (e: EmptyLegOfferGVModel): EmptyLegOffer => {
  const routeWithMinId = getRouteWithMinId(e);
  return {
    id: e.emptyLegOfferId.key,
    start: getELOStart(e, routeWithMinId),
    end: getELOEnd(e, routeWithMinId),
    departureAirportId: getDepartureAirport(e, routeWithMinId),
    arrivalAirportId: getArrivalAirport(e, routeWithMinId),
    aircraftId: e.aircraftId.key,
    emptyLegRoutes: e.emptyLegRoutes
      .map(transformRoutes)
      .sort(({ id }, { id: sId }) => id - sId),
    offerStatus: e.offerStatus.offerStatusId.key,
    cancellationReason: e.cancellationReason,
  };
};

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';

export interface ElementPosition {
  x: number;
  y: number;
}
interface IframeUi {
  isOpen: boolean;
  position: ElementPosition;
  offset: ElementPosition;
  dragStartPosition: ElementPosition;
}

export type IframeKey = 'editOneWayOfferIframe';
type iframeState = { [key in IframeKey]: IframeUi };
export interface IframeUiState {
  iframeUi: iframeState;
  isDragging: boolean;
}

const iframeInitialState: IframeUi = {
  isOpen: false,
  position: undefined,
  offset: { x: 0, y: 0 },
  dragStartPosition: undefined,
};
export const initialState: IframeUiState = {
  isDragging: false,
  iframeUi: {
    editOneWayOfferIframe: iframeInitialState,
  },
};

export default reducerWithInitialState(initialState)
  .case(actions.userCloseIframe, state => ({
    ...state,
    iframeUi: Object.keys(state.iframeUi).reduce((acc, key) => {
      acc[key] = { ...state.iframeUi[key], isOpen: false };
      return acc;
    }, {} as iframeState),
  }))
  .case(actions.userMoveIframeStart, (state, { mousePos, nodeOffset }) => {
    const keyOfIsOpenIframe = getIsOpenIframeKey(state.iframeUi);
    return {
      ...state,
      isDragging: true,
      iframeUi: {
        ...state.iframeUi,
        [keyOfIsOpenIframe]: {
          ...state.iframeUi[keyOfIsOpenIframe],
          dragStartPosition: { x: mousePos.x, y: mousePos.y },
          position: { x: nodeOffset.x, y: nodeOffset.y },
        },
      },
    };
  })
  .case(actions.userMoveIframe, (state, [x, y]) => {
    const keyOfIsOpenIframe = getIsOpenIframeKey(state.iframeUi);
    return {
      ...state,
      iframeUi: {
        ...state.iframeUi,
        [keyOfIsOpenIframe]: {
          ...state.iframeUi[keyOfIsOpenIframe],
          offset: {
            x:
              x > 0
                ? x - state.iframeUi[keyOfIsOpenIframe].dragStartPosition.x
                : state.iframeUi[keyOfIsOpenIframe].offset.x,
            y:
              y > 0
                ? y - state.iframeUi[keyOfIsOpenIframe].dragStartPosition.y
                : state.iframeUi[keyOfIsOpenIframe].offset.y,
          },
        },
      },
    };
  })
  .case(actions.userMoveIframeEnd, (state, [x, y]) => {
    const keyOfIsOpenIframe = getIsOpenIframeKey(state.iframeUi);
    return {
      ...state,
      isDragging: false,
      iframeUi: {
        ...state.iframeUi,
        [keyOfIsOpenIframe]: {
          ...state.iframeUi[keyOfIsOpenIframe],
          dragStartPosition: null,
          offset: {
            x: 0,
            y: 0,
          },
          position: {
            x:
              state.iframeUi[keyOfIsOpenIframe].position.x +
              x -
              state.iframeUi[keyOfIsOpenIframe].dragStartPosition.x,
            y:
              state.iframeUi[keyOfIsOpenIframe].position.y +
              y -
              state.iframeUi[keyOfIsOpenIframe].dragStartPosition.y,
          },
        },
      },
    };
  })
  .case(actions.userClickEditOneWayOffer, state => ({
    ...state,
    iframeUi: getUpdatesOnOpenIframeType(
      state.iframeUi,
      'editOneWayOfferIframe'
    ),
  }))
  .case(actions.userResetIframePosition, state => ({
    ...state,
    iframeUi: getUpdatesOnResetPosition(state.iframeUi),
  }));

function getStateKeyNamesOfIframes(state: iframeState) {
  return Object.keys(state);
}
function getIsOpenIframeKey(state: iframeState) {
  const keys = getStateKeyNamesOfIframes(state);
  return keys.filter(k => state[k].isOpen)[0];
}
function getUpdatesOnOpenIframeType(state: iframeState, type: IframeKey) {
  const keysOfIframes = getStateKeyNamesOfIframes(state);
  return keysOfIframes.reduce((acc, key: IframeKey) => {
    acc[key] = {
      ...state[key],
      isOpen: key === type,
    };
    return acc;
  }, state);
}
function getUpdatesOnResetPosition(state: iframeState) {
  const keyOfIsOpenIframe = getIsOpenIframeKey(state);
  return {
    ...state,
    [keyOfIsOpenIframe]: {
      ...iframeInitialState,
      isOpen: true,
    },
  };
}

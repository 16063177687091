import { store as reduxStore } from '../../../../../root';
import * as d3S from 'd3-selection';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import { AirportMismatch } from '../../../../../types/airport-mismatch';
import { utc } from 'moment';
import { getICAOLabelFromAirport } from '../../../../../utils/airport';

export const getAirportMismatchesD3creator = (
  d: d3S.Selection<any, any, any, any>
): d3S.Selection<any, any, any, any> => {
  const state = reduxStore.getState();
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { airportsById } = state.airports;
  const { togglersState } = state.aircraft;

  const container = d
    .append('div')
    .classed('label-text', true)
    .style(
      'transform',
      d =>
        `translate(${d.x}px, ${d.y -
          4 +
          d.ky *
            getElementOffsetWithKoef(
              segmentsVisibility,
              'airportMismatches',
              togglersState[d.aircraftId],
              positionMap
            )}px)`
    )
    .classed('mm-text-label', true)
    .style('padding-left', d => `${-d.x > d.width ? 0 : Math.max(-d.x, 0)}px`)
    .style(
      'padding-right',
      d => `${Math.max(d.x + d.width - transform.scaleX.range()[1], 0)}px`
    )
    .style(
      'height',
      d =>
        `${(positionMap.airportMismatches * d.ky) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )}px`
    )
    .style('width', d => `${d.width}px`)
    .text((d: AirportMismatch) => {
      const mins = utc(d.end).diff(d.start, 'm');
      const hrs = Math.floor(mins / 60);
      const justMins = mins % 60;
      let time = hrs ? `${hrs}h ` : '';
      time += justMins ? `${justMins}m ` : '';
      let departureIcao = getICAOLabelFromAirport(
        airportsById[d.departureAirportId]
      );
      let arrivalIcao = getICAOLabelFromAirport(
        airportsById[d.arrivalAirportId]
      );
      return `${time} - [${departureIcao}-${arrivalIcao}]`;
    });
  return container;
};

import { RootState } from '../../reducers';
import { connect } from 'react-redux';
import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { BasicMaintenancePattern } from './basic-pattern';

interface StateProps {
  kx: number;
  ky: number;
}

const maintenanceIsDispatchedInfoArr = [
  {
    name: 'UMX',
    color: '#e5d6f4',
  },
  {
    name: 'SMX',
    color: '#d5d5ff',
  },
  {
    name: 'AOG',
    color: '#fec0cc',
  },
  {
    name: 'AOGDispatchable',
    color: '#ff5454',
  },
];

const AllMaintenanceIsDispatchedPatterns = (props: StateProps) => (
  <>
    {maintenanceIsDispatchedInfoArr.map(obj => (
      <BasicMaintenancePattern
        patternIdName={`maintenanceIsDispatchedGradientFor${obj.name}`}
        color={obj.color}
        kx={props.kx}
        ky={props.ky}
        key={obj.name}
      />
    ))}
  </>
);

const mapStateToProps = (state: RootState): StateProps => ({
  kx: state.ui.transform.kx,
  ky: state.ui.transform.ky,
});
const advancedMap = createDelayedForScrollConnect(mapStateToProps);

export const AllMaintenanceIsDispatchedPatternsConnected = connect(advancedMap)(
  AllMaintenanceIsDispatchedPatterns
);

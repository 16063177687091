import { PureComponent } from 'react';
import './styles.scss';
import { DayZoomLevel } from '../../reducers/ui';
import { connect, DispatchProp } from 'react-redux';
import { userChangeZoomLevel } from '../../actions';
import { RootState } from '../../reducers';
import Flight from '../../types/flight';
import { AnyAction } from 'redux';

class ZoomButton extends PureComponent<{
  zoomLevel: DayZoomLevel;
  onClick: (l: DayZoomLevel) => void;
  selected: DayZoomLevel;
}> {
  btnClick = (e: React.MouseEvent) => {
    this.props.onClick(this.props.zoomLevel);
  };
  render() {
    const { selected, zoomLevel } = this.props;
    return (
      <div
        className={`btn-zoom-level ${selected === zoomLevel ? 'selected' : ''}`}
        onClick={this.btnClick}
      >
        {zoomLevel}
      </div>
    );
  }
}
const buttons: DayZoomLevel[] = ['1d', '3d', '1w', '2w', '3w'];
export class ZoomButtonGroup extends PureComponent<
  { selected: DayZoomLevel; searchedFlights: Flight[] } & DispatchProp<
    AnyAction
  >
> {
  changeZoomLevel = (l: DayZoomLevel) => {
    const { searchedFlights } = this.props;
    const toDate =
      searchedFlights && searchedFlights.length > 0
        ? searchedFlights[0].start
        : undefined;
    this.props.dispatch(userChangeZoomLevel({ zoomLevel: l, toDate }));
  };
  render() {
    return (
      <div className="zoom-buttons">
        {buttons.map((dzl, index) => (
          <ZoomButton
            key={index}
            zoomLevel={dzl}
            onClick={this.changeZoomLevel}
            selected={this.props.selected}
          />
        ))}
      </div>
    );
  }
}
export const ZoomButtonGroupConnected = connect((state: RootState) => ({
  selected: state.ui.zoomLvl.value,
  searchedFlights: state.search.searchedResult.flightArr,
}))(ZoomButtonGroup);

import { PureComponent } from 'react';
import { flightFerryColorizer } from '../../common/flight/flight-colorization';
import { LegBusinessType } from '../../types/flight';

interface Props {
  legBusinessTypeId: LegBusinessType;
}

class LegBusinessTypeRow extends PureComponent<Props> {
  render() {
    return (
      <div
        style={{
          backgroundColor: flightFerryColorizer(this.props.legBusinessTypeId),
        }}
        className="ft-live-row"
      />
    );
  }
}

export default LegBusinessTypeRow;

import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../reducers';
import { initialState as timezonesInitialState } from '../reducers/timezones';
import { initialState as aircraftInitialState } from '../reducers/aircraft';
import { initialState as airportsInitialState } from '../reducers/airports';
import { initialState as eventsInitialState } from '../reducers/timeline-events';

interface StateProps {
  isInitialLoaded: boolean;
  isTimezonesLoaded: boolean;
}

export class TestStatus extends PureComponent<StateProps> {
  render() {
    return (
      <div
        hidden
        id="data-test-loading-status"
        data-test-initial-loaded={this.props.isInitialLoaded}
        data-test-timezones-loaded={this.props.isTimezonesLoaded}
      />
    );
  }
}

export const TestStatusConnected = connect<StateProps, {}, {}, RootState>(
  state => ({
    isTimezonesLoaded:
      !state.timezones.loading &&
      state.timezones.timezonesById !== timezonesInitialState.timezonesById,
    isInitialLoaded: [
      !state.aircraft.loading &&
        state.aircraft.aircraft !== aircraftInitialState.aircraft,
      !state.airports.loading &&
        state.airports.airportsById !== airportsInitialState.airportsById,
      !state.loadingBar.default &&
        state.timelineEvents.flights !== eventsInitialState.flights,
    ].every(c => c),
  })
)(TestStatus);

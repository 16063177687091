import { PureComponent } from 'react';

interface StripeProps {
  color: string;
  height: number;
}

class Stripe extends PureComponent<StripeProps> {
  render() {
    const { height, color } = this.props;
    return (
      <div
        style={{
          height,
          background: color,
        }}
      />
    );
  }
}

interface HeaderProps {
  colors: string[];
  height: number;
}

export class Header extends PureComponent<HeaderProps> {
  render() {
    const { colors, height } = this.props;
    return (
      <>
        {colors.map((c, i) => (
          <Stripe key={i} height={height} color={c} />
        ))}
      </>
    );
  }
}

import {
  DEFAULT_USER_PREFERENCES,
  UserPreferences,
} from '../types/user-preferences';

export const updateDefaultUserPreferences = (
  data?: UserPreferences
): UserPreferences => {
  return {
    segmentsVisibility:
      data?.segmentsVisibility || DEFAULT_USER_PREFERENCES.segmentsVisibility,
    groundTimeType:
      data?.groundTimeType || DEFAULT_USER_PREFERENCES.groundTimeType,
    timeLabelDisplayMode:
      data?.timeLabelDisplayMode ||
      DEFAULT_USER_PREFERENCES.timeLabelDisplayMode,
    mxEventPresenceType:
      data?.mxEventPresenceType || DEFAULT_USER_PREFERENCES.mxEventPresenceType,
    statusMap: data?.statusMap || DEFAULT_USER_PREFERENCES.statusMap,
    kx: data?.kx || DEFAULT_USER_PREFERENCES.kx,
    scaleX: data?.scaleX || DEFAULT_USER_PREFERENCES.scaleX,
    ky: data?.ky || DEFAULT_USER_PREFERENCES.ky,
    togglersState:
      data?.togglersState || DEFAULT_USER_PREFERENCES.togglersState,
    emptyLegMap: data?.emptyLegMap || DEFAULT_USER_PREFERENCES.emptyLegMap,
    isDaySeparatorOnTop:
      data?.isDaySeparatorOnTop || DEFAULT_USER_PREFERENCES.isDaySeparatorOnTop,
    showSubCharters:
      data?.showSubCharters || DEFAULT_USER_PREFERENCES.showSubCharters,
    showLabels: data?.showLabels || DEFAULT_USER_PREFERENCES.showLabels,
    zoomLvl: data?.zoomLvl || DEFAULT_USER_PREFERENCES.zoomLvl,
    visibleHoldAcIds:
      data?.visibleHoldAcIds || DEFAULT_USER_PREFERENCES.visibleHoldAcIds,
    statisticsMode:
      data?.statisticsMode || DEFAULT_USER_PREFERENCES.statisticsMode,
    visibleAircraftTypeByOperatingCompanyMap:
      data?.visibleAircraftTypeByOperatingCompanyMap ||
      DEFAULT_USER_PREFERENCES.visibleAircraftTypeByOperatingCompanyMap,
    isGroupedByOperator:
      data?.isGroupedByOperator || DEFAULT_USER_PREFERENCES.isGroupedByOperator,
    visibleACTypes:
      data?.visibleACTypes || DEFAULT_USER_PREFERENCES.visibleACTypes,
    visibleOperatingCompanies:
      data?.visibleOperatingCompanies ||
      DEFAULT_USER_PREFERENCES.visibleOperatingCompanies,
    visibleAcIds: data?.visibleAcIds || DEFAULT_USER_PREFERENCES.visibleAcIds,
    acTypesPositionMap:
      data?.acTypesPositionMap || DEFAULT_USER_PREFERENCES.acTypesPositionMap,
  };
};

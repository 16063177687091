export const getOriginalTargetFirstChildForAntdPopConfirm = (
  e: React.FocusEvent<HTMLDivElement>
) => {
  const originalTarget =
    //@ts-ignore
    e?.relatedTarget || e?.nativeEvent.explicitOriginalTarget;
  return (
    originalTarget &&
    (originalTarget as HTMLButtonElement).children &&
    ((originalTarget as HTMLButtonElement).children[0] as HTMLSpanElement)
  );
};

import '../../edit-event-drawer/styles.scss';

import { Button, Drawer } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import * as Yup from 'yup';
import { userCloseSinglePeakDayModal, userSavePeakDay } from '../../../actions';
import {
  DAY_IN_MILLISECONDS,
  GENERATE_NEW_ID,
  RIGHT_DRAWER_WIDTH,
} from '../../../constants';
import { ConfirmationModal } from '../../../common/components/confirmation-modal/confirmation-modal';
import { FormId } from '../../edit-event-drawer/constants';
import PeakDay, { BusyDays } from '../../../types/peak-day';
import {
  getPeakDayFromStateById,
  getBusyDatesWithoutCurrent,
} from '../../../selectors/peak-days';
import { Form, Formik, FormikHelpers } from 'formik';
import { hasPermission } from '../../../utils/check-permissions';
import {
  defaultValues,
  isTimeGapLessThanDay,
  PeakDayFormFieldValues,
  transformPeakDayFormValuesForSave,
} from './helpers';
import { PeakDayFormDetails } from './components/form';
import { utc } from 'moment';
import { SavePeakDayMutationVariables } from '../../../types/operation-result-types';
import { getBusyDaysFromEvent } from '../../../utils/peak-days';
import { ConfirmationFooter } from '../../../common/components/confirmation-modal/confirmation-footer';
import { CloseOutlined } from '@ant-design/icons';

const validationSchema = (busyDays: BusyDays) =>
  Yup.object()
    .shape({
      start: Yup.number()
        .nullable()
        .required('Please select Start date')
        .test({
          test(value, ctx) {
            if (
              value &&
              busyDays.includes(value) &&
              isTimeGapLessThanDay(value)
            ) {
              return ctx.createError({
                message:
                  'This date overlaps with another peak day. Please choose another date',
              });
            }
            return true;
          },
        })
        .when('end', {
          is: end => !!end,
          then: Yup.number().max(
            Yup.ref('end'),
            'Start date must be before End date.'
          ),
        })
        .when('id', {
          is: id => id === GENERATE_NEW_ID,
          then: Yup.number()
            .nullable()
            .min(
              utc()
                .startOf('day')
                .valueOf() + DAY_IN_MILLISECONDS,
              "You must set a Start date higher than today's date"
            ),
        }),
      end: Yup.number()
        .nullable()
        .required('Please select End date')
        .min(Yup.ref('start'), 'End date must be after Start date.'),
      description: Yup.string()
        .nullable()
        .required('Description is required.')
        .max(400),
      regions: Yup.array()
        .nullable()
        .min(1, 'Region is required'),

      types: Yup.array()
        .nullable()
        .min(1, 'Type is required'),
    })
    .test(({ end, start }, schema) => {
      if (!!end && !!start) {
        const days = getBusyDaysFromEvent(end, start);
        const hasOverlap = days.filter(d => busyDays.includes(d)).length > 0;
        if (hasOverlap) {
          return schema.createError({
            path: 'end',
            message: "Peak day can't overlap dates of other peak days",
          });
        }
        return true;
      }
      return true;
    });

export const SinglePeakDayDrawer: FC<{}> = () => {
  const dispatch = useDispatch();

  const busyDays = useSelector<RootState, BusyDays>(getBusyDatesWithoutCurrent);
  const event = useSelector<RootState, PeakDay | null>(getPeakDayFromStateById);
  const hasViewPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Timeline-Event-View')
  );
  const hasEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Timeline-Event-Edit')
  );
  const height = useSelector<RootState, number>(state => state.ui.height);
  const loading = useSelector<RootState, boolean>(
    state => state.peakDays.loading
  );
  const visible = useSelector<RootState, boolean>(
    state => state.peakDays.isSingleEventModalVisible
  );

  const onClose = () => dispatch(userCloseSinglePeakDayModal());
  const onSubmit = (event: SavePeakDayMutationVariables['day']) =>
    dispatch(userSavePeakDay.started(event));

  const [initialValues, setInitialValues] = useState<PeakDayFormFieldValues>(
    defaultValues
  );
  const [formIsTouched, setFormIsTouched] = useState<boolean>(false);
  const [isToConfirmClose, setConfirmationVisibility] = useState<boolean>(
    false
  );
  const handleClose = () => {
    if (isToConfirmClose) {
      setConfirmationVisibility(false);
      onClose();
    }
    if (formIsTouched) {
      setConfirmationVisibility(true);
    } else {
      onClose();
    }
  };
  const onCloseConfirmationModal = () => setConfirmationVisibility(false);
  useEffect(() => {
    if (event) {
      const values = {
        description: event.text,
        end: event.end,
        id: event.id,
        autoAdvertising: event.isAutoAdvertising,
        crewChange: event.isCrewChange,
        start: event.start,
        types: event.peakDayTypes ? event.peakDayTypes.map(v => v.id) : [],
        regions: event.peakDayRegions
          ? event.peakDayRegions.map(v => v.id)
          : [],
      };
      setInitialValues(values);
    }
    return () => {
      setInitialValues(defaultValues);
      setFormIsTouched(false);
      setConfirmationVisibility(false);
    };
  }, [event]);

  if (!event || (!hasViewPermission && !hasEditPermission)) return null;
  const disabled = !hasEditPermission;
  const footer = (
    <ConfirmationFooter
      formId={FormId.peakDay}
      handleClose={handleClose}
      loading={loading}
      disabledSave={disabled}
      saveTitle={event?.id === GENERATE_NEW_ID ? 'Create' : 'Save'}
    />
  );
  const title = (
    <div className="drawer-title-wrapper">
      <span className="drawer-title">
        {event?.id === GENERATE_NEW_ID
          ? 'Create a Peak day'
          : `Edit the Peak day #${event.id || ''}`}
      </span>
      <Button
        icon={<CloseOutlined />}
        onClick={handleClose}
        style={{ border: 'none' }}
      />
    </div>
  );
  return (
    <>
      <ConfirmationModal
        onClose={onCloseConfirmationModal}
        open={isToConfirmClose}
        footer={footer}
      />
      <Drawer
        closable={false}
        placement="right"
        width={RIGHT_DRAWER_WIDTH}
        maskClosable
        onClose={handleClose}
        open={visible}
        title={title}
        keyboard
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema(busyDays)}
          validateOnMount
          onSubmit={(
            values: PeakDayFormFieldValues,
            { setSubmitting }: FormikHelpers<PeakDayFormFieldValues>
          ) => {
            onSubmit(transformPeakDayFormValuesForSave(values));
            setSubmitting(false);
          }}
        >
          {formikProps => (
            <Form
              className="edit-event-form"
              style={{
                height: `${height}px`,
              }}
              id={FormId.peakDay}
            >
              <PeakDayFormDetails
                formikProps={formikProps}
                disabled={disabled}
                formIsTouched={formIsTouched}
                setFormIsTouched={setFormIsTouched}
                busyDays={busyDays}
              />
              {footer}
            </Form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

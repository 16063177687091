import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { flattenPool } from '../data-processing/pool-structure';

export const getNoteByIdMap = createSelector(
  (state: RootState) => state.timelineEvents.generalNotes,
  (state: RootState) => state.timelineEvents.availabilityNotes,
  (generalNotes, availabilityNotes) => {
    return [
      ...flattenPool(generalNotes),
      ...flattenPool(availabilityNotes),
    ].reduce((acc, note) => {
      acc[note.id] = note;
      return acc;
    }, {});
  }
);

import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { utc } from 'moment';
import { WsHandoverUpdateBatch } from '../../actions';
import {
  CENTRED_DOT_SYMBOL,
  REG_EXP_NEW_LINE_IN_EDITOR,
} from '../../constants';
import { HandoverFilters } from '../../types/handover-remarks';
import Aircraft from '../../types/aircraft';
import Airport from '../../types/airport';
import Flight from '../../types/flight';
import {
  AllRemarksShape,
  HandoverEmail,
  HandoverRemark,
  HandoverRemarksViewByType,
  HandoverSelectFilterType,
  HandoverType,
} from '../../types/handover-remarks';
import { getNextDaySign } from '../../utils/hold-line-flights';
import { universalDateAndTimeFormatter } from '../../utils/time';
import { HANDOVER_HEADER_HEIGHT, HORemarkBackgroundColor } from './constants';

export const getFlightDetailsForHandoverTitle = (
  flight: Flight,
  airportsById: { [id: number]: Airport },
  aircraftById: { [id: number]: Aircraft }
): string => {
  if (!flight) return '';
  const {
    departureAirportId,
    departureUtcScheduled,
    arrivalAirportId,
    arrivalUtcScheduled,
    aircraftId,
    id,
  } = flight;
  const depTime = universalDateAndTimeFormatter(
    utc(departureUtcScheduled),
    'dayMonthYYtimez'
  );
  const nextDaySign = getNextDaySign(
    departureUtcScheduled,
    arrivalUtcScheduled
  ).trim();
  const arrTime = `${universalDateAndTimeFormatter(
    utc(arrivalUtcScheduled),
    'timez'
  )}${nextDaySign}`;
  const depICAO = airportsById[departureAirportId]?.ICAO || 'N/A';
  const arrICAO = airportsById[arrivalAirportId]?.ICAO || 'N/A';
  const tailNumber = aircraftById[aircraftId]?.tailNumber || 'N/A';
  return `${tailNumber}, ${depICAO}-${arrICAO}, Leg# ${id}, ${depTime} ${CENTRED_DOT_SYMBOL} ${arrTime}`;
};

export const getDrawerBodyHeight = (
  showRangeFilter: boolean,
  isAll: boolean
): number => {
  const windowHeight = window.innerHeight;
  const heightExisting = document
    .querySelector('.ant-drawer-header')
    ?.getBoundingClientRect()?.height;
  const headerHeight = heightExisting || HANDOVER_HEADER_HEIGHT;
  const padding = 16; // top and bottom
  const buttonHeight = isAll ? 32 : 0; // send email
  const bottom = 10; // below send email button
  const rangeFilterHeight = !heightExisting && showRangeFilter ? 40 : 0;
  const result =
    windowHeight -
    headerHeight -
    padding -
    buttonHeight -
    bottom -
    rangeFilterHeight;
  return result;
};
export const doCheckSetLastUpdated = (
  remarkForSave: HandoverRemark,
  currentRemarks: HandoverRemark[]
): boolean => {
  const previousStateOfThisRemark = currentRemarks.find(
    rem => rem.id === remarkForSave.id
  );
  if (!previousStateOfThisRemark) return false;
  const { remarks: currentText } = remarkForSave;
  const { remarks: previousText } = previousStateOfThisRemark;
  return currentText !== previousText;
};

export const transformRemarkForSave = (
  remark: HandoverRemark,
  userName: string,
  setLastUpdated: boolean
): HandoverRemark => {
  const {
    aircraftId,
    flightLegId,
    id,
    remarks,
    createdAtMs,
    createdBy,
    order,
    type,
    lastUpdatedAtMs,
    updatedBy,
  } = remark;
  return {
    ...remark,
    aircraftId,
    id,
    flightLegId,
    remarks,
    isActive: true,
    createdAtMs: createdAtMs || utc().valueOf(),
    createdBy: createdBy || userName || '',
    lastUpdatedAtMs: setLastUpdated ? utc().valueOf() : lastUpdatedAtMs,
    expired: false,
    updatedBy: setLastUpdated ? userName : updatedBy,
    order,
    type,
  };
};

export const sortCards = (a: HandoverRemark, b: HandoverRemark): number => {
  if (a.order && b.order) {
    return a.order > b.order ? 1 : -1;
  }
  const left = a.lastUpdatedAtMs ? a.lastUpdatedAtMs : a.createdAtMs;
  const right = b.lastUpdatedAtMs ? b.lastUpdatedAtMs : b.createdAtMs;
  if (left > right) return -1;
  if (left < right) return 1;
  return 0;
};

export const sortCardsInEditMode = (
  remarks: HandoverRemark[],
  cardIds: string[]
) => {
  let sorted = [];
  let newCards = [];
  for (let i = 0; i < remarks.length; i++) {
    const index = cardIds.indexOf(remarks[i].id);
    if (index === -1) {
      newCards.push(remarks[i]);
    } else {
      sorted[index] = remarks[i];
    }
  }
  if (newCards.length > 0) {
    remarks.sort(sortCards);
    for (let j = 0; j < newCards.length; j++) {
      const indexOfNewRemark = remarks.findIndex(c => c.id === newCards[j].id);
      if (indexOfNewRemark !== -1) {
        const left = sorted.slice(0, indexOfNewRemark);
        const right = sorted.slice(indexOfNewRemark);
        const insert = remarks[indexOfNewRemark];
        sorted = [...left, insert, ...right];
      }
    }
  }
  return sorted.filter(c => !!c);
};

export const prepareEditorStateForSave = (editorState: EditorState): string => {
  const asHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  const text = asHtml
    .replace(/<p>/g, '')
    .replace(new RegExp(`${REG_EXP_NEW_LINE_IN_EDITOR}$`, 'gi'), '')
    .replace(new RegExp(`${REG_EXP_NEW_LINE_IN_EDITOR}`, 'gi'), '<br>');
  return text;
};

export const getPlaceholderValueFromType = (
  type: HandoverSelectFilterType
): 'Operator' | 'AC Types' | 'Tail' => {
  if (type === 'acTypes') return 'AC Types';
  if (type === 'operator') return 'Operator';
  return 'Tail';
};

export const reduceRemarksByBatchUpdate = (
  list: HandoverRemark[],
  batch: WsHandoverUpdateBatch
): HandoverRemark[] => {
  if (Array.isArray(batch)) {
    const currentMap = list.reduce((acc, a) => {
      acc.set(a.id, a);
      return acc;
    }, new Map<string, HandoverRemark>());
    const nextAcMap = batch.reduce((acc, item) => {
      if (!item.isActive) {
        acc.delete(item.id);
      } else {
        acc.set(item.id, item);
      }
      return acc;
    }, currentMap);
    return [...nextAcMap.values()];
  }
  // remove
  if (!batch.isActive) {
    return list.filter(r => r.id !== batch.id);
  }
  const index = list.findIndex(r => r.id === batch.id);
  // new
  if (index === -1) {
    return list.concat(batch);
  }
  // update
  return list.reduce<HandoverRemark[]>((fAcc, it) => {
    if (it.id === batch.id) {
      return fAcc.concat(batch);
    }
    return fAcc.concat(it);
  }, []);
};

export const updateCurrentRemarks = (
  list: HandoverRemark[],
  batch: WsHandoverUpdateBatch,
  aircraftId: number | null,
  flight: Flight | null
): HandoverRemark[] => {
  if (!aircraftId && !flight) return list;
  if (Array.isArray(batch)) {
    const currentMap = list.reduce((acc, remark) => {
      acc.set(remark.id, remark);
      return acc;
    }, new Map<string, HandoverRemark>());
    const nextRemarkMap = batch.reduce((acc, item) => {
      if (currentMap.has(item.id) && !item.isActive) {
        acc.delete(item.id);
      } else if (
        (aircraftId !== null && item.aircraftId === aircraftId) ||
        item.flightLegId === flight?.id
      ) {
        acc.set(item.id, item);
      }
      return acc;
    }, currentMap);

    return [...nextRemarkMap.values()];
  }
  // remove
  if (!batch.isActive) {
    return list.filter(r => r.id !== batch.id);
  }
  const index = list.findIndex(r => r.id === batch.id);
  // new
  if (
    index === -1 &&
    ((aircraftId !== null && batch.aircraftId === aircraftId) ||
      batch.flightLegId === flight?.id)
  ) {
    return list.concat(batch);
  }
  // update
  return list.reduce<HandoverRemark[]>((fAcc, item) => {
    if (item.id === batch.id) {
      fAcc.push(batch);
    } else {
      fAcc.push(item);
    }
    return fAcc;
  }, []);
};

export const reduceCurrentRemarksByBatchUpdate = (
  list: HandoverRemark[],
  batch: WsHandoverUpdateBatch,
  aircraftId: number | null,
  flight: Flight | null
): HandoverRemark[] => {
  const remarks = updateCurrentRemarks(list, batch, aircraftId, flight);
  return sortAllRemarks(remarks);
};

export const getDepartmentEmailText = (filters: HandoverFilters) => {
  const { radioByDepartment, radioByType } = filters;
  const department = getDepartmentFilter(radioByDepartment);
  if (department === 'All') {
    return { subject: 'Ops and CS', header: 'Operations and Client Service' };
  }
  if (department === HandoverType.cs) {
    return { subject: 'CS', header: 'Client Service' };
  }
  if (
    department === HandoverType.ops ||
    (department === 'None' && radioByType === HandoverRemarksViewByType.all)
  ) {
    return { subject: 'Ops', header: 'Operations' };
  }
  return { subject: '', header: '' };
};

export const getHandoverEmailTemplate = (
  remarks: AllRemarksShape[],
  user: string,
  filters: HandoverFilters
): HandoverEmail => {
  const { subject: depSubject, header: depHeader } = getDepartmentEmailText(
    filters
  );
  const now = universalDateAndTimeFormatter(utc(), 'dayMonthYYtimez');
  const subject = `${depSubject} Handover ${now}`;
  const header = `<p style="margin: 8px 0px; text-align: center;">************* <strong>Vista ${depHeader} handover ${now}</strong> *****************</p>`;
  const lines = [];
  const operators = [];
  remarks.forEach(a => {
    if (!operators.includes(a.operatingCompanyName)) {
      lines.push(
        `<p style="margin: 8px 0px; text-align: center; text-decoration: underline;"><strong>${a.operatingCompanyName}</strong></p>`
      );
    }
    operators.push(a.operatingCompanyName);
    lines.push(
      `<p style="color: ${
        a.remarks.aircraftId ? '#1890ff' : 'orange'
      }"><strong>${a.remarks.title}</strong></p>`
    );
    a.remarks.cards.forEach(r => {
      lines.push(`<p>${r.remarks}</p>`);
    });
    return;
  });
  return {
    bodyHtml: header.concat(
      lines
        .join('\n')
        .concat(
          '<p style="margin: 8px 0px; text-align: center;">********************** End of Handover **********************</p>'
        )
    ),
    email_cc: [],
    email_from: user,
    email_to: [],
    subject,
  };
};

/**
 * @param title string
 * title shape for flight is
 * 9H-VCA, KMIA-KLAX, Leg# 3196998, 11 May’23 20:15z · 01:40z1
 * for tail is Tail# 9H-VCG
 * @param isRemarkForFlight boolean
 * @returns Object
 */

export const parseRemarkTitleForLinks = (
  title: string,
  isRemarkForFlight: boolean
): {
  tailNumber: string;
  route: string;
  time: string;
  flightLegId: number;
} => {
  if (isRemarkForFlight) {
    const [tailNumber, route, leg, time] = title.split(',');
    const [, flightLegId] = leg.split('#');
    return {
      flightLegId: +flightLegId.trim(),
      route,
      tailNumber,
      time,
    };
  }
  return {
    tailNumber: title.split(' ')[1].trim(),
    flightLegId: null,
    route: null,
    time: null,
  };
};

export const getUtilsForViewAllCardsStyle = (
  remarks: AllRemarksShape['remarks'][]
) => {
  const isForTailRemarkArray = remarks.map(el => Boolean(el.aircraftId));

  // Step 1: create an array with Tail Numbers
  // we need value which exists both in tail and flight remarks
  const tailsForAllRemarksArray = remarks.map(el =>
    el.aircraftId ? el.title.split(' ')[1] : el.title.split(',')[0]
  );

  // Step 2: create an array with unique values from tailsForAllRemarksArray
  // we need unique tails for step 3
  let uniqueTailsArray: string[] = [];
  tailsForAllRemarksArray.forEach(item => {
    if (uniqueTailsArray.indexOf(item) === -1) {
      uniqueTailsArray.push(item);
    }
  });

  // Step 3: create a Map of unique tails with unique keys
  // we will set key as zIndex according value - tail
  let mapOfTails = new Map();
  uniqueTailsArray.forEach((el, i) => mapOfTails.set(i + 1, el));

  // Step 4: function which will return key by value
  const getKeyByValue = (
    map: Map<number, string>,
    searchValue: string
  ): number => {
    for (let [key, value] of map.entries()) {
      if (value === searchValue) return key;
    }
  };

  return {
    getKeyByValue,
    isForTailRemarkArray,
    mapOfTails,
    tailsForAllRemarksArray,
  };
};

export const isOpsRemark = (remark: HandoverRemark) =>
  !remark.aircraftId &&
  (typeof remark.type === 'undefined' || remark.type === HandoverType.ops);

export const isCsRemark = (remark: HandoverRemark) =>
  !remark.aircraftId &&
  typeof remark.type !== 'undefined' &&
  remark.type === HandoverType.cs;

// TODO define permission type
export const editableByPermissions = (remark: HandoverRemark, permissions) => {
  const { hasCsEditPermission, hasOpsEditPermission } = permissions;
  if (isCsRemark(remark)) {
    return hasCsEditPermission;
  }
  if (isOpsRemark(remark)) {
    return hasOpsEditPermission;
  }
  return true;
};

export const getDividedFlightRemarks = (
  remarks: HandoverRemark[],
  newIds?: string[] // prevent cards from jumping in edit mode
) =>
  remarks.reduce<{
    opsCards: HandoverRemark[];
    csCards: HandoverRemark[];
  }>(
    (acc, c) => {
      if (isOpsRemark(c) || newIds?.includes(c.id)) {
        acc.opsCards.push(c);
      } else if (isCsRemark(c)) {
        acc.csCards.push(c);
      }
      return acc;
    },
    {
      opsCards: [],
      csCards: [],
    }
  );

const sortAllRemarks = (remarks: HandoverRemark[]) => {
  const { csCards, opsCards } = getDividedFlightRemarks(remarks);
  return [...opsCards.sort(sortCards), ...csCards.sort(sortCards)];
};

export const getRemarksBackgroundColor = (
  remark: HandoverRemark
): typeof HORemarkBackgroundColor[keyof typeof HORemarkBackgroundColor] => {
  if (isCsRemark(remark)) {
    return HORemarkBackgroundColor.cs;
  }
  if (isOpsRemark(remark)) {
    return HORemarkBackgroundColor.ops;
  }
  return HORemarkBackgroundColor.tail;
};

export const filterByTeam = (
  allRemarks: HandoverRemark[],
  team: HandoverType
) =>
  allRemarks.filter(
    rem =>
      (team === HandoverType.ops && typeof rem.type === 'undefined') ||
      rem.type?.toLowerCase() === team.toLocaleLowerCase()
  );

export const getFlightLegIds = (remarks: HandoverRemark[]) =>
  remarks.map(r => r.flightLegId).filter(n => !!n);

export const getDepartmentFilter = (
  arr: HandoverType[]
): HandoverType | 'None' | 'All' => {
  if (arr.length === 0) {
    return 'None';
  }
  if (arr.length === Object.keys(HandoverType).length) {
    return 'All';
  }
  return arr[0];
};

export const setCardsOnAdd = (
  newCard: HandoverRemark,
  allCards: HandoverRemark[]
): HandoverRemark[] => {
  if (!newCard.type || newCard.type === HandoverType.ops) {
    return [newCard, ...allCards];
  }
  const firstCsRemarkIndex = allCards.findIndex(
    c => c.type === HandoverType.cs
  );
  if (firstCsRemarkIndex === -1) {
    return [...allCards, newCard];
  }
  const left = allCards.slice(0, firstCsRemarkIndex);
  const right = allCards.slice(firstCsRemarkIndex);
  return [...left, newCard, ...right];
};

import EmptyLegOffer, { OfferStatus } from '../types/empty-leg-offer';
import EventElement from '../types/event-element';

export const getOfferStatusName = (os: OfferStatus) => {
  switch (os) {
    case OfferStatus.TRANSIENT:
      return 'Transient';
    case OfferStatus.NEW:
      return 'New';
    case OfferStatus.ADVERTISED:
      return 'Advertised';
    case OfferStatus.CANCELLED:
      return 'Cancelled';
    case OfferStatus.RESERVED:
      return 'Reserved';
    case OfferStatus.SOLD:
      return 'Sold';
  }
};

export function isEmptyLegOffer(el: EventElement): el is EmptyLegOffer {
  return !!(el as EmptyLegOffer).emptyLegRoutes;
}

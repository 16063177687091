import { gvUrl } from '../services/gv-service';

function getFromEnv<T>(
  defValue: T,
  getter: (env: EnvironmentVariables<T>) => T
): T {
  return (window && window.ENV && getter(window.ENV)) || defValue;
}

export const ENV_NAME = window?.ENV?.ENV_NAME || '';
export const SIDEBAR_ENABLED = window.ENV ? window.ENV.SIDEBAR_ENABLED : true;
export const SIDEBAR_BACKEND_URL = getFromEnv(
  '',
  env => env.SIDEBAR_BACKEND_URL
);
export const SIDEBAR_BACKEND_URL_AUTH0 = window?.ENV?.SIDEBAR_BACKEND_URL_AUTH0;
export const OVERLAP_MARKER_HEIGHT = 4;
export const FLIGHT_CENTER_URL =
  window && window.ENV && window.ENV.FLIGHT_CENTER_URL;
export const FLIGHT_CENTER_WIDTH =
  window && window.ENV && window.ENV.FLIGHT_CENTER_WIDTH;
export const FLIGHT_CENTER_HEIGHT =
  window && window.ENV && window.ENV.FLIGHT_CENTER_HEIGHT;

export const TOOLTIP_DEBOUNCE =
  window && window.ENV && window.ENV.TOOLTIP_DEBOUNCE;
export const ORDER_WINDOW_URL = window && window.ENV && window.ENV.ORDER_WINDOW;
export const ORDER_WINDOW_WIDTH =
  window && window.ENV && window.ENV.ORDER_WINDOW_WIDTH;
export const ORDER_WINDOW_HEIGHT =
  window && window.ENV && window.ENV.ORDER_WINDOW_HEIGHT;
export const ON_DEMAND_URL = window && window.ENV && window.ENV.ON_DEMAND_URL;

export const CENTRIK_ACCESS_URL = getFromEnv('', env => env.CENTRIK_ACCESS_URL);

export const CENTRIK_ACCIDENT_WORKFLOW_ID = getFromEnv(
  '',
  env => env.CENTRIK_ACCIDENT_WORKFLOW_ID
);

export const CENTRIK_INCIDENT_WORKFLOW_ID = getFromEnv(
  '',
  env => env.CENTRIK_INCIDENT_WORKFLOW_ID
);

export const SUPPORT_URL = getFromEnv('', env => env.SUPPORT_URL);

export const PATH_TO_CUSTOMER_PREFERENCES =
  window?.ENV?.PATH_TO_CUSTOMER_PREFERENCES;
export const LINK_TO_CUSTOMER_PREFERENCES = `${gvUrl}${PATH_TO_CUSTOMER_PREFERENCES}`;

export const EMPTY_LEG_WINDOW_URL =
  window && window.ENV && window.ENV.EMPTY_LEG_WINDOW;
export const EMPTY_LEG_WINDOW_WIDTH =
  (window && window.ENV && window.ENV.EMPTY_LEG_WINDOW_WIDTH) || 1510;
export const EMPTY_LEG_WINDOW_HEIGHT =
  (window && window.ENV && window.ENV.EMPTY_LEG_WINDOW_HEIGHT) || 880;

export const MX_URL = window && window.ENV && window.ENV.MX_URL;

export const AIRCRAFT_PROFILE_URL =
  window && window.ENV && window.ENV.AIRCRAFT_PROFILE_URL;
interface AircraftMxConfigZones {
  redZone: number;
  amberZone: number;
}
interface AircraftMxConfig {
  landings: AircraftMxConfigZones;
  hours: AircraftMxConfigZones;
  days: AircraftMxConfigZones;
}

export const AIRCRAFT_MX_CONFIG_BY_AC_TYPE: {
  [acType: string]: AircraftMxConfig;
} = window && window.ENV && window.ENV.AIRCRAFT_MX_CONFIG_BY_AC_TYPE;
export const AIRCRAFT_TYPE_IDS_FOR_MX_DUE: {
  [acType: string]: number[];
} = window && window.ENV && window.ENV.AIRCRAFT_TYPE_IDS_FOR_MX_DUE;
export const HOLD_AC_TYPE_ID =
  window && window.ENV && window.ENV.HOLD_AC_TYPE_ID;
export const VJ_OPERATING_COMPANY_ID =
  window && window.ENV && window.ENV.VJ_OPERATING_COMPANY_ID;
export const VISIBLE_AC_TYPES = (window &&
  window.ENV &&
  window.ENV.VISIBLE_AC_TYPES) || [5, 6, 7, 10, 16, 118];
export const VISIBLE_OPERATING_COMPANIES = (window &&
  window.ENV &&
  window.ENV.VISIBLE_OPERATING_COMPANIES) || [
  1,
  58,
  1451,
  10667,
  10134,
  416,
  55141,
  90791,
  84941,
  85441,
  12616,
  112290,
];
export const XO_JET_OPERATING_COMPANY_ID =
  window?.ENV?.XO_JET_OPERATING_COMPANY_ID;
export const RWA_OPERATING_COMPANY_ID = window?.ENV?.RWA_OPERATING_COMPANY_ID;
export const JS_OPERATING_COMPANY_ID = window?.ENV?.JS_OPERATING_COMPANY_ID;
export const WAC_OPERATING_COMPANY_ID = window?.ENV?.WAC_OPERATING_COMPANY_ID;
export const AH_OPERATING_COMPANY_ID = window?.ENV?.AH_OPERATING_COMPANY_ID;

export const USE_AUTH = window?.ENV?.AUTH_PROVIDER === 'auth0';
export const AUTH_DOMAIN = window?.ENV?.AUTH_DOMAIN;
export const AUTH_CLIENT_ID = window?.ENV?.AUTH_CLIENT_ID;
export const AUTH_AUDIENCE = window?.ENV?.AUTH_AUDIENCE;
export const AUTH_SCOPE = window?.ENV?.AUTH_SCOPE;

export const PAST_HANDOVER_REMARKS_DAYS_VIEW =
  window?.ENV?.PAST_HANDOVER_REMARKS_DAYS_VIEW;
export const GLOBAL_HUD_ENABLED = window?.ENV?.GLOBAL_HUD_ENABLED;
export const OLD_PFA_ENABLED = window?.ENV?.OLD_PFA_ENABLED;

export const DATADOG_RUM_APPLICATION_ID =
  window?.ENV?.DATADOG_RUM_APPLICATION_ID;
export const DATADOG_RUM_CLIENT_TOKEN = window?.ENV?.DATADOG_RUM_CLIENT_TOKEN;
export const DATADOG_SITE = window?.ENV?.DATADOG_SITE;
export const DATADOG_APPLICATION_SERVICE =
  window?.ENV?.DATADOG_APPLICATION_SERVICE;
export const PEAK_DAY_TYPES = window?.ENV?.PEAK_DAY_TYPES;
export const PEAK_DAY_REGIONS = window?.ENV?.PEAK_DAY_REGIONS;

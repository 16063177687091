import { PureComponent } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import { subMiddle } from '../../root';
import Popper from 'popper.js';
import TooltipMaintenance from './vistajet/maintenance';
import TooltipMaintenanceItem from './vistajet/maintenance-item';
import { SegmentType, PeakDaysSegmentType } from '../../types/segment-types';
import EventElement from '../../types/event-element';
import Airport from '../../types/airport';
import Maintenance from '../../types/maintenance';
import TooltipEmptyLeg from './vistajet/empty-leg';
import EmptyLegOffer from '../../types/empty-leg-offer';
import TooltipNote from './vistajet/note';
import Note, { MergedNote } from '../../types/note';
import TooltipOneWay from './vistajet/one-way';
import OneWayOffer from '../../types/one-way-offer';
import Aircraft from '../../types/aircraft';
import { getAircraftById } from '../../selectors';
import { store as reduxStore } from '../../root';
import TooltipPeakDay from './vistajet/peak-day';
import PeakDay from '../../types/peak-day';
import MaintenanceItem from '../../types/maintenance-item';
import { hasPermission } from '../../utils/check-permissions';
import { debounce } from 'lodash';
import { TOOLTIP_DEBOUNCE } from '../../constants/environment';
import { isMergeNote } from '../../utils/note';

interface ConnectedProps {
  type: SegmentType | PeakDaysSegmentType;
  exactHoveredNote: Note;
  segmentData: EventElement | PeakDay;
  airportsById: { [id: number]: Airport };
  aircraft: Aircraft;
  isNoteMenuOpened: boolean;
  hasEditNotePermission: boolean;
  hasDeleteNotePermission: boolean;
  screenHeight: number;
}
interface OwnProps {}

interface Props extends ConnectedProps, OwnProps {}

class TooltipSegment extends PureComponent<Props> {
  popper: Popper;
  createPopper = action => {
    const tooltipWrapper = document.getElementsByClassName(
      'tooltip-segment'
    )[0] as HTMLDivElement;
    const actionRef = action.payload[1];
    if (tooltipWrapper && actionRef) {
      this.popper = new Popper(actionRef, tooltipWrapper, {
        placement: 'top',
        modifiers: {
          preventOverflow: { enabled: true },
          computeStyle: { gpuAcceleration: false },
        },
        positionFixed: true,
      });
    }
  };
  debouncedHover = debounce(this.createPopper, TOOLTIP_DEBOUNCE);

  removePopper = () => {
    this.debouncedHover.cancel();
    if (this.popper) {
      this.popper.destroy();
      delete this.popper;
    }
  };
  componentDidMount() {
    window.addEventListener('resize', this.removePopper);
    subMiddle.on(actions.userHoverSegment, this.debouncedHover);
    subMiddle.on(actions.userHoverMergedNotes, this.debouncedHover);
    subMiddle.on(actions.userZoomHor, this.removePopper);
    subMiddle.on(actions.userZoomVer, this.removePopper);
    subMiddle.on(actions.userCloseTooltipSegment, this.removePopper);
    subMiddle.on(actions.userOpenNoteContextMenu, this.removePopper);
    subMiddle.on(actions.userOpenCreateEventsMenu, this.removePopper);
    subMiddle.on(actions.userOpenContextMenuForPeakDay, this.removePopper);
    subMiddle.on(actions.userOpenContextMenuForMxEvent, this.removePopper);
    subMiddle.on(actions.userOpenContextMenuForOneWayOffer, this.removePopper);
    subMiddle.on(
      actions.userOpenContextMenuForEmptyLegOffer,
      this.removePopper
    );
    subMiddle.on(actions.userHoverFlight, this.removePopper);
    subMiddle.on(actions.userHoverCrew, this.removePopper);
    subMiddle.on(actions.userHoverOverlap, this.removePopper);
    subMiddle.on(actions.doOpenNoteForEdit, this.removePopper);
    subMiddle.on(actions.doCreateNote, this.removePopper);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.removePopper);
  }
  getTooltipFromSegmentType(): React.ReactNode {
    const { type, hasDeleteNotePermission, hasEditNotePermission } = this.props;
    switch (type) {
      case 'maintenances': {
        const { segmentData, airportsById, aircraft } = this.props;
        return (
          <TooltipMaintenance
            segmentData={(segmentData as EventElement) as Maintenance}
            airportsById={airportsById}
            aircraft={aircraft}
          />
        );
      }

      case 'maintenanceItems': {
        const { segmentData } = this.props;
        const data = (segmentData as EventElement) as MaintenanceItem;
        return <TooltipMaintenanceItem segmentData={data} />;
      }
      case 'emptyLegOffers': {
        const { segmentData, airportsById } = this.props;
        return (
          <TooltipEmptyLeg
            segmentData={(segmentData as EventElement) as EmptyLegOffer}
            airportsById={airportsById}
          />
        );
      }
      case 'oneWayOffers': {
        const { segmentData, aircraft, airportsById } = this.props;
        return (
          <TooltipOneWay
            segmentData={(segmentData as EventElement) as OneWayOffer}
            airportsById={airportsById}
            aircraft={aircraft}
          />
        );
      }
      case 'generalNotes':
      case 'availabilityNotes': {
        const { segmentData, exactHoveredNote } = this.props;
        const data = (segmentData as EventElement) as Note | MergedNote;
        const typeName = type === 'generalNotes' ? 'General' : 'Availability';
        if (isMergeNote(data)) {
          return data.notes.map(n => (
            <div key={n.id}>
              {
                <TooltipNote
                  segmentData={n}
                  typeName={typeName}
                  hasEditPermission={hasEditNotePermission}
                  hasDeletePermission={hasDeleteNotePermission}
                  hasActionButtons={true}
                  exactHoveredNote={exactHoveredNote}
                />
              }
            </div>
          ));
        }
        return (
          <TooltipNote
            segmentData={data}
            typeName={typeName}
            hasEditPermission={hasEditNotePermission}
            hasDeletePermission={hasDeleteNotePermission}
            hasActionButtons={true}
            exactHoveredNote={exactHoveredNote}
          />
        );
      }
      case 'peakDays': {
        const { segmentData } = this.props;
        return <TooltipPeakDay segmentData={segmentData as PeakDay} />;
      }
    }
  }

  render() {
    const { type, isNoteMenuOpened, screenHeight } = this.props;
    return (
      <div>
        {type && type !== 'crewAssignment' && type !== 'crewRoster' && (
          <div
            style={{ zIndex: 99, maxHeight: `${screenHeight}px` }}
            className="tooltip-segment"
            onMouseLeave={() =>
              !isNoteMenuOpened &&
              reduxStore.dispatch(actions.userCloseTooltipSegment())
            }
          >
            {this.getTooltipFromSegmentType()}
          </div>
        )}
      </div>
    );
  }
}

export default connect((state: RootState) => ({
  isNoteMenuOpened: state.eventElementCreationEdit.isNoteContextMenuOpened,
  hasDeleteNotePermission: hasPermission(state, 'AG-Timeline-Delete-Note'),
  hasEditNotePermission: hasPermission(state, 'AG-Timeline-Create-Note'),
  type: state.ui.hoveredSegment,
  segmentData: state.ui.dataForSegment,
  exactHoveredNote: state.ui.exactHoveredNote,
  airportsById: state.airports.airportsById,
  aircraft:
    state.ui.dataForSegment &&
    (state.ui.dataForSegment as EventElement).aircraftId
      ? getAircraftById(
          state,
          (state.ui.dataForSegment as EventElement).aircraftId
        )
      : null,
  screenHeight: state.ui.height,
}))(TooltipSegment);

import { PureComponent, CSSProperties } from 'react';
import { IdcardOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { LINK_TO_CUSTOMER_PREFERENCES } from '../../constants/environment';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { hasPermission } from '../../utils/check-permissions';

const iconBox: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  marginLeft: '12px',
  height: '36px',
  cursor: 'pointer',
};
const iconStyle: CSSProperties = {
  fontSize: '22px',
  color: ' rgba(0, 0, 0, 0.65)',
};

interface StateProps {
  hasPermission: boolean;
}
class LinkToCustomerPreferencesTab extends PureComponent<StateProps> {
  render() {
    if (!this.props.hasPermission) {
      return null;
    }
    return (
      <Tooltip title="Go to customer account preferences">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={LINK_TO_CUSTOMER_PREFERENCES}
          style={iconBox}
        >
          <IdcardOutlined style={iconStyle} />
        </a>
      </Tooltip>
    );
  }
}

export const LinkToCustomerPreferencesTabComponent = connect<StateProps>(
  (state: RootState) => ({
    hasPermission: hasPermission(state, 'AG-Timeline-Customer-preferences'),
  })
)(LinkToCustomerPreferencesTab);

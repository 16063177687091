import { isEqual } from 'lodash';
import { utc } from 'moment';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { userCloseTooltip, userHoverFlight } from '../../actions';
import {
  getBlockDurationFormatted,
  getEstimatedDurationFormatted,
  getOpsMemo,
  getScheduledDurationFormatted,
} from '../../common/flight/flight-labels';
import { subMiddle } from '../../root';
import { AfbCrewType } from '../../types/afbCrewType';
import Aircraft from '../../types/aircraft';
import Airport from '../../types/airport';
import Flight, { AFBColorType } from '../../types/flight';
import Timezone from '../../types/timezone';
import { crewAFBVersionAdapter } from './adapter';
import AdditionalInfoRow from './additional-info-row';
import AirportPermitsRow from './airport-permits-row';
import AirportRow from './airport-row';
import LegBusinessTypeRow from './business-type-row';
import FlightTooltipCodes from './codes';
import CountryRow from './country-row';
import CrewGroup from './crew-group';
import HandlingRow from './handling-row';
import LegPermitsRow from './leg-permits-row';
import OpsMemoBlock from './ops-memo-block';
import OrderTypeAndFlightStateRow from './order-type-and-flight-state-row';
import PaxRow from './pax-row';
import PricesRow from './prices-row';
import RemarksBlock from './remarks-block';
import ServicesRow from './services-row';
import SlotsRow from './slots-row';
import TimeGroup from './time-group';

const MS_IN_SEC = 1000;
const SEC_IN_MIN = 60;
const MIN_IN_HOUR = 60;
const HRS_IN_DAY = 24;

const TWENTY_FOUR_HOURS_IN_MS =
  MS_IN_SEC * SEC_IN_MIN * MIN_IN_HOUR * HRS_IN_DAY;

const TOOLTIP_VIEPORT_BUFFER = 30;

export interface Crew {
  roleAbbr: string;
  crewCode: string;
  firstName: string;
  lastName: string;
}

interface DispatchProps {
  closeTooltip: () => void;
}

interface Props extends DispatchProps {
  selectedFlight: Flight;
  airportsById: { [id: number]: Airport };
  HAS_PSS_PERMISSION: boolean;
  HAS_PAX_PERMISSION: boolean;
  HAS_REMARKS_PERMISSION: boolean;
  HAS_PROGRAM_REVENUE_PERMISSION: boolean;
  HAS_OD_REVENUE_PERMISSION: boolean;
  timezonesData: { [id: number]: Timezone };
  aircraft: Aircraft;
  flightTooltipWidth?: number;
  crew: Array<Crew>;
  resizable?: boolean;
  flightBoundingRect?: DOMRect;
}
interface State {
  squareForm: boolean;
}

class TooltipParent extends PureComponent<Props, State> {
  crewType: Array<AfbCrewType>;
  tooltipContainerRef: HTMLDivElement;
  constructor(props: Props) {
    super(props);
    this.state = {
      squareForm: false,
    };
  }
  getTooltipContainerRef = ref => (this.tooltipContainerRef = ref);

  resetStateToDefault = () => {
    if (this.state.squareForm) {
      this.setState({ squareForm: false });
    }
  };
  componentDidMount() {
    if (this.shouldTransformToSquareForm()) {
      this.setState({
        squareForm: true,
      });
    }
    // Reset flight tooltip form to default on flight hover
    // because there's no obvious solution how to define
    // a condition based on components height values
    // when a tooltip should be resized back to the default form
    subMiddle.on(userHoverFlight, this.resetStateToDefault);
  }
  componentWillUnmount() {
    subMiddle.off(userHoverFlight, this.resetStateToDefault);
  }
  shouldTransformToSquareForm() {
    return (
      this.props.resizable &&
      !this.state.squareForm &&
      this.tooltipContainerRef &&
      this.tooltipContainerRef.offsetHeight >
        window.innerHeight - TOOLTIP_VIEPORT_BUFFER
    );
  }
  componentDidUpdate() {
    if (this.shouldTransformToSquareForm()) {
      this.setState({
        squareForm: true,
      });
    }
  }

  getAirport(type: 'dep' | 'arr') {
    const { airportsById } = this.props;
    const { departureAirportId, arrivalAirportId } = this.props.selectedFlight;
    if (type === 'dep') {
      return airportsById[departureAirportId];
    }
    if (type === 'arr') {
      return airportsById[arrivalAirportId];
    }
    return null;
  }
  getCrewType() {
    const { afbReedFeedsByCrewCode, afbSnapshot } = this.props.selectedFlight;
    const { crew } = this.props;
    const crewType = crew.length
      ? crew.map(c =>
          this.isCorrectAfb()
            ? afbReedFeedsByCrewCode && afbReedFeedsByCrewCode[c.crewCode]
              ? crewAFBVersionAdapter(c, afbSnapshot, afbReedFeedsByCrewCode)
              : { ...c, type: AFBColorType.RED }
            : { ...c, type: AFBColorType.BLACK }
        )
      : [];
    if (!isEqual(this.crewType, crewType)) {
      this.crewType = crewType;
    }
    return this.crewType;
  }
  isCorrectAfb() {
    const currentTime = utc().format('x');
    const { afbSnapshot, departureUtcScheduled } = this.props.selectedFlight;
    return (
      afbSnapshot &&
      ((departureUtcScheduled >= +currentTime &&
        departureUtcScheduled <= +currentTime + TWENTY_FOUR_HOURS_IN_MS) ||
        departureUtcScheduled < +currentTime)
    );
  }
  coversFlight() {
    const t = this.tooltipContainerRef.getBoundingClientRect();
    const fl = this.props.flightBoundingRect;
    return (
      t.left < fl.right &&
      t.right > fl.left &&
      t.top < fl.bottom &&
      t.bottom > fl.top
    );
  }

  render() {
    const { selectedFlight, HAS_PSS_PERMISSION } = this.props;
    const {
      departureUtcEstimated,
      departureUtcBlock,
      customerName,
      requestedAircraftTypeName,
      bookedBy,
      contractMsId,
      orderBusinessTypeId,
      id,
    } = selectedFlight;

    const { squareForm } = this.state;
    return (
      <div
        ref={this.getTooltipContainerRef}
        className={
          squareForm
            ? 'square-form-tooltip__container'
            : 'default-form-tooltip__container'
        }
        onMouseLeave={this.props.closeTooltip}
        onMouseOver={e => {
          e.stopPropagation();
          if (
            !this.state.squareForm ||
            (this.state.squareForm &&
              this.props.flightBoundingRect &&
              !this.coversFlight())
          ) {
            this.props.closeTooltip();
          }
        }}
      >
        <div className="ft">
          <LegBusinessTypeRow
            legBusinessTypeId={this.props.selectedFlight.legBusinessTypeId}
          />
          <OrderTypeAndFlightStateRow
            selectedFlight={this.props.selectedFlight}
          />
          <div className="ft-customer-name">{customerName}</div>

          <div
            className={
              squareForm ? 'square-form-tooltip__main-content__container' : ''
            }
          >
            <div
              className={
                squareForm
                  ? 'square-form-tooltip__main-content__left-column'
                  : ''
              }
            >
              <FlightTooltipCodes
                aircraft={this.props.aircraft}
                arrivalAirport={this.getAirport('arr')}
                callSignValue={this.props.selectedFlight.callSignValue}
                departureAirport={this.getAirport('dep')}
              />

              <AirportRow
                arrivalAirport={this.getAirport('arr')}
                departureAirport={this.getAirport('dep')}
              />

              <CountryRow
                arrivalAirport={this.getAirport('arr')}
                departureAirport={this.getAirport('dep')}
              />

              <TimeGroup
                arrivalAirportId={this.props.selectedFlight.arrivalAirportId}
                arrivalUtc={this.props.selectedFlight.arrivalUtcScheduled}
                departureAirportId={
                  this.props.selectedFlight.departureAirportId
                }
                departureUtc={this.props.selectedFlight.departureUtcScheduled}
                durationString={getScheduledDurationFormatted(selectedFlight)}
                title="scheduled"
              />

              {departureUtcEstimated && (
                <TimeGroup
                  arrivalAirportId={this.props.selectedFlight.arrivalAirportId}
                  arrivalUtc={this.props.selectedFlight.arrivalUtcEstimated}
                  departureAirportId={
                    this.props.selectedFlight.departureAirportId
                  }
                  departureUtc={this.props.selectedFlight.departureUtcEstimated}
                  durationString={getEstimatedDurationFormatted(selectedFlight)}
                  title="estimated"
                />
              )}

              {departureUtcBlock && (
                <TimeGroup
                  arrivalAirportId={this.props.selectedFlight.arrivalAirportId}
                  arrivalUtc={this.props.selectedFlight.arrivalUtcBlock}
                  departureAirportId={
                    this.props.selectedFlight.departureAirportId
                  }
                  departureUtc={this.props.selectedFlight.departureUtcBlock}
                  durationString={getBlockDurationFormatted(selectedFlight)}
                  title="block"
                />
              )}

              <div className="ft-line" />

              <PricesRow
                HAS_OD_REVENUE_PERMISSION={this.props.HAS_OD_REVENUE_PERMISSION}
                HAS_PROGRAM_REVENUE_PERMISSION={
                  this.props.HAS_PROGRAM_REVENUE_PERMISSION
                }
                selectedFlight={this.props.selectedFlight}
              />

              <PaxRow
                HAS_PAX_PERMISSION={this.props.HAS_PAX_PERMISSION}
                afbSnapshot={this.props.selectedFlight.afbSnapshot}
                leadName={this.props.selectedFlight.leadName}
                numberOfPassengers={
                  this.props.selectedFlight.numberOfPassengers
                }
                passengersCount={this.props.selectedFlight.passengersCount}
                crewType={this.getCrewType()}
                isCorrectAfb={this.isCorrectAfb()}
              />

              <div className="ft-line" />

              <AdditionalInfoRow
                requestedAircraftTypeName={requestedAircraftTypeName}
                bookedBy={bookedBy}
                contractMsId={contractMsId}
                orderBusinessTypeId={orderBusinessTypeId}
                flightId={id}
              />

              {this.getCrewType() && this.getCrewType().length > 0 && (
                <CrewGroup crewType={this.getCrewType()} />
              )}
            </div>
            <div
              className={
                squareForm
                  ? 'square-form-tooltip__main-content__right-column'
                  : ''
              }
            >
              {HAS_PSS_PERMISSION && (
                <>
                  <HandlingRow selectedFlight={this.props.selectedFlight} />

                  <SlotsRow selectedFlight={this.props.selectedFlight} />

                  <ServicesRow selectedFlight={this.props.selectedFlight} />

                  <AirportPermitsRow
                    selectedFlight={this.props.selectedFlight}
                  />
                  <LegPermitsRow
                    selectedFlight={this.props.selectedFlight}
                    arrivalAirport={this.getAirport('arr')}
                    departureAirport={this.getAirport('dep')}
                  />
                </>
              )}
            </div>
          </div>
          <div className="square-form-tooltip__remarks-and-ops-memo">
            <RemarksBlock
              HAS_REMARKS_PERMISSION={this.props.HAS_REMARKS_PERMISSION}
              remarks={this.props.selectedFlight.remarks}
            />

            <OpsMemoBlock
              operationalMemo={getOpsMemo(this.props.selectedFlight)}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  closeTooltip: () => {
    dispatch(userCloseTooltip());
  },
});

const TooltipParentConnected = connect(null, mapDispatchToProps)(TooltipParent);

export default TooltipParentConnected;

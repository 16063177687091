import { HandoverEmail, HandoverRemark } from '../../types/handover-remarks';
import { axiosAuthed as axios } from '../axios-config';

// environment variable
const baseUrl = 'https://timeline.dev.vistajet.io';
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  },
};

export const getAllHandoverRemarks = () =>
  axios
    .get(`${baseUrl}/handover/api`)
    .then(resp => resp.data)
    .catch(e => {
      throw e;
    });

export const getHandoverRemarksForFlight = (flightLegId: number) =>
  axios
    .get(`${baseUrl}/handover/api?flightLegId=${flightLegId}`, config)
    .then(resp => resp.data)
    .catch(e => {
      throw e;
    });

export const getHandoverRemarksForAircraft = (aircraftId: number) =>
  axios
    .get(`${baseUrl}/handover/api?aircraftId=${aircraftId}`, config)
    .then(resp => resp.data)
    .catch(e => {
      throw e;
    });

export const getHandoverRemarksById = (id: string) =>
  axios
    .get(`${baseUrl}/handover/api?id=${id}`, config)
    .then(resp => resp.data)
    .catch(e => {
      throw e;
    });

export const saveHandoverRemark = (remark: HandoverRemark) =>
  axios
    .post<{ saved: boolean }>(`${baseUrl}/handover/api`, JSON.stringify(remark), config)
    .then(d => d.data.saved)
    .catch(e => {
      throw e;
    });

export const deleteHandoverRemark = (id: string) =>
  axios
    .delete<{ deactivated: boolean }>(`${baseUrl}/handover/api?id=${id}`, config)
    .then(d => d.data.deactivated)
    .catch(e => {
      throw e;
    });

export const sendHandoverEmail = (email: HandoverEmail) =>
  axios
    .post(`${baseUrl}/handover/api`, JSON.stringify(email), config)
    .then(d => d)
    .catch(e => {
      throw e;
    });

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import Flight from '../types/flight';

export type SearchType =
  | 'Order ID'
  | 'Leg ID'
  | 'Customer'
  | 'Passenger'
  | 'Airport'
  | 'Country'
  | 'Crew Member'
  | 'Overflight country';

export type SearchQueryAllFilters = {
  Customer: string;
  Passenger: string;
  Airport: string;
  Country: string;
  'Crew Member': string;
  'Overflight country': string;
};

export const searchMultipleBarTypeList: SearchType[] = [
  'Customer',
  'Passenger',
  'Airport',
  'Country',
  'Crew Member',
  'Overflight country',
];

export const searchTypeList: SearchType[] = [
  'Order ID',
  'Leg ID',
  ...searchMultipleBarTypeList,
];

export interface SearchReducerShape {
  searchType: SearchType;
  searchQuery: string;
  searching: boolean;
  searchError: string;
  focusedFoundElement: number;
  searchedResult: {
    flightArr: Flight[];
  };
  searchQueryAllFilters: SearchQueryAllFilters;
}

const initialState: SearchReducerShape = {
  searchType: searchTypeList[0],
  searchQuery: '',
  searching: false,
  searchError: '',
  focusedFoundElement: 0,
  searchedResult: {
    flightArr: [],
  },
  searchQueryAllFilters: {
    Customer: '',
    Passenger: '',
    Airport: '',
    Country: '',
    'Crew Member': '',
    'Overflight country': '',
  },
};
const setDefaultSearchType = (payload: SearchType) => {
  if (payload === 'Leg ID') {
    return 'Leg ID';
  }
  return 'Order ID';
};

export const searchReducer = reducerWithInitialState(initialState)
  .case(actions.userChangeSearchQuery, (state, payload) => ({
    ...state,
    searchQuery: payload,
    searchError: initialState.searchError,
    focusedFoundElement: initialState.focusedFoundElement,
    searchedResult: initialState.searchedResult,
  }))
  .case(actions.userChangeSearchQueryAllFilters, (state, payload) => {
    const { query, searchType } = payload;
    const searchQueryAllFilters = {
      ...state.searchQueryAllFilters,
      [searchType]: query,
    };
    return {
      ...state,
      searchQueryAllFilters,
      searchError: initialState.searchError,
      focusedFoundElement: initialState.focusedFoundElement,
      searchedResult: initialState.searchedResult,
    };
  })
  .case(actions.userToggleMultipleSearchBarPanel, state => ({
    ...state,
    searchQuery: initialState.searchQuery,
    searchQueryAllFilters: initialState.searchQueryAllFilters,
    searchType: setDefaultSearchType(state.searchType),
  }))
  .case(actions.userResetMultipleSearchBarPanel, state => ({
    ...state,
    searchType: initialState.searchType,
    searchQueryAllFilters: initialState.searchQueryAllFilters,
    searchError: initialState.searchError,
    focusedFoundElement: initialState.focusedFoundElement,
    searchedResult: initialState.searchedResult,
  }))

  .case(actions.userSearchNext, (state, payload) => ({
    ...state,
    focusedFoundElement:
      state.focusedFoundElement + 2 > payload
        ? 0
        : state.focusedFoundElement + 1,
  }))
  .case(actions.userSearchPrev, (state, payload) => ({
    ...state,
    focusedFoundElement:
      state.focusedFoundElement === 0
        ? Math.max(0, payload - 1)
        : Math.max(0, state.focusedFoundElement - 1),
  }))
  .case(actions.userFocusFirstFound, state => ({
    ...state,
    focusedFoundElement: 0,
  }))
  .case(actions.userChangeSearchType, (state, payload) => ({
    ...state,
    searchType: payload,
    searchError: initialState.searchError,
    focusedFoundElement: initialState.focusedFoundElement,
    searchedResult: initialState.searchedResult,
  }))
  .case(actions.userSearchLeg.started, (state, payload) => {
    const { id, isNearestAC } = payload;
    const newState = {
      searching: true,
      searchError: initialState.searchError,
      searchedResult: initialState.searchedResult,
    };
    if (isNearestAC) {
      return {
        ...state,
        ...newState,
        searchType: 'Leg ID',
        searchQuery: `${id}`,
      };
    }
    return {
      ...state,
      ...newState,
    };
  })
  .case(actions.userSearchOrder.started, state => ({
    ...state,
    searching: true,
    searchError: initialState.searchError,
    searchedResult: initialState.searchedResult,
  }))
  .case(actions.userSearchOrder.done, (state, payload) => ({
    ...state,
    searching: false,
    searchedResult: {
      flightArr: payload.result,
    },
  }))
  .case(actions.userSearchLeg.done, (state, payload) => ({
    ...state,
    searching: false,
    searchedResult: {
      flightArr: payload.result && [payload.result],
    },
  }))
  .cases(
    [actions.userSearchLeg.failed, actions.userSearchOrder.failed],
    (state, payload) => ({
      ...state,
      searching: false,
      searchError: payload.error && payload.error.message,
    })
  );

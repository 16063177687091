import Flight, {
  OrderBusinessTypeId,
  LegBusinessType,
  FlightServiceStatus,
  PermissionSlotType,
  Permit,
  FlightPlanType,
  FlightLabelTimeType,
} from '../../types/flight';
import { duration, utc } from 'moment';
import { HANDLING_ID } from '../../constants/index';
import Airport from '../../types/airport';
import Timezone from '../../types/timezone';
import { getServicesStatusId } from './flight-check-status';
import { chain } from 'lodash';

export const getOrderTypeLabel = (ot: OrderBusinessTypeId) => {
  switch (ot) {
    case OrderBusinessTypeId.PROGRAM: {
      return 'Program';
    }
    case OrderBusinessTypeId.ON_DEMAND: {
      return 'On demand';
    }
    case OrderBusinessTypeId.PRIVATE: {
      return 'Private';
    }
    case OrderBusinessTypeId.DEMO: {
      return 'Demo';
    }
    case OrderBusinessTypeId.MAINTENANCE: {
      return 'Maintenance';
    }
    case OrderBusinessTypeId.MANUFACTURER: {
      return 'Manufacturer';
    }
    case OrderBusinessTypeId.JET_MEMBER: {
      return 'Jet Member';
    }
    case OrderBusinessTypeId.OWNER: {
      return 'Owner';
    }
    case OrderBusinessTypeId.OWNER_RELEASE: {
      return 'Owner release';
    }
    case OrderBusinessTypeId.AMBULANCE: {
      return 'Ambulance';
    }
    case OrderBusinessTypeId.MARKETING: {
      return 'Marketing';
    }
    case OrderBusinessTypeId.LEASE: {
      return 'Lease';
    }
    case OrderBusinessTypeId.OTHER_MISC: {
      return 'Other';
    }
    case OrderBusinessTypeId.VJ_DIRECT: {
      return 'VJ Direct';
    }
    case OrderBusinessTypeId.RETAIL: {
      return 'Retail';
    }
    case OrderBusinessTypeId.B2S: {
      return 'B2S';
    }
    case OrderBusinessTypeId.WHOLESALE: {
      return 'Wholesale';
    }
    case OrderBusinessTypeId.ELITE: {
      return 'Elite';
    }
  }
};

export const getReadyForPreparationFillPattern = (f: Flight) => {
  let patternId = 'notReadyForPreparationFlight';
  if (f.sensitive || (f.sensitive && f.demoFlight))
    return (patternId += 'SENSITIVE');
  if (f.demoFlight) return (patternId += 'DEMO');
  switch (f.orderBusinessTypeId) {
    case OrderBusinessTypeId.PROGRAM: {
      patternId += 'PROGRAM';
      break;
    }
    case OrderBusinessTypeId.ON_DEMAND: {
      patternId += 'ON_DEMAND';
      break;
    }
    case OrderBusinessTypeId.PRIVATE: {
      patternId += 'PRIVATE';
      break;
    }
    case OrderBusinessTypeId.DEMO: {
      patternId += 'DEMO';
      break;
    }
    case OrderBusinessTypeId.MAINTENANCE: {
      patternId += 'MAINTENANCE';
      break;
    }

    case OrderBusinessTypeId.MANUFACTURER: {
      patternId += 'MANUFACTURER';
      break;
    }
    case OrderBusinessTypeId.OWNER: {
      patternId += 'OWNER';
      break;
    }
    case OrderBusinessTypeId.AMBULANCE: {
      patternId += 'AMBULANCE';
      break;
    }
    case OrderBusinessTypeId.MARKETING: {
      patternId += 'MARKETING';
      break;
    }
    case OrderBusinessTypeId.OWNER_RELEASE:
      patternId += 'OWNER_RELEASE';
      break;
    case OrderBusinessTypeId.LEASE: {
      patternId += 'LEASE';
      break;
    }
    case OrderBusinessTypeId.OTHER_MISC: {
      patternId += 'OTHER_MISC';
      break;
    }
    case OrderBusinessTypeId.VJ_DIRECT: {
      patternId += 'VJ_DIRECT';
      break;
    }
    case OrderBusinessTypeId.B2S: {
      patternId += 'B2S';
      break;
    }
    case OrderBusinessTypeId.RETAIL: {
      patternId += 'RETAIL';
      break;
    }
    case OrderBusinessTypeId.WHOLESALE: {
      patternId += 'WHOLESALE';
      break;
    }
    case OrderBusinessTypeId.ELITE: {
      patternId += 'ELITE';
      break;
    }
    default: {
      patternId = 'DEFAULT';
      break;
    }
  }
  return patternId;
};

export const getBusinessTypeBadge = (legBusinessTypeId: LegBusinessType) => {
  switch (legBusinessTypeId) {
    case LegBusinessType.FERRY_CHARGEABLE:
    case LegBusinessType.FERRY_OPERATIONAL: {
      return ' - Ferry';
    }
    case LegBusinessType.LIVE_CHARGEABLE:
    case LegBusinessType.LIVE_OPERATIONAL: {
      return ' - Live';
    }
    case LegBusinessType.STAND_DAY: {
      return ' - Stand day';
    }
    case LegBusinessType.OVERNIGHT: {
      return ' - Overnight';
    }
    default: {
      return '';
    }
  }
};

export const getBlockDuration = (f: Flight) =>
  duration(f.arrivalUtcBlock - f.departureUtcBlock);
export const getEstimatedDuration = (f: Flight) =>
  duration(f.arrivalUtcEstimated - f.departureUtcEstimated);
export const getScheduledDuration = (f: Flight) =>
  duration(f.arrivalUtcScheduled - f.departureUtcScheduled);

export const getTripLength = (f: Flight) => {
  if (f.arrivalUtcBlock && f.departureUtcBlock) return getBlockDuration(f);
  if (f.arrivalUtcEstimated && f.departureUtcEstimated)
    return getEstimatedDuration(f);
  return getScheduledDuration(f);
};

export const getDurationFormatted = (duration: moment.Duration) => {
  const tripLengthHours = Math.floor(duration.asHours());
  const hoursString = tripLengthHours ? `${tripLengthHours}h` : '';
  const tripLengthMinutes = Math.floor(
    duration.subtract(tripLengthHours, 'hours').asMinutes()
  );
  const minutesString = tripLengthMinutes ? `${tripLengthMinutes}m` : '';
  return `${hoursString} ${minutesString}`;
};

export const getTripLengthFormatted = (f: Flight) =>
  getDurationFormatted(getTripLength(f));

export const getScheduledDurationFormatted = (f: Flight) =>
  getDurationFormatted(getScheduledDuration(f));

export const getEstimatedDurationFormatted = (f: Flight) => {
  if (!f.departureUtcEstimated || !f.arrivalUtcEstimated) return '';
  return getDurationFormatted(getEstimatedDuration(f));
};

export const getBlockDurationFormatted = (f: Flight) => {
  if (!f.departureUtcBlock || !f.arrivalUtcBlock) return '';
  return getDurationFormatted(getBlockDuration(f));
};

export const getOpsMemo = (f: Flight) => {
  if (f.opsMemo) return f.opsMemo;
  return 'No Operational Memo.';
};

export const getStatusName = (statusId: FlightServiceStatus) => {
  switch (statusId) {
    case FlightServiceStatus.NEW: {
      return 'New';
    }
    case FlightServiceStatus.NOT_NEEDED: {
      return 'Not Needed';
    }
    case FlightServiceStatus.REQUESTED: {
      return 'Requested';
    }
    case FlightServiceStatus.CONFIRMED: {
      return 'Confirmed';
    }
    case FlightServiceStatus.INVALID: {
      return 'Invalid';
    }
    case FlightServiceStatus.CANCELLED: {
      return 'Cancelled';
    }
    case FlightServiceStatus.NEEDS_REVIEW: {
      return 'Needs Review';
    }
    default: {
      return '';
    }
  }
};

export const getFlightServiceStyle = (styleId: FlightServiceStatus) => {
  switch (styleId) {
    case FlightServiceStatus.NEW: {
      return 'new';
    }
    case FlightServiceStatus.NOT_NEEDED:
    case FlightServiceStatus.CONFIRMED: {
      return 'confirmed';
    }
    case FlightServiceStatus.REQUESTED: {
      return 'requested';
    }
    case FlightServiceStatus.NEEDS_REVIEW: {
      return 'needs-review';
    }
    case FlightServiceStatus.INVALID:
    case FlightServiceStatus.CANCELLED: {
      return 'default';
    }
    default: {
      return 'new';
    }
  }
};

export const getRequestManagementTypeName = (typeId: boolean) => {
  if (typeId) return 'Auto';
  return 'Manual';
};

export const getServiceInfo = (f: Flight, airportId: number) =>
  getStatusName(getServicesStatusId(f, airportId));

export const getSlotTypeName = (slotTypeId: number) => {
  switch (slotTypeId) {
    case PermissionSlotType.INBOUND_PERMISSION: {
      return 'IN';
    }
    case PermissionSlotType.OUTBOUND_PERMISSION: {
      return 'OUT';
    }
    case PermissionSlotType.OVERFLIGHT_PERMISSION: {
      return 'OVF';
    }
    case PermissionSlotType.CANPASS: {
      return 'CANPASS';
    }
    case PermissionSlotType.IAG: {
      return 'IAG';
    }
    case PermissionSlotType.eAPIS: {
      return 'eAPIS';
    }
    case PermissionSlotType.PMT_TO_PRC: {
      return 'PMT_TO_PRC';
    }
    case PermissionSlotType.CAICOM: {
      return 'CAICOM';
    }
    case PermissionSlotType.USA_FSDO: {
      return 'USA_FSDO';
    }
    case PermissionSlotType.US_CUSTOMS: {
      return 'US_CUSTOMS';
    }
    case PermissionSlotType.CUSTOMS_CLEARENCE: {
      return 'CUSTOMS_CLEARENCE';
    }
    case PermissionSlotType.API: {
      return 'API';
    }
    case PermissionSlotType.DOT: {
      return 'DOT';
    }
    case PermissionSlotType.PTP: {
      return 'PTP';
    }
    case PermissionSlotType.TSA: {
      return 'TSA';
    }
    default:
      return 'unknown';
  }
};

export const getSlotInfo = (f: Flight, airportId: number): string => {
  if (f.slots) {
    const slot = f.slots.find(c => c.airportId === airportId);
    if (slot) {
      const info =
        slot.fromDate && slot.toDate
          ? `${utc(slot.fromDate).format('DD-MMM-YYYY HH:mm')} > ${utc(
              slot.toDate
            ).format('HH:mm')}
          `
          : '';
      return `${info} ${getStatusName(
        slot.flightServiceStatusId
      )} - ${getRequestManagementTypeName(slot.requestManagementType)}${
        slot.slotNumber ? ` - Slot # ${slot.slotNumber}` : ' - N/A'
      }`;
    }
  }
  return '';
};

export const getPermitInfo = (f: Flight, airportId: number): string => {
  if (f.permits) {
    const permit = f.permits.find(
      c =>
        c.airportId === airportId &&
        (c.permissionSlotTypeId === PermissionSlotType.INBOUND_PERMISSION ||
          c.permissionSlotTypeId === PermissionSlotType.OUTBOUND_PERMISSION)
    );
    if (permit) {
      return `${
        permit.flightServiceStatusId === FlightServiceStatus.INVALID
          ? ''
          : `${getStatusName(permit.flightServiceStatusId)} - `
      } ${getSlotTypeName(
        permit.permissionSlotTypeId
      )} - ${getRequestManagementTypeName(permit.requestManagementType)}${
        permit.flightServiceStatusId === FlightServiceStatus.CONFIRMED &&
        permit.number
          ? ` - PR # ${permit.number}`
          : ' - N/A'
      }`;
    }
  }
  return '';
};

export const getHandlingServiceInfo = (
  f: Flight,
  airportId: number
): string => {
  if (f.requests) {
    const request = f.requests.find(
      c => c.airportId === airportId && c.serviceTypeId === HANDLING_ID
    );
    if (request) {
      return `${getStatusName(
        request.flightServiceStatusId
      )} - ${getRequestManagementTypeName(request.requestManagementType)}`;
    }
  }
  return '';
};

export const getTimezonesByAirportId = (
  airportsbyId: { [id: number]: Airport },
  timezonesById: { [id: number]: Timezone },
  airportId: number
) => {
  if (!airportsbyId || !timezonesById) return [];
  const airport = airportsbyId[airportId];
  if (!airport || !airport.timezoneIds) return [];
  const timezones = chain(airport.timezoneIds)
    .map(id => timezonesById[id])
    .compact()
    .value();
  return timezones;
};

export const getTimezoneOffset = (
  timezones: Timezone[],
  flightTime: number
): number => {
  const effectiveTimezoneValue = timezones.find(
    r =>
      r.effectiveFromTimeUtc < flightTime && r.effectiveToTimeUtc > flightTime
  );
  if (!effectiveTimezoneValue) return null;
  if (
    effectiveTimezoneValue.dstFromTimeUtc < flightTime &&
    effectiveTimezoneValue.dstToTimeUtc > flightTime
  )
    return effectiveTimezoneValue.dstUtcOffset;
  return effectiveTimezoneValue.standardUtcOffset;
};
const getLegPermitsSlotType = (
  permitNumber: string,
  flightServiceStatusId: FlightServiceStatus
) => {
  if (permitNumber && flightServiceStatusId === FlightServiceStatus.CONFIRMED)
    return `PR # ${permitNumber}`;
  if (
    flightServiceStatusId !== FlightServiceStatus.CONFIRMED &&
    flightServiceStatusId !== FlightServiceStatus.INVALID
  )
    return getStatusName(flightServiceStatusId);
  return 'N/A';
};
export const getAdditionalPermitInfoForItem = (
  p: Permit,
  dep: Airport,
  arr: Airport
) => {
  if (!dep || !arr) return '';
  const icao =
    p.airportId === dep.id
      ? dep.ICAO
      : p.airportId === arr.id
      ? arr.ICAO
      : null;
  return `${
    p.place ? ` ${p.place.country} - ` : icao ? ` ${icao} - ` : ''
  } ${getSlotTypeName(p.permissionSlotTypeId)} - ${getLegPermitsSlotType(
    p.number,
    p.flightServiceStatusId
  )} - ${getRequestManagementTypeName(p.requestManagementType)}`;
};

export const getSensitiveAndDemo = (f: Flight) => {
  let result = '';
  result += f.sensitive ? 'Sensitive' : '';
  result += f.sensitive && f.demoFlight ? ' • ' : '';
  result += f.demoFlight ? 'Demo' : '';
  return result;
};

export const getFlightPlanTypeName = (planType: string) => {
  switch (planType) {
    case FlightPlanType.AUTO:
      return 'A';
    case FlightPlanType.MANUAL:
      return 'M';
    default:
      return 'N/A';
  }
};

export const getTimeLabelForFlightDep = (
  f: Flight,
  type: FlightLabelTimeType
) => {
  if (type === FlightLabelTimeType.NONE) {
    return '';
  }
  let depTime = 0;
  if (type === FlightLabelTimeType.SCHEDULED) {
    const scheduled = f.departureUtcScheduled;
    if (!scheduled) return '';
    depTime = scheduled;
  }
  if (type === FlightLabelTimeType.ESTIMATED) {
    if (!f.departureUtcEstimated) return '';
    depTime = f.departureUtcEstimated;
  }

  return ' ' + utc(depTime).format('HH:mm');
};

export const getTimeLabelForFlightArr = (
  f: Flight,
  type: FlightLabelTimeType
) => {
  if (type == FlightLabelTimeType.NONE) {
    return '';
  }
  let arrTime = 0;
  if (type == FlightLabelTimeType.SCHEDULED) {
    const scheduled = f.arrivalUtcScheduled;
    if (!f.arrivalUtcScheduled) return '';
    arrTime = scheduled;
  }
  if (type == FlightLabelTimeType.ESTIMATED) {
    if (!f.arrivalUtcEstimated) return '';
    arrTime = f.arrivalUtcEstimated;
  }
  return ' ' + utc(arrTime).format('HH:mm');
};

export const formatterOrderPrice = (f: Flight): string => {
  if (f.amounts && f.amounts.currencySymbol && f.amounts.orderAmountCents) {
    return (
      f.amounts.currencySymbol +
      new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
      }).format(f.amounts.orderAmountCents / 100)
    );
  }
  return 'N/A';
};

export const formatterLegPrice = (f: Flight): string => {
  if (f.amounts && f.amounts.currencySymbol) {
    return `${f.amounts.currencySymbol}${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
    }).format(f.amounts.flightLegAmountCents / 100)}`;
  }
  return 'N/A';
};

export const getVJ25Label = (status: string) => {
  if (status === 'VJ25_US' || status === 'VJ25_EUR') {
    return 'VJ25';
  }
  return '';
};

import { AfbCrewType } from '../../types/afbCrewType';
import { AfbSnapshot, Feeds } from '../../services/flight-service/flight-model';
import { AFBColorType, FLIGHT_FEED } from '../../types/flight';
import { isEqual } from 'lodash';
import { Crew } from './parent';

export const crewAFBVersionAdapter = (
  crew: Crew,
  afbSnapshot: AfbSnapshot,
  afbReedFeedsByCrewCode: {
    [crewCode: string]: Feeds;
  }
): AfbCrewType => {
  const feedsByCrewCode: Feeds = afbReedFeedsByCrewCode[crew.crewCode];
  const nameFeedsByCrewCode: string[] = Object.keys(feedsByCrewCode);
  const type = isEqual(feedsByCrewCode, afbSnapshot.feeds)
    ? AFBColorType.GREEN
    : nameFeedsByCrewCode.find(f => f === FLIGHT_FEED) &&
      feedsByCrewCode[FLIGHT_FEED].identity ===
        afbSnapshot.feeds[FLIGHT_FEED].identity
    ? AFBColorType.AMBER
    : AFBColorType.RED;
  return {
    roleAbbr: crew.roleAbbr,
    crewCode: crew.crewCode,
    firstName: crew.firstName,
    lastName: crew.lastName,
    type,
  };
};

import { duration, utc } from 'moment';
import { ONE_HOUR_IN_MINUTES } from '../../../constants';
import Aircraft from '../../../types/aircraft';

export const getHoursFlown = (aircraft: Aircraft): number =>
  !aircraft.minutesFlown
    ? 0
    : Math.round(aircraft.minutesFlown / ONE_HOUR_IN_MINUTES);

export const getTotalLandings = (aircraft: Aircraft): number =>
  !aircraft.totalLandings ? 0 : aircraft.totalLandings;

export const getHoursBeforeMx = (aircraft: Aircraft): number | string =>
  aircraft.airTimeBeforeMX
    ? Math.round(aircraft.airTimeBeforeMX / ONE_HOUR_IN_MINUTES)
    : 'N/A';

export const getLeftHoursFlown = (
  hoursBeforeMx: number | string,
  hoursFlown: number
): string => {
  if (typeof hoursBeforeMx === 'string') {
    return 'N/A';
  }
  return `${hoursBeforeMx - hoursFlown} h`;
};

export const getLeftTotalLandings = (
  landingsBeforeMX: number | string,
  totalLandings: number
): number | string => {
  if (typeof landingsBeforeMX === 'string') {
    return 'N/A';
  }
  return `${landingsBeforeMX - totalLandings}`;
};

export const getLeftDueDate = (today: number, nextDueDateMs?: number) => {
  if (!nextDueDateMs) {
    return 'N/A';
  }
  return `${duration(utc(nextDueDateMs).diff(utc(getDateWithoutTime(today))))
    .asDays()
    .toFixed(0)} d`;
};

export const getDateWithoutTime = (today: number): number => {
  return utc(today)
    .clone()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .valueOf();
};

export const AircraftIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 16.6667H8.5L12.25 10.7018H16.375C16.9963 10.7018 17.5 10.201 17.5 9.58333C17.5 8.96565 16.9963 8.46491 16.375 8.46491H12.25L8.5 2.5H7L8.875 8.46491H4.75L3.625 6.97368H2.5L3.25 9.58333L2.5 12.193L3.625 12.193L4.75 10.7018H8.875L7 16.6667Z"
      fill="#1890FF"
    ></path>
    <mask
      id="mask0"
      maskUnits="userSpaceOnUse"
      x="2"
      y="2"
      width="16"
      height="15"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 16.6667H8.5L12.25 10.7018H16.375C16.9963 10.7018 17.5 10.201 17.5 9.58333C17.5 8.96565 16.9963 8.46491 16.375 8.46491H12.25L8.5 2.5H7L8.875 8.46491H4.75L3.625 6.97368H2.5L3.25 9.58333L2.5 12.193L3.625 12.193L4.75 10.7018H8.875L7 16.6667Z"
        fill="white"
      ></path>
    </mask>
    <g mask="url(#mask0)"></g>
  </svg>
);

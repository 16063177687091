import { FC } from 'react';
import { utc } from 'moment';
import Flight from '../../../../types/flight';
import Airport from '../../../../types/airport';
import { capitalize } from '../../../../utils';
import { FlightActionButtonsConnected as ActionButtons } from '../../../tooltip/action-buttons';
import {
  getICAOLabelFromAirport,
  getCityLabelFromAirport,
  getCountryLabelFromAirport,
} from '../../../../utils/airport';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../reducers';
import { isEqual } from 'lodash';

interface Props {
  flight: Flight;
}

const FlightCardContent: FC<Props> = ({ flight }) => {
  const { arrAirport, depAirport } = useSelector<
    RootState,
    {
      arrAirport: Airport;
      depAirport: Airport;
    }
  >(
    state => ({
      arrAirport: state.airports.airportsById[flight.arrivalAirportId],
      depAirport: state.airports.airportsById[flight.departureAirportId],
    }),
    isEqual
  );

  const depICAO = getICAOLabelFromAirport(depAirport);
  const arrICAO = getICAOLabelFromAirport(arrAirport);
  const depCity = getCityLabelFromAirport(depAirport);
  const arrCity = getCityLabelFromAirport(arrAirport);
  const depCountry = getCountryLabelFromAirport(depAirport);
  const arrCountry = getCountryLabelFromAirport(arrAirport);
  return (
    <div className="card-content">
      <div style={{ display: 'flex' }}>
        <svg height={40} width={15}>
          <circle cx={7} cy={10} r={2} fill="rgba(0, 0, 0, 0.85)" />
          <line
            x1={7}
            x2={7}
            y1={10}
            y2={34}
            strokeWidth={1}
            stroke="rgba(0, 0, 0, 0.85)"
          />
          <circle cx={7} cy={34} r={2} fill="rgba(0, 0, 0, 0.85)" />
        </svg>
        <div>
          <div className="card-flight-airport-info">
            <strong>{depICAO}</strong>
            {` ${capitalize(depCity)}, ${capitalize(depCountry)} `}
            <span className="card-flight-airport-info-time-duration">
              {utc(flight.start).format('DD MMM, HH:mm [Z]')}
            </span>
          </div>
          <div>
            <strong>{arrICAO}</strong>
            {` ${capitalize(arrCity)}, ${capitalize(arrCountry)} `}
            <span className="card-flight-airport-info-time-duration">
              {utc(flight.end).format('DD MMM, HH:mm [Z]')}
            </span>
          </div>
        </div>
      </div>
      <ActionButtons flight={flight} />
    </div>
  );
};

export default FlightCardContent;

import { EmailFormValues } from './interfaces';

export const initialFormValues: EmailFormValues = {
  from: [],
  to: [],
  bcc: [],
  cc: [],
  subject: '',
  body: '',
};

export const ARINC_EMAIL_CONFIG = (window as any)?.ENV?.ARINC_EMAIL_CONFIG;

export const arincFormValues: EmailFormValues = {
  from: [
    {
      key: ARINC_EMAIL_CONFIG.from,
      label: ARINC_EMAIL_CONFIG.from,
      value: ARINC_EMAIL_CONFIG.from,
    },
  ],
  to: [
    {
      key: ARINC_EMAIL_CONFIG.to,
      label: ARINC_EMAIL_CONFIG.to,
      value: ARINC_EMAIL_CONFIG.to,
    },
  ],
  bcc: [],
  cc: [
    {
      key: ARINC_EMAIL_CONFIG.cc,
      label: ARINC_EMAIL_CONFIG.cc,
      value: ARINC_EMAIL_CONFIG.cc,
    },
  ],
  subject: '',
  body: '',
};

export enum EmailFormFieldName {
  from = 'from',
  to = 'to',
  bcc = 'bcc',
  cc = 'cc',
  subject = 'subject',
  body = 'body',
}

export enum FieldTitle {
  from = 'From:',
  to = 'To:',
  cc = 'Cc:',
  bcc = 'Bcc:',
}

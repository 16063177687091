import { PureComponent } from 'react';
import { utc } from 'moment';
import * as actions from '../../../../actions';
import { connect, DispatchProp } from 'react-redux';
import Flight, {
  UpgradeReason,
  LegBusinessType,
} from '../../../../types/flight';
import { RootState } from '../../../../reducers';
import { getAircraftById } from '../../../../selectors';
import Airport from '../../../../types/airport';
import Aircraft from '../../../../types/aircraft';
import Timezone from '../../../../types/timezone';
import { TimeInput } from '../../maskedTimeInput';
import { UPGRADE_REASON_LABEL_MAPPER } from '../../../../constants';
import { getFormattedDuration } from '../../../../utils/time';
import { DurationArrowSmall } from '../../../svg-icons/durationArrowSmall';
import { LastRow } from './last-row';
import { TimeMode, FirstRow } from './first-row';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Select, Space, Table, Tooltip } from 'antd';
import DatePicker from '../../../../common/components/date-time-pickers/date-picker';
import { hasPermission } from '../../../../utils/check-permissions';
import { isFerryFlight } from '../../../../common/flight/flight-check-status';
import {
  getTimezonesByAirportId,
  getTimezoneOffset,
} from '../../../../common/flight/flight-labels';
import {
  getDraggingFlights,
  getAircraftByDraggingFlights,
} from '../../../../selectors/flights';
import { getBorderStyleByFlightLegBusinessType } from '../../../../common/flight/flight-colorization';
import LockedIcon from '../../../svg-icons/flight-details-bar/locked';
import { includes } from 'lodash';
import { getICAOLabelFromAirport } from '../../../../utils/airport';
import { ColumnProps } from 'antd/lib/table';
import { getFontSize } from './utils';
import { AnyAction } from 'redux';

const ICAO_COLUM_WIDTH = 200;
const ICAO_COLUM_WIDTH_THIN = 110;

export interface DndDataSource {
  key: number;
  locked: boolean;
  icao: string;
  legId: number;
  tailNumber: string;
  capacity: number;
  changeDeparture: (
    dateTime: moment.Moment | number,
    id?: number
  ) => JSX.Element;
  upgradeReason: (key: number) => JSX.Element;
  delete: (id: number) => JSX.Element;
  legBusinessType: LegBusinessType;
}
interface StateProps {
  flights: Flight[];
  airportsById: { [id: number]: Airport };
  newAircraft: Aircraft;
  aircraftBySelectedFlights: Aircraft[];
  timezonesData: { [id: number]: Timezone };
  hasEditDepartureTimePermissions: boolean;
  isVerticalMode: boolean;
}

interface State {
  isDatePickerVisible: { [flightId: number]: boolean };
  upgradeReasonById: { [flightId: number]: UpgradeReason };
  timeMode: TimeMode;
  savedDate: { [flightId: number]: number };
  pickedDate: { [flightId: number]: number };
}
class MainContent extends PureComponent<
  StateProps & DispatchProp<AnyAction>,
  State
> {
  element: HTMLInputElement;
  constructor(props) {
    super(props);
    this.state = {
      isDatePickerVisible: {},
      upgradeReasonById: {},
      timeMode: 'UTC',
      savedDate: {},
      pickedDate: {},
    };
  }
  getRef = ref => (this.element = ref);
  onChangeTimeView = (timeMode: TimeMode) => {
    this.setState({
      timeMode,
    });
  };
  changeDate = (m: moment.Moment, id: number) => {
    this.setState({
      pickedDate: {
        ...this.state.pickedDate,
        [id]: m.valueOf(),
      },
    });
  };
  onCancelNewDate = (id: number) => (e: React.MouseEvent) => {
    this.setState({
      isDatePickerVisible: {
        ...this.state.isDatePickerVisible,
        [id]: false,
      },
    });
  };
  onSaveNewDate = (id: number) => (e: React.MouseEvent) => {
    this.setState({
      isDatePickerVisible: {
        ...this.state.isDatePickerVisible,
        [id]: false,
      },
      savedDate: {
        ...this.state.savedDate,
        [id]: this.state.pickedDate[id],
      },
    });
  };
  onShowDatePicker = (id: number) => (e: React.MouseEvent) => {
    this.setState({
      isDatePickerVisible: {
        ...this.state.isDatePickerVisible,
        [id]: true,
      },
    });
  };
  getTimeInCurrentMode(time: number, offset: number) {
    return this.state.timeMode === 'LT' && !!offset
      ? utc(time).utcOffset(offset)
      : utc(time);
  }
  renderDateTimePicker = (
    departureDateTime: moment.Moment,
    id: number,
    depOffset: number
  ) => {
    const { isVerticalMode } = this.props;
    const defaultValue = departureDateTime;

    const savedValue =
      this.state.savedDate &&
      this.state.savedDate[id] &&
      this.getTimeInCurrentMode(this.state.savedDate[id], depOffset);

    const pickedValue =
      this.state.pickedDate &&
      this.state.pickedDate[id] &&
      this.getTimeInCurrentMode(this.state.pickedDate[id], depOffset);

    const getValue = pickedValue || savedValue || defaultValue;
    const size = isVerticalMode ? 'small' : 'middle';
    const style = isVerticalMode
      ? { marginTop: '6px', alignSelf: 'center' }
      : { display: 'flex' };
    return (
      <span
        style={{
          display: 'flex',
          flexDirection: isVerticalMode ? 'column' : 'row',
          maxWidth: isVerticalMode ? '230px' : '350px',
          fontSize: getFontSize(isVerticalMode),
        }}
        key={id}
      >
        <Space.Compact size={isVerticalMode ? 'small' : 'middle'}>
          <DatePicker
            key={id}
            allowClear={false}
            value={getValue}
            style={{ width: 120 }}
            onChange={m => this.changeDate(m, id)}
            ref={this.getRef}
          />
          <TimeInput value={getValue} onChange={m => this.changeDate(m, id)} />
        </Space.Compact>
        <span style={style}>
          <Button type="primary" onClick={this.onSaveNewDate(id)} size={size}>
            Save
          </Button>
          <Button onClick={this.onCancelNewDate(id)} size={size}>
            Cancel
          </Button>
        </span>
      </span>
    );
  };
  renderChangeButton = (hasPermissions, id) => {
    const { isVerticalMode } = this.props;
    return (
      <Button
        key={id}
        type="link"
        className="dnd-multiple-modal-main-content-button"
        disabled={!hasPermissions}
        style={{
          paddingLeft: '0px',
          fontSize: getFontSize(isVerticalMode),
        }}
        onClick={this.onShowDatePicker(id)}
      >
        Change Departure time
      </Button>
    );
  };
  renderDates = (departure, arrival) => {
    return (
      <div className="dnd-multiple-modal-main-content-dates">
        <div className="dnd-multiple-modal-main-content-dates-departure">
          {`${departure.format('DD MMM')}. ${departure.format('HH:mm')}`}
        </div>
        <div className="dnd-multiple-modal-main-content-dates-duration">
          <div className="dnd-multiple-modal-main-content-dates-duration-time">
            {getFormattedDuration(departure, arrival)}
          </div>
          <div className="dnd-multiple-modal-main-content-dates-duration-arrow">
            <DurationArrowSmall color={'black'} opacity={0.09} />
          </div>
        </div>
        <div className="dnd-multiple-modal-main-content-dates-arrival">
          {`${arrival.format('DD MMM')}. ${arrival.format('HH:mm')}`}
        </div>
      </div>
    );
  };
  renderNewDates = (departure, arrival, id) => {
    return (
      <div className="dnd-multiple-modal-main-content-dates" key={id}>
        <div
          className="dnd-multiple-modal-main-content-dates-departure-clickable"
          onClick={this.onShowDatePicker(id)}
          style={{ color: 'green' }}
        >
          {`${departure.format('DD MMM')}. ${departure.format('HH:mm')}`}
        </div>
        <div className="dnd-multiple-modal-main-content-dates-duration">
          <div className="dnd-multiple-modal-main-content-dates-duration-time">
            {getFormattedDuration(departure, arrival)}
          </div>
          <div
            className="dnd-multiple-modal-main-content-dates-duration-arrow"
            style={{ color: 'green' }}
          >
            <DurationArrowSmall color={'green'} opacity={0.2} />
          </div>
        </div>
        <div
          className="dnd-multiple-modal-main-content-dates-arrival"
          style={{ color: 'green' }}
        >
          {`${arrival.format('DD MMM')}. ${arrival.format('HH:mm')}`}
        </div>
      </div>
    );
  };
  onSetUpgradeReason = (id: number, val: number) => {
    this.setState({
      upgradeReasonById: {
        ...this.state.upgradeReasonById,
        [id]: val,
      },
    });
  };
  renderSelectUpgradeReason = (id: number) => {
    const { isVerticalMode } = this.props;
    const flight = this.props.flights.find(f => f.id === id);
    const aircraft = this.props.aircraftBySelectedFlights.find(
      ac => ac.id === flight.aircraftId
    );
    const highlighted =
      aircraft.aircraftTypeId !== this.props.newAircraft.aircraftTypeId &&
      !this.state.upgradeReasonById[id];
    const fontSize = getFontSize(isVerticalMode);
    return (
      <Select
        showSearch
        key={id}
        placeholder="Select reason"
        style={{
          width: isVerticalMode ? 260 : 350,
          fontSize,
          cursor: 'pointer',
        }}
        optionFilterProp="value"
        onChange={val => this.onSetUpgradeReason(id, Number(val))}
        filterOption={(input, option) =>
          option.props.value
            .toString()
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        className={highlighted ? 'highlighted' : ''}
      >
        {Object.keys(UPGRADE_REASON_LABEL_MAPPER).map(k => (
          <Select.Option value={k} key={k} style={{ fontSize }}>
            <Tooltip
              key={k}
              title={UPGRADE_REASON_LABEL_MAPPER[k]}
              placement="topLeft"
            >
              {UPGRADE_REASON_LABEL_MAPPER[k]}
            </Tooltip>
          </Select.Option>
        ))}
      </Select>
    );
  };
  getDataSourceFromProps = (): DndDataSource[] => {
    const {
      flights,
      aircraftBySelectedFlights,
      airportsById,
      timezonesData,
      hasEditDepartureTimePermissions,
    } = this.props;
    return flights.reduce((acc, fl): DndDataSource[] => {
      const aircraft = aircraftBySelectedFlights.find(
        ac => ac.id === fl.aircraftId
      );
      const departureAirport: Airport = airportsById[fl.departureAirportId];
      const arrivalAirport: Airport = airportsById[fl.arrivalAirportId];
      const departureTime =
        fl.departureUtcEstimated ?? fl.departureUtcScheduled;
      const arrivalTime = fl.arrivalUtcEstimated ?? fl.arrivalUtcScheduled;
      const duration = arrivalTime - departureTime;

      const depOffset = getTimezoneOffset(
        getTimezonesByAirportId(
          airportsById,
          timezonesData,
          departureAirport.id
        ),
        departureTime
      );
      const arrOffset = getTimezoneOffset(
        getTimezonesByAirportId(airportsById, timezonesData, arrivalAirport.id),
        arrivalTime
      );

      const currentDepartureArrival = this.renderDates(
        this.getTimeInCurrentMode(departureTime, depOffset),
        this.getTimeInCurrentMode(arrivalTime, arrOffset)
      );

      const newDepartureArrival = this.renderNewDates(
        this.getTimeInCurrentMode(this.state.savedDate[fl.id], depOffset),
        this.getTimeInCurrentMode(
          this.state.savedDate[fl.id] + duration,
          arrOffset
        ),
        [fl.id]
      );

      const changeDepartureColumn =
        this.state.isDatePickerVisible && this.state.isDatePickerVisible[fl.id]
          ? this.renderDateTimePicker(
              this.getTimeInCurrentMode(departureTime, depOffset),
              fl.id,
              depOffset
            )
          : this.renderChangeButton(hasEditDepartureTimePermissions, fl.id);

      const getCurrentView =
        this.state.savedDate &&
        this.state.savedDate[fl.id] &&
        this.state.isDatePickerVisible &&
        !this.state.isDatePickerVisible[fl.id]
          ? newDepartureArrival
          : changeDepartureColumn;

      return acc.concat({
        key: fl.id,
        locked: fl.lockedFlight,
        icao: `${getICAOLabelFromAirport(
          departureAirport
        )} - ${getICAOLabelFromAirport(arrivalAirport)}`,
        legId: fl.id,
        tailNumber: aircraft.tailNumber,
        capacity: fl.numberOfPassengers,
        departure: currentDepartureArrival,
        changeDeparture: getCurrentView,
        upgradeReason: this.renderSelectUpgradeReason(fl.id),
        delete: (
          <DeleteOutlined
            className="dnd-multiple-modal-main-content-button"
            key={fl.id}
            onClick={e => this.onDeleteFlight(fl.id)}
          />
        ),
        legBusinessType: fl.legBusinessTypeId,
      });
    }, []);
  };
  getColumns = (): ColumnProps<DndDataSource>[] => {
    const { isVerticalMode } = this.props;
    return [
      {
        title: 'ICAO',
        dataIndex: 'icao',
        width: isVerticalMode ? ICAO_COLUM_WIDTH_THIN : ICAO_COLUM_WIDTH,
        render: (text, data: DndDataSource) => ({
          props: {
            style: {
              borderLeft: getBorderStyleByFlightLegBusinessType(
                data.legBusinessType
              ),
            },
          },
          children: data.locked ? (
            <div className="dnd-multiple-modal-main-content-icao-container">
              <div>{text}</div>
              <div className="dnd-multiple-modal-main-content-icao-container-locked-icon-container">
                <LockedIcon color="red" />
              </div>
            </div>
          ) : (
            text
          ),
        }),
      },
      {
        title: 'Leg ID',
        dataIndex: 'legId',
        width: isVerticalMode ? 70 : 100,
      },
      {
        title: 'Tail #',
        dataIndex: 'tailNumber',
        width: isVerticalMode ? 70 : 100,
      },
      {
        title: 'Cap.',
        dataIndex: 'capacity',
        width: isVerticalMode ? 40 : 65,
      },
      {
        title: 'Departure - Arrival',
        dataIndex: 'departure',
        width: isVerticalMode ? 230 : 325,
      },
      {
        title: Object.keys(this.state.isDatePickerVisible).length
          ? 'New Departure - Arrival'
          : '',
        dataIndex: 'changeDeparture',
        width: isVerticalMode ? 230 : 450,
      },
      {
        title: 'Upgrade Reason',
        dataIndex: 'upgradeReason',
      },
      {
        title: '',
        dataIndex: 'delete',
        width: 70,
      },
    ];
  };
  renderHeader = () => {
    return (
      <FirstRow
        timeMode={this.state.timeMode}
        numberOfSelectedFlights={this.props.flights.length}
        onChangeTimeView={this.onChangeTimeView}
      />
    );
  };
  isNotValidByUpgradeReason = () => {
    const { flights, aircraftBySelectedFlights, newAircraft } = this.props;
    const getFlightId = flights
      .filter(
        fl =>
          aircraftBySelectedFlights.find(ac => ac.id === fl.aircraftId)
            .aircraftTypeId !== newAircraft.aircraftTypeId
      )
      .map(fl => fl.id);
    if (!getFlightId.length) return false;
    if (
      this.state.upgradeReasonById &&
      Object.keys(this.state.upgradeReasonById).length < getFlightId.length
    )
      return true;
    return false;
  };
  renderFooter = () => {
    const notEnoughSpace = this.props.flights.some(
      f => this.props.newAircraft.numberOfSeats < f.numberOfPassengers
    );
    const toDisable = notEnoughSpace
      ? this.isNotValidByUpgradeReason()
      : includes(this.state.isDatePickerVisible, true);
    const { isVerticalMode } = this.props;
    //TODO: on change screen width margin should be set dynamically
    const marginForNextThinColumn = 15;
    const marginForNextWideColumn = 55;
    const marginLeft = isVerticalMode
      ? ICAO_COLUM_WIDTH_THIN + marginForNextThinColumn
      : ICAO_COLUM_WIDTH + marginForNextWideColumn;
    return (
      <LastRow
        marginLeft={marginLeft}
        newAircraft={this.props.newAircraft}
        isExceededSpace={notEnoughSpace}
        isDisabled={toDisable}
        onCancel={this.onClickCancel}
        onConfirm={this.onClickConfirm}
        dndDataSource={this.getDataSourceFromProps()}
        isVerticalMode={this.props.isVerticalMode}
      />
    );
  };
  onDeleteFlight = (flightId: number) => {
    this.props.dispatch(actions.userToggleFlightSelection({ flightId }));
  };
  onClickCancel = () => this.props.dispatch(actions.userCloseDndModal());
  getModifiedFlights = (): Flight[] => {
    return this.props.flights.reduce((acc, fl): Flight[] => {
      const departureTime = fl.departureUtcEstimated
        ? fl.departureUtcEstimated
        : fl.departureUtcScheduled;
      const arrivalTime = fl.arrivalUtcEstimated
        ? fl.arrivalUtcEstimated
        : fl.arrivalUtcScheduled;
      const duration = arrivalTime - departureTime;

      if (!this.state.savedDate) return acc.concat(fl);
      if (isFerryFlight(fl.legBusinessTypeId)) {
        return acc.concat({
          ...fl,
          departureUtcEstimated: this.state.savedDate[fl.id]
            ? this.state.savedDate[fl.id]
            : fl.departureUtcEstimated
            ? fl.departureUtcEstimated
            : null,
          departureUtcScheduled: this.state.savedDate[fl.id]
            ? this.state.savedDate[fl.id]
            : fl.departureUtcScheduled,
          arrivalUtcEstimated: this.state.savedDate[fl.id]
            ? this.state.savedDate[fl.id] + duration
            : fl.arrivalUtcEstimated
            ? fl.arrivalUtcEstimated
            : null,
          arrivalUtcScheduled: this.state.savedDate[fl.id]
            ? this.state.savedDate[fl.id] + duration
            : fl.arrivalUtcScheduled,
        });
      } else {
        return acc.concat({
          ...fl,
          departureUtcEstimated: this.state.savedDate[fl.id]
            ? this.state.savedDate[fl.id]
            : fl.departureUtcEstimated
            ? fl.departureUtcEstimated
            : null,
          arrivalUtcEstimated: this.state.savedDate[fl.id]
            ? this.state.savedDate[fl.id] + duration
            : fl.arrivalUtcEstimated
            ? fl.arrivalUtcEstimated
            : null,
        });
      }
    }, []);
  };
  onClickConfirm = () => {
    this.props.dispatch(
      actions.userChangeTailForFlights.started({
        flights: this.getModifiedFlights(),
        newAircraftId: this.props.newAircraft.id,
        upgradeReason: this.state.upgradeReasonById,
      })
    );
  };
  render() {
    const dataSource = this.getDataSourceFromProps();
    const { isVerticalMode } = this.props;
    return (
      <div
        className={`dnd-multiple-modal-main-content${
          isVerticalMode ? ' vertical-mode' : ''
        }`}
      >
        <Table
          dataSource={dataSource}
          columns={this.getColumns()}
          pagination={false}
          title={this.renderHeader}
          footer={this.renderFooter}
          scroll={{ y: dataSource.length > 3 ? 195 : 100 }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: RootState): StateProps => ({
  flights: getDraggingFlights(state),
  aircraftBySelectedFlights: getAircraftByDraggingFlights(state),
  airportsById: state.airports.airportsById,
  newAircraft: getAircraftById(state, state.ui.newAircraftId),
  timezonesData: state.timezones.timezonesById,
  hasEditDepartureTimePermissions: hasPermission(state, 'AG-Timeline-ETD-Edit'),
  isVerticalMode: state.ui.isVerticalMode,
});

export const MainContentConnected = connect(mapStateToProps)(MainContent);

export const OrderWindowIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0136 7.99923L11.9159 3.85917C11.796 3.42058 11.5187 3.25958 11.0841 3.37616C10.6465 3.49408 10.4851 3.77468 10.6 4.21798L11.6023 7.99923H8.39773L9.4 4.21108C9.51487 3.76778 9.35351 3.48718 8.91591 3.36926C8.47788 3.25301 8.2006 3.41632 8.08409 3.85917L6.98636 7.99923H2.5V9.31025L4.50455 16.9694H15.4955L17.5 9.48966V7.99923H13.0136ZM7.11591 10.9732C6.59318 10.9732 6.33182 10.7087 6.33182 10.1797C6.33182 9.65065 6.59318 9.38614 7.11591 9.38614C7.63863 9.38614 7.9 9.65065 7.9 10.1797C7.90191 10.3996 7.82604 10.5873 7.67237 10.7428C7.5187 10.8983 7.33321 10.9751 7.11591 10.9732L7.11591 10.9732ZM12.8841 10.973C12.5349 10.973 12.2934 10.8097 12.1597 10.4832C12.0261 10.1566 12.0827 9.86836 12.3297 9.61842C12.5767 9.36847 12.8615 9.31114 13.1842 9.4464C13.5069 9.58167 13.6682 9.82604 13.6682 10.1795C13.6682 10.7085 13.4069 10.973 12.8841 10.973Z"
      fill="#1890FF"
    ></path>
  </svg>
);

import Note, { GeneralNote, ImportanceFactor, MergedNote } from '../types/note';
import { chain, maxBy } from 'lodash';

export function isNoteGeneral(a: Note): a is GeneralNote {
  return a.importanceFactor === ImportanceFactor.GENERAL;
}

export function isMergeNote(n: Note | MergedNote): n is MergedNote {
  return !!(n as MergedNote).notes;
}

export const mergeNoteForAircraft = (notes: Note[]) => {
  return chain(notes)
    .sortBy('start')
    .reduce((acc: (Note | MergedNote)[], n: Note) => {
      if (acc.length === 0) return [n];
      const lastNote = acc[acc.length - 1];
      if (n.start < lastNote.end) {
        if (isMergeNote(lastNote)) {
          lastNote.end = maxBy(lastNote.notes, 'end').end;
          lastNote.notes.push(n);
          return acc;
        }
        const mergedNote: MergedNote = {
          start: lastNote.start,
          end: Math.max(n.end, lastNote.end),
          aircraftId: n.aircraftId,
          id: lastNote.id,
          notes: [lastNote, n],
        };
        acc.splice(-1, 1, mergedNote);
        return acc;
      }
      acc.push(n);
      return acc;
    }, [])
    .value();
};

export const getMergedNotes = (notes: Note[]): (Note | MergedNote)[] => {
  return chain(notes)
    .groupBy('aircraftId')
    .flatMap(mergeNoteForAircraft)
    .value();
};

import { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { getAircraftById } from '../../../selectors';
import { FlightGhost } from './flight-ghost';
import { HOLD_FLIGHT_HEIGHT } from '../../../constants';
import { utc } from 'moment';
import Aircraft from '../../../types/aircraft';
import { isEqual } from 'lodash';
import { getDraggingFlights } from '../../../selectors/flights';

export interface FlightGhostStateProps {
  width: number;
  height: number;
  offset: {
    top: number;
    left: number;
  };
  currentTail: string;
  newTail: string;
}
export interface FlightGhostOwnProps {
  id: number;
  selectedAircraft: Aircraft;
  index?: number;
}

const zeroProps: FlightGhostStateProps = {
  currentTail: '',
  height: 0,
  newTail: '',
  offset: {
    left: 0,
    top: 0,
  },
  width: 0,
};
export const FlightGhostConnected: FC<FlightGhostOwnProps> = ({
  id,
  selectedAircraft,
  index,
}) => {
  const { currentTail, height, newTail, offset, width } = useSelector<
    RootState,
    FlightGhostStateProps
  >(state => {
    const flight = getDraggingFlights(state).find(fl => fl.id === id);
    if (!flight) {
      return zeroProps;
    }
    const { start, end, aircraftId } = flight;
    const { transform, positionMap, holdAircraftPositionMap } = state.ui;
    const top =
      (index - Object.keys(holdAircraftPositionMap).length) *
        state.ui.rowHeight *
        state.ui.transform.ky +
      state.ui.transform.translateY;
    return {
      width: transform.scaleX(utc(end)) - transform.scaleX(utc(start)),
      height: transform.ky * positionMap.flights,
      offset: {
        top,
        left: transform.scaleX(utc(start)),
      },
      currentTail: getAircraftById(state, aircraftId)?.tailNumber,
      newTail: selectedAircraft?.tailNumber,
    };
  }, isEqual);
  return (
    <FlightGhost
      currentTail={currentTail}
      height={height}
      newTail={newTail}
      offset={offset}
      width={width}
    />
  );
};

export const HoldFlightGhostConnected: FC<FlightGhostOwnProps> = ({
  id,
  selectedAircraft,
}) => {
  const { currentTail, height, newTail, offset, width } = useSelector<
    RootState,
    FlightGhostStateProps
  >(state => {
    const flight = getDraggingFlights(state).find(fl => fl.id === id);
    if (!flight) {
      return zeroProps;
    }
    const { start, end, aircraftId } = flight;
    const newTail = selectedAircraft;
    const { transform, holdAircraftPositionMap, holdLineHeight } = state.ui;
    const top = holdAircraftPositionMap[newTail.id]?.y1 ?? holdLineHeight;
    return {
      width: transform.scaleX(utc(end)) - transform.scaleX(utc(start)),
      height: HOLD_FLIGHT_HEIGHT,
      offset: {
        top,
        left: transform.scaleX(utc(start)),
      },
      currentTail: getAircraftById(state, aircraftId)?.tailNumber,
      newTail: newTail?.tailNumber,
    };
  }, isEqual);
  return (
    <FlightGhost
      currentTail={currentTail}
      height={height}
      newTail={newTail}
      offset={offset}
      width={width}
    />
  );
};

import { Component, Fragment } from 'react';
import { ReducerShape } from '../../../../reducers/ui';
import { connect } from 'react-redux';
import { RootState } from '../../../../reducers';
import {
  getFlightsForHoldTails,
  getHoldFlightsPoolDividedByTimeRange,
} from '../../../../selectors';
import { HoldFlightSvgColumns } from './holdFlightSvgColumns';
import { getHoursOffsetForHoldFlight } from '../../../../utils/hold-line-flights';
import {
  HoldFlightsPoolByAircraftIdMap,
  HoldFlightsPoolHoursDelimiter,
  HoursFlight,
  TimeFlightType,
} from '../../../../types/flight';
import {
  getZoomLevelForHoldFlightRender,
  ZoomLevelForHoldFlights,
} from '../../../../d3/components/zoom';
import { MAX_HOLD_FLIGHTS_ON_LESS_THAN_3_DAYS_VIEW } from '../../../../constants';
interface Props {
  holdAircraftPositionMap: { [id: number]: { y1: number; y2: number } };
  holdFlightsDelimiter: HoldFlightsPoolHoursDelimiter;
  holdFlightsPool: HoldFlightsPoolByAircraftIdMap;
  marginTop: number;
  transform: ReducerShape['transform'];
  visibleHoldAcIds: number[];
  zoomLevel: ZoomLevelForHoldFlights;
}
class Container extends Component<Props> {
  groupRef: SVGGElement;
  getGroupRef = ref => (this.groupRef = ref);
  render() {
    const {
      transform: { translateX, kx },
      marginTop,
      holdFlightsPool,
      visibleHoldAcIds,
      holdAircraftPositionMap,
      holdFlightsDelimiter,
      zoomLevel,
    } = this.props;
    return (
      <g
        className="events-node"
        ref={this.getGroupRef}
        clipPath="url(#eventsSVGClipHold)"
      >
        <g
          transform={`translate(${translateX}, ${marginTop})scale(${kx}, 1)`}
          style={{ willChange: 'transform' }}
        >
          {visibleHoldAcIds.map(id => {
            switch (zoomLevel) {
              case 'fullView': {
                if (holdFlightsDelimiter[id] && holdAircraftPositionMap[id]) {
                  return holdFlightsDelimiter[id].map(ob => {
                    const flightsByTime = ob.pool;
                    const day = ob.day;
                    return Object.keys(flightsByTime).map(key => {
                      const flights = flightsByTime[key];
                      return (
                        <Fragment key={`${day}_${key}`}>
                          {flights.length > 0 ? (
                            <HoldFlightSvgColumns
                              flights={flights}
                              flightTimeStart={getHoursOffsetForHoldFlight(
                                key as TimeFlightType
                              )}
                              y1={holdAircraftPositionMap[id].y1}
                              zoomLevel={zoomLevel}
                              columnId={`${day}_${key}`}
                              kx={kx}
                              translateX={translateX}
                            />
                          ) : null}
                        </Fragment>
                      );
                    });
                  });
                }
                return null;
              }
              case 'weeklyView': {
                if (holdFlightsPool[id] && holdAircraftPositionMap[id]) {
                  return holdFlightsPool[id].map(ob => {
                    const flights = ob.pool.slice(
                      0,
                      MAX_HOLD_FLIGHTS_ON_LESS_THAN_3_DAYS_VIEW
                    );
                    return (
                      <Fragment key={`${ob.day}`}>
                        {flights.length > 0 ? (
                          <HoldFlightSvgColumns
                            flights={flights}
                            flightTimeStart={HoursFlight.Midnight}
                            y1={holdAircraftPositionMap[id].y1}
                            zoomLevel={zoomLevel}
                            columnId={`${ob.day}`}
                            kx={kx}
                            translateX={translateX}
                          />
                        ) : null}
                      </Fragment>
                    );
                  });
                }
                return null;
              }
              default:
                return null;
            }
          })}
        </g>
      </g>
    );
  }
}
export const HoldFlightSvgLayer = connect(
  (state: RootState): Props => ({
    holdAircraftPositionMap: state.ui.holdAircraftPositionMap,
    holdFlightsDelimiter: getHoldFlightsPoolDividedByTimeRange(state),
    holdFlightsPool: getFlightsForHoldTails(state),
    marginTop: state.ui.marginTop,
    transform: state.ui.transform,
    visibleHoldAcIds: state.ui.visibleHoldAcIds,
    zoomLevel: getZoomLevelForHoldFlightRender(state.ui.transform.scaleX),
  })
)(Container);

import { OptionsShape } from '../common/components/multi-select/helper';
import {
  PeakDayRegions,
  PeakDayTypeId,
} from '../components/peak-days/single-event-modal/helpers';
import { DAY_IN_MILLISECONDS } from '../constants';
import { PEAK_DAY_REGIONS } from '../constants/environment';

export const getBusyDaysFromEvent = (end: number, start: number): number[] => {
  let days = [start];
  const daysInRange = Math.ceil((end - start) / DAY_IN_MILLISECONDS);
  let i = 1;
  while (i < daysInRange) {
    days.push(DAY_IN_MILLISECONDS * i + start);
    i += 1;
  }
  return days;
};

export const getPeakDayText = (options: OptionsShape[]) =>
  options.map(el => el.value).join(', ');

export const getRegion = (regions: OptionsShape[]) => {
  if (regions.length === PEAK_DAY_REGIONS.length) {
    return `${PeakDayRegions.nam} / ${PeakDayRegions.int}`;
  }
  if (
    regions.length === PEAK_DAY_REGIONS.length - 1 &&
    !regions.find(el => el.value === 'North America')
  ) {
    return PeakDayRegions.int;
  }
  if (regions.length === 1 && regions[0].value === 'North America') {
    return PeakDayRegions.nam;
  }
  return getPeakDayText(regions);
};

export const getPeakDayColor = (peakDayTypeId: PeakDayTypeId): string => {
  if (!peakDayTypeId) return 'transparent';
  switch (peakDayTypeId) {
    case PeakDayTypeId.HIGH_DEMAND: {
      return 'rgba(255,155,0,.4)'; // orange
    }
    case PeakDayTypeId.PEAK: {
      return 'rgba(255,0,0,.4)'; // red
    }
    case PeakDayTypeId.ROLLING_PEAK: {
      return 'rgba(255,255,0,.4)'; // yellow
    }
    default:
      return 'transparent';
  }
  // deprecated colorizing
  // if (!a.isActive) {
  //   return 'transparent';
  // }
  // if (a.isCrewChange) {
  //   return 'rgba(0,0,255,.4)';
  // } else if (a.isAutoAdvertising) {
  //   return 'rgba(255,255,0,.4)';
  // } else {
  //   return 'rgba(255,0,0,.4)';
  // }
};

export const getPeakDayTooltipColor = (
  peakDayTypes: OptionsShape[]
): string => {
  switch (peakDayTypes.length) {
    case 1: {
      const color = getPeakDayColor(peakDayTypes[0].id);
      return `linear-gradient(to right, ${color} 0%, ${color} 100%)`;
    }
    case 2: {
      const color1 = getPeakDayColor(peakDayTypes[0].id);
      const color2 = getPeakDayColor(peakDayTypes[1].id);
      return `linear-gradient(to right, ${color1} 0%, ${color1} 50%, ${color2} 50%, ${color2} 100%)`;
    }
    case 3: {
      const color1 = getPeakDayColor(peakDayTypes[0].id);
      const color2 = getPeakDayColor(peakDayTypes[1].id);
      const color3 = getPeakDayColor(peakDayTypes[2].id);
      return `linear-gradient(to right, ${color1} 0%, ${color1} 33.33%, ${color2} 33.33%, ${color2} 66.66%, ${color3} 66.66%, ${color3} 100%)`;
    }
    default:
      return 'transparent';
  }
};

const peakDayHeight = 40;
const firstLineHeigh = 14;
const secondLineHeigh = 13;
const thirdLineHeigh = 13;
export const getPeakDayElementHeight = (
  partIndex: number,
  partsLength: number
): number => {
  if (partsLength === 1) {
    return peakDayHeight;
  }
  if (partsLength === 2) return peakDayHeight / 2;
  else {
    switch (partIndex) {
      case 0:
        return firstLineHeigh;
      case 1:
        return secondLineHeigh;
      case 2:
        return thirdLineHeigh;
    }
  }
};
export const getPeakDayElementY = (
  partIndex: number,
  partsLength: number
): number => {
  if (partsLength === 1) return peakDayHeight;
  if (partsLength === 2)
    return partIndex === 0 ? peakDayHeight : peakDayHeight / 2;
  else {
    switch (partIndex) {
      case 0:
        return firstLineHeigh + secondLineHeigh + thirdLineHeigh;
      case 1:
        return secondLineHeigh + thirdLineHeigh;
      case 2:
        return thirdLineHeigh;
    }
  }
};

export const sortById = (a: OptionsShape, b: OptionsShape): number => {
  if (a.id > b.id) return 1;
  if (a.id < b.id) return -1;
  return 0;
};

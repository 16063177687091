import { PureComponent } from 'react';
import { setDataTestEntityId } from '../../../utils';

interface ConnectedProps {}
interface OwnProps {
  id: number | string;
  name: React.ReactNode;
  color: string;
  backgroundColor?: string;
  backgroundImage?: string;
}
interface Props extends ConnectedProps, OwnProps {}

export default class TooltipHeader extends PureComponent<Props> {
  render() {
    const {
      backgroundColor = '',
      backgroundImage = '',
      color,
      id,
      name,
    } = this.props;
    return (
      <div className="tooltip-header-common">
        <div
          style={{
            color,
            backgroundColor,
            backgroundImage,
          }}
          className="tooltip-header-group"
        >
          <div
            className="tooltip-header-left"
            {...setDataTestEntityId('tooltip-header-name')}
          >
            {name}
          </div>
          <div
            className="tooltip-header-right"
            {...setDataTestEntityId('tooltip-header-id', true)}
          >{`ID ${id}`}</div>
        </div>
      </div>
    );
  }
}

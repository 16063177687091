import { FC } from 'react';
import { FlightGhostStateProps } from './ghosts-render';

interface Props {
  currentTail: string;
  newTail: string;
}
const FlightGhostTailNumber: FC<Props> = ({ currentTail, newTail }) => {
  return (
    <>
      <span className="tail-number-red">{currentTail}</span>
      <br />
      <span className="tail-number-green">{newTail}</span>
    </>
  );
};

export const FlightGhost: FC<FlightGhostStateProps> = ({
  offset,
  height,
  width,
  currentTail,
  newTail,
}) => {
  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: offset.top,
          left: offset.left,
          height,
          border: 'solid 1px rgba(100,100,100,0.8)',
          width,
          background: 'rgba(250,250,250,0.65)',
          overflow: 'visible',
        }}
      >
        <div
          style={{
            marginLeft: width,
            width: 100,
            fontSize: 12,
            lineHeight: '16px',
          }}
        >
          <FlightGhostTailNumber currentTail={currentTail} newTail={newTail} />
        </div>
      </div>
    </>
  );
};

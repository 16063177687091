import { FC, PureComponent } from 'react';
import Popper from 'popper.js';
import { subMiddle } from '../../root';
import { getOriginalTargetFirstChildForAntdPopConfirm } from './utils';
import { ActionCreator } from 'typescript-fsa';
import Flight from '../../types/flight';
import Note from '../../types/note';
import * as actions from '../../actions';
import Maintenance from '../../types/maintenance';

type ContextMenuType = 'flight' | 'note' | 'mx-events';

interface ContextMenuProps {
  isOpen: boolean;
  onCloseContextMenu: () => void;
  openMenuAction: ActionCreator<
    [HTMLElement | Popper.ReferenceObject, Flight | Note | Maintenance]
  >;
  menuType: ContextMenuType;
}

export class ContextMenuWrapperAsPopper extends PureComponent<
  ContextMenuProps
> {
  popper: Popper;
  createPopper = action => {
    const className = `${this.props.menuType}-menu-root`;
    const menuRoot = document.getElementsByClassName(
      className
    )[0] as HTMLDivElement;
    const actionRef = action.payload[0];
    if (menuRoot && actionRef) {
      this.popper = new Popper(actionRef, menuRoot, {
        placement: 'right-end',
        modifiers: {
          preventOverflow: { enabled: true },
          computeStyle: { gpuAcceleration: false },
        },
        positionFixed: true,
      });
      menuRoot.focus();
    }
  };
  removePopper = _action => {
    if (this.popper) {
      this.popper.destroy();
      delete this.popper;
    }
  };
  componentDidMount() {
    const { openMenuAction } = this.props;
    window.addEventListener('resize', this.removePopper);
    subMiddle.on(openMenuAction, this.createPopper);
    subMiddle.on(actions.userZoomHor, this.removePopper);
    subMiddle.on(actions.userZoomVer, this.removePopper);
    subMiddle.on(actions.userCloseContextMenu, this.removePopper);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.removePopper);
  }
  onBlur = (e: React.FocusEvent<HTMLDivElement>) => {
    const antdPopConfirmOkBtnTextElement = getOriginalTargetFirstChildForAntdPopConfirm(
      e
    );
    if (
      this.props.menuType !== 'flight' &&
      antdPopConfirmOkBtnTextElement &&
      antdPopConfirmOkBtnTextElement.dataset.stopbubbling == 'true'
    ) {
      return false;
    }
    this.props.onCloseContextMenu();
  };
  render() {
    const { isOpen, menuType } = this.props;
    const className = `${menuType}-menu-root`;
    if (!isOpen) return null;
    return (
      <div className={className} tabIndex={-1} onBlur={this.onBlur}>
        {this.props.children}
      </div>
    );
  }
}

export const ContextMenuSimpleWrapper: FC<{
  position: {
    x: number;
    y: number;
  };
}> = ({ position, children }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: position.y,
        left: position.x,
      }}
    >
      <ul className="context-menu">{children}</ul>
    </div>
  );
};

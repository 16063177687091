import {
  createWsThrottle,
  createWsHandoverThrottle,
} from '../data-processing/ws-income/throttle-ws-income';
import * as actions from '../actions';
import { getTokenFromLocalStorage } from '../utils/tokenLocalStorage';
import { logError } from '../utils/loggingUtils';
import { handleError } from '../components/error-handling/utils';
import { parseFromUnit8Array } from '../utils/json-parse';

const wsUrl = window?.ENV?.WS_URL;
const wsHandoverUrl = window?.ENV?.WS_HANDOVER_URL;

export function connectWs() {
  try {
    window.wsClose = (timeout: number = 0) =>
      setTimeout(() => ws.close(), timeout);
    const token = getTokenFromLocalStorage();
    const throttledWsPush = createWsThrottle(500);
    const ws = new WebSocket(wsUrl);
    let authSuccessful = null;
    if (!token) {
      logError('Unable to get valid token. WS connection is skipped.');
    }
    ws.onopen = () => ws.send(`Authorization: Bearer ${token}`);
    ws.onmessage = ev => {
      if (ev.data) {
        const parsed = JSON.parse(ev.data);
        if (parsed.response === 'Authorized successfully')
          return (authSuccessful = true);
        if (!authSuccessful) return ws.close();
        throttledWsPush(parsed);
      }
    };
    ws.onclose = () => {
      store.dispatch(actions.webSocketConnectionClosed());
    };
  } catch (e) {
    logError(
      `Unable to init WS connection. Unexpected exception: ${JSON.stringify(e)}`
    );
  }
}

export function connectHandoverWs() {
  try {
    const token = getTokenFromLocalStorage();
    if (!token) {
      handleError({
        content: '',
        error: new Error('Unable to get valid token.'),
        message:
          'Connection to Handover server is skipped.\nRemarks live updates will not work',
      });
    }
    const throttledWsPush = createWsHandoverThrottle(5000);
    const queryParams = `?Authorization=Bearer ${token}`;
    const ws = new WebSocket(`${wsHandoverUrl}${queryParams}`);
    ws.onopen = () => {
      console.log('Handover WS connection established');
    };
    ws.onmessage = ev => {
      if (ev.data) {
        try {
          const parsed = parseFromUnit8Array(ev.data, 'remarks');
          if (!!parsed) {
            throttledWsPush(parsed);
          }
        } catch (error) {
          console.error(
            `Failed to parse Handover WS message\nError: ${error}\nData: ${ev.data}`
          );
        }
      }
    };
    ws.onclose = () => {
      store.dispatch(actions.wsHandoverConnectionClosed());
    };
  } catch (error) {
    logError('Web socket failed to connect' + error.toString());
    handleError({
      content: JSON.stringify(error),
      error,
      message: `Unable to install WS connection to Handover server. Unexpected exception: ${JSON.stringify(
        error
      )}`,
      url: wsHandoverUrl,
    });
  }
}

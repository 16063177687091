import { PureComponent } from 'react';
import { FlightPreviewCard } from './flight/';
import { isMaintenance } from '../../../types/maintenance';
import { MaintenancePreviewCard } from './maintenance/';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { isFlight } from '../../../common/flight/flight-check-status';
import EventElement from '../../../types/event-element';
import { OneWayOfferPreviewCard } from './one-way';
import { EmptyLegOfferPreviewCard } from './empty-leg';
import { isEmptyLegOffer } from '../../../utils/empty-leg-offer';
import { isOneWayOffer } from '../../../utils/one-way-offer';
import { COMMON_TOOLTIP_WIDTH } from '../../../constants';
interface Props {
  element: EventElement;
  index: number;
  focused: number;
  onFocus: (index: number) => () => void;
  onBlur: () => void;
  placement: TooltipPlacement;
  isVerticalMode: boolean;
  flightTooltipWidth: number;
  boundingRect?: DOMRect;
}

export default class Card extends PureComponent<Props> {
  render() {
    const {
      focused,
      index,
      onFocus,
      onBlur,
      element,
      placement,
      boundingRect,
      isVerticalMode,
      flightTooltipWidth,
    } = this.props;
    let recalculatedPlacement = placement;
    if (isVerticalMode && boundingRect) {
      const elementWidth = isFlight(element)
        ? flightTooltipWidth
        : COMMON_TOOLTIP_WIDTH;
      if (window.innerWidth - boundingRect.right < elementWidth) {
        recalculatedPlacement =
          boundingRect.left < elementWidth ? 'bottom' : 'left';
      } else {
        recalculatedPlacement = 'right';
      }
    }
    const properties = {
      element,
      isFocused: index === focused,
      isFocusing: focused !== null,
      onFocus: onFocus(index),
      onBlur,
      placement: recalculatedPlacement,
    };
    if (isFlight(element))
      return <FlightPreviewCard {...properties} element={element} />;
    if (isMaintenance(element))
      return <MaintenancePreviewCard {...properties} element={element} />;
    if (isEmptyLegOffer(element))
      return <EmptyLegOfferPreviewCard {...properties} element={element} />;
    if (isOneWayOffer(element))
      return <OneWayOfferPreviewCard {...properties} element={element} />;
  }
}

import { PureComponent } from 'react';
import Maintenance from '../../../../types/maintenance';
import Airport from '../../../../types/airport';
import TooltipDates from '../../dates';
import TooltipHeader from '../../header/';
import { getMaintenanceTooltipColor } from '../../../../common/maintenance/maintenance-colorization';
import {
  getMaintenanceTypeLabel,
  getMaintenanceTypeTooltipLabel,
} from '../../../../common/maintenance/maintenance-labels';
import { LINE_HEIGHT } from '../../../../constants';
import { setDataTestEntityId } from '../../../../utils';
import { utc } from 'moment';
import Aircraft from '../../../../types/aircraft';
import { isJSOperatedAircraft } from '../../../../common/aircraft/aircraft-check-status';

interface ConnectedProps {}

interface OwnProps {
  segmentData: Maintenance;
  airportsById: { [id: number]: Airport };
  aircraft?: Aircraft;
}
interface Props extends ConnectedProps, OwnProps {}

export function getIcaoOrMessage(airport: Airport): string {
  if (airport) {
    return airport.ICAO;
  }
  return 'No airport specified';
}

export default class TooltipMaintenanceCommon extends PureComponent<
  Props,
  { visible: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  hide = () => {
    this.setState({ visible: false });
  };
  handleVisibleChange = visible => {
    this.setState({ visible });
  };
  render() {
    const { airportsById, segmentData, aircraft } = this.props;
    const {
      airportId,
      start,
      end,
      lastUpdated,
      creator,
      reason,
      releasedBy,
      releasedTime,
      id,
      orderId,
      updatedBy,
      createdAt,
      companyName,
    } = segmentData;
    const airport = airportsById[airportId];
    const createdBy = creator && `${creator.firstName} ${creator.lastName}`;
    const updatedTime = lastUpdated
      ? utc(lastUpdated).format('DD MMM,’YY, HH:mm[Z]')
      : 'N/A';
    const createdTime = createdAt
      ? utc(createdAt).format('DD MMM,’YY, HH:mm[Z]')
      : 'N/A';
    const showLastUpdated =
      updatedBy && (updatedBy !== createdBy || createdTime !== updatedTime);
    const { numberOfSeats } = aircraft || {};
    const awrCondition = numberOfSeats >= 10 && isJSOperatedAircraft(aircraft);
    return (
      <div style={{ maxWidth: '320px' }}>
        <TooltipHeader
          id={id}
          color="#fff"
          backgroundColor={getMaintenanceTooltipColor(
            segmentData.maintenanceType
          ).toLowerCase()}
          name={
            <div className="tooltip-header-adv">
              <span
                className="tooltip-header-adv-abbreviation"
                {...setDataTestEntityId('tooltip-mx-adv-abbreviation')}
              >
                {getMaintenanceTypeLabel(segmentData)}
              </span>
              <span
                className="tooltip-header-adv-description"
                {...setDataTestEntityId('tooltip-mx-adv-description')}
              >
                ({getMaintenanceTypeTooltipLabel(segmentData)})
              </span>
            </div>
          }
        />
        <div
          className="tooltip-common"
          style={{ padding: releasedBy ? '0 16px 12px' : '0 16px 24px' }}
        >
          <div
            className="tooltip-common-updated-by"
            {...setDataTestEntityId('tooltip-mx-created-by')}
          >
            {`Created by ${createdBy ?? 'N/A'} at ${createdTime}`}
          </div>
          {showLastUpdated ? (
            <div
              className="tooltip-common-updated-by"
              {...setDataTestEntityId('tooltip-mx-updated-by')}
            >
              {`Updated by ${updatedBy} at ${updatedTime}`}
            </div>
          ) : null}
          <div className="tooltip-airport-values">
            <div className="tooltip-current-airport-group-maintenance">
              <div
                className={
                  airport
                    ? 'tooltip-current-airport-icao'
                    : 'tooltip-current-airport-without-icao'
                }
                {...setDataTestEntityId('tooltip-mx-icao')}
              >
                {getIcaoOrMessage(airport)}
              </div>
              <div className="tooltip-current-airport-name-maintenance">
                {airport && airport.name}
              </div>
            </div>
          </div>
          {orderId ? (
            <div
              className="tooltip-mx-order"
              {...setDataTestEntityId('tooltip-mx-order')}
            >{`Order# ${orderId}`}</div>
          ) : null}
          <div
            className="tooltip-mx-service-provider"
            {...setDataTestEntityId('tooltip-mx-service-provider')}
          >
            {`Service Provider: ${companyName}`}
          </div>
          <div
            className="tooltip-common-description"
            {...setDataTestEntityId('tooltip-mx-description')}
            style={{
              maxHeight: `${LINE_HEIGHT * 5.5}px`,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                display: '-webkit-box',
                WebkitLineClamp: 5,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {reason}
            </div>
          </div>
          <TooltipDates start={start} end={end} />
          {(releasedBy || releasedTime) && (
            <div className="tooltip-mx-rts">
              {`RTS${awrCondition ? '/AWR' : ''}: ${releasedBy || 'N/A'}, ${utc(
                releasedTime
              ).format('DD MMM,’YY, HH:mm[z]')}`}
            </div>
          )}
        </div>
      </div>
    );
  }
}

import { CSSProperties } from 'react';

export const tooltipWideStyles: CSSProperties = {
  minWidth: '389px',
  maxWidth: '431px',
};

export const tooltipMediumWidthStyles: CSSProperties = {
  minWidth: '222px',
  maxWidth: '250px',
};

export const tooltipNarrowWidthStyles: CSSProperties = {
  minWidth: '208px',
  maxWidth: '250px',
};

export const tooltipMxDueStyles: CSSProperties = {
  minWidth: '208px',
  maxWidth: '320px',
  width: 'initial',
};

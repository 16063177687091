const DragAndDropIcon = () => (
  <svg
    width="6"
    height="16"
    viewBox="0 0 6 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={`M0.5 0.5H2.16667V2.16667H0.5V0.5ZM3.833330 0.5H5.5V2.16667H3.83333V0.5ZM0.5 3.83333H2.16667V5.5H0.5V3.83333ZM3.83333 3.83333H5.5V5.5H3.83333V3.83333ZM0.5 7.16667H2.16667V8.83333H0.5V7.16667ZM3.83333 7.16667H5.5V8.83333H3.83333V7.16667ZM0.5 10.5H2.16667V12.1667H0.5V10.5ZM3.83333 10.5H5.5V12.1667H3.83333V10.5ZM0.5 13.8333H2.16667V15.5H0.5V13.8333ZM3.83333 13.8333H5.5V15.5H3.83333V13.8333Z`}
      fill="black"
      fillOpacity="0.25"
    ></path>
  </svg>
);

export default DragAndDropIcon;

import Keycloak from 'keycloak-js';

export const storageKey = 'tl-token';

export const getTokenFromLocalStorage = () => {
  const storageValue = localStorage.getItem(storageKey);
  let token = '';
  if (storageValue) {
    try {
      token = JSON.parse(storageValue).token;
    } catch (err) {
      localStorage.removeItem(storageKey);
    }
  }
  return token;
};

export const setKeycloakTokenPair = (
  keycloak: Keycloak.KeycloakInstance
): void => {
  const storageValue = {
    refreshToken: keycloak.refreshToken || '',
    token: keycloak.token || '',
  };
  localStorage.setItem(storageKey, JSON.stringify(storageValue));
  console.debug('keycloak-set-tokens-to-local-storage', storageValue);
};

export const setAuth0Token = (token: string): void => {
  localStorage.setItem(storageKey, JSON.stringify({ token }));
  console.debug('auth0-set-token-to-local-storage');
};

export const removeTokenPair = (): void => {
  localStorage.removeItem(storageKey);
  console.debug('keycloak-remove-tokens-from-local-storage');
};

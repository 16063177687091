import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { getRects } from '../../selectors';
import { getDashboardTraverseRects } from '../../reducers/dashboard';

interface FocusedRect {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

interface Props {
  rect: FocusedRect;
  color: string;
  marginTop: number;
  height: number;
  width: number;
  idType: string;
}
class FocusWindow extends PureComponent<Props> {
  static getWindowString(w, h, marginTop) {
    return `M 0 ${marginTop} v ${h} h ${w} v -${h} z`;
  }
  static getHoleString(r: FocusedRect, marginTop) {
    const { x1, x2 } = r;
    const y1 = Math.max(r.y1, marginTop);
    const y2 = Math.max(r.y2, marginTop);
    return `M${x1} ${y1} h ${x2 - x1} v ${y2 - y1} h ${x1 - x2}z`;
  }
  render() {
    const { height, width, marginTop, rect, color, idType } = this.props;
    if (!rect) return null;
    const { x1, x2 } = rect;
    const y1 = Math.max(rect.y1, marginTop);
    const y2 = Math.max(rect.y2, marginTop);
    const windowString = FocusWindow.getWindowString(width, height, marginTop);
    const holeString = FocusWindow.getHoleString(rect, marginTop);
    const id = 'focus-window-clip' + idType;
    const rectWidth = x2 - x1;
    const rectHeight = y2 - y1;
    return (
      <g className="focus-window">
        <clipPath id={id}>
          <path d={windowString + holeString} clipRule="evenodd" />
        </clipPath>
        <ellipse
          clipPath={`url(#${id})`}
          fill={color}
          cx={x1 + rectWidth / 2}
          cy={y1 + rectHeight / 2}
          rx={rectWidth / 2}
          ry={rectHeight / 2}
        >
          <animate
            attributeType="SVG"
            attributeName="rx"
            begin="0s"
            dur="1.5s"
            repeatCount="indefinite"
            from={rectWidth / 2}
            to={rectWidth / 2 + 50}
          />
          <animate
            attributeType="SVG"
            attributeName="ry"
            begin="0s"
            dur="1.5s"
            repeatCount="indefinite"
            from={rectHeight / 2}
            to={rectHeight / 2 + 30}
          />
          <animate
            attributeType="CSS"
            attributeName="opacity"
            begin="0s"
            dur="1.5s"
            repeatCount="indefinite"
            from="1"
            to="0"
          />
        </ellipse>
      </g>
    );
  }
}

export const SearchFocus = connect((state: RootState) => ({
  color: 'rgba(0, 128, 128,0.2)',
  idType: 'search-focus',
  marginTop: state.ui.marginTop,
  height: state.ui.height,
  width: state.ui.width,
  rect:
    !state.ui.isScrollingVertically &&
    !state.ui.isScrollingHorizontally &&
    getRects(state)[state.search.focusedFoundElement],
}))(FocusWindow);
export const DelayFocus = connect((state: RootState) => ({
  color: 'rgba(	255, 128, 0, 0.5)',
  idType: 'delay-focus',
  marginTop: state.ui.marginTop,
  height: state.ui.height,
  width: state.ui.width,
  rect: getDashboardTraverseRects(state)[state.dashboard.focusedElement],
}))(FocusWindow);

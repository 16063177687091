import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';

export interface OneWayOfferController {
  id: number;
  contextMenu: {
    isOpen: boolean;
    position: {
      x: number;
      y: number;
    };
  };
  iframe: {
    isOpen: boolean;
  };
}

export const initialState: OneWayOfferController = {
  id: undefined,
  contextMenu: {
    isOpen: false,
    position: undefined,
  },
  iframe: {
    isOpen: false,
  },
};

export default reducerWithInitialState(initialState)
  .case(actions.userOpenContextMenuForOneWayOffer, (state, payload) => {
    const { id, position } = payload;
    return {
      ...state,
      id,
      contextMenu: {
        ...state.contextMenu,
        isOpen: true,
        position,
      },
    };
  })
  .case(actions.userClickEditOneWayOffer, (state, payload) => ({
    ...state,
    id: payload || state.id,
    contextMenu: {
      ...state.contextMenu,
      isOpen: false,
      position: null,
    },
    iframe: {
      ...state.iframe,
      isOpen: true,
    },
  }))
  .case(actions.userTimelineSelectionBegin, state => ({
    ...state,
    contextMenu: {
      ...state.contextMenu,
      isOpen: false,
    },
  }))
  .case(actions.userCloseIframe, state => ({
    ...state,
    iframe: {
      ...state.iframe,
      isOpen: false,
    },
  }))
  .cases(
    [
      actions.userFocusOverlapCards,
      actions.userOpenNoteContextMenu,
      actions.userOpenFlightMenu,
      actions.userOpenCreateEventsMenu,
      actions.userOpenContextMenuForPeakDay,
      actions.userOpenContextMenuForMxEvent,
      actions.userOpenContextMenuForEmptyLegOffer,
    ] as any[],
    state => ({
      ...state,
      contextMenu: {
        ...state.contextMenu,
        isOpen: false,
      },
    })
  );

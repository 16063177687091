import Airport from '../../types/airport';

export interface Place {
  city: string;
  country: string;
  description: string;
  region: string;
}
export interface AirportGVModel {
  airportId: {
    key: number;
  };
  place: Place;
  icao: string;
  iata: string;
  name: string;
  iso: string;
  isActive: true;
  timezoneIds: { key: number }[];
}
function getKey(keyedId: { key: number }): number {
  return keyedId.key;
}
export const transformAirportToFE = (a: AirportGVModel): Airport => ({
  id: a.airportId.key,
  ICAO: a.icao,
  IATA: a.iata,
  name: a.name,
  place: a.place,
  isActive: a.isActive,
  timezoneIds: (a.timezoneIds || []).map(getKey),
});

export const getSvgHoldFlightsD3creator = entered => {
  const rowHoldFlight = entered.append('g').classed('hold-flight', true);
  rowHoldFlight
    .append('rect')
    .attr('fill', d => d.backgroundColor)
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    .attr('width', d => d.width)
    .attr('height', d => d.height);
  rowHoldFlight
    .append('rect')
    .attr('fill', d => d.stroke)
    .attr('x', d => d.x)
    .attr('y', d => d.y)
    //adjust width on zoom level: width=3 for 1 week view, 2 for 3days view, 1 for super zoomed level
    .attr('width', d => (d.kx <= 1 ? 3 : d.kx <= 1.5 ? 2 : 1))
    .attr('height', d => d.height);
  return rowHoldFlight;
};

import { PureComponent } from 'react';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { getAircraftByIndex } from '../../../selectors';
import Aircraft from '../../../types/aircraft';
import { isNumber } from 'lodash';

interface StateProps {
  translateY: number;
  ky: number;
  marginTop: number;
  width: number;
  rowHeight: number;
  hoveredAircraftIndex: number;
  hoveredAircraft: Aircraft;
  holdAircraftPositionMap: { [id: number]: { y1: number; y2: number } };
  holdLineHeight: number;
}

class AircraftHighlighterComponent extends PureComponent<StateProps> {
  render() {
    const {
      translateY,
      ky,
      marginTop,
      hoveredAircraftIndex,
      rowHeight,
      holdAircraftPositionMap,
      hoveredAircraft,
      holdLineHeight,
      width,
    } = this.props;
    const hoveredAircraftId = hoveredAircraft && hoveredAircraft.id;
    const position = holdAircraftPositionMap[hoveredAircraftId];
    const holdHeight = position ? position.y2 - position.y1 : rowHeight;

    const holdAcLength = Object.keys(holdAircraftPositionMap).length;
    return (
      <>
        <g clipPath="url(#eventsSVGClipHold)">
          <g
            className="aircraft-highlighter"
            transform={`translate(0,${marginTop})scale(1,1)`}
          >
            {position ? (
              <rect
                className="background"
                x={0}
                y={position.y1}
                height={holdHeight}
                width={width}
                fill="black"
                stroke="rgba(0,0,0,0.3)"
                fillOpacity={0.1}
              />
            ) : null}
          </g>
        </g>
        <g clipPath="url(#eventsSVGClip)">
          <g
            className="aircraft-highlighter"
            transform={`translate(0,${translateY +
              marginTop +
              holdLineHeight})scale(1, ${ky})`}
          >
            {!position ? (
              <rect
                className="background"
                x={0}
                y={(hoveredAircraftIndex - holdAcLength) * rowHeight}
                height={rowHeight}
                width={width}
                fill="black"
                stroke="rgba(0,0,0,0.3)"
                fillOpacity={0.1}
              />
            ) : null}
          </g>
        </g>
      </>
    );
  }
}
export const AircraftHighlighterConnected = connect((state: RootState) => ({
  translateY: state.ui.transform.translateY,
  ky: state.ui.transform.ky,
  marginTop: state.ui.marginTop,
  width: state.ui.width,
  rowHeight: state.ui.rowHeight,
  hoveredAircraftIndex: state.ui.hoveredAircraftIndex,
  hoveredAircraft:
    isNumber(state.ui.hoveredAircraftIndex) &&
    getAircraftByIndex(state, state.ui.hoveredAircraftIndex),
  holdAircraftPositionMap: state.ui.holdAircraftPositionMap,
  holdLineHeight: state.ui.holdLineHeight,
}))(AircraftHighlighterComponent);

import { AllNotReadyForPreparationFlightPatternsConnected } from './not-ready-for-preparation-flight';
import { AllMaintenanceIsDispatchedPatternsConnected } from './maintenanceIs-dispatched';
import { SlotMismatchDotSymbolConnected } from './slot-mismatch-dot';
import { AllOverlapWithMaintenancePatternsConnected } from './overlappedMaintenances';
import { FlightPatternForHandoverRemarksConnected } from './handover-remarks-on-flight';

export const SvgPatterns = () => (
  <>
    <AllMaintenanceIsDispatchedPatternsConnected />
    <AllNotReadyForPreparationFlightPatternsConnected />
    <SlotMismatchDotSymbolConnected />
    <AllOverlapWithMaintenancePatternsConnected />
    <FlightPatternForHandoverRemarksConnected />
  </>
);

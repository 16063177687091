import { FC } from 'react';
import { AutoComplete, ConfigProvider } from 'antd';
import { SearchQueryAllFilters, SearchType } from '../../reducers/search';
import { LabeledValue } from 'antd/es/select';
import { setDataTestEntityId } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../reducers';
import {
  userChangeSearchQueryAllFilters,
  userChangeSearchType,
} from '../../actions';
import { getMultipleSearchAutoCompleteOptions } from '../../selectors';
import { toOptionsValue } from '../email-component/utils';
import { isEqual } from 'lodash';

interface Props {
  searchType: SearchType;
  onSearch: () => void;
}

export const MultiSearchAutoComplete: FC<Props> = ({
  searchType,
  onSearch,
}) => {
  const dispatch = useDispatch();
  const isVerticalMode = useSelector<RootState, boolean>(
    state => state.ui.isVerticalMode
  );
  const options = useSelector<RootState, LabeledValue[]>(
    state =>
      toOptionsValue(getMultipleSearchAutoCompleteOptions(state, searchType)),
    isEqual
  );
  const searchQueryAllFilters = useSelector<RootState, SearchQueryAllFilters>(
    state => state.search.searchQueryAllFilters
  );
  const value = searchQueryAllFilters[searchType];
  const onChangeQuery = (query: string) =>
    dispatch(
      userChangeSearchQueryAllFilters({
        query,
        searchType,
      })
    );
  const onFocus = () => dispatch(userChangeSearchType(searchType));
  return (
    <div className="filter-bar-block">
      <label htmlFor={searchType}>{searchType}</label>
      <ConfigProvider
        theme={{
          token: {
            controlHeight: 25,
          },
        }}
      >
        <AutoComplete
          onChange={onChangeQuery}
          onFocus={onFocus}
          onSelect={onSearch}
          options={options}
          popupMatchSelectWidth={false}
          popupClassName="more-filters-search-autocomplete-dropdown"
          style={{ width: isVerticalMode ? '121px' : '200px' }}
          {...setDataTestEntityId('search-all-filters-autocomplete')}
          value={value}
          id={searchType}
        />
      </ConfigProvider>
    </div>
  );
};

import { PureComponent } from 'react';
import RadioGroup from 'antd/lib/radio/group';
import RadioButton from 'antd/lib/radio/radioButton';
import { RadioChangeEvent } from 'antd/lib/radio';

export type TimeMode = 'UTC' | 'LT';
interface Props {
  numberOfSelectedFlights: number;
  timeMode: TimeMode;
  onChangeTimeView: (timeMode: TimeMode) => void;
}
export class FirstRow extends PureComponent<Props> {
  changeTimeView = (event: RadioChangeEvent) => {
    this.props.onChangeTimeView(event.target.value);
  };
  render() {
    const { numberOfSelectedFlights } = this.props;
    return (
      <div className="dnd-multiple-modal-first-row">
        <div className="dnd-multiple-modal-first-row-title">
          <h1 className="dnd-multiple-modal-first-row-title-h1">
            Change Flight Tail
          </h1>
          <p className="dnd-multiple-modal-first-row-title-text">
            {numberOfSelectedFlights}
            {numberOfSelectedFlights === 1
              ? ` Flight Selected`
              : ` Flights Selected`}
          </p>
        </div>
        <div className="dnd-multiple-modal-first-row-buttons">
          <div className="dnd-multiple-modal-first-row-prompt">
            <span className="dnd-multiple-modal-first-row-prompt-button">
              SHIFT
            </span>
            <span className="dnd-multiple-modal-first-row-prompt-plus">{` + `}</span>
            <span className="dnd-multiple-modal-first-row-prompt-button">
              LEFT-CLICK
            </span>
            <span className="dnd-multiple-modal-first-row-prompt-text">{` To select few flights`}</span>
          </div>
          <div className="dnd-multiple-modal-first-row-radio-group">
            <RadioGroup
              onChange={this.changeTimeView}
              value={this.props.timeMode}
            >
              <RadioButton value={'UTC'}>UTC</RadioButton>
              <RadioButton value={'LT'}>LT</RadioButton>
            </RadioGroup>
          </div>
        </div>
      </div>
    );
  }
}

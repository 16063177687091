import { PureComponent } from 'react';
import { utc } from 'moment';
import Airport from '../../../../types/airport';
import { capitalize } from 'lodash';
import EmptyLegOffer from '../../../../types/empty-leg-offer';
import { EmptyLegActionButtons } from '../../../tooltip/action-buttons';
import {
  getICAOLabelFromAirport,
  getCityLabelFromAirport,
  getCountryLabelFromAirport,
} from '../../../../utils/airport';

interface Props {
  element: EmptyLegOffer;
  airportsById: { [id: number]: Airport };
  hasCheckedEmptyLegOffersPermission: boolean;
}

export default class EmptyLegOfferCardContent extends PureComponent<Props> {
  render() {
    const {
      element,
      airportsById,
      hasCheckedEmptyLegOffersPermission,
    } = this.props;
    const depAirport = airportsById[element.departureAirportId];
    const arrAirport = airportsById[element.arrivalAirportId];
    const depICAO = getICAOLabelFromAirport(depAirport);
    const arrICAO = getICAOLabelFromAirport(arrAirport);
    const depCity = getCityLabelFromAirport(depAirport);
    const arrCity = getCityLabelFromAirport(arrAirport);
    const depCountry = getCountryLabelFromAirport(depAirport);
    const arrCountry = getCountryLabelFromAirport(arrAirport);
    return (
      <div className="card-content">
        <div style={{ display: 'flex' }}>
          <svg height={40} width={15}>
            <circle cx={7} cy={10} r={2} fill="rgba(0, 0, 0, 0.45)" />
            <line
              x1={7}
              x2={7}
              y1={10}
              y2={34}
              strokeWidth={1}
              stroke="rgba(0, 0, 0, 0.45)"
            />
            <circle cx={7} cy={34} r={2} fill="rgba(0, 0, 0, 0.45)" />
          </svg>
          <div>
            <div className="card-flight-airport-info">
              <strong>{depICAO}</strong>
              {` ${capitalize(depCity)}, ${capitalize(depCountry)} `}
              <span className="card-flight-airport-info-time-duration">
                {utc(element.start).format('DD MMM, HH:mm [Z]')}
              </span>
            </div>
            <div>
              <strong>{arrICAO}</strong>
              {` ${capitalize(arrCity)}, ${capitalize(arrCountry)} `}
              <span className="card-flight-airport-info-time-duration">
                {utc(element.end).format('DD MMM, HH:mm [Z]')}
              </span>
            </div>
          </div>
        </div>
        {hasCheckedEmptyLegOffersPermission && (
          <EmptyLegActionButtons
            id={element.id}
            hasEditPermission={hasCheckedEmptyLegOffersPermission}
          />
        )}
      </div>
    );
  }
}

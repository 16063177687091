import { PureComponent } from 'react';

interface Props {
  color: string;
}

export default class PlaneIcon extends PureComponent<Props> {
  render() {
    return (
      <div>
        <svg width="16px" height="16px" viewBox="0 0 16 16">
          <g
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            opacity="0.400000006"
          >
            <g
              transform="translate(-940.000000, -236.000000)"
              fill={this.props.color}
              fillRule="nonzero"
            >
              <g id="Group-02" transform="translate(736.000000, 149.000000)">
                <path
                  d="M220,98.2 L220,96.6 L213.263158,92.6 L213.263158,88.2 C213.263158,87.5372583 212.697623,87 212,87 C211.302377,87 210.736842,87.5372583 210.736842,88.2 L210.736842,92.6 L204,96.6 L204,98.2 L210.736842,96.2 L210.736842,100.6 L209.052632,101.8 L209.052632,103 L212,102.2 L214.947368,103 L214.947368,101.8 L213.263158,100.6 L213.263158,96.2 L220,98.2 Z"
                  transform="translate(212.000000, 95.000000) rotate(90.000000) translate(-212.000000, -95.000000) "
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

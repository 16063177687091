import { PureComponent, MouseEvent } from 'react';

interface Props {
  isFocusing: boolean;
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  draggable?: boolean;
  onDragStart?: (e: React.DragEvent) => void;
}

export default class Wrapper extends PureComponent<Props> {
  ref: HTMLDivElement;
  constructor(props) {
    super(props);
    this.unHover = this.unHover.bind(this);
  }
  getRef = ref => (this.ref = ref);
  unHover(event: MouseEvent<HTMLDivElement>) {
    const { clientX, clientY } = event;
    const { left, right, top, bottom } = this.ref.getBoundingClientRect();
    const isOverCard =
      clientX > left && clientX < right && clientY > top && clientY < bottom;
    if (!isOverCard) {
      this.props.onBlur();
    }
  }

  render() {
    const {
      isFocusing,
      isFocused,
      onFocus,
      draggable,
      onDragStart,
    } = this.props;
    return (
      <div
        ref={this.getRef}
        className="card"
        style={{
          marginTop: 8,
          border: '1px solid #DDDDDD',
          borderRadius: 4,
          opacity: !isFocusing || isFocused ? 1 : 0.3,
        }}
        onMouseEnter={onFocus}
        onMouseLeave={this.unHover}
        draggable={draggable}
        onDragStart={onDragStart}
      >
        {this.props.children}
      </div>
    );
  }
}

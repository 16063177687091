import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../actions';
import { RootState } from '../../../../reducers';
import { hasPermission } from '../../../../utils/check-permissions';

export const EventListActionButtons: FC<{ id: number }> = ({ id }) => {
  const dispatch = useDispatch();
  const hasEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Timeline-Event-Edit')
  );
  const onClickEditPeakDay = () => dispatch(actions.userClickEditPeakDay(id));
  const onClickDeletePeakDay = () =>
    dispatch(actions.userDeletePeakDay.started(id));

  const deleteButton = (
    <Button
      icon={<DeleteOutlined />}
      className="event-list-table-action-button"
      disabled={!hasEditPermission}
      shape="circle"
      size="small"
    />
  );
  return (
    <>
      <div className="event-list-table-action-buttons">
        <Tooltip title="Edit Peak day">
          <Button
            icon={<EditOutlined />}
            className="event-list-table-action-button"
            onClick={onClickEditPeakDay}
            disabled={!hasEditPermission}
            shape="circle"
            size="small"
          />
        </Tooltip>
        <Tooltip title="Delete Peak day">
          {hasEditPermission ? (
            <Popconfirm
              title="Do you want to delete this Peak day?"
              onConfirm={onClickDeletePeakDay}
              okText={<span data-stopbubbling="true">Yes</span>}
              cancelText="No"
            >
              {deleteButton}
            </Popconfirm>
          ) : (
            deleteButton
          )}
        </Tooltip>
      </div>
    </>
  );
};

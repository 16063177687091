import ResponseSizeLimitError from '../../errors/ResponseSizeLimitError';
import { axiosAuthed as axios } from '../axios-config';
import { transformToFE } from './flight-model';

export const getFlights = (startTime: number, endTime: number) =>
  axios
    .get(
      `/integration/timeline/flights?startTime=${startTime}&endTime=${endTime}`
    )
    .then(resp => {
      return resp.data.reduce((acc, f) => {
        if (f.error) {
          throw new ResponseSizeLimitError(f.error);
        }
        if (!f) return acc;
        return acc.concat(transformToFE(f));
      }, []);
    })
    .catch(e => {
      if (e instanceof ResponseSizeLimitError) {
        throw new ResponseSizeLimitError(e, true);
      }
      throw e;
    });

import { CSSProperties, PureComponent } from 'react';
import { DragTopBarConnected } from './dragTopBar';
import { DraggableContent } from './draggableContent';
import { clamp } from 'lodash';

interface Props {
  style?: CSSProperties;
}

interface StateProps {
  isDragging: boolean;
  position: { x: number; y: number };
  offset: { x: number; y: number };
  width: number;
  height: number;
}

interface DispatchProps {
  onDragStart: (
    mousePos: { x: number; y: number },
    nodeOffset: { x: number; y: number }
  ) => void;
}

export class DraggableItem extends PureComponent<
  Props & StateProps & DispatchProps
> {
  ref: HTMLDivElement;
  getRef = ref => (this.ref = ref);
  render() {
    return (
      <div
        ref={this.getRef}
        style={
          this.props.style
            ? getStyles({
                position: this.props.position,
                offset: this.props.offset,
                width: this.props.width,
                style: this.props.style,
              })
            : null
        }
      >
        <DragTopBarConnected
          isDragging={this.props.isDragging}
          onDragStart={this.props.onDragStart}
        />
        <DraggableContent
          isDragging={this.props.isDragging}
          width={this.props.width}
          height={this.props.height}
        >
          {this.props.children}
        </DraggableContent>
      </div>
    );
  }
}
const isAbsolutelyPositioned = (position: { x: number; y: number }): boolean =>
  !!position;
const getDefaultStyles = ({ width, style }) => ({
  width: `${width}px`,
  ...style,
});
const getStyles = ({ position, offset, width, style }) =>
  isAbsolutelyPositioned(position)
    ? getIsDraggablePositionStyles({ position, offset, width, style })
    : getDefaultStyles({ width, style });
const getIsDraggablePositionStyles = ({ position, offset, width, style }) => ({
  ...style,
  position: 'absolute',
  top: getYOfElementInsideWindow(position.y + offset.y),
  left: getXOfElementInsideWindow(position.x + offset.x, width),
  width: `${width}px`,
});
const getXOfElementInsideWindow = (
  positionX: number,
  width: number,
  restrictionWidth: number = 300
) =>
  clamp(
    positionX,
    -width + restrictionWidth,
    window.innerWidth - restrictionWidth
  );
const getYOfElementInsideWindow = (
  positionY: number,
  restrictionHeight: number = 300
) =>
  clamp(positionY, -restrictionHeight, window.innerHeight - restrictionHeight);

import Flight, {
  OrderBusinessTypeId,
  OrderTypeColor,
  LegBusinessType,
  OperationalStatus,
  HandlingStatus,
  FlightServiceStatus,
  Slot,
  Permit,
  AFBColorType,
  AFB_COLORS,
  FLIGHT_VERSION_ACK,
} from '../../types/flight';
import {
  LIGHT_TEXT_COLOR,
  DARK_TEXT_COLOR,
  HANDLING_ID,
  HOUR_IN_MS,
} from '../../constants';
import {
  isFlightOnDemandLike,
  getSlotWithLeadTimeByAirport,
  getServicesStatusId,
} from './flight-check-status';
import {
  getReadyForPreparationFillPattern,
  getFlightServiceStyle,
} from './flight-labels';
import { max } from 'lodash';
import { AfbCrewType } from '../../types/afbCrewType';

export const getOrderTypeColor = (o: OrderBusinessTypeId): OrderTypeColor => {
  switch (o) {
    case OrderBusinessTypeId.PROGRAM: {
      return {
        contrastTextColor: LIGHT_TEXT_COLOR,
        primaryColor: 'rgba(72,163,72,1)',
        backgroundColor: 'rgba(72,163,72, 0.1)',
      };
    }
    case OrderBusinessTypeId.ON_DEMAND: {
      return {
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(217,217,32,1)',
        backgroundColor: 'rgba(217,217,32, 0.1)',
      };
    }
    case OrderBusinessTypeId.PRIVATE: {
      return {
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(213,154,35,1)',
        backgroundColor: 'rgba(213,154,35, 0.1)',
      };
    }
    case OrderBusinessTypeId.DEMO: {
      return {
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(255,153,204,1)',
        backgroundColor: 'rgba(255,153,204, 0.1)',
      };
    }
    case OrderBusinessTypeId.MAINTENANCE: {
      return {
        backgroundColor: 'rgba(100,84,82, 0.1)',
        contrastTextColor: LIGHT_TEXT_COLOR,
        primaryColor: 'rgba(100,84,82,1)',
      };
    }
    case OrderBusinessTypeId.MANUFACTURER: {
      return {
        backgroundColor: 'rgba(165,42,42, 0.1)',
        contrastTextColor: LIGHT_TEXT_COLOR,
        primaryColor: 'rgba(165,42,42,1)',
      };
    }
    case OrderBusinessTypeId.OWNER: {
      return {
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(204,153,255,1)',
        backgroundColor: 'rgba(204,153,255, 0.1)',
      };
    }
    case OrderBusinessTypeId.AMBULANCE: {
      return {
        contrastTextColor: LIGHT_TEXT_COLOR,
        primaryColor: 'rgba(34,119,119,1)',
        backgroundColor: 'rgba(34,119,119, 0.1)',
      };
    }
    case OrderBusinessTypeId.MARKETING: {
      return {
        contrastTextColor: LIGHT_TEXT_COLOR,
        primaryColor: 'rgba(119,34,119,1)',
        backgroundColor: 'rgba(119,34,119, 0.1)',
      };
    }
    case OrderBusinessTypeId.OWNER_RELEASE: {
      return {
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(188,143,143,1)',
        backgroundColor: 'rgba(188,143,143, 0.1)',
      };
    }
    case OrderBusinessTypeId.LEASE: {
      return {
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(153,195,255,1)',
        backgroundColor: 'rgba(153,195,255, 0.1)',
      };
    }
    case OrderBusinessTypeId.OTHER_MISC: {
      return {
        contrastTextColor: LIGHT_TEXT_COLOR,
        primaryColor: 'rgba(99,36,35,1)',
        backgroundColor: 'rgba(99,36,35, 0.1)',
      };
    }
    case OrderBusinessTypeId.VJ_DIRECT: {
      return {
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(135,206,250,1)',
        backgroundColor: 'rgba(135,206,250, 0.1)',
      };
    }
    case OrderBusinessTypeId.B2S: {
      return {
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(226,188,152,1)',
        backgroundColor: 'rgba(226,188,152, 0.1)',
      };
    }
    case OrderBusinessTypeId.RETAIL: {
      return {
        backgroundColor: 'rgba(255,127,80, 0.1)',
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(255,127,80,1)',
      };
    }
    case OrderBusinessTypeId.WHOLESALE: {
      return {
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(0,204,187,1)',
        backgroundColor: 'rgba(0,204,187, 0.1)',
      };
    }
    case OrderBusinessTypeId.ELITE: {
      return {
        backgroundColor: 'rgba(71,163,255, 0.1)',
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: 'rgba(71,163,255,1)',
      };
    }
    case OrderBusinessTypeId.SHUTTLE: {
      return {
        backgroundColor: 'rgba(71,163,255, 0.1)',
        contrastTextColor: DARK_TEXT_COLOR,
        primaryColor: '#069778',
      };
    }
    default:
      return {
        contrastTextColor: LIGHT_TEXT_COLOR,
        primaryColor: '#808080',
        backgroundColor: 'rgba(128, 128, 128, 0.1)',
      };
  }
};

export const flightFerryColorizer = (
  legBusinessTypeId: LegBusinessType
): string => {
  switch (legBusinessTypeId) {
    case LegBusinessType.FERRY_CHARGEABLE:
    case LegBusinessType.FERRY_OPERATIONAL: {
      return 'yellow';
    }
    case LegBusinessType.LIVE_CHARGEABLE:
    case LegBusinessType.LIVE_OPERATIONAL: {
      return '#ff771c';
    }
    case LegBusinessType.STAND_DAY: {
      return '#483d8b';
    }
    case LegBusinessType.OVERNIGHT: {
      return '#9f40ff';
    }
    default: {
      return 'lightgray';
    }
  }
};

export const accountTypeColorizer = (f: Flight) => {
  if (f.legOperationalStatusId == OperationalStatus.NO_SHOW) return '#696969';
  if (f.legOperationalStatusId == OperationalStatus.OPTION) return '#999999';
  if (f.sensitive || (f.sensitive && f.demoFlight)) return '#ff26ff';
  if (f.demoFlight) return '#FFC1E3';
  return getOrderTypeColor(f.orderBusinessTypeId)?.primaryColor;
};

export const getScheduledPlusBusinessTypeColor = (f: Flight): string =>
  f.legOperationalStatusId !== OperationalStatus.QUOTED &&
  f.legOperationalStatusId !== OperationalStatus.CANCELLED &&
  f.legOperationalStatusId !== OperationalStatus.NO_SHOW &&
  f.legOperationalStatusId !== OperationalStatus.OPTION &&
  !f.readyForPreparation
    ? `url(#${getReadyForPreparationFillPattern(f)})`
    : accountTypeColorizer(f);

export const handlingStatusColorizator = (f: Flight) => {
  if (f.legOperationalStatusId == OperationalStatus.NO_SHOW) return '#696969';
  if (f.legOperationalStatusId == OperationalStatus.OPTION) return '#999999';
  if (
    f.legOperationalStatusId == OperationalStatus.LANDED ||
    (f.departureUtcBlock && f.arrivalUtcBlock)
  )
    return '#808080';
  switch (f.handlingStatus) {
    case HandlingStatus.AUTOMATIC_HANDLING: {
      return '#10bbe8';
    }
    case HandlingStatus.MANUAL_HANDLING: {
      return '#ffa641';
    }
    case HandlingStatus.ERROR_HANDLING: {
      return 'black';
    }
    case HandlingStatus.CONFIRMED_HANDLING: {
      return '#666666';
    }
  }
  if (
    isFlightOnDemandLike(f) ||
    (f.legOperationalStatusId !== OperationalStatus.QUOTED &&
      f.legOperationalStatusId !== OperationalStatus.CANCELLED &&
      !f.readyForPreparation)
  )
    return 'darkgrey';
  return 'white';
};

export const getBorderStyleSlotSLA = (
  f: Flight,
  airportId: number,
  slot: Slot
) => {
  if (
    slot &&
    slot.leadTime > 0 &&
    (slot.flightServiceStatusId === FlightServiceStatus.NEW ||
      slot.flightServiceStatusId === FlightServiceStatus.REQUESTED ||
      slot.flightServiceStatusId === FlightServiceStatus.NEEDS_REVIEW) &&
    slot.airportId > 0 &&
    f.departureUtcEstimated &&
    f.arrivalUtcEstimated
  ) {
    const estimatedTime =
      f.departureAirportId === airportId
        ? f.departureUtcEstimated
        : f.arrivalUtcEstimated;
    if (estimatedTime) {
      const nowTime = new Date().getTime();
      const redTime = nowTime + slot.leadTime * HOUR_IN_MS;
      const insideSLA = f.departureUtcEstimated <= redTime;

      const orangeTime = redTime + 24 * HOUR_IN_MS;
      const approachingSLA = f.departureUtcEstimated <= orangeTime;
      if (insideSLA) return ' border-red';
      if (approachingSLA) return ' border-orange';
    }
  }
  return '';
};

export const getBorderStylePermitSLA = (
  f: Flight,
  airportId: number,
  permit: Permit
) => {
  if (
    permit &&
    permit.leadTime > 0 &&
    (permit.flightServiceStatusId == FlightServiceStatus.NEW ||
      permit.flightServiceStatusId == FlightServiceStatus.REQUESTED ||
      permit.flightServiceStatusId == FlightServiceStatus.NEEDS_REVIEW) &&
    f.departureUtcEstimated &&
    f.arrivalUtcEstimated
  ) {
    const estimatedTime =
      f.departureAirportId == airportId || airportId === 0 // Airport ID === 0 if airport not assigned to any country
        ? f.departureUtcEstimated
        : f.arrivalUtcEstimated;
    if (estimatedTime) {
      const nowTime = new Date().getTime();
      const redTime = nowTime + permit.leadTime * HOUR_IN_MS;
      const insideSLA = f.departureUtcEstimated <= redTime;

      const orangeTime = redTime + 24 * HOUR_IN_MS;
      const approachingSLA = f.departureUtcEstimated <= orangeTime;
      if (insideSLA) return ' border-red';
      if (approachingSLA) return ' border-orange';
    }
  }
  return '';
};

export const getPermitStyle = (f: Flight, airportId: number) => {
  let styleId = FlightServiceStatus.NEW;
  let borderStyle = '';
  const permit = f.permits && f.permits.find(c => c.airportId == airportId);
  if (
    !permit ||
    (permit && permit.flightServiceStatusId == FlightServiceStatus.CANCELLED) ||
    (permit && permit.flightServiceStatusId == FlightServiceStatus.INVALID)
  ) {
    return 'default';
  }
  borderStyle = getBorderStylePermitSLA(f, airportId, permit);
  if (permit.needsReview) styleId = FlightServiceStatus.NEEDS_REVIEW;
  else styleId = permit.flightServiceStatusId;
  return getFlightServiceStyle(styleId) + borderStyle;
};

export const getServiceStatusType = (f: Flight, airportId: number) => {
  let styleId = getServicesStatusId(f, airportId);
  if (!styleId) return 'confirmed';
  return getFlightServiceStyle(styleId);
};

export const getHandlingServiceStatusType = (f: Flight, airportId: number) => {
  let styleId = FlightServiceStatus.NEW;
  const request =
    f.requests &&
    f.requests.find(
      c => c.airportId === airportId && c.serviceTypeId === HANDLING_ID
    );
  if (
    !request ||
    (request &&
      request.flightServiceStatusId == FlightServiceStatus.CANCELLED) ||
    (request && request.flightServiceStatusId == FlightServiceStatus.INVALID)
  ) {
    styleId = FlightServiceStatus.INVALID;
    return getFlightServiceStyle(styleId);
  }
  if (request.needsReview) styleId = FlightServiceStatus.NEEDS_REVIEW;
  else styleId = request.flightServiceStatusId;
  return getFlightServiceStyle(styleId);
};

export const getSlotStyle = (f: Flight, airportId: number) => {
  let styleId = FlightServiceStatus.NEW;
  let borderStyle = '';
  const slot = f.slots && f.slots.find(c => c.airportId == airportId);
  if (
    !slot ||
    (slot && slot.flightServiceStatusId == FlightServiceStatus.CANCELLED) ||
    (slot && slot.flightServiceStatusId == FlightServiceStatus.INVALID)
  ) {
    return 'default';
  }
  borderStyle = getBorderStyleSlotSLA(f, airportId, slot);
  if (slot.needsReview) styleId = FlightServiceStatus.NEEDS_REVIEW;
  else styleId = slot.flightServiceStatusId;
  return getFlightServiceStyle(styleId) + borderStyle;
};

export const getPermitStyleForItem = (
  f: Flight,
  airportId: number,
  p: Permit
) => {
  let borderStyle = '';
  let styleId = FlightServiceStatus.NEW;
  if (p) {
    if (p.needsReview) {
      styleId = FlightServiceStatus.NEEDS_REVIEW;
    } else {
      styleId = p.flightServiceStatusId;
    }
    borderStyle = getBorderStylePermitSLA(f, airportId, p);
  }
  return getFlightServiceStyle(styleId) + borderStyle;
};

export const getLeadTimeBorderStyleId = (
  leadTime: number,
  estimatedTime: number,
  currentBorderStyleId: number,
  nowTime: number
) => {
  if (!leadTime) return currentBorderStyleId;
  const redTime = nowTime + leadTime * HOUR_IN_MS;
  const insideSLA = estimatedTime <= redTime;

  const orangeTime = redTime + 24 * HOUR_IN_MS;
  const approachingSLA = estimatedTime <= orangeTime;
  if (insideSLA) return 2;
  if (approachingSLA) return Math.max(1, currentBorderStyleId);
  return Math.max(0, currentBorderStyleId);
};

export const getLeadTimeBorderStyle = (f: Flight, nowTime: number) => {
  let borderStyleState = 0;

  let endTime =
    f.arrivalUtcBlock != null ? f.arrivalUtcBlock : f.arrivalUtcEstimated;

  if (endTime < nowTime) {
    return 'none';
  }

  if (f.departureUtcEstimated && f.arrivalUtcEstimated) {
    const depSlot = getSlotWithLeadTimeByAirport(f, f.departureAirportId);
    const arrSlot = getSlotWithLeadTimeByAirport(f, f.arrivalAirportId);
    if (depSlot) {
      borderStyleState = getLeadTimeBorderStyleId(
        depSlot.leadTime,
        f.departureUtcEstimated,
        borderStyleState,
        nowTime
      );
    }
    if (arrSlot) {
      borderStyleState = getLeadTimeBorderStyleId(
        arrSlot.leadTime,
        f.departureUtcEstimated,
        borderStyleState,
        nowTime
      );
    }
    if (f.permits && f.permits.length) {
      borderStyleState = max(
        f.permits
          .filter(
            f =>
              f.flightServiceStatusId == FlightServiceStatus.NEW ||
              f.flightServiceStatusId == FlightServiceStatus.REQUESTED ||
              f.flightServiceStatusId == FlightServiceStatus.NEEDS_REVIEW
          )
          .map(p =>
            getLeadTimeBorderStyleId(
              p.leadTime,
              f.departureUtcEstimated,
              borderStyleState,
              nowTime
            )
          )
          .concat(borderStyleState)
      );
    }
  }
  switch (borderStyleState) {
    case 1:
      return '#f87b05';
    case 2:
      return 'red';
    default:
      return 'none';
  }
};

export const getBorderColorForNotCoverWithOtherBorderFlights = (f: Flight) => {
  if (f.legOperationalStatusId === OperationalStatus.NO_SHOW) {
    return 'lightgrey';
  }
  switch (f.orderBusinessTypeId) {
    case OrderBusinessTypeId.ON_DEMAND: {
      return 'yellow';
    }
    case OrderBusinessTypeId.PROGRAM: {
      return '#00C851';
    }
    default: {
      return 'lightgrey';
    }
  }
};

export const getBorderStyleByFlightLegBusinessType = (
  legBusinessType: LegBusinessType
) => {
  switch (legBusinessType) {
    case LegBusinessType.FERRY_CHARGEABLE:
    case LegBusinessType.FERRY_OPERATIONAL: {
      return '2px solid yellow';
    }
    case LegBusinessType.LIVE_CHARGEABLE:
    case LegBusinessType.LIVE_OPERATIONAL: {
      return '2px solid #ff771c';
    }
    default: {
      return 'none';
    }
  }
};

export const getAFBVersionInfo = (crewType: AfbCrewType[]) => {
  const afbStatus =
    crewType.length && !crewType.find(c => c.type === AFBColorType.BLACK)
      ? crewType.find(c => !c.type)
        ? AFBColorType.RED
        : crewType.find(c => c.type === AFBColorType.AMBER)
        ? AFBColorType.AMBER
        : AFBColorType.GREEN
      : AFBColorType.NONE;
  return {
    color: AFB_COLORS[afbStatus],
    ack: FLIGHT_VERSION_ACK[afbStatus],
  } as { color: string; ack: string };
};

import React from 'react';
import axios from 'axios';
import { getTokenFromLocalStorage } from '../../utils/tokenLocalStorage';


const token = getTokenFromLocalStorage();

const ButtonTestConnection = () => {
    const handleClick = () => {
        axios.get('https://timeline.dev.vistajet.io/handover/api', {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Origin': '*',
            },
        })
            .then(response => {
                // Handle the response
                console.log(response.data);
            })
            .catch(error => {
                // Handle the error
                console.error(error);
            });
    };

    return (
        <button onClick={handleClick}>Test Connection</button>
    );
};

export default ButtonTestConnection;

import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';

export interface ErrorHandlingState {
  errorCount: number;
  fullStack: string;
  fullTitle: string;
}

export const initialState: ErrorHandlingState = {
  errorCount: 0,
  fullStack: '',
  fullTitle: '',
};

export default reducerWithInitialState(initialState)
  .case(actions.doSetFirstError, (state, payload) => ({
    ...state,
    errorCount: 1,
    fullStack: payload.content,
    fullTitle: payload.title,
  }))
  .case(actions.doUpdateErrorDialog, (state, payload) => ({
    ...state,
    errorCount: state.errorCount + 1,
    fullStack: `${state.fullStack}\n${payload.content}`,
    fullTitle: `${state.fullTitle}\n${payload.title}`,
  }))
  .case(actions.doResetErrorHandlingState, () => ({ ...initialState }));

import { Component } from 'react';
import { utc } from 'moment';
import * as d3S from 'd3-selection';
import * as d3Zoom from 'd3-zoom';
import { clamp, isFinite, maxBy, sortBy } from 'lodash';
import * as actions from '../actions';
import { connect, DispatchProp } from 'react-redux';
import { TopBarConnected } from './topbar';
import Tooltip from './flight-tooltip/tooltip';
import { subMiddle, store as reduxStore } from '../root';
import SidebarComponentConnected from './timeline-bar/sidebarComponent/SidebarComponent';
import {
  getInitialScale,
  getIndexByYPosition,
  getLaneHeightKoef,
  getRowFullHeight,
  getDragPosition,
} from '../reducers/ui';
import { RootState } from '../reducers';
import { ZoomButtonGroupConnected } from './btn-zoom-level/zoom-button-group';
import { TimelineTextLabels } from './timeline-elements/text-labels';
import { AircraftList } from './timeline-elements/aircraft-list';
import { CurrentTimeMarker } from './timeline-elements/current-time-marker';
import {
  getAircraftIndexById,
  getAircraftIndexMapExcludingHolding,
  getSortedVisibleAircraft,
  getAircraftByIndex,
  isFlightSearchType,
  getFoundElements,
  getVisibleHoldAircraft,
  jumpToFirstTailBySearch,
} from '../selectors';
import { getTraverseArray } from '../reducers/dashboard';
import TooltipSegment from './tooltip';
import TooltipCrew from './tooltip/vistajet/crew';
import { TestStatusConnected } from './test-status';
import { VerticalScrollBarConnected } from './vertical-scrollbar';
import { TimelineBar } from './timeline-bar';
import { HorizontalScrollBarConnected } from './horizontal-scrollbar';
import { TooltipWrapperConnected } from './overlap-tooltip';
import { FilterTopbarConnected } from './filter-top-bar';
import { SearchByAircraftConnected } from './topbar/search-by-aircraft';
import { SvgLayer } from './svg-layer';
import { ContextMenuWrappers } from './context-menu/index';
import { IframeWrappers } from './iframe-wrappers';
import { ConnectedDaySeparator } from './dayAxisTopDelimeter/dayAxis';
import Aircraft from '../types/aircraft';
import { isHoldAircraft } from '../common/aircraft/aircraft-check-status';
import TooltipAircraft from './aircraft/tooltip';
import {
  getDraggingFlights,
  getFilteredByOpStatusUpdatedSelectedFlightIds,
} from '../selectors/flights';
import { UserProfile } from '../types/user';
import { HoldAircraftListConnected } from './timeline-elements/aircraft-list/hold-ac-list';
import { HoldFlightsTextLabelsConnected } from './timeline-elements/text-labels/hold-flights';
import {
  HOLD_LANE_STROKE,
  HOUR_IN_MS,
  OFFSET_FOR_VERTICAL_MODE,
} from '../constants';
import { MultipleSearchBarConnected } from './multiple-search-bar';
import { ErrorDialogConnected } from './error-handling/error-dialog';
import { MinimizedNearestAcComponent } from './nearest-aircraft-modal/minimized-component';
import { AnyAction } from 'redux';

interface TimelineStateProps {
  planeBlockWidth: number;
  rowHeight: number;
  marginTop: number;
  holdLineHeight: number;
  controlsBarHeight: number;
  timelineBarHeight: number;
  filterBarHeight: number;
  multipleSearchBarHeight: number;
  height: number;
  width: number;
  isDragging: boolean;
  isSelecting: boolean;
  isDraggingIframe: boolean;
  aircraftLoadingComplete: boolean;
  userProfile: UserProfile;
  visibleHoldAircraft: Aircraft[];
  isVerticalMode: boolean;
}

class TimelineComponent extends Component<
  DispatchProp<AnyAction> & TimelineStateProps
> {
  verZoom: d3Zoom.ZoomBehavior<Element, {}>;
  horZoom: d3Zoom.ZoomBehavior<Element, {}>;
  aircraftContainerRef: HTMLDivElement;
  timelineCanvasRef: HTMLDivElement;
  chartRef: HTMLDivElement;
  constructor(props) {
    super(props);
    subMiddle.on(actions.doGetUserPreferences.done, (_action, state) => {
      const { ui } = reduxStore.getState();
      const offset =
        -getInitialScale(ui.width - ui.planeBlockWidth)(utc()) *
          ui.transform.kx +
        250;
      const { kx, ky } = ui.transform;
      const transform = d3Zoom.zoomIdentity.translate(offset, 0).scale(kx);
      const transformY = d3Zoom.zoomIdentity.translate(0, 0).scale(ky);
      d3S
        .select(this.timelineCanvasRef)
        .call(this.horZoom.transform, transform);
      d3S
        .select(this.aircraftContainerRef)
        .call(this.verZoom.transform, transformY);
    });
    subMiddle.on(actions.userResetPreferences, (action, state) => {
      this.moveToNow();
    });
    subMiddle.on(actions.userGoToNow, () => {
      this.moveToNow();
    });
    subMiddle.on(actions.userSearchOrder.done, (action, state) => {
      const { ui } = state;
      if (action.payload.result.length == 0) return;
      const sorted = sortBy(action.payload.result, f => f.start);
      const tailIndex = getAircraftIndexById(state, sorted[0].aircraftId);
      const keepVerticalPosition = isHoldAircraft(
        getAircraftByIndex(state, tailIndex)
      );
      !keepVerticalPosition &&
        this.moveToRow(tailIndex - state.ui.visibleHoldAcIds.length);
      this.moveToX(
        (-getInitialScale(ui.width - ui.planeBlockWidth)(sorted[0].start) +
          250) *
          ui.transform.kx,
        ui.transform.kx
      );
    });
    subMiddle.on(actions.userSearchLeg.done, (action, state) => {
      const flight = action.payload.result;
      if (!flight) return;
      const { ui } = state;
      const tailIndex = getAircraftIndexById(state, flight.aircraftId);
      const keepVerticalPosition = isHoldAircraft(
        getAircraftByIndex(state, tailIndex)
      );
      !keepVerticalPosition &&
        this.moveToRow(tailIndex - state.ui.visibleHoldAcIds.length);
      this.moveToX(
        (-getInitialScale(ui.width - ui.planeBlockWidth)(flight.start) + 250) *
          ui.transform.kx,
        ui.transform.kx
      );
    });
    subMiddle.on(actions.userGoToNextDate, (action, state) => {
      const { ui } = state;
      this.moveToX(
        -getInitialScale(ui.width - ui.planeBlockWidth)(
          utc(ui.transform.scaleX.invert(0)).add(1, 'd')
        ) * ui.transform.kx,
        ui.transform.kx
      );
    });
    subMiddle.on(actions.userScrollScrollbar, (action, state) => {
      this.moveToY(-action.payload);
    });
    subMiddle.on(actions.userHorizontalScrollingTimeline, (action, { ui }) => {
      const translateX = -getInitialScale(ui.width - ui.planeBlockWidth)(
        action.payload.date
      );
      this.moveToX(translateX * ui.transform.kx, ui.transform.kx);
    });
    subMiddle.on(actions.userGoToPrevDate, (action, state) => {
      const { ui } = state;
      this.moveToX(
        -getInitialScale(ui.width - ui.planeBlockWidth)(
          utc(ui.transform.scaleX.invert(0)).subtract(1, 'd')
        ) * ui.transform.kx,
        ui.transform.kx
      );
    });
    subMiddle.on(actions.timelineResized, (action, state) => {
      const { ui } = state;
      this.moveToX(
        -getInitialScale(ui.width - ui.planeBlockWidth)(
          ui.transform.scaleX.domain()[0]
        ) * ui.transform.kx,
        ui.transform.kx
      );
    });

    subMiddle.on(actions.userGoToCertainDate, (action, state) => {
      const { ui } = state;
      if (action.payload) {
        this.moveToX(
          -getInitialScale(ui.width - ui.planeBlockWidth)(
            action.payload.startOf('d').add(1, 'm')
          ) *
            ui.transform.kx +
            250,
          ui.transform.kx
        );
      }
    });
    const searchFocus = (action, state: RootState) => {
      if (
        !state.search.searchQuery &&
        !state.search.searchQueryAllFilters[state.search.searchType]
      )
        return;
      const elements = getFoundElements(state);
      if (isFlightSearchType(state.search.searchType)) {
        const flights = elements;
        const selectedFlight = flights[state.search.focusedFoundElement];
        if (selectedFlight) {
          const tailIndex = getAircraftIndexById(
            state,
            selectedFlight.aircraftId
          );
          const keepVerticalPosition = isHoldAircraft(
            getAircraftByIndex(state, tailIndex)
          );
          if (state.search.searchType == 'Order ID') {
            const { ui } = state;
            !keepVerticalPosition && this.moveToRow(tailIndex);
            this.moveToX(
              (-getInitialScale(ui.width - ui.planeBlockWidth)(
                selectedFlight.start
              ) +
                250) *
                ui.transform.kx,
              ui.transform.kx
            );
          }
          const { visibleHoldAcIds } = state.ui;
          !keepVerticalPosition &&
            this.moveToRow(tailIndex - visibleHoldAcIds.length);
        }
      } else {
        const selectedElement = elements[state.search.focusedFoundElement];
        const tailIndex = getAircraftIndexById(
          state,
          selectedElement ? selectedElement.aircraftId : -1
        );
        const keepVerticalPosition = isHoldAircraft(
          getAircraftByIndex(state, tailIndex)
        );
        !keepVerticalPosition && this.moveToRow(tailIndex);
      }
    };
    const searchTailFocus = (action, state: RootState) => {
      const aircraftRowIndex = jumpToFirstTailBySearch(state);
      const { visibleHoldAcIds } = state.ui;
      if (aircraftRowIndex === null) return;
      const keepVerticalPosition = isHoldAircraft(
        getAircraftByIndex(state, aircraftRowIndex)
      );
      !keepVerticalPosition &&
        this.moveToRow(aircraftRowIndex - visibleHoldAcIds.length, true);
    };
    const focusWidgetTraverse = (action, state: RootState) => {
      const { type } = action.payload as actions.DashboardWidgetPayload;
      const arr = getTraverseArray(state);
      const selectedFlight = arr[state.dashboard.focusedElement];
      if (
        type === 'Delayed Flights' ||
        // widget nex or widget prev actions
        typeof action.payload === 'number'
      ) {
        this.moveToRow(getAircraftIndexById(state, selectedFlight.aircraftId));
        this.moveToNow();
      }
      if (type === 'Handover Remark Flights') {
        const tailIndex = getAircraftIndexById(
          state,
          selectedFlight.aircraftId
        );
        const keepVerticalPosition = isHoldAircraft(
          getAircraftByIndex(state, tailIndex)
        );
        !keepVerticalPosition && this.moveToRow(tailIndex);
        const { ui } = state;
        this.moveToX(
          (-getInitialScale(ui.width - ui.planeBlockWidth)(
            selectedFlight.start
          ) +
            250) *
            ui.transform.kx,
          ui.transform.kx
        );
      }
    };
    const changeZoomLevel = (action, state: RootState) => {
      const { toDate, zoomLevel } = action.payload as actions.ChangeZoomPayload;
      const initialScale = getInitialScale(
        state.ui.width - state.ui.planeBlockWidth
      );
      const defaultDayWidth =
        initialScale(utc().add(1, 'day')) - initialScale(utc());
      const center = state.ui.transform.scaleX.invert(
        (state.ui.width - state.ui.planeBlockWidth) / 2
      );
      const centerX = initialScale(
        toDate ? utc(toDate + HOUR_IN_MS * 12) : center
      );
      let kx = 1;
      const timelineWidth = state.ui.width - state.ui.planeBlockWidth;
      switch (zoomLevel) {
        case '3w': {
          kx = timelineWidth / 21 / defaultDayWidth;
          break;
        }
        case '2w': {
          kx = timelineWidth / 14 / defaultDayWidth;
          break;
        }
        case '1w': {
          kx = timelineWidth / 7 / defaultDayWidth;
          break;
        }
        case '3d': {
          kx = timelineWidth / 3 / defaultDayWidth;
          break;
        }
        case '1d': {
          kx = timelineWidth / 1 / defaultDayWidth;
          break;
        }
        default:
      }
      const distanceFromStartToCenter =
        (initialScale.range()[1] - initialScale.range()[0]) / 2;
      this.smoothMoveToX(-centerX * kx + distanceFromStartToCenter, kx);
    };
    const onRowHeightChange = (
      _action,
      state: RootState,
      prevState: RootState
    ) => {
      const heightPrev = prevState.ui.rowHeight;
      const heightNow = state.ui.rowHeight;
      const koef = heightPrev / heightNow;
      const transform = d3Zoom.zoomIdentity
        .translate(0, state.ui.transform.translateY + state.ui.transform.ky)
        .scale(Math.max(state.ui.transform.ky * koef, 1));
      d3S
        .select(this.aircraftContainerRef)
        .call(this.verZoom.transform, transform);
    };
    const onUserToggleTailElementVisibility = (action, state: RootState) => {
      const { segmentsVisibility, transform, positionMap } = state.ui;
      const { togglersState } = state.aircraft;
      const visibleAircraft = getSortedVisibleAircraft(state);
      const aircraftByMaxHeight = maxBy(visibleAircraft, a =>
        getRowFullHeight(segmentsVisibility, togglersState[a.id], positionMap)
      );
      const lineHeightСoeff = getLaneHeightKoef(
        segmentsVisibility,
        togglersState[aircraftByMaxHeight.id],
        positionMap
      );
      const requiredAircraftIndex = getAircraftIndexMapExcludingHolding(state)[
        action.payload.aircraftId
      ];
      const requiredY =
        requiredAircraftIndex *
        state.ui.rowHeight *
        Math.max(transform.ky, lineHeightСoeff);
      const mouseOffset =
        action.payload.top -
        state.ui.marginTop -
        state.ui.holdLineHeight -
        state.ui.timelineBarHeight -
        state.ui.filterBarHeight -
        state.ui.multipleSearchBarHeight -
        state.ui.controlsBarHeight;
      const newTransform = d3Zoom.zoomIdentity
        .translate(0, -requiredY + mouseOffset)
        .scale(Math.max(transform.ky, lineHeightСoeff));
      d3S
        .select(this.aircraftContainerRef)
        .call(this.verZoom.transform, newTransform);
    };
    subMiddle.on(actions.userFocusFirstFound, searchFocus);
    subMiddle.on(actions.userSearchNext, searchFocus);
    subMiddle.on(actions.userSearchPrev, searchFocus);
    subMiddle.on(actions.userSelectDashboardWidget, focusWidgetTraverse);
    subMiddle.on(actions.userWidgetNext, focusWidgetTraverse);
    subMiddle.on(actions.userWidgetPrev, focusWidgetTraverse);
    subMiddle.on(actions.userSetSegmentVisibility, onRowHeightChange);
    subMiddle.on(actions.userChangeZoomLevel, changeZoomLevel);
    subMiddle.on(
      actions.userToggleTailElementsVisibility,
      onUserToggleTailElementVisibility
    );
    subMiddle.on(
      actions.setReInitLoadingDataOnWebSocketConnectionLost.done,
      searchFocus
    );
    subMiddle.on(actions.userChangeSearchACQuery, searchTailFocus);
    this.setRaygunUser(); // Init setRaygunUser
  }
  preventBrowserZoom = (e: WheelEvent) => {
    if (e.ctrlKey) e.preventDefault();
  };
  dispatchTimelineSize = () => {
    const height = this.chartRef.getBoundingClientRect().height;
    const width = this.chartRef.getBoundingClientRect().width;
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;
    this.props.dispatch(
      actions.timelineResized({
        refDimensions: [width, height],
        innerDimensions: [innerWidth, innerHeight],
      })
    );
  };
  handleDragFlights = (event: DragEvent) => {
    const reduxState = reduxStore.getState();
    const { isDragging, draggingPosition } = reduxState.flightDetails;
    const { selectedFlights } = reduxState.ui;
    const { visibleHoldAircraft } = this.props;
    if (isDragging && selectedFlights.length > 0) {
      const { x, y } = getDragPosition(reduxState.ui, event);
      const newTailIndex = getIndexByYPosition(
        reduxState.ui,
        y,
        visibleHoldAircraft
      );
      const { index, y: prevY } = draggingPosition;
      if (Math.abs(y - prevY) > 5)
        if (newTailIndex !== index) {
          reduxStore.dispatch(
            actions.userDragFlightsInProgress({
              draggingPosition: { x, y, index: newTailIndex },
            })
          );
        }
    }
  };
  handleDragFlightsEnd = (event: DragEvent) => {
    const reduxState = reduxStore.getState();
    const { isDragging } = reduxState.flightDetails;
    const {
      selectedFlights,
      planeBlockWidth,
      transform,
      width,
    } = reduxState.ui;
    const {
      timelineBarHeight,
      filterBarHeight,
      multipleSearchBarHeight,
      marginTop,
      visibleHoldAircraft,
    } = this.props;
    const index = getIndexByYPosition(
      reduxState.ui,
      event.pageY -
        timelineBarHeight -
        filterBarHeight -
        multipleSearchBarHeight -
        marginTop,
      visibleHoldAircraft
    );
    const selectedAircraft = getAircraftByIndex(reduxState, index);
    if (isDragging && selectedFlights.length > 0) {
      const draggingFlights = getDraggingFlights(reduxState);
      const initialScale = getInitialScale(width - planeBlockWidth);
      const { start, end } = draggingFlights[0];
      const ghostLeft = transform.scaleX(start);
      const ghostWidth =
        transform.scaleX(utc(end)) - transform.scaleX(utc(start));
      const isFlightGhostHidden = ghostLeft + ghostWidth < 0;
      if (isFlightGhostHidden) {
        const quarterOfDistanceFromStartToCenter =
          (initialScale.range()[1] - initialScale.range()[0]) / 4;
        this.smoothMoveToX(
          -initialScale(utc(start + HOUR_IN_MS * 12)) * transform.kx +
            quarterOfDistanceFromStartToCenter,
          transform.kx
        );
      }
      return reduxStore.dispatch(
        actions.userDragFlightsEnd({
          newAircraftId: selectedAircraft.id,
        })
      );
    }
  };
  handleMouseDown = (event: React.MouseEvent<HTMLOrSVGElement>) => {
    const reduxState = reduxStore.getState();
    const { isDragging } = reduxState.flightDetails;
    const {
      timelineBarHeight,
      filterBarHeight,
      multipleSearchBarHeight,
      marginTop,
      visibleHoldAircraft,
      isVerticalMode,
    } = this.props;
    const dateStart = utc(
      reduxState.ui.transform.scaleX.invert(
        event.clientX - this.props.planeBlockWidth
      )
    );
    const dividerHeight = visibleHoldAircraft
      ? visibleHoldAircraft.length * HOLD_LANE_STROKE
      : 0;
    const offsetOfVerticalMode = isVerticalMode ? OFFSET_FOR_VERTICAL_MODE : 0;
    const y =
      event.pageY -
      timelineBarHeight -
      filterBarHeight -
      multipleSearchBarHeight -
      marginTop -
      dividerHeight -
      offsetOfVerticalMode;
    const index = getIndexByYPosition(reduxState.ui, y, visibleHoldAircraft);
    const selectedAircraft = getAircraftByIndex(reduxState, index);
    const isOnHoldLine = selectedAircraft && isHoldAircraft(selectedAircraft);
    if (
      !isOnHoldLine &&
      !isDragging &&
      !event.ctrlKey &&
      !event.metaKey &&
      !event.shiftKey &&
      event.button === 0 &&
      selectedAircraft
    ) {
      return reduxStore.dispatch(
        actions.userTimelineSelectionBegin({
          mouseX: event.clientX,
          mouseY: event.clientY,
          aircraftId: selectedAircraft.id,
          dateStart: dateStart.valueOf(),
        })
      );
    }
    if (!isOnHoldLine && event.shiftKey) {
      return reduxStore.dispatch(
        actions.userSelectFlightsForDnDBegin({
          start: {
            x: event.clientX,
            y: event.clientY,
          },
          firstAircraftId: selectedAircraft.id,
          dateStart: dateStart.valueOf(),
        })
      );
    }
  };
  handleMouseMove = (event: MouseEvent) => {
    const reduxState = reduxStore.getState();
    const { isDragging } = reduxState.flightDetails;
    const { isSelecting } = reduxState.ui.timelineSelection;
    if (
      !isDragging &&
      !event.ctrlKey &&
      !event.metaKey &&
      !event.shiftKey &&
      isSelecting &&
      event.button === 0
    )
      return reduxStore.dispatch(
        actions.userTimelineSelection({
          x: event.clientX,
          y: event.clientY,
        })
      );
    if (event.shiftKey)
      return reduxStore.dispatch(
        actions.userSelectFlightsForDnDInProgress({
          end: {
            x: event.clientX,
            y: event.clientY,
          },
        })
      );
  };
  handleMouseUp = (event: MouseEvent) => {
    const reduxState = reduxStore.getState();
    const { isDragging } = reduxState.flightDetails;
    const { isSelecting } = reduxState.ui.timelineSelection;
    if (isDragging) {
      return reduxStore.dispatch(actions.userCancelDraggingFlights());
    }
    const dateEnd = utc(
      reduxState.ui.transform.scaleX.invert(
        event.clientX - this.props.planeBlockWidth
      )
    ).valueOf();
    if (isSelecting && event.button === 0 && !event.shiftKey)
      return reduxStore.dispatch(actions.userTimelineSelectionEnd(dateEnd));
    if (event.shiftKey) {
      const selectedFlights = getFilteredByOpStatusUpdatedSelectedFlightIds(
        reduxState
      );
      reduxStore.dispatch(
        actions.userSelectFlightsForDnDEnd({
          selectedFlights,
        })
      );
    }
  };
  handleMouseRightClick = (event: React.MouseEvent<HTMLOrSVGElement>) => {
    event.preventDefault();
    const {
      timelineBarHeight,
      filterBarHeight,
      multipleSearchBarHeight,
      controlsBarHeight,
      visibleHoldAircraft,
    } = this.props;
    const reduxState = reduxStore.getState();
    const currentDate = utc(
      reduxState.ui.transform.scaleX.invert(
        event.clientX - this.props.planeBlockWidth
      )
    );
    const y =
      event.pageY -
      timelineBarHeight -
      filterBarHeight -
      multipleSearchBarHeight -
      controlsBarHeight;
    const index = getIndexByYPosition(reduxState.ui, y, visibleHoldAircraft);
    const selectedAircraft = getAircraftByIndex(reduxState, index);
    if (!event.ctrlKey && !event.metaKey && selectedAircraft) {
      return reduxStore.dispatch(
        actions.userOpenCreateEventsMenu({
          start: currentDate.valueOf(),
          end: currentDate.valueOf(),
          aircraftId: selectedAircraft.id,
          mousePositionAtRightClick: {
            x: event.clientX,
            y: event.clientY,
          },
        })
      );
    }
  };
  handleMouseOut = (event: React.MouseEvent) => {
    const reduxState = reduxStore.getState();
    const { isDragging } = reduxState.iframeUi;
    if (!isDragging) return;
    if (
      event.clientX < 0 ||
      event.clientX > window.innerWidth ||
      event.clientY < 0 ||
      event.clientY > window.innerHeight
    ) {
      reduxStore.dispatch(
        actions.userMoveIframeEnd([event.clientX, event.clientY])
      );
    }
  };
  setRaygunUser = () => {
    const { email, sub, given_name, name } = this.props.userProfile;
    let ragunSetUserTimeout = setTimeout(
      function createTimeoutBySetRaygunUser() {
        if (!sessionStorage.getItem('raygun:inited'))
          return setTimeout(createTimeoutBySetRaygunUser, 300);
        try {
          console.log('TimelineComponent:setRayGunUser::userProfile', {
            email,
            sub,
            given_name,
            name,
          });
          (window as any).rg4js('setUser', {
            email,
            identifier: sub,
            isAnonymous: false,
            firstName: given_name,
            fullName: name,
          });
          (window as any).rg4js('trackEvent', {
            type: 'pageView',
            path: '/' + window.location.hash,
          });
          sessionStorage.removeItem('raygun:inited');
          clearTimeout(ragunSetUserTimeout);
        } catch (error) {
          console.error('Raygun did not set a user!!! \n Error:', error);
        }
      },
      500
    );
  };
  componentDidMount() {
    window.document.addEventListener('mousemove', this.handleMouseMove);
    window.document.addEventListener('wheel', this.preventBrowserZoom, {
      passive: false,
    });
    window.document.addEventListener('dragover', this.handleDragFlights);
    window.document.addEventListener('drop', this.handleDragFlightsEnd);
    window.document.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('resize', this.dispatchTimelineSize);
    const height = this.chartRef.getBoundingClientRect().height;
    const width = this.chartRef.getBoundingClientRect().width;
    const innerWidth = window.innerWidth;
    const innerHeight = window.innerHeight;
    this.props.dispatch(
      actions.timelineMounted({
        refDimensions: [width, height],
        innerDimensions: [innerWidth, innerHeight],
      })
    );

    this.horZoom = d3Zoom
      .zoom()
      .scaleExtent([-Infinity, Infinity])
      .translateExtent([
        [-Infinity, Infinity],
        [Infinity, Infinity],
      ])
      // filter applies only for user-triggered zoom
      .filter(() => {
        const isntFiltered = d3S.event.ctrlKey || d3S.event.type == 'wheel';
        return isntFiltered;
      })
      .wheelDelta(
        () => (-d3S.event.deltaY * (d3S.event.deltaMode ? 60 : 1)) / 5000
      )
      .on('zoom', () => {
        if (!d3S.event.sourceEvent || d3S.event.sourceEvent.ctrlKey) {
          // sometimes x or k can be Infinity or NaN
          if (
            isFinite(d3S.event.transform.x) &&
            isFinite(d3S.event.transform.k)
          ) {
            const transform = d3Zoom.zoomIdentity
              .translate(d3S.event.transform.x, 0)
              .scale(clamp(d3S.event.transform.k, 0.1, 100));
            d3S.select(this.timelineCanvasRef).property('__zoom', transform);
            this.props.dispatch(actions.userZoomHor(transform));
          } else {
            const { translateX, kx } = reduxStore.getState().ui.transform;
            const transform = d3Zoom.zoomIdentity
              .translate(translateX, 0)
              .scale(kx);
            d3S.select(this.timelineCanvasRef).property('__zoom', transform);
          }
        } else if (d3S.event.sourceEvent.type == 'wheel') {
          const { ui } = reduxStore.getState();
          const ev = d3S.event.sourceEvent;
          const deltaOffsetY = -(ev.deltaY / Math.abs(ev.deltaY)) * 40;
          const deltaOffsetX = -(ev.deltaX / Math.abs(ev.deltaX)) * 40;
          if (ev.shiftKey) {
            const offsetX = ui.transform.translateX + deltaOffsetY;
            const transform = d3Zoom.zoomIdentity
              .translate(offsetX, 0)
              .scale(ui.transform.kx);
            d3S.select(this.timelineCanvasRef).property('__zoom', transform);
            this.props.dispatch(actions.userZoomHor(transform));
          } else {
            if (deltaOffsetY) {
              const transform = d3Zoom.zoomIdentity
                .translate(0, ui.transform.translateY + deltaOffsetY)
                .scale(ui.transform.ky);
              d3S
                .select(this.aircraftContainerRef)
                .call(this.verZoom.transform, transform);
              d3S
                .select(this.timelineCanvasRef)
                .property(
                  '__zoom',
                  d3Zoom.zoomIdentity
                    .translate(ui.transform.translateX, 0)
                    .scale(ui.transform.kx)
                );
            }
            if (deltaOffsetX) {
              const offsetX = ui.transform.translateX + deltaOffsetX;
              const transform = d3Zoom.zoomIdentity
                .translate(offsetX, 0)
                .scale(ui.transform.kx);
              d3S.select(this.timelineCanvasRef).property('__zoom', transform);
              this.props.dispatch(actions.userZoomHor(transform));
            }
          }
        }
      });
    const horizontalScrollHeight = 20;

    this.verZoom = d3Zoom
      .zoom()
      .scaleExtent([1, 3])
      .wheelDelta(
        () => (-d3S.event.deltaY * (d3S.event.deltaMode ? 60 : 1)) / 5000
      )
      .translateExtent([
        [0, 0],
        [Infinity, Infinity],
      ])
      .on('zoom', () => {
        const { k, y, isNotScolling } = d3S.event
          .transform as d3Zoom.ZoomTransform & { isNotScolling: boolean };
        const state = reduxStore.getState();
        const { ui } = state;
        const fleetSize = getSortedVisibleAircraft(state).length;
        const minTy =
          ui.height -
          ui.rowHeight * k * fleetSize -
          ui.marginTop -
          ui.filterBarHeight -
          ui.multipleSearchBarHeight -
          ui.holdLineHeight -
          horizontalScrollHeight;
        const ty = Math.min(0, Math.max(y, minTy));
        const transform = d3Zoom.zoomIdentity.translate(ty, ty).scale(k);
        // manually set internal transform parametr
        d3S.select(this.aircraftContainerRef).property('__zoom', transform);
        const currentTranslateY = ui.transform.translateY;
        if (currentTranslateY !== ty || k !== ui.transform.ky)
          this.props.dispatch(
            actions.userZoomVer(transform, {
              isNotScolling,
            })
          );
        return transform;
      })
      .on('start', () => {});
    d3S.select(this.timelineCanvasRef).call(this.horZoom);
    d3S.select(this.aircraftContainerRef).call(this.verZoom);
    this.moveToNow();
  }
  componentWillUnmount() {
    window.document.removeEventListener('mousemove', this.handleMouseMove);
    window.document.removeEventListener('wheel', this.preventBrowserZoom);
    window.removeEventListener('resize', this.dispatchTimelineSize);
    window.document.removeEventListener('dragover', this.handleDragFlights);
    window.document.removeEventListener('drop', this.handleDragFlightsEnd);
  }
  moveToNow = () => {
    const { ui } = reduxStore.getState();
    this.moveToX(
      -getInitialScale(ui.width - ui.planeBlockWidth)(utc()) * ui.transform.kx +
        250,
      ui.transform.kx
    );
  };
  moveToX = (offset, k) => {
    const transform = d3Zoom.zoomIdentity.translate(offset, 0).scale(k);
    d3S.select(this.timelineCanvasRef).call(this.horZoom.transform, transform);
  };
  smoothMoveToX = (offset, k) => {
    const transform = d3Zoom.zoomIdentity.translate(offset, 0).scale(k);
    (d3S.select(this.timelineCanvasRef) as d3S.Selection<any, any, any, any> & {
      transition: Function;
    })
      .transition()
      .duration(400)
      .call(this.horZoom.transform, transform);
  };
  moveToRow = (rowIndex: number, isNotScolling?: boolean) => {
    const { ui } = reduxStore.getState();
    const transform = d3Zoom.zoomIdentity
      .translate(0, -(rowIndex - 4) * ui.rowHeight * ui.transform.ky)
      .scale(ui.transform.ky);
    d3S
      .select(this.aircraftContainerRef)
      .call(
        this.verZoom.transform,
        isNotScolling ? { ...transform, isNotScolling } : transform
      );
  };
  moveToY = (y: number) => {
    const { ui } = reduxStore.getState();
    const transform = d3Zoom.zoomIdentity
      .translate(0, y)
      .scale(ui.transform.ky);
    d3S
      .select(this.aircraftContainerRef)
      .call(this.verZoom.transform, transform);
  };
  getChartRef = ref => {
    this.chartRef = ref;
  };
  getTimelineCanvasRef = ref => {
    this.timelineCanvasRef = ref;
  };
  getAircraftContainerRef = ref => {
    this.aircraftContainerRef = ref;
  };
  onMouseMove = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    const reduxState = reduxStore.getState();
    const {
      timelineBarHeight,
      filterBarHeight,
      multipleSearchBarHeight,
      dispatch,
      controlsBarHeight,
      visibleHoldAircraft,
    } = this.props;
    const y =
      e.pageY -
      controlsBarHeight -
      timelineBarHeight -
      filterBarHeight -
      multipleSearchBarHeight;
    dispatch(
      actions.userHoverAircraft(
        getIndexByYPosition(reduxState.ui, y, visibleHoldAircraft)
      )
    );
  };
  render() {
    const {
      filterBarHeight,
      multipleSearchBarHeight,
      height,
      width,
      planeBlockWidth,
      timelineBarHeight,
      marginTop,
      controlsBarHeight,
      holdLineHeight,
      aircraftLoadingComplete,
      visibleHoldAircraft,
    } = this.props;
    return (
      <div
        style={{
          display: 'flex',
          height: '100%',
          overflow: 'hidden',
        }}
        data-mode={process.env.NODE_ENV}
        onMouseOut={this.handleMouseOut}
      >
        <SidebarComponentConnected />
        <div
          id="timeline"
          style={{
            height: '100%',
            width: `100%`,
            display: 'flex',
            flex: 'none',
            flexDirection: 'column',
            alignContent: 'stretch',
            userSelect: this.props.isDraggingIframe ? 'none' : 'auto',
          }}
        >
          <TimelineBar isNotPermitted={false} />
          <TopBarConnected />
          <MultipleSearchBarConnected />
          <FilterTopbarConnected />
          <div id="chart" ref={this.getChartRef} style={{ flex: 1, zIndex: 0 }}>
            <div className="timeline-rootnode" style={{ height }}>
              <div
                className="timeline-leftbar"
                style={{
                  width: planeBlockWidth - 1,
                }}
              >
                <div
                  style={{
                    paddingTop: '8px',
                    height: marginTop,
                    borderBottom: '1px solid #c5c5c5',
                  }}
                >
                  <SearchByAircraftConnected />
                </div>
                <div
                  className="aircraft-container"
                  style={{ top: marginTop }}
                  onMouseMove={this.onMouseMove}
                >
                  <HoldAircraftListConnected />
                </div>
                <div
                  className="aircraft-container"
                  ref={this.getAircraftContainerRef}
                  onMouseMove={this.onMouseMove}
                  style={{ top: marginTop + holdLineHeight }}
                >
                  <AircraftList />
                </div>
              </div>
              <div
                className="timeline-canvas"
                ref={this.getTimelineCanvasRef}
                style={{
                  cursor: this.props.isDragging ? 'grabbing' : 'auto',
                }}
              >
                <SvgLayer
                  width={width}
                  height={height}
                  planeBlockWidth={planeBlockWidth}
                  marginTop={marginTop}
                  timelineBarHeight={timelineBarHeight}
                  filterBarHeight={filterBarHeight}
                  multipleSearchBarHeight={multipleSearchBarHeight}
                  controlsBarHeight={controlsBarHeight}
                  holdLineHeight={holdLineHeight}
                  aircraftLoadingComplete={aircraftLoadingComplete}
                  visibleHoldAcLines={visibleHoldAircraft.length}
                />
                <HoldFlightsTextLabelsConnected
                  onMouseMove={this.onMouseMove}
                  onMouseDown={this.handleMouseDown}
                  onContextMenu={this.handleMouseRightClick}
                />
                <TimelineTextLabels
                  onMouseMove={this.onMouseMove}
                  onMouseDown={this.handleMouseDown}
                  onContextMenu={this.handleMouseRightClick}
                />
                <ConnectedDaySeparator />
                <MinimizedNearestAcComponent />
                <ZoomButtonGroupConnected />
              </div>
              <CurrentTimeMarker />
            </div>
          </div>
        </div>
        <TooltipAircraft />
        <Tooltip />
        <TooltipSegment />
        <TooltipCrew />
        <ContextMenuWrappers />
        <IframeWrappers />
        <TooltipWrapperConnected />
        <TestStatusConnected />
        <VerticalScrollBarConnected />
        <HorizontalScrollBarConnected />
        <ErrorDialogConnected />
      </div>
    );
  }
}

export default connect<TimelineStateProps>((state: RootState) => ({
  planeBlockWidth: state.ui.planeBlockWidth,
  rowHeight: state.ui.rowHeight,
  marginTop: state.ui.marginTop,
  holdLineHeight: state.ui.holdLineHeight,
  controlsBarHeight: state.ui.controlsBarHeight,
  timelineBarHeight: state.ui.timelineBarHeight,
  filterBarHeight: state.ui.filterBarHeight,
  multipleSearchBarHeight: state.ui.multipleSearchBarHeight,
  height: state.ui.height,
  width: state.ui.width,
  isDragging: state.flightDetails.isDragging,
  draggingPosition: state.flightDetails.draggingPosition,
  isSelecting: state.ui.timelineSelection.isSelecting,
  isDraggingIframe: state.iframeUi.isDragging,
  aircraftLoadingComplete: state.aircraft.statusLoading.vistajet,
  userProfile: state.user.userProfile,
  visibleHoldAircraft: getVisibleHoldAircraft(state),
  isVerticalMode: state.ui.isVerticalMode,
}))(TimelineComponent);

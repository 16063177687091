import EventElement from '../../types/event-element';

export function getTooltipMessage(
  unfilteredFoundElements: EventElement[],
  count: number,
  query: string
) {
  if (query.length <= 0) {
    return null;
  }
  if (unfilteredFoundElements.length === 0) {
    return 'No results found';
  }
  if (count === 0) {
    return 'Search results are hidden by defined filters';
  }
  if (unfilteredFoundElements.length > count) {
    return 'Some search results are hidden by defined filters';
  }
}

import { MailOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import {
  getCloseToMxTagColor,
  isOwnedAndIsOperatedByVJ,
  isSubCharter,
} from '../../../common/aircraft/aircraft-check-status';
import { getMelTagColor } from '../../../common/mels/mel-colorize';
import { getMELsByAircraftId } from '../../../selectors';
import Aircraft, { MxDueTagColor } from '../../../types/aircraft';
import { MaintenanceStatusTag } from '../../../types/maintenance';
import { openMaintenanceModule, setDataTestEntityId } from '../../../utils';
import { hasPermission } from '../../../utils/check-permissions';
import { HoveredTailPart } from '../tooltip';

interface OwnProps {
  aircraft: Aircraft;
  gvUrl: string;
  baseCompaniesIds: number[];
}
interface StateProps {
  melBackgroundColor: string;
  hasMels: boolean;
  mxTagColor: MxDueTagColor | null;
  hasArincPermission: boolean;
}
interface DispatchProps {
  onHover: (aircraftId: number, part: HoveredTailPart, ref) => void;
  onLeave: () => void;
  onClickSendEmail: (aircraftId: number) => void;
}

class Statuses extends PureComponent<StateProps & OwnProps & DispatchProps> {
  ref: HTMLDivElement;
  getRef = ref => (this.ref = ref);
  onHover = (part: HoveredTailPart) => {
    this.props.onHover(this.props.aircraft.id, part, this.ref);
  };
  onLeave = () => {
    this.props.onLeave();
  };
  openMx = e => {
    e.stopPropagation();
    openMaintenanceModule(this.props.aircraft.id);
  };
  sendArincEmail = e => {
    e.stopPropagation();
    this.props.onClickSendEmail(this.props.aircraft.id);
  };
  render() {
    const {
      aircraft,
      baseCompaniesIds,
      hasMels,
      melBackgroundColor,
      mxTagColor,
      hasArincPermission,
    } = this.props;
    const paxTag = aircraft.hasPaxAffecting ? MaintenanceStatusTag.PAX : '';
    return (
      <div
        className="aircraft-content-statuses"
        {...setDataTestEntityId('aircraft-statuses')}
      >
        <div className="aircraft-content-statuses-labels">
          {isSubCharter(aircraft, baseCompaniesIds) && (
            <span
              className="aircraft-content-statuses-labels-subcharter"
              {...setDataTestEntityId('aircraft-statuses-subcharter')}
            >
              VJ SUB
            </span>
          )}
          {isOwnedAndIsOperatedByVJ(aircraft, baseCompaniesIds) && paxTag && (
            <span
              className="aircraft-content-statuses-labels-mx-pax"
              onMouseEnter={() => {
                if (paxTag && aircraft.customerAffectingNotes?.length > 0) {
                  this.onHover('pax');
                }
              }}
              onMouseLeave={this.onLeave}
              ref={this.getRef}
              onClick={this.openMx}
              {...setDataTestEntityId(`aircraft-statuses-labels-${paxTag}`)}
            >
              {paxTag}
            </span>
          )}
          {aircraft.hasMelDefects && hasMels && (
            <div
              className="aircraft-content-statuses-labels-mel"
              style={{ backgroundColor: melBackgroundColor }}
              onMouseEnter={() => this.onHover('mel')}
              onMouseLeave={this.onLeave}
              ref={this.getRef}
              onClick={this.openMx}
              {...setDataTestEntityId('aircraft-statuses-labels-mel')}
            >
              MEL
            </div>
          )}
          {mxTagColor && (
            <div
              className="aircraft-content-statuses-labels-mx-due"
              style={{
                backgroundColor:
                  mxTagColor === 'amber' ? '#F5A623' : mxTagColor,
              }}
              onMouseEnter={() => this.onHover('mx-due')}
              onMouseLeave={this.onLeave}
              ref={this.getRef}
              onClick={this.openMx}
              {...setDataTestEntityId('aircraft-statuses-mx-due')}
            >
              MX DUE
            </div>
          )}
        </div>
        {hasArincPermission && (
          <Tooltip title="Send ARINC Message" placement="rightTop">
            <div
              className="aircraft-content-statuses-email"
              onClick={this.sendArincEmail}
              {...setDataTestEntityId('aircraft-sen-arinc-email')}
            >
              <MailOutlined />
            </div>
          </Tooltip>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props: OwnProps): StateProps => {
  const mels = getMELsByAircraftId(state, props.aircraft.id);
  return {
    melBackgroundColor: getMelTagColor(props.aircraft, mels, state.time.today),
    hasMels: mels.length > 0,
    mxTagColor: getCloseToMxTagColor(props.aircraft, state.time.today),
    hasArincPermission: hasPermission(state, 'AG-Timeline-MOC_ARINC-send'),
  };
};

const mapDispatchToProps = dispatch => ({
  onHover: (aircraftId, part, ref) => {
    dispatch(
      actions.userHoverTailBlock({
        aircraftId,
        part,
        ref,
      })
    );
  },
  onLeave: () => {
    dispatch(actions.userLeaveTailTooltipWithDebounce());
  },
  onClickSendEmail: (aircraftId: number) => {
    dispatch(actions.userClickToOpenArincEmail(aircraftId));
  },
});

const StatusesConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Statuses);

export default StatusesConnected;

import * as d3S from 'd3-selection';
import { isEqual } from 'lodash';
import { PureComponent } from 'react';

import { ExtendedEventElement } from '../';
import * as actions from '../../../../actions';
import {
  HOLD_FLIGHT_HEIGHT,
  HOLD_FLIGHT_MARGIN,
  HOLD_LANE_STROKE,
} from '../../../../constants';
import { RootState } from '../../../../reducers';
import { store as reduxStore, subMiddle } from '../../../../root';
import EventElement from '../../../../types/event-element';
import Flight from '../../../../types/flight';
import { getHoldFlightTextColor } from '../../../../utils/hold-line-flights';
import { holdFlightDataMapper } from '../vistajet/data-mappers';
import { getHoldFlightsD3creator } from './d3-creator';

export interface ExtendedHoldFlightEventElement extends ExtendedEventElement {
  color: string;
}

interface Props {
  flights: Flight[];
  aircraftId: number;
  width: number;
  left: number;
}
const className = 'label-text-hold';
export class HoldFlightsColumn extends PureComponent<Props> {
  rootGroupRef: HTMLDivElement;
  d3Updater: (action: any, state: RootState) => void;
  scrollProtectedUpdate: (action: any, state: RootState) => void;
  linkedSelection: d3S.Selection<any, ExtendedEventElement, HTMLDivElement, {}>;
  constructor(props: Props) {
    super(props);
    this.d3Updater = (action, state) => {
      this.update(
        this.props.flights.map(fl => ({
          ...fl,
          isSelected: state.ui.selectedFlights.includes(fl.id),
        }))
      );
    };

    this.scrollProtectedUpdate = (action, state) => {
      if (state.ui.isScrollingHorizontally) return;
      this.d3Updater(action, state);
    };
  }

  toggleUpdateSubscription(mode: 'on' | 'off') {
    subMiddle[mode](
      actions.doAirportsMappersFetch.done,
      this.scrollProtectedUpdate
    );
    subMiddle[mode](actions.getSegmentRequest.done, this.scrollProtectedUpdate);
    subMiddle[mode](actions.userChangeSearchQuery, this.d3Updater);
    subMiddle[mode](actions.userChangeSearchQueryAllFilters, this.d3Updater);
    subMiddle[mode](actions.userChangeSearchACQuery, this.d3Updater);
    subMiddle[mode](actions.userChangeSearchType, this.d3Updater);
    subMiddle[mode](actions.userToggleHoldLineTypeView, this.d3Updater);
    subMiddle[mode](actions.doSetHeightForHoldAircraft, this.d3Updater);
    subMiddle[mode](actions.wsUpdateBatch, this.d3Updater);
    subMiddle[mode](actions.doStopScroll, this.d3Updater);
    subMiddle[mode](actions.userToggleFlightSelection, this.d3Updater);
    subMiddle[mode](actions.userSelectFlightsForDnDEnd, this.d3Updater);
    subMiddle[mode](actions.userCloseDndModal, this.d3Updater);
    subMiddle[mode](actions.userChangeTailForFlights.done, this.d3Updater);
    subMiddle[mode](actions.userCancelDraggingFlights, this.d3Updater);
    subMiddle[mode](actions.userDragFlightsEnd, this.d3Updater);
    subMiddle[mode](actions.userTimelineSelectionEnd, this.d3Updater);
    subMiddle[mode](actions.userResetMultipleSearchBarPanel, this.d3Updater);
    subMiddle[mode](actions.userToggleMultipleSearchBarPanel, this.d3Updater);
    subMiddle[mode](actions.userChangeCurrentAcTypePosition, this.d3Updater);
    subMiddle[mode](
      actions.userResetChangesInAcTypesPositionMap,
      this.d3Updater
    );
  }
  componentDidMount() {
    const state = reduxStore.getState();
    this.d3Updater(null, state);
    this.toggleUpdateSubscription('on');
  }
  componentWillUnmount() {
    this.toggleUpdateSubscription('off');
  }
  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.flights, this.props.flights)) {
      this.update(this.props.flights);
    }
  }
  getRootRef = ref => {
    this.rootGroupRef = ref;
  };
  update = (data: Flight[]) => {
    const { ui } = reduxStore.getState();
    if (ui.isScrollingHorizontally) return;
    if (!this.linkedSelection) {
      this.linkedSelection = d3S
        .select(this.rootGroupRef)
        .selectAll(`.${className}`);
    }
    const mappedWithData = this.linkedSelection.data(
      data.map((d, i) => this.buildPropsForComponent(d, i)),
      holdFlightDataMapper
    );
    const exit = mappedWithData.exit();
    exit.remove();
    this.linkedSelection = this.renderEntered(mappedWithData.enter()).merge(
      mappedWithData
    );
  };
  renderEntered(entered) {
    return getHoldFlightsD3creator(entered).attr(
      'data-test-entity-id',
      (d: EventElement) => d.id
    );
  }
  rectTopCalculator = (index: number) => {
    const { aircraftId } = this.props;
    const state = reduxStore.getState();
    const { holdAircraftPositionMap } = state.ui;
    const offset = holdAircraftPositionMap[aircraftId]?.y1 + HOLD_LANE_STROKE;
    return HOLD_FLIGHT_MARGIN * index + offset;
  };
  buildPropsForComponent = (d: EventElement, index: number) => {
    const state = reduxStore.getState();
    const {
      ui: {
        transform: { ky },
      },
    } = state;
    const newD = Object.create(d) as ExtendedHoldFlightEventElement;
    newD.x = this.props.left;
    newD.airportsDataLoaded = state.airports.airportsLoadingComplete;
    newD.width = this.props.width;
    newD.ky = ky;
    newD.rh = HOLD_FLIGHT_HEIGHT + HOLD_FLIGHT_MARGIN;
    newD.laneKoef = 1;
    newD.y = this.rectTopCalculator(index);
    newD.color = getHoldFlightTextColor(state, d as Flight);
    return newD;
  };
  render() {
    return <div ref={this.getRootRef} />;
  }
}

import { gql } from '@apollo/client';

export const GET_CONTRACT_SUB_PROGRAM_TYPE = gql`
  query getContractSubProgramType($contractId: String!, $flightId: Int!) {
    getContractSubProgramType(contractId: $contractId, flightId: $flightId) {
      subProgramType
      flightId
    }
  }
`;

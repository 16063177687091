import { includes, isNumber } from 'lodash';

import {
  AIRCRAFT_MX_CONFIG_BY_AC_TYPE,
  AIRCRAFT_TYPE_IDS_FOR_MX_DUE,
  HOLD_AC_TYPE_ID,
  VJ_OPERATING_COMPANY_ID,
  RWA_OPERATING_COMPANY_ID,
  XO_JET_OPERATING_COMPANY_ID,
  JS_OPERATING_COMPANY_ID,
  WAC_OPERATING_COMPANY_ID,
  AH_OPERATING_COMPANY_ID,
} from '../../constants/environment';
import { DAY_IN_MILLISECONDS, ONE_HOUR_IN_MINUTES } from '../../constants';
import Aircraft, {
  MaintenanceStatus,
  MxDueTagColor,
  MxEventPresenceType,
  OperatingCompany,
} from '../../types/aircraft';

export const isHoldAircraft = (ac: Aircraft) =>
  ac?.aircraftTypeId === HOLD_AC_TYPE_ID;

export function isSubCharter(ac: Aircraft, baseCompaniesIds: number[]) {
  const isOwned = includes(baseCompaniesIds, ac.owningCompanyId);
  const isOperated = includes(baseCompaniesIds, ac.operatingCompanyId);
  return !isOwned && !isOperated;
}

export const isOwnedAndIsOperatedByVJ = (
  ac: Aircraft,
  baseCompaniesIds: number[]
): boolean => {
  const isOwned = includes(baseCompaniesIds, ac.owningCompanyId);
  const isOperated = includes(baseCompaniesIds, ac.operatingCompanyId);
  return isOwned && isOperated;
};

export const isOwnedAndIsNotOperatedByVJ = (
  ac: Aircraft,
  baseCompaniesIds: number[]
): boolean => {
  const isOwned = includes(baseCompaniesIds, ac.owningCompanyId);
  const isOperated = includes(baseCompaniesIds, ac.operatingCompanyId);
  return isOwned && !isOperated;
};

export const isOwnedByVJ = (
  ac: Aircraft,
  baseCompaniesIds: number[]
): boolean => {
  return includes(baseCompaniesIds, ac?.owningCompanyId);
};

export const getAcMxType = (typeId: number): string | null => {
  if (AIRCRAFT_TYPE_IDS_FOR_MX_DUE) {
    return Object.keys(AIRCRAFT_TYPE_IDS_FOR_MX_DUE).reduce((acc, key) => {
      if (
        AIRCRAFT_TYPE_IDS_FOR_MX_DUE[key] &&
        AIRCRAFT_TYPE_IDS_FOR_MX_DUE[key].find(id => id === typeId)
      ) {
        return key;
      }
      return acc;
    }, '');
  }
  return null;
};

export const getCloseToMxTagColor = (
  ac: Aircraft,
  today: number
): MxDueTagColor | null => {
  const {
    landingsBeforeMX,
    airTimeBeforeMX,
    aircraftTypeId,
    totalLandings,
    minutesFlown,
    nextDueDateMs,
  } = ac;
  const aircraftMxType = getAcMxType(aircraftTypeId);
  if (
    AIRCRAFT_MX_CONFIG_BY_AC_TYPE &&
    aircraftMxType &&
    AIRCRAFT_MX_CONFIG_BY_AC_TYPE[aircraftMxType]
  ) {
    const { hours, landings, days } = AIRCRAFT_MX_CONFIG_BY_AC_TYPE[
      aircraftMxType
    ];
    const hoursBeforeMx = airTimeBeforeMX
      ? Math.round(airTimeBeforeMX / ONE_HOUR_IN_MINUTES) -
        Math.round(minutesFlown / ONE_HOUR_IN_MINUTES)
      : null;
    const restLandings = landingsBeforeMX
      ? landingsBeforeMX - totalLandings
      : null;
    if (
      (isNumber(hoursBeforeMx) && hours && hoursBeforeMx <= hours.redZone) ||
      (landingsBeforeMX && landings && restLandings <= landings.redZone) ||
      (nextDueDateMs &&
        (nextDueDateMs - today) / DAY_IN_MILLISECONDS <= days.redZone)
    ) {
      return 'red';
    }
    if (
      (isNumber(hoursBeforeMx) && hours && hoursBeforeMx <= hours.amberZone) ||
      (landingsBeforeMX && landings && restLandings <= landings.amberZone) ||
      (nextDueDateMs &&
        (nextDueDateMs - today) / DAY_IN_MILLISECONDS <= days.amberZone)
    ) {
      return 'amber';
    }
    if (
      (isNumber(hoursBeforeMx) && hours && hoursBeforeMx > hours.amberZone) ||
      (landingsBeforeMX && landings && restLandings > landings.amberZone) ||
      (nextDueDateMs &&
        (nextDueDateMs - today) / DAY_IN_MILLISECONDS > days.amberZone)
    ) {
      return null;
    }
  }
  return null;
};

export const isVJOperatedAircraft = (ac: Aircraft): boolean =>
  ac.operatingCompanyId === VJ_OPERATING_COMPANY_ID;

export const isXOOperatedAircraft = (ac: Aircraft): boolean =>
  ac.operatingCompanyId === XO_JET_OPERATING_COMPANY_ID;

export const isRWAOperatedAircraft = (ac: Aircraft): boolean =>
  ac.operatingCompanyId === RWA_OPERATING_COMPANY_ID;

export const isAirHamburgAircraft = (ac: Aircraft): boolean =>
  ac.operatingCompanyId === AH_OPERATING_COMPANY_ID;

export const isJSOperatedAircraft = (ac: Aircraft): boolean =>
  ac.operatingCompanyId === +JS_OPERATING_COMPANY_ID;

const isWAOperatedAircraft = (ac: Aircraft): boolean =>
  ac.operatingCompanyId === +WAC_OPERATING_COMPANY_ID;

export const isJetEdgeAircraft = (ac: Aircraft): boolean =>
  isJSOperatedAircraft(ac) || isWAOperatedAircraft(ac);

export const getAircraftOperator = (ac: Aircraft): OperatingCompany => {
  if (isXOOperatedAircraft(ac)) return 'XO';
  if (isRWAOperatedAircraft(ac)) return 'RWA';
  if (isJetEdgeAircraft(ac)) return 'JS';
  if (isAirHamburgAircraft(ac)) return 'AH';
  return 'VJ';
};

export const isAircraftAvailable = (el: Aircraft) => {
  if (
    el.maintenanceStatusId !== MaintenanceStatus.AOG &&
    el.maintenanceStatusId !== MaintenanceStatus.UMX &&
    el.maintenanceStatusId !== MaintenanceStatus.SMX
  ) {
    return true;
  }
  return false;
};

export const isAircraftForStats = (ac: Aircraft): boolean =>
  isVJOperatedAircraft(ac) ||
  isXOOperatedAircraft(ac) ||
  isRWAOperatedAircraft(ac) ||
  isJetEdgeAircraft(ac) ||
  isAirHamburgAircraft(ac);

export const isAircraftForMxEmails = (ac: Aircraft): boolean =>
  isVJOperatedAircraft(ac) ||
  isXOOperatedAircraft(ac) ||
  isRWAOperatedAircraft(ac) ||
  isJetEdgeAircraft(ac) ||
  isAirHamburgAircraft(ac);

import { PureComponent } from 'react';
import { LINE_HEIGHT } from '../../constants';

interface Props {
  operationalMemo: string;
}

class OpsMemoBlock extends PureComponent<Props> {
  render() {
    const { operationalMemo } = this.props;
    return (
      <>
        <div className="ft-name not-centered">OPS Memo</div>
        <div
          className="ft-text"
          style={{
            maxHeight: `${LINE_HEIGHT * 4.5}px`,
          }}
        >
          {operationalMemo &&
            operationalMemo
              .split(/[\r\n]/)
              .map(el => el.trim())
              .join('\n')}
        </div>
      </>
    );
  }
}

export default OpsMemoBlock;

import { PureComponent } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import Airport from '../../types/airport';
import Timezone from '../../types/timezone';
import './styles.scss';
import { subMiddle } from '../../root';
import Flight, { FlightLabelTimeType } from '../../types/flight';
import Popper from 'popper.js';
import { getAircraftById } from '../../selectors';
import Aircraft from '../../types/aircraft';
import { hasPermission } from '../../utils/check-permissions';
import { Crew } from '../../types/crew-roster';
import { debounce } from 'lodash';
import { TOOLTIP_DEBOUNCE } from '../../constants/environment';
import TooltipParent from './parent';
import { Action } from 'typescript-fsa';
import {
  getCrewByFlightLegIdMap,
  getFlightCrewDetailsSorted,
} from '../../utils/crew-roster';

const TOOLTIP_SQUARED_FORM_WIDTH = 800;

interface ConnectedProps {
  isDepArrTimeEnabled: boolean;
  isDragging: boolean;
  selectedFlight: Flight;
  airportsById: { [id: number]: Airport };
  timezonesData: { [id: number]: Timezone };
  aircraft: Aircraft;
  planeBlockWidth: number;
  flightTooltipWidth: number;
  HAS_PSS_PERMISSION: boolean;
  HAS_PAX_PERMISSION: boolean;
  HAS_REMARKS_PERMISSION: boolean;
  HAS_PROGRAM_REVENUE_PERMISSION: boolean;
  HAS_OD_REVENUE_PERMISSION: boolean;
  crew: { [flightLegId: number]: Crew[] };
}

class Tooltip extends PureComponent<ConnectedProps> {
  tooltipContainerRef: HTMLDivElement;
  getTooltipContainerRef = ref => (this.tooltipContainerRef = ref);
  popper: Popper;
  state = {
    flightBoundingRect: undefined,
  };
  updateFlightBoundingRect(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    if (
      !this.state.flightBoundingRect ||
      rect.left !== this.state.flightBoundingRect.left ||
      rect.right !== this.state.flightBoundingRect.right
    ) {
      this.setState({ flightBoundingRect: rect });
    }
  }
  createPopper = (action: Action<[Flight, HTMLElement]>) => {
    if (this.props.isDragging) {
      return;
    }
    const { planeBlockWidth } = this.props;
    const tooltipWidth =
      this.tooltipContainerRef && this.tooltipContainerRef.offsetWidth;
    const pageWidth = Math.max(window.innerWidth - planeBlockWidth, 0);
    const actionRef = action.payload[1];
    this.updateFlightBoundingRect(actionRef);
    const tooltipWrapper = document.getElementsByClassName(
      'ft-tooltip'
    )[0] as HTMLDivElement;
    if (tooltipWrapper && actionRef) {
      this.popper = new Popper(actionRef, tooltipWrapper, {
        placement:
          pageWidth - actionRef.getBoundingClientRect().left < tooltipWidth
            ? 'left-end'
            : 'auto-end',
        modifiers: {
          preventOverflow: { enabled: true },
          computeStyle: { gpuAcceleration: false },
        },
        positionFixed: true,
      });
    }
  };
  debouncedHover = debounce(this.createPopper, TOOLTIP_DEBOUNCE);

  removePopper = action => {
    this.debouncedHover.cancel();
    if (this.popper) {
      this.popper.destroy();
      delete this.popper;
    }
  };
  getTooltipWidth() {
    return this.tooltipContainerRef &&
      this.tooltipContainerRef.offsetWidth === TOOLTIP_SQUARED_FORM_WIDTH
      ? TOOLTIP_SQUARED_FORM_WIDTH
      : this.props.flightTooltipWidth;
  }
  componentDidMount() {
    subMiddle.on(actions.userHoverFlight, this.debouncedHover);
    subMiddle.on(actions.userZoomHor, this.removePopper);
    subMiddle.on(actions.userZoomVer, this.removePopper);
    subMiddle.on(actions.userCloseTooltip, this.removePopper);
    subMiddle.on(actions.userDragFlightsBegin, this.removePopper);
    subMiddle.on(actions.userOpenFlightMenu, this.removePopper);
    subMiddle.on(actions.userHoverSegment, this.removePopper);
    subMiddle.on(actions.userHoverMergedNotes, this.removePopper);
    subMiddle.on(actions.userHoverOverlap, this.removePopper);
    subMiddle.on(actions.userHoverCrew, this.removePopper);
    window.addEventListener('resize', this.removePopper);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.removePopper);
  }
  render() {
    return (
      <div
        className="ft-tooltip"
        style={{
          margin: `0 ${this.props.isDepArrTimeEnabled ? '60px' : 'inherit'}`,
        }}
        ref={this.getTooltipContainerRef}
      >
        {this.props.selectedFlight && (
          <TooltipParent
            flightBoundingRect={this.state.flightBoundingRect}
            resizable
            selectedFlight={this.props.selectedFlight}
            airportsById={this.props.airportsById}
            timezonesData={this.props.timezonesData}
            aircraft={this.props.aircraft}
            HAS_PSS_PERMISSION={this.props.HAS_PSS_PERMISSION}
            HAS_PAX_PERMISSION={this.props.HAS_PAX_PERMISSION}
            HAS_REMARKS_PERMISSION={this.props.HAS_REMARKS_PERMISSION}
            HAS_PROGRAM_REVENUE_PERMISSION={
              this.props.HAS_PROGRAM_REVENUE_PERMISSION
            }
            HAS_OD_REVENUE_PERMISSION={this.props.HAS_OD_REVENUE_PERMISSION}
            crew={getFlightCrewDetailsSorted(
              this.props.crew[
                this.props.selectedFlight && this.props.selectedFlight.id
              ] || []
            )}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<ConnectedProps, {}, RootState> = (
  state: RootState
) => ({
  isDepArrTimeEnabled:
    state.ui.timeLabelDisplayMode === FlightLabelTimeType.ESTIMATED ||
    state.ui.timeLabelDisplayMode === FlightLabelTimeType.SCHEDULED,
  isDragging: state.flightDetails.isDragging,
  selectedFlight: state.flightDetails.selectedFlight,
  airportsById: state.airports.airportsById,
  timezonesData: state.timezones.timezonesById,
  aircraft: state.flightDetails.selectedFlight
    ? getAircraftById(state, state.flightDetails.selectedFlight.aircraftId)
    : null,
  planeBlockWidth: state.ui.planeBlockWidth,
  flightTooltipWidth: state.ui.flightTooltipWidth,
  HAS_PSS_PERMISSION: hasPermission(state, 'AG-Timeline-PSS-View'),
  HAS_PAX_PERMISSION: hasPermission(state, 'AG-Timeline-Lead-Pax-View'),
  HAS_REMARKS_PERMISSION: hasPermission(state, 'AG-Timeline-Remarks-View'),
  HAS_PROGRAM_REVENUE_PERMISSION: hasPermission(
    state,
    'AG-Timeline-Program-Revenue-View'
  ),
  HAS_OD_REVENUE_PERMISSION: hasPermission(
    state,
    'AG-Timeline-OD-Revenue-View'
  ),
  crew: getCrewByFlightLegIdMap(state, ''),
});

export default connect(mapStateToProps)(Tooltip);

import EventElement from './event-element';

export enum ImportanceFactor {
  GENERAL = 1,
  AVAILABILITY,
}
export type NoteType = 'General' | 'Availability';
export default interface Note extends EventElement {
  message: string;
  person: {
    id: number;
    firstName: string;
    lastName: string;
  };
  inputSourceName: string;
  lastUpdated: number;
  crewNotAvailable: boolean;
  importanceFactor: ImportanceFactor;
  isActive: boolean;
  inputSourceId: number;
}
export interface AvailabilityNote extends Note {
  importanceFactor: ImportanceFactor.AVAILABILITY;
}
export interface GeneralNote extends Note {
  importanceFactor: ImportanceFactor.GENERAL;
}
export interface MergedNote extends EventElement {
  notes: Note[];
}

import { utc } from 'moment';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
  isOwnedAndIsOperatedByVJ,
  isOwnedByVJ,
} from '../../../common/aircraft/aircraft-check-status';
import { RootState } from '../../../reducers';
import {
  getAircraftById,
  getBaseCompaniesIds,
  getUnavailableForAdvertisementMapLabels,
} from '../../../selectors';
import Aircraft from '../../../types/aircraft';
import { setDataTestEntityId, shortenStringLength } from '../../../utils';
import { getAircraftLocationStatus } from '../../../utils/aircraft';
import { universalDateAndTimeFormatter } from '../../../utils/time';
import SeatingManIcon from '../../svg-icons/aircraft/SeatingManIcon';
import UnavailableIcon from '../../svg-icons/aircraft/UnavailableIcon';
import WarningIcon from '../../svg-icons/aircraft/WarningIcon';
import ArrowIcon from '../../svg-icons/tooltip/arrowIcon';
import { DescriptionsContent } from './descriptions-content';
import { getHoursFlown, getTotalLandings } from './utils';

interface StateProps {
  aircraft: Aircraft;
  isOwnedAndOperatedByVJ: boolean;
  isOwnedByVJ: boolean;
  isUnavailableForAdvertisement: boolean;
}

class MainTooltipContent extends PureComponent<StateProps> {
  getAOGDescription = () => {
    const { aogDescriptions } = this.props.aircraft;
    if (aogDescriptions.length) {
      return aogDescriptions;
    }
    return ['Please, review MEL list'];
  };

  render() {
    const { aircraft, isOwnedByVJ, isUnavailableForAdvertisement } = this.props;
    const {
      manufactureDate,
      lastRefurbishmentDate,
      refurbishmentNotes,
    } = aircraft;
    const yom = manufactureDate
      ? universalDateAndTimeFormatter(utc(manufactureDate), 'dayMonthYear')
      : 'N/A';
    const yor = lastRefurbishmentDate
      ? universalDateAndTimeFormatter(
          utc(lastRefurbishmentDate),
          'dayMonthYear'
        )
      : 'N/A';
    return (
      <>
        <div className="custom-tooltip-row">
          <div
            className="custom-tooltip-tailNumber"
            {...setDataTestEntityId('tooltip-aircraft-tail-number')}
          >
            {aircraft.tailNumber}
          </div>
          <div className="custom-tooltip-serialNumber">{`Serial #${
            aircraft.serialNumber ? aircraft.serialNumber : `N/A`
          }`}</div>
          <div
            className="aircraft-seats-number custom-tooltip-value"
            style={{
              paddingTop: 2,
            }}
            {...setDataTestEntityId('tooltip-aircraft-number-of-seats')}
          >
            <div className="custom-tooltip-seats">
              <SeatingManIcon />
            </div>
            {aircraft.numberOfSeats}
          </div>
        </div>
        <div
          className="custom-tooltip-row"
          style={{
            margin: 0,
          }}
          {...setDataTestEntityId('tooltip-aircraft-type-code')}
        >
          {aircraft.aircraftTypeCode}
        </div>
        <div className="custom-tooltip-row" style={{ marginTop: 8 }}>
          <span>Operating Company</span>
          <span
            className="custom-tooltip-value"
            {...setDataTestEntityId('tooltip-aircraft-operating-company')}
          >
            {aircraft.operatingCompanyName}
          </span>
        </div>
        {isOwnedByVJ && (
          <div className="custom-tooltip-row">
            <span>Current Location</span>
            <span
              className="custom-tooltip-value"
              {...setDataTestEntityId('tooltip-aircraft-location-status')}
            >
              {getAircraftLocationStatus(aircraft)}
            </span>
          </div>
        )}
        <div className="custom-tooltip-row">
          <span>Hours flown</span>
          <span
            className="custom-tooltip-value"
            {...setDataTestEntityId('tooltip-aircraft-hours-flown')}
          >
            {getHoursFlown(aircraft)}
          </span>
        </div>
        <div className="custom-tooltip-row">
          <span>Total landings</span>
          <span
            className="custom-tooltip-value"
            {...setDataTestEntityId('tooltip-aircraft-total-landings')}
          >
            {getTotalLandings(aircraft)}
          </span>
        </div>
        <div className="custom-tooltip-row">
          <span>YoM</span>
          <span
            className="custom-tooltip-value"
            {...setDataTestEntityId('tooltip-aircraft-yom')}
          >
            {yom}
          </span>
        </div>
        <div className="custom-tooltip-row">
          <span>YoR</span>
          <span
            className="custom-tooltip-value"
            {...setDataTestEntityId('tooltip-aircraft-yor')}
          >
            {yor}
          </span>
        </div>
        {refurbishmentNotes && (
          <div
            className="refurbishment-notes"
            {...setDataTestEntityId('tooltip-aircraft-refurbishment-notes')}
          >
            <div className="refurbishment-notes-header">
              Refurbishment Notes
            </div>
            <div className="refurbishment-notes-text">
              {shortenStringLength(refurbishmentNotes, 150)}
            </div>
          </div>
        )}
        {isUnavailableForAdvertisement && (
          <div
            className="custom-tooltip-unavailable-for-advertisement"
            style={{
              border: '1px solid #FFA39E',
              borderRadius: 3,
              backgroundColor: '#FFF1F0',
              marginTop: '8px',
              padding: '12px 25px 16px',
              position: 'relative',
            }}
            {...setDataTestEntityId(
              'tooltip-aircraft-unavailable-for-advertisement'
            )}
          >
            <div style={{ position: 'absolute', left: '8px', top: '12px' }}>
              <UnavailableIcon />
            </div>
            <div className="custom-tooltip-unavailable-for-advertisement-text">
              <div
                className="custom-tooltip-unavailable-for-advertisement-text-header"
                style={{
                  fontSize: '12px',
                  fontWeight: 800,
                  lineHeight: '15px',
                }}
                {...setDataTestEntityId(
                  'tooltip-aircraft-unavailable-for-advertisement-text-header'
                )}
              >
                Unavailable for Auto <br /> Advertisement
              </div>
              <div
                className="custom-tooltip-unavailable-for-advertisement-text-dates"
                {...setDataTestEntityId(
                  'tooltip-aircraft-unavailable-for-advertisement-text-dates'
                )}
              >
                <div
                  className="custom-tooltip-unavailable-for-advertisement-text-startDate"
                  style={{
                    lineHeight: '16px',
                  }}
                  {...setDataTestEntityId(
                    'tooltip-aircraft-unavailable-for-advertisement-text-startDate'
                  )}
                >
                  {utc(aircraft.availabilityStartDate).format('DD MMM YYYY')}
                </div>
                <div className="custom-tooltip-unavailable-for-advertisement-text-arrow">
                  <ArrowIcon color="rgba(0,0,0,0.25)" />
                </div>
                <div
                  className="custom-tooltip-unavailable-for-advertisement-text-endDate"
                  {...setDataTestEntityId(
                    'tooltip-aircraft-unavailable-for-advertisement-text-endDate'
                  )}
                >
                  {aircraft.availabilityEndDate === null ? (
                    <>
                      <span
                        style={{
                          height: '20px',
                          width: '20px',
                          fontFamily: 'Apple Color Emoji',
                          fontSize: '20px',
                          lineHeight: '14px',
                        }}
                      >
                        &#8734;
                      </span>
                    </>
                  ) : (
                    utc(aircraft.availabilityEndDate).format('DD MMM YYYY')
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {aircraft.hasAogDrivers && (
          <div
            className="aog-drivers-warning"
            {...setDataTestEntityId('tooltip-aircraft-aog-drivers-warning')}
          >
            <header
              className="aog-drivers-warning-header"
              {...setDataTestEntityId(
                'tooltip-aircraft-aog-drivers-warning-header'
              )}
            >
              <div
                className="aog-drivers-warning-icon"
                {...setDataTestEntityId(
                  'tooltip-aircraft-aog-drivers-warning-icon'
                )}
              >
                <WarningIcon fill={'red'} />
              </div>
              <h1 className="aog-drivers-warning-h1">Has AOG driver defects</h1>
            </header>
            <DescriptionsContent
              warningType="aog-drivers-warning"
              descriptions={this.getAOGDescription()}
            />
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const aircraft = getAircraftById(state, state.ui.hoveredAircraftId);
  return {
    aircraft,
    isOwnedAndOperatedByVJ: isOwnedAndIsOperatedByVJ(
      aircraft,
      getBaseCompaniesIds(state)
    ),
    isOwnedByVJ: isOwnedByVJ(aircraft, getBaseCompaniesIds(state)),
    isUnavailableForAdvertisement: getUnavailableForAdvertisementMapLabels(
      state
    )[aircraft.id],
  };
};

const MainTooltipContentConnected = connect(mapStateToProps)(
  MainTooltipContent
);

export default MainTooltipContentConnected;

import { Popconfirm } from 'antd';
import { PureComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';
import * as actions from '../../../actions';
import { RootState } from '../../../reducers';
import { getNoteByIdMap } from '../../../selectors/notes';
import { InputSourceIdType } from '../../../types/input-source';
import Note from '../../../types/note';
import { hasPermission } from '../../../utils/check-permissions';
import { ContextMenuWrapperAsPopper } from '../wrappers';
import { AnyAction, Dispatch } from 'redux';

interface StateProps {
  isOpen: boolean;
  noteId: number;
  exactNote: Note;
  hasDeletePermission: boolean;
}
interface OwnProps {
  id?: number;
}
interface DispatchProps {
  onEditNote: (exactNote: Note) => void;
  onDeleteNote: (id: number) => void;
  onCloseContextMenu: () => void;
}
export class ContextNoteMenu extends PureComponent<
  StateProps & OwnProps & DispatchProps
> {
  handleEditNote = () => {
    this.props.onEditNote(this.props.exactNote);
  };
  render() {
    const {
      hasDeletePermission,
      noteId,
      onDeleteNote,
      onCloseContextMenu,
      id,
      exactNote,
    } = this.props;

    const confirm = () => {
      onDeleteNote(id ? id : noteId);
    };
    const cancel = () => {
      onCloseContextMenu();
    };
    const isCrewRestNote = exactNote?.inputSourceId === InputSourceIdType.SQL;
    return (
      <ul className="context-menu">
        <li onClick={this.handleEditNote}>Edit</li>
        {hasDeletePermission && !isCrewRestNote && (
          <Popconfirm
            title="Do you want to delete this note?"
            onConfirm={confirm}
            onCancel={cancel}
            okText={<span data-stopbubbling="true">Yes</span>}
            cancelText="No"
          >
            <li>Delete Note</li>
          </Popconfirm>
        )}
      </ul>
    );
  }
}

export class ContextNoteMenuWrapper extends PureComponent<{
  isOpen: boolean;
  closeContextMenu: () => void;
}> {
  render() {
    const { isOpen, closeContextMenu } = this.props;
    return (
      <ContextMenuWrapperAsPopper
        isOpen={isOpen}
        menuType={'note'}
        onCloseContextMenu={closeContextMenu}
        openMenuAction={actions.userOpenNoteContextMenu}
      >
        <ContextNoteMenuConnected />
      </ContextMenuWrapperAsPopper>
    );
  }
}
const mapStateToProps = (state: RootState) => ({
  isOpen: state.eventElementCreationEdit.isNoteContextMenuOpened,
  noteId: state.eventElementCreationEdit.id,
  exactNote:
    state.eventElementCreationEdit.id &&
    getNoteByIdMap(state)[state.eventElementCreationEdit.id],
  hasDeletePermission: hasPermission(state, 'AG-Timeline-Delete-Note'),
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>): DispatchProps => ({
  onEditNote: (exactNote: Note) => {
    dispatch(
      actions.userClickOpenNote({
        id: exactNote.id,
        end: exactNote.end,
        start: exactNote.start,
      })
    );
  },
  onDeleteNote: (id: number) => {
    dispatch(actions.userDeleteNote.started(id));
  },
  onCloseContextMenu: () => {
    dispatch(actions.userCloseContextMenu());
  },
});
export const ContextNoteMenuConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextNoteMenu);

export const NoteContextMenuConnected = connect(
  (state: RootState) => ({
    isOpen: state.eventElementCreationEdit.isNoteContextMenuOpened,
  }),
  (dispatch: Dispatch<AnyAction>) => ({
    closeContextMenu: () => {
      dispatch(actions.userCloseContextMenu());
    },
  })
)(ContextNoteMenuWrapper);

import { PureComponent } from 'react';
import { Alert } from 'antd';
import PlaneIcon from '../../../svg-icons/flight-details-bar/plane';
import Aircraft from '../../../../types/aircraft';
import { DndMultipleModalLastRowButtons } from './dnd-multiple-modal-last-row-buttons';
import { DndDataSource } from './main-content';
import { getFontSize } from './utils';

interface Props {
  newAircraft: Aircraft;
  isDisabled: boolean;
  isExceededSpace: boolean;
  marginLeft: number;
  onCancel: () => void;
  onConfirm: () => void;
  dndDataSource: Array<DndDataSource>;
  isVerticalMode: boolean;
}

export class LastRow extends PureComponent<Props> {
  render() {
    const {
      isExceededSpace,
      marginLeft,
      newAircraft,
      isDisabled,
      dndDataSource,
      onConfirm,
      onCancel,
      isVerticalMode,
    } = this.props;
    return (
      <div
        className="dnd-multiple-modal-last-row"
        style={{ fontSize: getFontSize(isVerticalMode) }}
      >
        <div
          className="dnd-multiple-modal-last-row-tail-box"
          style={{
            borderColor: isExceededSpace ? '#ffa39e' : '#91d5ff',
            backgroundColor: isExceededSpace ? '#fff1f0' : '#e6f7ff',
          }}
        >
          <div className="dnd-multiple-modal-last-row-tail-box-icon">
            <PlaneIcon color={isExceededSpace ? '#FF5454' : '#1890FF'} />
          </div>
          <div
            className="dnd-multiple-modal-last-row-tail-box-number"
            style={{
              borderColor: isExceededSpace ? '#ffa39e' : '#91d5ff',
              marginLeft: marginLeft,
            }}
          >
            {newAircraft.tailNumber}
          </div>
          <div
            className="dnd-multiple-modal-last-row-tail-box-capacity"
            style={{
              color: isExceededSpace ? '#ffa39e' : 'rgba(0, 0, 0, 0.45)',
              marginLeft: isVerticalMode ? '24px' : '45px',
            }}
          >
            {newAircraft.numberOfSeats}
          </div>
          {isExceededSpace && (
            <div className="dnd-multiple-modal-last-row-warning-message">
              <Alert
                type="error"
                message={`Not enough capacity for some flights`}
              />
            </div>
          )}
        </div>
        <DndMultipleModalLastRowButtons
          isDisabled={isDisabled}
          onCancel={onCancel}
          onConfirm={onConfirm}
          dndDataSource={dndDataSource}
          isVerticalMode={isVerticalMode}
        />
      </div>
    );
  }
}

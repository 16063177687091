import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';

export interface EmptyLegOfferController {
  id: number;
  contextMenu: {
    isOpen: boolean;
    position: {
      x: number;
      y: number;
    };
  };
}

export const initialState: EmptyLegOfferController = {
  id: undefined,
  contextMenu: {
    isOpen: false,
    position: { x: 0, y: 0 },
  },
};

export default reducerWithInitialState(initialState)
  .case(actions.userOpenContextMenuForEmptyLegOffer, (state, payload) => {
    const { id, position } = payload;
    return {
      ...state,
      id,
      contextMenu: {
        ...state.contextMenu,
        isOpen: true,
        position,
      },
    };
  })
  .case(actions.userCloseContextMenu, () => ({ ...initialState }))
  .case(actions.userTimelineSelectionBegin, state => ({
    ...state,
    contextMenu: initialState.contextMenu,
  }))
  .cases(
    [
      actions.userFocusOverlapCards,
      actions.userOpenNoteContextMenu,
      actions.userOpenFlightMenu,
      actions.userOpenCreateEventsMenu,
      actions.userOpenContextMenuForPeakDay,
      actions.userOpenContextMenuForMxEvent,
      actions.userOpenContextMenuForOneWayOffer,
    ] as any[],
    state => ({
      ...state,
      contextMenu: initialState.contextMenu,
    })
  );

import { RootState } from '../../../../../reducers';

export function hoverNoteStateChanger(action: any, state: RootState) {
  const { id } = action.payload[0];
  this.linkedSelection
    .filter(n => n.id === id)
    .select('.note-body')
    .raise()
    .style('fill', '#fdd835');
}
export function hoverOutNoteStateChanger(action: any, state: RootState) {
  this.linkedSelection
    .filter(
      n => state.ui.exactHoveredNote && n.id === state.ui.exactHoveredNote.id
    )
    .lower()
    .select('.note-body')
    .style('fill', 'rgba(255,244,157,0.6)');
}
export function hoverMergedNotes(action: any, state: RootState) {
  this.linkedSelection
    .filter(n => n.id === action.payload[3].id)
    .raise()
    .select('rect')
    .style('fill', '#fdd835');
}

export function hoverNoteTooltip(action: any, state: RootState) {
  this.linkedSelection
    .filter(n =>
      state.ui.hoveredNoteId
        ? state.ui.hoveredNoteId && n.id === state.ui.hoveredNoteId
        : state.ui.exactHoveredNote && n.id === state.ui.exactHoveredNote.id
    )
    .raise()
    .select('rect')
    .style('fill', '#fdd835');
}

export function unhoverNoteTooltip(action: any, state: RootState) {
  this.linkedSelection
    .filter(n => n.id === action.payload)
    .lower()
    .select('rect')
    .style('fill', 'rgba(255,244,157,0.6)');
}

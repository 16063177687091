import { Checkbox } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Field, FieldInputProps, FormikProps } from 'formik';
import { FC, useEffect } from 'react';
import { BusyDays } from '../../../../types/peak-day';
import { PeakDayFormFieldName, PeakDayFormFieldValues } from '../helpers';
import { DatePickerHandler } from './date-picker';
import { SelectComponent } from './select';
import {
  PEAK_DAY_REGIONS,
  PEAK_DAY_TYPES,
} from '../../../../constants/environment';

const MAX_CHARACTERS = 400;

export const PeakDayFormDetails: FC<{
  busyDays: BusyDays;
  disabled: boolean;
  formikProps: FormikProps<PeakDayFormFieldValues>;
  formIsTouched: boolean;
  setFormIsTouched: (v: boolean) => void;
}> = props => {
  const {
    busyDays,
    formikProps,
    formIsTouched,
    setFormIsTouched,
    disabled,
  } = props;
  const {
    dirty,
    errors,
    handleChange,
    touched,
    values: { description, autoAdvertising, crewChange },
  } = formikProps;
  useEffect(() => {
    if (!formIsTouched && dirty) {
      setFormIsTouched(true);
    }
  }, [formIsTouched, dirty]);
  return (
    <div className="edit-event-from-body">
      <div className="edit-event-form-body-new-line-row-start">
        <div className="edit-event-form-body-single-picker">
          <label
            htmlFor={PeakDayFormFieldName.start}
            className="edit-event-form-body-label-required"
          >
            Start
          </label>
          <div>
            <Field
              id={PeakDayFormFieldName.start}
              name={PeakDayFormFieldName.start}
              as={(
                formProps: FieldInputProps<PeakDayFormFieldValues['start']>
              ) => (
                <DatePickerHandler
                  {...formikProps}
                  {...formProps}
                  busyDays={busyDays}
                  type={PeakDayFormFieldName.start}
                />
              )}
            />
          </div>
          {errors.start ? (
            <div className="edit-event-form-body-error-message">
              {errors.start}
            </div>
          ) : null}
        </div>
        <div>
          <label
            htmlFor={PeakDayFormFieldName.end}
            className="edit-event-form-body-label-required"
          >
            End
          </label>
          <div>
            <Field
              id={PeakDayFormFieldName.end}
              name={PeakDayFormFieldName.end}
              as={(
                formProps: FieldInputProps<PeakDayFormFieldValues['end']>
              ) => (
                <DatePickerHandler
                  {...formikProps}
                  {...formProps}
                  busyDays={busyDays}
                  type={PeakDayFormFieldName.end}
                />
              )}
            />
          </div>
          {errors.end && touched.end ? (
            <div className="edit-event-form-body-error-message">
              {errors.end}
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="edit-event-form-body-new-line-row-start"
        style={{ marginTop: '30px' }}
      >
        <label htmlFor={PeakDayFormFieldName.autoAdvertising}>
          <Field
            id={PeakDayFormFieldName.autoAdvertising}
            name={PeakDayFormFieldName.autoAdvertising}
            as={(
              formProps: FieldInputProps<
                PeakDayFormFieldValues['autoAdvertising']
              >
            ) => (
              <Checkbox
                {...formProps}
                checked={formProps.value}
                disabled={disabled || crewChange}
              >
                Allow Auto Advertising
              </Checkbox>
            )}
          />
        </label>
        <label htmlFor={PeakDayFormFieldName.crewChange}>
          <Field
            id={PeakDayFormFieldName.crewChange}
            name={PeakDayFormFieldName.crewChange}
            as={(
              formProps: FieldInputProps<PeakDayFormFieldValues['crewChange']>
            ) => (
              <Checkbox
                {...formProps}
                checked={formProps.value}
                disabled={disabled || autoAdvertising}
              >
                Crew Change
              </Checkbox>
            )}
          />
        </label>
      </div>
      <div className="edit-event-form-body-new-line">
        <label
          htmlFor={PeakDayFormFieldName.regions}
          className="edit-event-form-body-label-required"
        >
          Region
        </label>
        <Field
          id={PeakDayFormFieldName.regions}
          name={PeakDayFormFieldName.regions}
          as={(
            fieldProps: FieldInputProps<PeakDayFormFieldValues['regions']>
          ) => (
            <SelectComponent
              {...fieldProps}
              {...formikProps}
              options={PEAK_DAY_REGIONS}
            />
          )}
        />
      </div>
      <div className="edit-event-form-body-new-line">
        <label
          htmlFor={PeakDayFormFieldName.types}
          className="edit-event-form-body-label-required"
        >
          Type
        </label>
        <Field
          id={PeakDayFormFieldName.types}
          name={PeakDayFormFieldName.types}
          as={(
            fieldProps: FieldInputProps<PeakDayFormFieldValues['types']>
          ) => (
            <SelectComponent
              {...fieldProps}
              {...formikProps}
              options={PEAK_DAY_TYPES}
            />
          )}
        />
      </div>
      <div
        className="edit-event-form-body-new-line"
        style={{ marginTop: '30px' }}
      >
        <label
          htmlFor={PeakDayFormFieldName.description}
          className="edit-event-form-body-label-required"
        >
          Description
        </label>
        <div>
          <TextArea
            autoSize={{
              minRows: 8,
              maxRows: 14,
            }}
            maxLength={MAX_CHARACTERS}
            onChange={handleChange}
            disabled={disabled}
            id={PeakDayFormFieldName.description}
            value={description}
            name={PeakDayFormFieldName.description}
            style={{
              border:
                errors.description && touched.description
                  ? '1px solid red'
                  : '',
            }}
            className="edit-event-form-body-input"
          />
        </div>
        <div className="edit-event-form-body-input-char-count">
          {MAX_CHARACTERS - description.length} characters left
        </div>
        {errors.description && touched.description ? (
          <div className="edit-event-form-body-error-message">
            {errors.description}
          </div>
        ) : null}
      </div>
    </div>
  );
};

import { PureComponent } from 'react';

interface Props {
  color: string;
  opacity: number;
}

export class DurationArrowSmall extends PureComponent<Props> {
  render() {
    const { color, opacity } = this.props;
    return (
      <svg
        width="51"
        height="16"
        viewBox="0 0 51 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Rectangle"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 0H41.619L51 8.00006L41.619 16H0L9.38095 8.00006L0 0Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}

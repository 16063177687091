import * as d3S from 'd3-selection';
import { store } from '../../../../../root';
import * as actions from '../../../../../actions';
import { EventGroup } from '../event-group';
import { ExtendedEventElement } from '..';
import { FlightExtended } from '../../../../../types/flight';
import parts from './parts';
import { SegmentType } from '../../../../../types/segment-types';
import * as d3Scale from 'd3-scale';
import { AircraftTogglerState } from '../../../../../reducers/aircraft';
import { getInitialScale } from '../../../../../reducers/ui';
import {
  FLIGHT_FIRST_LINE_HEIGHT,
  FLIGHT_SECOND_LINE_HEIGHT,
  FLIGHT_THIRD_LINE_HEIGHT,
} from '../../../../../constants';

export interface SegmentsAppenderArgs {
  selection: d3S.Selection<
    d3S.BaseType,
    FlightExtended & ExtendedEventElement,
    SVGGElement,
    {}
  >;
  elementName?: SegmentType;
  segmentsVisibility?: { [st in SegmentType]: boolean };
  positionMap?: { [key in SegmentType]: number };
  togglersState?: { [aircraftId: number]: AircraftTogglerState };
  nowTime?: number;
  scaleX?: d3Scale.ScaleTime<number, number>;
  firstLineHeight?: number;
  secondLineHeight?: number;
  thirdLineHeight?: number;
}

export class FlightEventGroup extends EventGroup {
  calculateWidthFor = (start, end, scaleX) => {
    return Math.max(scaleX(end) - scaleX(start), 0);
  };
  segmentsAppender = (
    selection: d3S.Selection<
      d3S.BaseType,
      FlightExtended & ExtendedEventElement,
      SVGGElement,
      {}
    >,
    elementName: SegmentType
  ) => {
    const firstLineHeight = FLIGHT_FIRST_LINE_HEIGHT;
    const secondLineHeight = FLIGHT_SECOND_LINE_HEIGHT;
    const thirdLineHeight = FLIGHT_THIRD_LINE_HEIGHT;
    const state = store.getState();
    const { time, ui } = state;
    const { segmentsVisibility, positionMap } = ui;
    const { togglersState } = state.aircraft;
    const nowTime = time.now;
    const scaleX = getInitialScale(ui.width - ui.planeBlockWidth);
    const argsObject: SegmentsAppenderArgs = {
      selection,
      elementName,
      segmentsVisibility,
      positionMap,
      togglersState,
      nowTime,
      scaleX,
      firstLineHeight,
      secondLineHeight,
      thirdLineHeight,
    };
    return func => func.call(this, argsObject);
  };
  renderEntered(
    entered: d3S.Selection<
      d3S.EnterElement,
      ExtendedEventElement,
      SVGGElement,
      {}
    >
  ) {
    const flightsEntered = entered as d3S.Selection<
      d3S.EnterElement,
      FlightExtended & ExtendedEventElement,
      SVGGElement,
      {}
    >;
    let flightGr = flightsEntered
      .append('g')
      .classed('event', true)
      .on('mouseover', function(d: FlightExtended) {
        const event = d3S.event as MouseEvent;
        if (event.shiftKey) return;
        store.dispatch(actions.userHoverFlight([d, this]));
      })
      .on('click', function(d: FlightExtended) {
        store.dispatch(actions.userOpenFlightMenu([this, d]));
      })
      .on('mouseout', function(d: FlightExtended) {
        store.dispatch(actions.userCloseTooltip());
      });
    const appender = this.segmentsAppender(flightGr, 'flights');
    parts.forEach(part => appender(part));
    return flightGr;
  }
}

import { PureComponent } from 'react';
import { utc } from 'moment';
import './local-time-tooltip-style.scss';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import { ClockCircleOutlined } from '@ant-design/icons';
import Airport from '../../../types/airport';
import Aircraft from '../../../types/aircraft';
import { getAircraftById } from '../../../selectors';

import { setDataTestEntityId } from '../../../utils';
import { getTimezoneOffsetForAirportByIcao } from '../../../selectors/timezones';
import { getAirportByIcao } from '../../../selectors/airport';

interface StateProps {
  timezoneOffset: number;
  aircraft: Aircraft;
  nowTime: number;
  airport: Airport;
}
class LocalTimeTooltipContent extends PureComponent<StateProps> {
  render() {
    const { aircraft, airport, timezoneOffset, nowTime } = this.props;
    return (
      airport && (
        <div className="content">
          <div className="basic-info">
            <div
              className="icao"
              {...setDataTestEntityId('tooltip-aircraft-icao')}
            >
              {aircraft.location}
            </div>
            <div className="time-block">
              <span>
                <ClockCircleOutlined />
              </span>
              <span>
                {utc(nowTime)
                  .utcOffset(timezoneOffset)
                  .format('HH:mm') + 'LT'}
              </span>
              <span className="timezone-offset">{timezoneOffset / 60}h</span>
            </div>
          </div>
          <div className="text">{airport.name}</div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const aircraft = getAircraftById(state, state.ui.hoveredAircraftId);
  return {
    aircraft,
    airport: aircraft.location && getAirportByIcao(state, aircraft.location),
    nowTime: state.time.now,
    timezoneOffset:
      aircraft.location &&
      getTimezoneOffsetForAirportByIcao(state, aircraft.location),
  };
};

const LocalTimeTooltipContentConnected = connect(mapStateToProps)(
  LocalTimeTooltipContent
);

export default LocalTimeTooltipContentConnected;

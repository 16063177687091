import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Input, Tooltip } from 'antd';
import { debounce } from 'lodash';
import { PureComponent } from 'react';
import { connect, DispatchProp } from 'react-redux';

import * as actions from '../../actions';
import { RootState } from '../../reducers';
import { subMiddle } from '../../root';
import { getAllFoundAircraft, getFoundAircraft } from '../../selectors';
import { setDataTestEntityId } from '../../utils';
import { createDelayedForScrollConnect } from '../../utils/create-delayed-for-scroll-connect';
import { AnyAction } from 'redux';

interface StateProps {
  searchQuery: string;
  count: number;
  countAll: number;
  isFromDifferentModal: boolean;
}

interface State {
  queryStr: string;
  isActive: boolean;
  isForceUpdated: boolean;
}

class SearchByAircraft extends PureComponent<
  StateProps & DispatchProp<AnyAction>,
  State
> {
  ref: HTMLDivElement;
  constructor(props) {
    super(props);
    this.state = {
      queryStr: props.queryStr || '',
      isActive: false,
      isForceUpdated: false,
    };
    this.onChangeQueryRequest = debounce(this.onChangeQueryRequest, 400);
  }
  static getDerivedStateFromProps = (props: StateProps) => {
    if (props.isFromDifferentModal) {
      return SearchByAircraft.getForceUpdate(props);
    }
    return null;
  };
  static getForceUpdate = (props: StateProps): State => {
    return {
      queryStr: props.searchQuery,
      isActive: true,
      isForceUpdated: true,
    };
  };
  setActiveToFalse = () => {
    this.setState(componentState => {
      return {
        ...componentState,
        isActive: false,
      };
    });
    (this.ref.children[0] as HTMLInputElement).blur();
  };
  setBlurInput = () => {
    if (this.props.count < 3 && this.ref.children[0]) {
      this.setActiveToFalse();
    }
  };
  setBlurInputOnMouseScroll = action => {
    const { meta } = action;
    if (
      meta &&
      !meta.isNotScolling &&
      this.state.isActive &&
      this.props.count < 3 &&
      this.ref.children[0]
    ) {
      this.setActiveToFalse();
    }
  };
  setRefInput = (ref: HTMLDivElement) => {
    this.ref = ref;
    subMiddle.on(actions.userScrollScrollbar, this.setBlurInput);
    subMiddle.on(actions.userZoomVer, this.setBlurInputOnMouseScroll);
  };
  onChangeQueryRequest = (queryString: string) => {
    this.props.dispatch(
      actions.userChangeSearchACQuery({
        query: queryString,
        isFromNearestAC: false,
        isFromHandover: false,
      })
    );
  };
  changeQuery = evt => {
    if (evt.type === 'focus' && !this.state.queryStr) return;
    this.setState({
      queryStr: evt.target.value,
      isActive: true,
      isForceUpdated: false,
    });
    this.onChangeQueryRequest(evt.target.value);
  };
  onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value && this.state.isActive) return;
    this.onChangeQueryRequest('');
    this.setState({
      isActive: false,
      isForceUpdated: false,
    });
  };
  componentDidUpdate() {
    if (this.state.isForceUpdated) {
      (this.ref.children[0] as HTMLInputElement).focus();
    }
  }
  componentWillUnmount() {
    subMiddle.off(actions.userScrollScrollbar, this.setBlurInput);
    subMiddle.off(actions.userZoomVer, this.setBlurInputOnMouseScroll);
  }
  render() {
    const { countAll, count, searchQuery } = this.props;
    const { queryStr } = this.state;
    const visibleTooltip = searchQuery.length > 1 && !count;
    return (
      <>
        <Tooltip
          placement="bottomRight"
          title={
            <div>
              <ExclamationCircleTwoTone twoToneColor="gold" />
              {countAll > 0
                ? ' Found tail is hidden by your filter settings'
                : ' No result found'}
            </div>
          }
          open={visibleTooltip}
        >
          <div ref={this.setRefInput}>
            <Input
              id="go-to-tail-input"
              size="small"
              placeholder="Go to tail"
              onChange={this.changeQuery}
              value={queryStr}
              onBlur={this.onBlur}
              onFocus={this.changeQuery}
              {...setDataTestEntityId('go-to-tail-input')}
            />
          </div>
        </Tooltip>
      </>
    );
  }
}

export const SearchByAircraftConnected = connect(
  createDelayedForScrollConnect(
    (state: RootState): StateProps => ({
      searchQuery: state.aircraft.acQuery,
      count: getFoundAircraft(state).length,
      countAll: getAllFoundAircraft(state).length,
      isFromDifferentModal:
        state.nearestAircraft.isTailSearch ||
        state.handoverRemarks.isTailSearch,
    })
  )
)(SearchByAircraft);

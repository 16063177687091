const shallowEqual = require('shallowequal');

// TODO GT-5901: type this function and refactor it to hook
export function createDelayedForScrollConnect(mapStateToProps) {
  return function(dispatch) {
    let result;
    return (nextState, nextOwnProps) => {
      if (!result) {
        result = {
          ...mapStateToProps(nextState, nextOwnProps),
          dispatch,
        };
        return result;
      }
      if (
        nextState.ui.isScrollingVertically ||
        nextState.ui.isScrollingHorizontally
      ) {
        return result;
      }
      const nextResult = {
        ...mapStateToProps(nextState, nextOwnProps),
        dispatch,
      };
      if (!shallowEqual(result, nextResult)) {
        result = nextResult;
      }
      return result;
    };
  };
}

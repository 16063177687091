import { CrewDuty } from '../../types/crew-roster';

export interface CrewRosterGVModel {
  crewRosterId: {
    key: number;
  };
  aircraftId: {
    key: number;
  };
  crewMemberCode: string;
  crewRole: {
    crewRoleId: {
      key: number;
    };
    name: string;
    customerFacingName: string;
    rank: number;
    abbreviation: string;
  };
  person: {
    personId: {
      key: number;
    };
    firstName: string;
    lastName: string;
    active: boolean;
  };
  dutyDate: number;
}

export const transformToFE = (c: CrewRosterGVModel): CrewDuty => {
  try {
    return {
      id: c.crewRosterId.key,
      start: c.dutyDate,
      end: c.dutyDate + 24 * 60 * 60 * 1000,
      aircraftId: c.aircraftId.key,
      crewMemberCode: c.crewMemberCode,
      dutyDate: c.dutyDate,
      person: {
        id: c.person.personId.key,
        firstName: c.person.firstName,
        lastName: c.person.lastName,
      },
      role: {
        id: c.crewRole.crewRoleId.key,
        name: c.crewRole.name,
        customerFacingName: c.crewRole.customerFacingName,
        rank: c.crewRole.rank,
        abbreviation: c.crewRole.abbreviation,
      },
    };
  } catch (error) {
    console.error(error, c);
  }
};

import { axiosAuthed as axios } from '../axios-config';
import { transformToFE } from './empty-leg-offer-model';

export const getEmptyLegOffers = (startTime: number, endTime: number) =>
  axios
    .get(
      `/integration/timeline/empty-legs?startTime=${startTime}&endTime=${endTime}`
    )
    .then(resp => resp.data.map(transformToFE))
    .catch(e => {
      throw e;
    });

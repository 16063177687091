import { Button } from 'antd';
import { FC } from 'react';

export const ConfirmationFooter: FC<{
  disabledSave?: boolean;
  formId: string;
  handleClose: () => void;
  loading?: boolean;
  saveTitle?: string;
  cancelTitle?: string;
}> = ({
  formId,
  handleClose,
  disabledSave,
  loading,
  saveTitle = 'Save',
  cancelTitle = 'Cancel',
}) => (
  <div className="edit-event-form-footer">
    <Button
      htmlType="reset"
      key="reset"
      onClick={handleClose}
      form={formId}
      className="edit-event-form-footer-button-cancel"
    >
      {cancelTitle}
    </Button>
    <Button
      type="primary"
      htmlType="submit"
      key="save"
      form={formId}
      className="edit-event-form-footer-button-submit"
      loading={loading}
      disabled={disabledSave}
    >
      {saveTitle}
    </Button>
  </div>
);

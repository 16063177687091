import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from '../actions';
import PeakDay, { PeakDaysSelectFilterOptionValue } from '../types/peak-day';
import { reduceListByBatchUpdate } from '../data-processing/pool-structure';
import { GENERATE_NEW_ID } from '../constants';
import { message } from 'antd';

export interface PeakDaysState {
  peakDaysData: PeakDay[];
  isEventListModalVisible: boolean;
  isSingleEventModalVisible: boolean;
  contextMenu: {
    isOpen: boolean;
    position: {
      x: number;
      y: number;
    };
  };
  eventId: number | null;
  loading: boolean;
  filters: {
    from: number | null;
    to: number | null;
    advertising: PeakDaysSelectFilterOptionValue[];
    crew: PeakDaysSelectFilterOptionValue[];
  };
}

export const initialState: PeakDaysState = {
  peakDaysData: [],
  isEventListModalVisible: false,
  eventId: null,
  isSingleEventModalVisible: false,
  loading: false,
  contextMenu: {
    isOpen: false,
    position: {
      x: 0,
      y: 0,
    },
  },
  filters: {
    from: null,
    to: null,
    advertising: [],
    crew: [],
  },
};

export default reducerWithInitialState(initialState)
  .case(actions.doGetPeakDays.done, (state, payload) => ({
    ...state,
    peakDaysData: payload.result || [],
  }))
  .case(actions.wsUpdateBatch, (state, { data }) => {
    if (!data.availabilityEvents) return state;
    const { availabilityEvents: peakDaysUpdateData } = data;
    const { peakDaysData: peakDaysData } = state;
    let otherState: Partial<PeakDaysState> = {};
    if (
      Object.keys(peakDaysUpdateData).length === 1 &&
      +Object.keys(peakDaysUpdateData)[0] === state.eventId &&
      state.loading
    ) {
      otherState = {
        eventId: null,
        loading: false,
        isSingleEventModalVisible: false,
      };
      message.success('Peak day is saved');
    }
    return {
      ...state,
      ...otherState,
      peakDaysData: reduceListByBatchUpdate(peakDaysData, peakDaysUpdateData),
    };
  })
  .case(actions.userTogglePeakDaysModal, state => ({
    ...state,
    isEventListModalVisible: !state.isEventListModalVisible,
    contextMenu: initialState.contextMenu,
    filters: initialState.filters,
  }))
  .case(actions.userClickCreatePeakDay, state => ({
    ...state,
    eventId: GENERATE_NEW_ID,
    isSingleEventModalVisible: true,
  }))
  .case(actions.userClickEditPeakDay, (state, payload) => ({
    ...state,
    eventId: payload,
    isSingleEventModalVisible: true,
    contextMenu: initialState.contextMenu,
  }))
  .case(actions.userCloseSinglePeakDayModal, state => ({
    ...state,
    eventId: null,
    isSingleEventModalVisible: false,
  }))
  .case(actions.userOpenContextMenuForPeakDay, (state, payload) => ({
    ...state,
    contextMenu: {
      isOpen: true,
      position: payload.position,
    },
    eventId: payload.id,
  }))
  .cases(
    [actions.userCloseContextMenu, actions.userTimelineSelectionBegin],
    state => ({
      ...state,
      contextMenu: initialState.contextMenu,
    })
  )
  .cases(
    [actions.userDeletePeakDay.started, actions.userSavePeakDay.started],
    state => ({
      ...state,
      loading: true,
      contextMenu: initialState.contextMenu,
    })
  )
  .cases(
    [actions.userDeletePeakDay.failed, actions.userSavePeakDay.failed],
    state => ({
      ...state,
      loading: false,
    })
  )
  .cases(
    [actions.userDeletePeakDay.done, actions.userSavePeakDay.done],
    state => ({
      ...state,
      loading: false,
      eventId: null,
      isSingleEventModalVisible: false,
    })
  )
  .cases(
    [
      actions.userFocusOverlapCards,
      actions.userOpenNoteContextMenu,
      actions.userOpenFlightMenu,
      actions.userOpenCreateEventsMenu,
      actions.userOpenContextMenuForEmptyLegOffer,
      actions.userOpenContextMenuForMxEvent,
      actions.userOpenContextMenuForOneWayOffer,
    ] as any[],
    state => ({
      ...state,
      contextMenu: initialState.contextMenu,
    })
  )
  .cases(
    [actions.userChangeSelectFilter, actions.userChangePeakDaysRangeFilter],
    (state, payload) => {
      const { type, value } = payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          [type]: value,
        },
      };
    }
  )
  .case(actions.userResetPeakDaysFilters, state => ({
    ...state,
    filters: initialState.filters,
  }));

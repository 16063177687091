import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import { store as reduxStore } from '../../../../../root';
import * as d3S from 'd3-selection';
import * as actions from '../../../../../actions';

export const getOverlappedMainenancesD3creator = (
  d: d3S.Selection<any, any, any, any>
): d3S.Selection<any, any, any, any> => {
  const state = reduxStore.getState();
  const { segmentsVisibility, positionMap } = state.ui;
  const { togglersState } = state.aircraft;
  const container = d
    .append('div')
    .style(
      'transform',
      ovEl =>
        `translate(${ovEl.x}px, ${ovEl.y +
          ovEl.ky *
            getElementOffsetWithKoef(
              segmentsVisibility,
              'maintenances',
              togglersState[ovEl.aircraftId],
              positionMap
            )}px)`
    )
    .classed('label-text', true)
    .style('width', d => `${d.width}px`)
    .style(
      'height',
      d =>
        `${(positionMap.maintenances * d.ky) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )}px`
    )
    .on('mouseover', function(d) {
      reduxStore.dispatch(actions.userHoverOverlap([d, this]));
    })
    .on('mouseout', () => reduxStore.dispatch(actions.userUnhoverOvlElement()));
  return container;
};

import { Spin } from 'antd';
import { isEqual } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  HandoverRemark,
  HandoverType,
} from '../../../../types/handover-remarks';
import { transformRemarkForSave } from '../../utils';
import { HandoverCardEditor } from './HandoverCardEditor';
import { HandoverCardView } from './HandoverCardView';
import {
  doResetPushedId,
  userDeleteHandoverRemark,
  userSaveHandoverRemark,
} from '../../../../actions';
import { RootState } from '../../../../reducers';
import { RadioChangeEvent } from 'antd/lib';
import { hasPermission } from '../../../../utils/check-permissions';

export const EditableHandoverCard: FC<{
  card: HandoverRemark;
  editAllowed: boolean;
  editingIds: string[];
  newRemarkIds: string[];
  userName: string;
  updatedIds: string[];
  onCancelUpdate: (id: string) => void;
  onUpdateConfirm: (id: string) => void;
  setEditIds: (id: string, keepShowing: boolean) => void;
  setNewRemarkIds: (id: string[]) => void;
  setCard: (card: HandoverRemark) => void;
}> = ({
  card,
  editAllowed,
  editingIds,
  newRemarkIds,
  userName,
  updatedIds,
  onCancelUpdate,
  onUpdateConfirm,
  setCard,
  setEditIds,
  setNewRemarkIds,
}) => {
  const [handoverType, setHandoverType] = useState<HandoverType | null>(
    undefined
  );
  const dispatch = useDispatch();
  const { deletingRemarkIds, savingRemarkIds } = useSelector<
    RootState,
    { deletingRemarkIds: string[]; savingRemarkIds: string[] }
  >(
    state => ({
      deletingRemarkIds: state.handoverRemarks.deletingIds,
      savingRemarkIds: state.handoverRemarks.savingIds,
    }),
    isEqual
  );
  const { id, remarks, type, aircraftId } = card;
  const isNew = newRemarkIds.includes(id);
  useEffect(() => {
    if (!aircraftId && typeof type === 'undefined' && !isNew) {
      setCard({
        ...card,
        type: HandoverType.ops,
      });
    }
    if (type) {
      setHandoverType(type);
    }
  }, [type]);
  const isEditMode = editingIds.includes(id);
  const wasUpdated = updatedIds.includes(id);
  const onCancel = () => {
    setEditIds(id, false);
    setNewRemarkIds(newRemarkIds.filter(r => r !== id));
  };
  const onSave = (text: HandoverRemark['remarks']) => {
    const remark = transformRemarkForSave(
      {
        ...card,
        remarks: text,
        type: handoverType,
      },
      userName,
      !newRemarkIds.includes(card.id)
    );
    dispatch(
      userSaveHandoverRemark.started({
        remark,
        keepOpened: true,
        isNew,
      })
    );
    setEditIds(id, true);
    setCard(remark);
    setNewRemarkIds(newRemarkIds.filter(r => r !== id));
  };
  // needs onBlur to allow Submit from confirmation popup
  const onSetCard = (text: HandoverRemark['remarks']) => {
    setCard({
      ...card,
      remarks: text,
      type: handoverType,
    });
  };
  const setEditMode = () => {
    setEditIds(id, true);
    dispatch(doResetPushedId(id));
  };
  const onDelete = () => dispatch(userDeleteHandoverRemark.started(id));
  const onChangeHandoverType = (e: RadioChangeEvent) =>
    setHandoverType(e.target.value);
  const deleting = deletingRemarkIds.includes(id);
  const saving = savingRemarkIds.includes(id);
  const hasOpsEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Handover-Ops_Edit')
  );
  const hasCsEditPermission = useSelector<RootState, boolean>(state =>
    hasPermission(state, 'AG-Timeline-Handover-CS_Edit')
  );
  const hasFullEditPermission = hasCsEditPermission && hasOpsEditPermission;
  return isEditMode ? (
    <HandoverCardEditor
      description={remarks}
      disabledHandoverType={
        hasFullEditPermission &&
        !aircraftId && // is not a tail remark
        typeof type !== 'undefined' && // and type was previously defined
        !newRemarkIds.includes(id) // and it is not a new remark
      }
      id={id}
      handoverType={handoverType}
      onCancel={onCancel}
      onCancelUpdate={onCancelUpdate}
      onChangeHandoverType={onChangeHandoverType}
      onSave={onSave}
      onUpdateConfirm={onUpdateConfirm}
      setCardText={onSetCard}
      wasUpdated={wasUpdated}
      aircraftId={aircraftId}
    />
  ) : (
    <>
      <HandoverCardView
        editAllowed={editAllowed}
        remark={card}
        setEditMode={setEditMode}
        onDelete={onDelete}
      />
      <Spin className="handover-loading" spinning={saving || deleting} />
    </>
  );
};

import { PureComponent } from 'react';

interface StateProps {
  width?: number;
  height?: number;
  strokeWidth?: number;
}
export default class NoteIcon extends PureComponent<StateProps> {
  render() {
    const { width = 12, height = 12, strokeWidth = 1 } = this.props;
    return (
      <svg
        width={`${width}px`}
        height={`${height}px`}
        viewBox={`0 0 ${width} ${height}`}
        className="aircraft-toggle-icon"
        version="1.1"
      >
        <g stroke="none" strokeWidth={strokeWidth} fillRule="evenodd">
          <g transform="translate(-4.000000, -316.000000)">
            <g transform="translate(2.000000, 294.000000)">
              <g transform="translate(0.000000, 20.000000)">
                <g
                  strokeWidth={strokeWidth}
                  transform="translate(2.000000, 2.000000)"
                >
                  <path
                    className="icon-path"
                    d="M7.33333333,4.66666667 L7.33333333,1 L11,4.66666667 L7.33333333,4.66666667 Z M1.33333333,0 C0.593333333,0 0,0.593333333 0,1.33333333 L0,10.6666667 C0,11.4030463 0.596953667,12 1.33333333,12 L10.6666667,12 C11.4030463,12 12,11.4030463 12,10.6666667 L12,4 L8,0 L1.33333333,0 Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

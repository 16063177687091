import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider as StoreProvider } from 'react-redux';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import rootReducer, { RootState } from './reducers';
import { SubMiddle } from './data-processing/subscribe-middleware';
import * as actions from './actions';
import createSagaMiddleware from 'redux-saga';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import { raygunMiddleware } from './utils/raygun-middleware';
import rootSaga from './sagas';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { HelmetComponent } from './HelmetComponent';
import { ConfigProvider } from 'antd';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './data-processing/graphql';

export const subMiddle = new SubMiddle<RootState>();
const composeEnhancers =
  process.env.NODE_ENV !== 'production'
    ? composeWithDevTools({
        actionsBlacklist: [actions.userHoverAircraft.type],
      })
    : f => f;
const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      subMiddle.middleware,
      loadingBarMiddleware({
        promiseTypeSuffixes: ['STARTED', 'DONE', 'FAILED'],
      }),
      raygunMiddleware,
      store => next => action => {
        if (action) {
          console.debug(
            action.type,
            (action as any).payload ? (action as any).payload : '',
            (action as any).meta ? (action as any).meta : ''
          );
          return next(action);
        }
        console.error('action is invalid', action);
      }
    )
  )
);
// Very important set global variable `store`!
window.store = store;

sagaMiddleware.run(rootSaga);

const root = () => (
  <>
    <BrowserRouter>
      <StoreProvider store={store} stabilityCheck="always">
        <ConfigProvider
          theme={{
            token: {
              borderRadius: 4,
              colorText: 'rgba(0,0,0,0.65)',
              colorTextLabel: 'rgba(0,0,0,0.65)',
              colorTextDisabled: 'rgba(0,0,0,0.65)',
              controlItemBgHover: 'rgba(5, 145, 255, 0.1)',
              fontFamily: `'Nunito Sans', sans-serif`,
              lineHeight: 1.5,
            },
            components: {
              Menu: {
                colorSplit: 'transparent',
                itemHoverBg: 'rgba(5, 145, 255, 0.1)',
              },
            },
          }}
        >
          <ApolloProvider client={apolloClient}>
            <App />
          </ApolloProvider>
        </ConfigProvider>
      </StoreProvider>
    </BrowserRouter>
    <HelmetComponent />
  </>
);

export default root;

import { capitalize } from 'lodash';
import { PureComponent } from 'react';
import { CENTRED_DOT_SYMBOL_BOLD } from '../../constants';
import Airport from '../../types/airport';

interface Props {
  departureAirport: Airport;
  arrivalAirport: Airport;
}

class CountryRow extends PureComponent<Props> {
  render() {
    const { arrivalAirport, departureAirport } = this.props;
    return (
      <div className="ft-country-row">
        {departureAirport && departureAirport.place && (
          <span>
            {`${capitalize(
              departureAirport.place.country || ''
            )} ${CENTRED_DOT_SYMBOL_BOLD}
                ${capitalize(departureAirport.place.city || '')}`}
          </span>
        )}
        {arrivalAirport && arrivalAirport.place && (
          <span>
            {`${capitalize(
              arrivalAirport.place.city || ''
            )} ${CENTRED_DOT_SYMBOL_BOLD}
              ${capitalize(arrivalAirport.place.country || '')}`}
          </span>
        )}
      </div>
    );
  }
}

export default CountryRow;

import { store as reduxStore } from '../../../../../root';
import * as d3S from 'd3-selection';
import * as actions from '../../../../../actions';
import Note, { MergedNote } from '../../../../../types/note';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import {
  getNoteBoundingElement,
  getEuclideanDistance,
  setDataTestEntityIdD3Elements,
} from '../../../../../utils';
import { ExtendedEventElement } from '../..';
import { buildPropsForComponent, getNoteFromMergedNotesWidth } from '../utils';
import { hasPermission } from '../../../../../utils/check-permissions';
import { MIN_RANGE_SELECTION } from '../../../../../constants';
import { isMergeNote } from '../../../../../utils/note';

export const getMergedAvailabilityNotesD3creator = d => {
  const state = reduxStore.getState();
  const hasViewPermission = hasPermission(state, 'AG-Timeline-View-Note');
  const hasEditPermission = hasPermission(state, 'AG-Timeline-Create-Note');
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { togglersState } = state.aircraft;

  const container = d
    .append('div')
    .classed('label-text', true)
    .style('transform', d => `translate(${d.x}px, ${d.y}px)`)
    .each(function(d: Note | MergedNote) {
      const container = this;
      if (isMergeNote(d)) {
        d.notes.forEach(function(note, i) {
          const message = note.message;
          const id = note.id;
          const parentNote = d as MergedNote & ExtendedEventElement;
          const noteWithProps = buildPropsForComponent(note);
          const widthOfNotOverlappedArea = getNoteFromMergedNotesWidth(
            note,
            d.notes[i + 1]
          );
          d3S
            .select(container)
            .append('div')
            .style('width', `${noteWithProps.width}px`)
            .attr('data-test-entity-id', note.id)
            .classed('notes-text-label', true)
            .attr(
              'data-test-entity',
              setDataTestEntityIdD3Elements('notes-text-label')
            )
            .style(
              'margin-left',
              `${
                parentNote.x - noteWithProps.x > noteWithProps.width
                  ? 0
                  : Math.max(noteWithProps.x - parentNote.x, 0)
              }px`
            )
            .style('position', 'absolute')
            .style('top', `0px`)
            .style('cursor', 'pointer')
            .style(
              'height',
              (d: Note) =>
                `${(positionMap.availabilityNotes * transform.ky) /
                  getLaneHeightKoef(
                    segmentsVisibility,
                    togglersState[d.aircraftId],
                    positionMap
                  )}px`
            )
            .on('mouseover', function(n: MergedNote) {
              reduxStore.dispatch(
                actions.userHoverMergedNotes([
                  d,
                  getNoteBoundingElement(this),
                  'availabilityNotes',
                  note,
                ])
              );
              d3S
                .select(this)
                .raise()
                .select('span')
                .style('width', `${noteWithProps.width}px`)
                .style('background', '#fdd835');
            })
            .on('mouseout', function(d: MergedNote) {
              reduxStore.dispatch(actions.userUnhoverOvlElement());
              d3S
                .select(this)
                .lower()
                .select('span')
                .style('width', `${widthOfNotOverlappedArea}px`)
                .style('background', 'transparent');
            })
            .on(
              'click',
              () =>
                hasViewPermission &&
                getEuclideanDistance(
                  reduxStore.getState().ui.timelineSelection
                ) < MIN_RANGE_SELECTION &&
                reduxStore.dispatch(
                  actions.userClickOpenNote({
                    id,
                    end: note.end,
                    start: note.start,
                  })
                )
            )
            .append('span')
            .style(
              'margin-left',
              `${noteWithProps.x < 0 ? -noteWithProps.x : 0}px`
            )
            .style('display', 'inline-block')
            .style('width', `${widthOfNotOverlappedArea}px`)
            .classed('notes-text-label', true)
            .attr(
              'data-test-entity',
              setDataTestEntityIdD3Elements('notes-text-label')
            )
            .text(message);
        });
      } else {
        d3S
          .select(container)
          .text((d: ExtendedEventElement) => d.message)
          .classed('notes-text-label', true)
          .attr(
            'data-test-entity',
            setDataTestEntityIdD3Elements('notes-text-label')
          )
          .style(
            'width',
            (d: ExtendedEventElement) =>
              `${d.x < 0 ? Math.max(d.width + d.x, 0) : d.width}px`
          )
          .style(
            'margin-left',
            (d: ExtendedEventElement) =>
              `${-d.x > d.width ? 0 : Math.max(-d.x, 0)}px`
          )
          .style(
            'top',
            (el: ExtendedEventElement) =>
              `${getElementOffsetWithKoef(
                segmentsVisibility,
                'availabilityNotes',
                togglersState[el.aircraftId],
                positionMap
              ) * transform.ky}px`
          )
          .style(
            'height',
            (d: ExtendedEventElement) =>
              `${(positionMap.availabilityNotes * transform.ky) /
                getLaneHeightKoef(
                  segmentsVisibility,
                  togglersState[d.aircraftId],
                  positionMap
                )}px`
          )
          .attr('data-test-entity-id', (d: ExtendedEventElement) => d.id)
          .on('mouseover', function(d: Note) {
            d3S.select(this).style('cursor', 'pointer');
            reduxStore.dispatch(
              actions.userHoverSegment([
                d,
                getNoteBoundingElement(this as HTMLDivElement),
                'availabilityNotes',
              ])
            );
          })
          .on('mouseout', function(d: Note | MergedNote) {
            reduxStore.dispatch(actions.userCloseTooltipSegment());
          })
          .on(
            'click',
            (d: Note) =>
              hasViewPermission &&
              getEuclideanDistance(reduxStore.getState().ui.timelineSelection) <
                MIN_RANGE_SELECTION &&
              reduxStore.dispatch(
                actions.userClickOpenNote({
                  id: d.id,
                  end: d.end,
                  start: d.start,
                })
              )
          )
          .on('contextmenu', function(d: Note) {
            d3S.event.preventDefault();
            d3S.event.stopPropagation();
            hasEditPermission &&
              reduxStore.dispatch(
                actions.userOpenNoteContextMenu([
                  getNoteBoundingElement(this),
                  d,
                ])
              );
          });
      }
    });
  return container;
};

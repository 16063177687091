import { PureComponent } from 'react';
import { AfbCrewType } from '../../types/afbCrewType';
import { AFB_COLORS } from '../../types/flight';

interface Props {
  crewType: AfbCrewType[];
}

class CrewGroup extends PureComponent<Props> {
  render() {
    const { crewType } = this.props;
    return (
      crewType &&
      crewType.length > 0 && (
        <>
          <div className="ft-line" />
          <div className="ft-box-group">
            {crewType.map((c, i: number) => (
              <div
                className={crewType.length < 4 ? 'ft-box' : 'ft-box-thin'}
                key={c.crewCode ? `${c.crewCode}-${i}` : `${c.firstName}-${i}`}
              >
                <div className="ft-box-label">{c.roleAbbr}</div>
                {c.crewCode ? (
                  <div
                    className="ft-box-label-crew"
                    style={{ color: AFB_COLORS[c.type] }}
                  >
                    {crewType.some(c => !c.crewCode) && <br />}
                    {c.crewCode}
                  </div>
                ) : (
                  <div className="ft-box-label-crew">
                    {c.firstName}
                    <br />
                    {c.lastName}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="ft-line" />
        </>
      )
    );
  }
}

export default CrewGroup;

import EventElement from './event-element';

export enum MaintenanceType {
  AOG = 1,
  SCHEDULED,
  UNSCHEDULED,
}
export enum MaintenanceOrderType {
  CAMP_WORK_ODER = 2,
  SERVICE_ORDER,
  PURCHASE_ORDER,
}
export enum MaintenanceCategoryType {
  NORMAL,
  PROVISIONAL,
  MUST_DO,
}
export default interface Maintenance extends EventElement {
  reason: string;
  maintenanceType: MaintenanceType;
  maintenanceOrderType: MaintenanceOrderType;
  creator: {
    id: number;
    firstName: string;
    lastName: string;
  };
  lastUpdated: number;
  airportId: number;
  categoryId: MaintenanceCategoryType;
  isDispatchable: boolean;
  releasedBy: string;
  releasedTime: string;
  orderId: string;
  updatedBy: string;
  createdAt: number;
  companyName: string;
}
export function isMaintenance(el: EventElement): el is Maintenance {
  return !!(el as Maintenance).maintenanceType;
}

export const isServiceOrder = (m: Maintenance): boolean =>
  m.maintenanceOrderType === MaintenanceOrderType.SERVICE_ORDER;

export interface MXEventDtoFull {
  id: number;
  startTimeMs: number;
  endTimeMs: number;
  lastUpdatedMs?: number;
  aircraftId?: number;
  aircraftName?: string;
  orderNumber?: string;
  orderTypeId?: number;
  orderTypeName?: string;
  maintenanceTypeId: number;
  maintenanceTypeName: string;
  remarks?: string;
  reason?: string;
  airportId?: number;
  airportIcao?: string;
  serviceProviderId?: number;
  serviceProviderName?: string;
  serviceProviderTypeName?: string;
  inWarranty?: boolean;
  birdStrike?: boolean;
  insurance?: boolean;
  fod?: boolean;
  servicing?: boolean;
  mrt?: boolean;
  incident?: boolean;
  accident?: boolean;
  crewNotAvailable?: boolean;
  mocMsgSent?: boolean;
  dispatchable?: boolean;
  categoryId?: number;
  categoryName?: string;
  createdBy?: string;
  releasedBy?: string;
  releasedTimeMs?: number;
  active: boolean;
}

export interface MXEventOrderTypeDto {
  id: number;
  name: string;
}

export interface MXServiceProviderDto {
  id: number;
  name: string;
  typeName?: string;
}

export enum MaintenanceStatusTag {
  AOG = 'AOG',
  SMX = 'SMX',
  UMX = 'UMX',
  PAX = 'PAX',
  RTS = 'RTS',
}

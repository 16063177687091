import { utc } from 'moment';
import { GENERATE_NEW_ID, GV_INPUT_SOURCE_ID } from '../../constants';
import { MaintenanceType, MXEventDtoFull } from '../../types/maintenance';
import { ImportanceFactor } from '../../types/note';
import {
  MXEventDtoInput,
  NoteTimelineSegmentDtoInput,
} from '../../types/operation-result-types';
import { MxEventCategoryId, MxEventCategoryName } from './constants';
import { MxEventFormValues, NoteFormValues } from './interfaces';

export const mapMxCategoryToCategoryId = (
  mxCategory: MxEventCategoryName
): MxEventCategoryId => {
  switch (mxCategory) {
    case MxEventCategoryName.Normal: {
      return MxEventCategoryId.Normal;
    }
    case MxEventCategoryName.Provisional: {
      return MxEventCategoryId.Provisional;
    }
    case MxEventCategoryName.Must: {
      return MxEventCategoryId.Must;
    }
    default:
      return MxEventCategoryId.Normal;
  }
};

export const transformMxFromStateForSave = (
  formState: MxEventFormValues,
  isExistingEvent: boolean,
  restEventValues: MXEventDtoFull
): MXEventDtoInput => {
  const {
    id,
    aircraftId,
    airportId,
    startTimeMs,
    endTimeMs,
    categoryName,
    dispatchable,
    maintenanceTypeId,
    orderNumber,
    orderTypeId,
    reason,
    rtsSelected,
    serviceProviderId,
    notSplit,
    splitHours,
  } = formState;
  const mxEvent: MXEventDtoInput = {
    id,
    categoryId: mapMxCategoryToCategoryId(categoryName),
    dispatchable,
    maintenanceTypeId,
    endTimeMs: utc(endTimeMs).valueOf(),
    orderNumber,
    orderTypeId: !orderTypeId ? null : orderTypeId,
    reason,
    rtsSelected,
    startTimeMs: utc(startTimeMs).valueOf(),
    aircraftId,
    airportId,
    serviceProviderId,
    restrictAogSplit:
      maintenanceTypeId === MaintenanceType.AOG ? notSplit : null,
    customAogSplitHrs:
      maintenanceTypeId === MaintenanceType.AOG ? splitHours : null,
  };
  if (isExistingEvent) {
    const {
      accident,
      birdStrike,
      crewNotAvailable,
      fod,
      inWarranty,
      incident,
      insurance,
      mocMsgSent,
      mrt,
      remarks,
      servicing,
    } = restEventValues;
    return {
      ...mxEvent,
      accident,
      birdStrike,
      crewNotAvailable,
      fod,
      inWarranty,
      incident,
      insurance,
      mocMsgSent,
      mrt,
      remarks,
      servicing,
    };
  }
  return mxEvent;
};

// [GT-3718] crew not available checkbox is marked automatically when user fill text that contains '36' AND 'MUST DO'
export const getCrewNotAvailableStatus = (values: NoteFormValues) => {
  const { crewNotAvailable, importanceFactor, message } = values;
  if (importanceFactor === ImportanceFactor.GENERAL) return false;
  const messageToLowerCase = message.toLowerCase();
  const indexMust = messageToLowerCase.indexOf('must do', 0);
  const index36 = messageToLowerCase.indexOf('36', 0);
  if (!crewNotAvailable && indexMust !== -1 && index36 !== -1) return true;
  return crewNotAvailable;
};

export const transformNoteFormStateForSave = (
  values: NoteFormValues,
  userName: string,
  now: number
): NoteTimelineSegmentDtoInput => {
  const {
    aircraftId,
    fromDate,
    id,
    importanceFactor,
    message,
    toDate,
  } = values;
  const isCrewNotAvailable = getCrewNotAvailableStatus(values);

  return {
    crewNotAvailable: isCrewNotAvailable,
    id: id || GENERATE_NEW_ID,
    inputSource: {
      id: GV_INPUT_SOURCE_ID,
    },
    fromDate,
    aircraftId,
    importanceFactor,
    message,
    toDate,
    personName: userName,
    lastUpdated: now,
  };
};

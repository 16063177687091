import { Place } from './airport';
import FlightBase from './flight-base';
import { AfbSnapshot, Feeds } from '../services/flight-service/flight-model';

export enum LegBusinessType {
  AOG = 1,
  SCHEDULED_MAINTENANCE,
  UNSCHEDULED_MAINTENANCE,
  STAND_DAY,
  OVERNIGHT,
  LIVE_CHARGEABLE,
  LIVE_OPERATIONAL,
  FERRY_CHARGEABLE,
  FERRY_OPERATIONAL,
}

export enum LegBusinessStatus {
  QUOTED = 1,
  OPTION,
  CONFIRMED,
  EXPIRED,
  CHARGEABLE_CANCELLATION,
  NON_CHARGEABLE_CANCELLATION,
  UNCONFIRMED,
  INVOICE_CANCELLED,
  INVOICE_POSTED,
  INVOICE_REFUNDED,
  INVOICE_REVIEWED,
  INVOICE_RAISED,
  INVOICE_NOT_NEEDED,
}

export enum OrderBusinessTypeId {
  PROGRAM = 1,
  ON_DEMAND,
  PRIVATE,
  DEMO,
  MAINTENANCE,
  MANUFACTURER,
  JET_MEMBER,
  OWNER,
  AMBULANCE,
  MARKETING,
  LEASE,
  OTHER_MISC,
  OWNER_RELEASE,
  VJ_DIRECT,
  B2S,
  RETAIL,
  ELITE,
  WHOLESALE,
  SHUTTLE,
}

export enum HandlingStatus {
  NO_STATUS = 0,
  ERROR_HANDLING,
  AUTOMATIC_HANDLING,
  MANUAL_HANDLING,
  CONFIRMED_HANDLING,
}

export enum BpmItemStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export enum OperationalStatus {
  QUOTED = 1,
  OPTION,
  CONFIRMED,
  READY,
  FLYING,
  LANDED,
  COMPLETED,
  CANCELLED,
  UNCONFIRMED,
  NO_SHOW,
}

export enum FlightLabelTimeType {
  SCHEDULED = 'Scheduled',
  ESTIMATED = 'Estimated',
  NONE = 'None',
}

export enum FlightServiceStatus {
  NEW = 1,
  NOT_NEEDED,
  REQUESTED,
  CONFIRMED,
  INVALID,
  CANCELLED,
  NEEDS_REVIEW,
}

export enum PermissionSlotType {
  INBOUND_PERMISSION = 1,
  OUTBOUND_PERMISSION,
  OVERFLIGHT_PERMISSION,
  CANPASS,
  IAG,
  eAPIS,
  PMT_TO_PRC,
  CAICOM,
  USA_FSDO,
  US_CUSTOMS,
  CUSTOMS_CLEARENCE,
  API,
  DOT,
  PTP,
  TSA,
}

export enum FlightPlanType {
  AUTO = 'A',
  MANUAL = 'B',
}

export interface Slot {
  airportId: number;
  fromDate: number;
  toDate: number;
  permissionSlotTypeId: number;
  flightServiceStatusId: number;
  needsReview: boolean;
  requestManagementType: boolean;
  leadTime: number;
  flightLegId: number;
  slotNumber: string;
}

export interface Permit {
  airportId: number;
  fromDate: number;
  toDate: number;
  permissionSlotTypeId: number;
  flightServiceStatusId: number;
  needsReview: boolean;
  requestManagementType: boolean;
  number: string;
  place: Place;
  leadTime: number;
  flightLegId: number;
}

export interface Request {
  airportId: number;
  flightServiceStatusId: number;
  needsReview: boolean;
  requestManagementType: boolean;
  flightLegId: number;
  serviceTypeId: number;
  serviceProviderId: number;
}

export interface BookedBy {
  id: number;
  firstName: string;
  lastName: string;
}

export interface Amounts {
  currencyId: number;
  currencySymbol: string;
  orderAmountCents: number;
  flightLegAmountCents: number;
}

export default interface Flight extends FlightBase {
  bpmItemStatus: string;
  arrivalUtcScheduled: number;
  departureUtcScheduled: number;
  legBusinessTypeId: LegBusinessType;
  legBusinessStatusId: LegBusinessStatus;
  legOperationalStatusId: OperationalStatus;
  orderBusinessTypeId: OrderBusinessTypeId;
  sensitive: boolean;
  handlingStatus: HandlingStatus;
  leadName: string;
  passengerNames: string[];
  passengersCount: number;
  flightCrews: {
    roleAbbr: string;
    crewCode: string;
  }[];
  arrivalSlotMismatch: boolean;
  departureSlotMismatch: boolean;
  readyForPreparation: boolean;
  departureUtcBlock: number;
  arrivalUtcBlock: number;
  demoFlight: boolean;
  lineCheck: boolean;
  lockedFlight: boolean;
  remarks: string;
  opsMemo: string;
  baseCompanyId: number;
  requestedAircraftTypeName: string;
  callSignValue: string;
  slots: Slot[];
  permits: Permit[];
  requests: Request[];
  flightPlanId: number;
  flightPlanType: string;
  pfaFailed: boolean;
  fpFailed: boolean;
  bookedBy: BookedBy;
  amounts: Amounts;
  afbSnapshot: AfbSnapshot;
  afbReedFeedsByCrewCode: {
    [crewCode: string]: Feeds;
  };
  fufi: string;
  tripProfile: string;
  emptyLegOffer: {
    flightLegId: {
      key: number;
    };
    id: {
      key: number;
    };
    offerStatusId: {
      key: number;
    };
    wasAdvertised: boolean;
  };
  flightAdvertise: boolean;
  originalFlightLegId: number;
  csTeam: string;
  contractMsId: string;
}

export interface OrderTypeColor {
  contrastTextColor: string;
  primaryColor: string;
  backgroundColor: string;
}

export enum UpgradeReason {
  NO_UPGRADE_ID = 1,
  OPERATIONAL_UPGRADE_ID = 2,
  GUARANTEED_UPGRADE_ID = 3,
  COMPLIMENTARY_UPGRADE_ID = 4,
  CLIENT_REQUESTED_UPGRADE_ID = 5,
  OPERATIONAL_UPGRADE_CREW_AVAILABILITY_ID = 6,
  OPERATIONAL_UPGRADE_AIRCRAFT_AVAILABILITY_ID = 7,
  OPERATIONAL_UPGRADE_AICRAFT_PERFORMANCE_ID = 8,
  OPERATIONAL_UPGRADE_AOG_ID = 9,
  GUARANTEED_UPGRADE_COMMERCIAL_RELATED_ID = 10,
  GUARANTEED_UPGRADE_CUSTOMER_SERVICE_RELATED_ID = 11,
  COMPLIMENTARY_UPGRADE_COMMERCIAL_RELATED_ID = 12,
  COMPLIMENTARY_UPGRADE_CUSTOMER_SERVICE_RELATED_ID = 13,
}

type slaBorders = 'red' | '#f87b05' | 'none';
export interface FlightExtended extends Flight {
  slaBorder: slaBorders;
  hasOpsRemarks: boolean;
  hasCsRemarks: boolean;
}

type afbColors = 'red' | '#f87b05' | 'rgba(0, 200, 0, 1)' | 'black' | '#99a6b3';
export enum AFBColorType {
  'RED',
  'AMBER',
  'GREEN',
  'BLACK',
  'NONE',
}
export const AFB_COLORS: { [key in AFBColorType]: afbColors } = {
  [AFBColorType.BLACK]: 'black',
  [AFBColorType.GREEN]: 'rgba(0, 200, 0, 1)',
  [AFBColorType.AMBER]: '#f87b05',
  [AFBColorType.RED]: 'red',
  [AFBColorType.NONE]: '#99a6b3',
};
export const FLIGHT_VERSION_ACK: { [key in AFBColorType]: string } = {
  [AFBColorType.RED]: 'No ACK',
  [AFBColorType.AMBER]: "Not all ACK'd",
  [AFBColorType.GREEN]: "All ACK'd",
  [AFBColorType.BLACK]: 'No ACK',
  [AFBColorType.NONE]: 'No ACK',
};
export const FLIGHT_FEED = 'FlightLegEvent';

export enum FUFI_CLASS_NAME {
  disabled = 'disabled-fufi',
  active = 'active-fufi',
}

export const FUFI_TOOLTIP_TITLE: { [key in FUFI_CLASS_NAME]: string } = {
  [FUFI_CLASS_NAME.active]: '',
  [FUFI_CLASS_NAME.disabled]: 'No FUFI information',
};

export enum TimeFlightType {
  Midnight = '00:00',
  SixMorning = '06:00',
  Midday = '12:00',
  SixEvening = '18:00',
}

export enum HoursFlight {
  Midnight = 0,
  SixMorning = 6,
  Midday = 12,
  SixEvening = 18,
}
export interface HoursDelimiter {
  [TimeFlightType.Midnight]: Flight[];
  [TimeFlightType.SixMorning]: Flight[];
  [TimeFlightType.Midday]: Flight[];
  [TimeFlightType.SixEvening]: Flight[];
}
export interface HoldFlightsPoolHoursDelimiter {
  [aircraftId: number]: Array<{
    day: number;
    pool: HoursDelimiter;
  }>;
}
export interface HoldFlightsPoolByAircraftIdMap {
  [aircraftId: number]: Array<{
    day: number;
    pool: Flight[];
  }>;
}

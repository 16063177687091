import { OfferStatus } from '../../types/empty-leg-offer';

export const getBackgroundColorToOneWay = (os: OfferStatus) =>
  os === OfferStatus.CANCELLED ? '#D5D3D3' : '#FF8F00';

export const getBackgroundColorToEmptyLeg = (os: OfferStatus) =>
  os === OfferStatus.CANCELLED ? '#D5D3D3' : '#6AC8E0';

export const getBorderColorToOneWay = (os: OfferStatus) =>
  os === OfferStatus.CANCELLED ? '#636363' : '#FF8F00';

export const getBorderColorToEmptyLeg = (os: OfferStatus) =>
  os === OfferStatus.CANCELLED ? '#636363' : '#6AC8E0';

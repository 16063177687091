import { difference, uniq } from 'lodash';
import { createSelector } from 'reselect';

import { isDraggableByOpStatus } from '../common/flight/flight-check-status';
import { flattenPool, Pool } from '../data-processing/pool-structure';
import { RootState } from '../reducers';
import {
  getElementOffset,
  getLaneHeightKoef,
  ReducerShape,
} from '../reducers/ui';
import { getSortedVisibleAircraft, getVisibleElements } from '../selectors';
import Aircraft from '../types/aircraft';
import Flight from '../types/flight';
import { getAircraftIndexMapExcludingHolding } from '.';
import { AircraftTogglerState } from '../reducers/aircraft';
interface Dimensions {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}
type DimensionsWithFlightId = Dimensions & { id: number };

export const getFlightsTransformedToRects = createSelector<
  RootState,
  Flight[],
  { [aircraftId: number]: AircraftTogglerState },
  ReducerShape['segmentsVisibility'],
  ReducerShape['positionMap'],
  { [aircraftId: number]: number },
  number,
  ReducerShape['transform']['scaleX'],
  number,
  number,
  DimensionsWithFlightId[]
>(
  state => getVisibleElements(state, 'flights'),
  state => state.aircraft.togglersState,
  state => state.ui.segmentsVisibility,
  state => state.ui.positionMap,
  getAircraftIndexMapExcludingHolding,
  state => state.ui.rowHeight,
  state => state.ui.transform.scaleX,
  state => state.ui.width - state.ui.planeBlockWidth,
  state => state.ui.transform.translateY,
  (
    flights,
    togglersState,
    segmentsVisibility,
    positionMap,
    aircraftIndexMap,
    rowHeight,
    scaleX,
    uiWidth,
    translateY
  ) => {
    return flights.map(fl => {
      const y1 =
        aircraftIndexMap[fl.aircraftId] * rowHeight +
        getElementOffset(
          segmentsVisibility,
          'flights',
          togglersState[fl.aircraftId],
          positionMap
        ) /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[fl.aircraftId],
            positionMap
          ) +
        translateY;
      const x1 = scaleX(fl.start) % uiWidth;
      const width = scaleX(fl.end) - scaleX(fl.start);
      const height =
        positionMap['flights'] /
        getLaneHeightKoef(
          segmentsVisibility,
          togglersState[fl.aircraftId],
          positionMap
        );
      return {
        id: fl.id,
        x1,
        y1,
        x2: x1 + width,
        y2: y1 + height,
      };
    });
  }
);
const getSelectionInVisibleArea = createSelector<
  RootState,
  ReducerShape['draggingFlightsSelection'],
  number,
  number,
  Dimensions
>(
  state => state.ui.draggingFlightsSelection,
  state => state.ui.planeBlockWidth,
  state =>
    state.ui.controlsBarHeight +
    state.ui.timelineBarHeight +
    state.ui.filterBarHeight +
    state.ui.multipleSearchBarHeight +
    state.ui.marginTop +
    state.ui.holdLineHeight,
  ({ start, end }, planeBlockWidth, offsetTop) => {
    return {
      x1: start.x - planeBlockWidth,
      x2: end.x - planeBlockWidth,
      y1: start.y - offsetTop,
      y2: end.y - offsetTop,
    };
  }
);
interface ISegment {
  start: number;
  end: number;
}
export const isInRange = (segment: ISegment, range: ISegment): boolean =>
  !(
    Math.max(segment.start, segment.end) <= Math.min(range.start, range.end) ||
    Math.min(segment.start, segment.end) >= Math.max(range.start, range.end)
  );
export const getFlightsIdsInsideSelection = createSelector<
  RootState,
  Dimensions,
  DimensionsWithFlightId[],
  number[]
>(
  getSelectionInVisibleArea,
  getFlightsTransformedToRects,
  (selection, rects) => {
    return rects.reduce<number[]>((acc, rect) => {
      if (
        isInRange(
          { start: rect.x1, end: rect.x2 },
          { start: selection.x1, end: selection.x2 }
        ) &&
        isInRange(
          { start: rect.y1, end: rect.y2 },
          { start: selection.y1, end: selection.y2 }
        )
      ) {
        acc.push(rect.id);
      }
      return acc;
    }, []);
  }
);
export const getUpdatedSelectedFlightsIds = createSelector<
  RootState,
  number[],
  number[],
  number[]
>(
  state => state.ui.selectedFlights,
  getFlightsIdsInsideSelection,
  (alreadySelected, elementsToAdd) => {
    return alreadySelected.concat(difference(elementsToAdd, alreadySelected));
  }
);

export const getFlightsByIdFromPool = createSelector<
  RootState,
  Pool<Flight>,
  { [id: number]: Flight }
>(
  state => state.timelineEvents.flights,
  flightsPool =>
    flattenPool(flightsPool).reduce<{
      [id: number]: Flight;
    }>((acc, flight) => {
      acc[flight.id] = flight;
      return acc;
    }, {})
);
export const getFlightsByOriginalIdFromPool = createSelector<
  RootState,
  Pool<Flight>,
  { [id: number]: Flight }
>(
  state => state.timelineEvents.flights,
  flightsPool =>
    flattenPool(flightsPool).reduce<{
      [id: number]: Flight;
    }>((acc, flight) => {
      if (flight.originalFlightLegId) {
        acc[flight.originalFlightLegId] = flight;
        return acc;
      }
      return acc;
    }, {})
);

export const getFilteredByOpStatusUpdatedSelectedFlightIds = createSelector<
  RootState,
  number[],
  { [id: number]: Flight },
  number[]
>(
  getUpdatedSelectedFlightsIds,
  getFlightsByIdFromPool,
  (selectedFlights, flightsById) =>
    selectedFlights.filter(id =>
      isDraggableByOpStatus(flightsById[id].legOperationalStatusId)
    )
);
export const getDraggingFlights = createSelector<
  RootState,
  number[],
  { [flightId: number]: Flight },
  Flight[]
>(
  state => state.ui.selectedFlights,
  getFlightsByIdFromPool,
  (ids, map) => ids.map(id => map[id])
);

export const getAircraftByDraggingFlights = createSelector<
  RootState,
  Aircraft[],
  Flight[],
  Aircraft[]
>(getSortedVisibleAircraft, getDraggingFlights, (aircraft, flights) => {
  if (!flights.length) return [];
  const aircraftIdsByFlights = uniq(flights.map(f => f.aircraftId));
  return aircraft.filter(a => aircraftIdsByFlights.includes(a.id));
});

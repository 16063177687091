import MaintenanceItem, {
  MaintenanceItemType,
} from '../../types/maintenance-item';

export interface MaintenanceItemGVModel {
  maintenanceItemId: {
    key: number;
  };
  createdDate: number;
  updatedDate: number;
  dueDate: number;
  description: string;
  opDescription?: string;
  melRef: string;
  melItem: string;
  isActive: boolean;
  isDiscreet: boolean;
  clearedTimestamp: number;
  aircraftId: {
    key: number;
  };
  hours: number;
  cycles: number;
  isAogDriver: boolean;
}

export const transformToFE = (m: MaintenanceItemGVModel): MaintenanceItem => ({
  id: m.maintenanceItemId.key,
  start: m.createdDate,
  end: m.dueDate === null ? m.createdDate + 1000 * 60 * 60 * 12 : m.dueDate,
  aircraftId: m.aircraftId.key,
  createdDate: m.createdDate,
  clearedTimestamp: m.clearedTimestamp,
  description: m.description.split('\n'),
  opDescription: m.opDescription ? m.opDescription.split('\n') : null,
  dueDate: m.dueDate,
  maintenanceItemRef: m.melRef,
  maintenanceItemType:
    (m.melItem as MaintenanceItemType) || MaintenanceItemType.NA,
  updatedDate: m.updatedDate,
  isActive: m.isActive,
  isDiscreet: m.isDiscreet,
  cycles: m.cycles,
  hoursInMinutes: m.hours,
  isAogDriver: m.isAogDriver,
});

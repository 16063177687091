import { PureComponent } from 'react';
import { RootState } from '../../../reducers';
import { connect } from 'react-redux';
import * as d3S from 'd3-selection';
import * as d3Scale from 'd3-scale';
import PeakDay from '../../../types/peak-day';
import { store, subMiddle } from '../../../root';
import { utc } from 'moment';
import { getInitialScale, ReducerShape } from '../../../reducers/ui';
import * as actions from '../../../actions';
import { getBoundingElement } from '../../../utils';
import { getVisiblePeakDays } from '../../../selectors/peak-days';
import { hasPermission } from '../../../utils/check-permissions';
import {
  getPeakDayColor,
  getPeakDayElementHeight,
  getPeakDayElementY,
} from '../../../utils/peak-days';
import EventElement from '../../../types/event-element';

interface AppenderArgs {
  selection;
  scaleX?: d3Scale.ScaleTime<number, number>;
  partIndex: number;
}
function rectAppender(argsObject: AppenderArgs) {
  const { selection, scaleX, partIndex } = argsObject;

  selection
    .filter((d: PeakDay) => d.peakDayTypes.length > partIndex)
    .append('rect')
    .attr('x', (d: PeakDay) => scaleX(utc(d.start)))
    .attr(
      'y',
      (d: PeakDay) => -getPeakDayElementY(partIndex, d.peakDayTypes.length)
    )
    .style('fill', (d: PeakDay) =>
      getPeakDayColor(d.peakDayTypes[partIndex]?.id)
    )
    .attr('width', this.rectWidthCalculator)
    .attr('height', (d: PeakDay) =>
      getPeakDayElementHeight(partIndex, d.peakDayTypes.length)
    );
}

const parts = [rectAppender, rectAppender, rectAppender];

interface StateProps {
  peakDays: PeakDay[];
  scaleX: ReducerShape['transform']['scaleX'];
  translateX: number;
  width: number;
  kx: number;
  marginTop: number;
}
interface DispatchProps {
  onLeaveElement: () => void;
}
interface ExtendedPeakDay extends PeakDay {
  canvasWidth: number;
}
class PeakDaysElements extends PureComponent<StateProps & DispatchProps> {
  constructor(props) {
    super(props);
    const updater = (_action, state: RootState) => {
      this.update(getVisiblePeakDays(state));
    };
    subMiddle.on(actions.userZoomHor, updater);
    subMiddle.on(actions.doGetPeakDays.done, updater);
    subMiddle.on(actions.wsUpdateBatch, updater);
  }
  linkedSelection: d3S.Selection<
    d3S.BaseType & SVGGElement,
    ExtendedPeakDay,
    SVGGElement,
    {}
  >;
  rootRef: SVGGElement;
  getRootRef = ref => (this.rootRef = ref);
  update = (data: PeakDay[]) => {
    if (!this.linkedSelection) {
      this.linkedSelection = d3S.select(this.rootRef).selectAll('.peak-day');
    }
    const mappedWithData = this.linkedSelection.data(
      data.map(this.buildPropsForComponent),
      (d: ExtendedPeakDay) =>
        `${d.id}:${d.start}-${d.end}-${d.canvasWidth}-${d.isAutoAdvertising}-${
          d.isCrewChange
        }-${d.isActive}-
      ${d.peakDayRegions
        .map(r => `${r.id}-${r.value}`)
        .join('-')}-${d.peakDayTypes.map(t => `${t.id}-${t.value}`).join('-')}`
    );
    mappedWithData.exit().remove();
    this.linkedSelection = this.renderEntered(mappedWithData.enter()).merge(
      mappedWithData
    );
  };
  buildPropsForComponent = (d: PeakDay) => {
    const newD = Object.create(d) as ExtendedPeakDay;
    newD.canvasWidth = this.props.width;
    return newD;
  };
  renderEntered(
    entered: d3S.Selection<d3S.EnterElement, ExtendedPeakDay, SVGGElement, {}>
  ) {
    const state = store.getState();
    const allowedToOpenEditForm =
      hasPermission(state, 'AG-Timeline-Timeline-Event-View') ||
      hasPermission(state, 'AG-Timeline-Timeline-Event-Edit');
    const { ui } = state;
    const scaleX = getInitialScale(ui.width - ui.planeBlockWidth);

    let peakDayGroup = entered
      .append('g')
      .classed('peak-day', true)
      .attr('data-test-entity-id', d => d.id)
      .on('mouseover', function(d) {
        store.dispatch(
          actions.userHoverSegment([
            d as ExtendedPeakDay & EventElement,
            getBoundingElement(this),
            'peakDays',
          ])
        );
      })
      .on('contextmenu', function(d: PeakDay) {
        d3S.event.preventDefault();
        d3S.event.stopPropagation();
        store.dispatch(
          actions.userOpenContextMenuForPeakDay({
            id: d.id,
            position: { x: d3S.event.clientX, y: d3S.event.clientY },
          })
        );
      })
      .on(
        'click',
        d =>
          allowedToOpenEditForm &&
          store.dispatch(actions.userClickEditPeakDay(d.id))
      );

    parts.forEach((_, partIndex) =>
      rectAppender.call(this, {
        selection: peakDayGroup,
        partIndex,
        partsLength: parts.length,
        scaleX,
      })
    );
    return peakDayGroup;
  }
  rectWidthCalculator = ({ end, start }: PeakDay) => {
    const { ui } = store.getState();
    const { width, planeBlockWidth } = ui;
    const scaleX = getInitialScale(width - planeBlockWidth);
    return Math.max(0, scaleX(end) - scaleX(start));
  };
  componentDidMount() {
    this.update(this.props.peakDays);
  }
  render() {
    const { translateX, kx, marginTop } = this.props;
    return (
      <g
        className="peak-days-elements"
        ref={this.getRootRef}
        onMouseLeave={this.props.onLeaveElement}
        transform={`translate(${translateX}, ${marginTop})scale(${kx}, 1)`}
      />
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onLeaveElement: () => dispatch(actions.userCloseTooltipSegment()),
});
export const PeakDaysElementsConnected = connect(
  (state: RootState) => ({
    peakDays: getVisiblePeakDays(state),
    scaleX: state.ui.transform.scaleX,
    kx: state.ui.transform.kx,
    width: state.ui.width,
    translateX: state.ui.transform.translateX,
    marginTop: state.ui.marginTop,
  }),
  mapDispatchToProps
)(PeakDaysElements);

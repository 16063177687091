import { ExtendedEventElement } from '../..';
import EmptyLegOffer from '../../../../../types/empty-leg-offer';
import Flight from '../../../../../types/flight';
import { OverlappedElements } from '../../../../../types/event-element';
import Maintenance from '../../../../../types/maintenance';
import Note, { MergedNote } from '../../../../../types/note';
import { isFlight } from '../../../../../common/flight/flight-check-status';
import OneWayOffer from '../../../../../types/one-way-offer';
import { ExtendedHoldFlightEventElement } from '../../hold-flights/holdFlightsTextLabels';
import { isEmptyLegOffer } from '../../../../../utils/empty-leg-offer';
import { isMergeNote } from '../../../../../utils/note';
import MaintenanceItem, {
  MergedMEL,
} from '../../../../../types/maintenance-item';
import { isMergedMaintenanceItems } from '../../../../../utils/maintenance-item';
import {
  FboMismatch,
  isGroundTimeFbo,
} from '../../../../../types/fbo-mismatch';

export const plainDataMapper = (d: ExtendedEventElement) =>
  `${d.airportsDataLoaded}-${d.id}:${d.x}-${d.width}-${d.y}-${d.ky}-${d.rh}-${d.laneKoef}`;

export const groundTimeTypeDataMapper = (d: ExtendedEventElement) =>
  `${plainDataMapper(d)}-${d.groundTimeType}`;

export const fboMismatchDataMapper = (d: FboMismatch & ExtendedEventElement) =>
  `${groundTimeTypeDataMapper(d)}-${
    isGroundTimeFbo(d)
      ? d.baseAirportId
      : `${d.departureAirportId}-${d.arrivalAirportId}`
  }-${d.hasFBOMismatch}`;

export const crewDataMapper = (d: ExtendedEventElement) =>
  `${plainDataMapper(d)}-${
    d.team ? d.team.map(c => c.crewMemberCode).join('-') : 'non-team'
  }`;

export const emptyLegDataMapper = (d: ExtendedEventElement & EmptyLegOffer) =>
  `${plainDataMapper(d)}-${d.offerStatus}-${d.departureAirportId}-${
    d.arrivalAirportId
  }-${(d.emptyLegRoutes || [])
    .map(r => r.departureAirportId + '-' + r.arrivalAirportId)
    .join('-')}`;

export const oneWayDataMapper = (d: ExtendedEventElement) =>
  `${plainDataMapper(d)}-${d.offerStatus}-${d.departureAirportId}`;

export const maintenanceDataMapper = (d: ExtendedEventElement & Maintenance) =>
  `${plainDataMapper(d)}-${d.maintenanceType}-${d.airportId}-${
    d.isDispatchable
  }`;

export const maintenanceItemDataMapper = (
  d: ExtendedEventElement & (MaintenanceItem | MergedMEL)
) =>
  isMergedMaintenanceItems(d)
    ? `${plainDataMapper(d)}-` +
      d.maintenanceItems
        .map(
          (m: MaintenanceItem) =>
            `${m.start}-${m.end}-${m.maintenanceItemType}-${(
              m.description || []
            ).join(' ')}`
        )
        .join('-')
    : `${plainDataMapper(d)}-${d.maintenanceItemType}-${(
        d.description || []
      ).join(' ')}`;

export const noteDataMapper = (d: ExtendedEventElement & (Note | MergedNote)) =>
  isMergeNote(d)
    ? d.notes && d.notes.length
      ? `${plainDataMapper(d)}-` +
        d.notes
          .map(
            (n: Note) =>
              `${n.start}-${n.end}-${n.message}-${n.importanceFactor}`
          )
          .join('-')
      : plainDataMapper(d)
    : `${plainDataMapper(d)}-${d.message}-${d.importanceFactor}`;

export const flightDataMapper = (
  f: Flight & ExtendedEventElement & { isSelected?: boolean }
) =>
  `${plainDataMapper(f)}-${f.departureAirportId}-${f.arrivalAirportId}-${
    f.timeLabelFilterId
  }-${f.isSelected}`;

export const holdFlightDataMapper = (
  f: Flight & ExtendedHoldFlightEventElement & { isSelected?: boolean }
) =>
  `${plainDataMapper(f)}-${f.departureAirportId}-${f.arrivalAirportId}-${
    f.color
  }-${f.isSelected}`;

export const overlappedFlightsMapper = (
  oe: OverlappedElements & ExtendedEventElement
) => `${plainDataMapper(oe)}-${oe.elements.map((e: Flight) => e.id).join(',')}`;

export const overlappedFlightWithMaintenancesMapper = (
  oe: OverlappedElements & ExtendedEventElement
) =>
  `${plainDataMapper(
    oe
  )}-${oe.elements.map((e: (Flight | Maintenance) & ExtendedEventElement) =>
    isFlight(e) ? flightDataMapper(e) : maintenanceDataMapper(e)
  )}`;

export const overlappedMaintenancesDataMapper = (
  oe: OverlappedElements & ExtendedEventElement
) =>
  `${plainDataMapper(oe)}-${oe.elements
    .map((e: Maintenance) => e.id)
    .join('-')}`;

export const overlappedFlightEmptyLegOneWayDataMapper = (
  oe: OverlappedElements & ExtendedEventElement
) =>
  `${plainDataMapper(oe)}-${oe.elements.map(
    (e: (Flight | EmptyLegOffer | OneWayOffer) & ExtendedEventElement) => {
      if (isFlight(e)) {
        flightDataMapper(e);
      }
      if (isEmptyLegOffer(e)) {
        emptyLegDataMapper(e);
      }
      oneWayDataMapper(e);
    }
  )}`;

export default {
  plainDataMapper,
  groundTimeTypeDataMapper,
  crewDataMapper,
  emptyLegDataMapper,
  oneWayDataMapper,
  maintenanceDataMapper,
  maintenanceItemDataMapper,
  noteDataMapper,
  flightdataMapper: flightDataMapper,
  overlappedFlightsMapper,
  fboMismatchDataMapper,
};

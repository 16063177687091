import { PureComponent } from 'react';
import * as actions from '../../actions';
import { connect, DispatchProp } from 'react-redux';
import { RootState } from '../../reducers';
import Popper from 'popper.js';
import { subMiddle } from '../../root';
import { TooltipConnected } from './tooltip';
import './styles.scss';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { AnyAction } from 'redux';
interface Props {
  isOpened: boolean;
  isHiddenTooltipWrapper: boolean;
  planeBlockWidth: number;
  flightTooltipWidth: number;
  isVerticalMode: boolean;
}
class TooltipWrapper extends PureComponent<
  Props & DispatchProp<AnyAction>,
  { timer: number; placement: TooltipPlacement }
> {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      placement: null,
    };
  }
  popper: Popper;
  ref: HTMLDivElement;
  getRef = ref => (this.ref = ref);
  createPopper = action => {
    clearTimeout(this.state.timer);
    const {
      isOpened,
      planeBlockWidth,
      flightTooltipWidth,
      isVerticalMode,
    } = this.props;
    if (!isOpened) return;
    const width = isVerticalMode
      ? window.innerWidth
      : window.innerWidth - planeBlockWidth;
    const pageWidth = Math.max(width, 0);
    const tooltipWrapper = document.getElementsByClassName(
      'overlapping-tooltip'
    )[0] as HTMLDivElement;
    const actionRef = action.payload[1];
    if (tooltipWrapper && actionRef) {
      this.popper = new Popper(actionRef, tooltipWrapper, {
        placement: 'auto-end',
        modifiers: {
          preventOverflow: { enabled: true },
          computeStyle: { gpuAcceleration: false },
        },
        positionFixed: true,
      });
    }
    this.setState({
      placement:
        pageWidth - actionRef.getBoundingClientRect().left < flightTooltipWidth
          ? 'left'
          : 'right',
    });
  };

  removePopper = () => {
    if (this.popper) {
      this.popper.destroy();
      delete this.popper;
    }
  };
  removePopperWithTimeout = () => {
    this.setState({
      timer: window.setTimeout(() => this.removePopper(), 500),
    });
  };
  componentDidMount() {
    window.addEventListener('resize', this.removePopper);
    subMiddle.on(actions.userUnhoverOvlElement, this.removePopperWithTimeout);
    subMiddle.on(actions.userHoverOverlap, this.createPopper);
    subMiddle.on(actions.userZoomHor, this.removePopper);
    subMiddle.on(actions.userZoomVer, this.removePopper);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.removePopper);
    this.removePopper();
  }
  onMouseLeave = (e: React.MouseEvent) => {
    const { dispatch } = this.props;
    dispatch(actions.userBlurOverlap());
  };
  onMouseEnter = (e: React.MouseEvent) => {
    clearTimeout(this.state.timer);
  };
  getBoundingRectFromRef = () => {
    const rect = this.ref?.getBoundingClientRect();
    return rect;
  };
  render() {
    const {
      isOpened,
      isHiddenTooltipWrapper,
      flightTooltipWidth,
      isVerticalMode,
    } = this.props;
    const { placement } = this.state;
    return (
      isOpened && (
        <div
          className={`overlapping-tooltip ${
            isHiddenTooltipWrapper ? 'hidden' : ''
          }`}
          onMouseLeave={this.onMouseLeave}
          onMouseEnter={this.onMouseEnter}
          tabIndex={-1}
          style={{
            position: 'absolute',
            background: 'white',
            borderRadius: 4,
            border: '1px solid #DDDDDD',
            boxShadow: '0 2px 4px 0 rgba(4,25,50,0.15)',
            padding: 16,
            zIndex: 20,
          }}
          ref={this.getRef}
        >
          {placement && (
            <TooltipConnected
              placement={placement}
              boundingRect={this.getBoundingRectFromRef()}
              flightTooltipWidth={flightTooltipWidth}
              isVerticalMode={isVerticalMode}
            />
          )}
        </div>
      )
    );
  }
}
export const TooltipWrapperConnected = connect((state: RootState) => ({
  isOpened: !!state.ui.openedOverlappedElement,
  isHiddenTooltipWrapper: state.ui.isHiddenTooltipWrapper,
  planeBlockWidth: state.ui.planeBlockWidth,
  flightTooltipWidth: state.ui.flightTooltipWidth,
  isVerticalMode: state.ui.isVerticalMode,
}))(TooltipWrapper);

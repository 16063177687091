import { PureComponent } from 'react';
import Maintenance from '../../../../types/maintenance';
import { MaintenanceActionButtons as ActionButtons } from '../../../tooltip/action-buttons';
import Airport from '../../../../types/airport';
import { capitalize } from 'lodash';
import { getMaintenanceTypeLabel } from '../../../../common/maintenance/maintenance-labels';
import TooltipDates from '../../../tooltip/dates';
import {
  getICAOLabelFromAirport,
  getCityLabelFromAirport,
  getCountryLabelFromAirport,
} from '../../../../utils/airport';

interface Props {
  element: Maintenance;
  airportsById: { [id: number]: Airport };
  hasEditMxEventPermission: boolean;
  hasDeleteMxEventPermission: boolean;
}

export default class MaintenanceCardContent extends PureComponent<Props> {
  getAirportInfo = (a: Airport) =>
    a ? (
      <>
        <strong>{getICAOLabelFromAirport(a)}</strong>
        {` ${capitalize(getCityLabelFromAirport(a))}, ${capitalize(
          getCountryLabelFromAirport(a)
        )} `}
      </>
    ) : (
      <strong>{' No airport specified '}</strong>
    );
  render() {
    const {
      airportsById,
      element,
      hasEditMxEventPermission,
      hasDeleteMxEventPermission,
    } = this.props;
    const airport = airportsById[element.airportId];
    const maintenanceTypeLabel = getMaintenanceTypeLabel(element);
    return (
      <div className="card-content">
        <div>
          <strong
            style={{
              paddingRight: '8px',
            }}
          >
            {maintenanceTypeLabel}
          </strong>
          {this.getAirportInfo(airport)}
          <TooltipDates start={element.start} end={element.end} />
        </div>
        <ActionButtons
          id={element.id}
          hasDeletePermission={hasDeleteMxEventPermission}
          hasEditPermission={hasEditMxEventPermission}
        />
      </div>
    );
  }
}

import { flatMap, uniq } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import { UserPreferences } from '../types/user-preferences';

const getFirstTen = createSelector(
  (state: RootState) => state.ui.segmentsVisibility,
  (state: RootState) => state.timelineEvents.groundTimeType,
  (state: RootState) => state.ui.timeLabelDisplayMode,
  (state: RootState) => state.ui.mxEventPresenceType,
  (state: RootState) => state.opstatuses.statusMap,
  (state: RootState) => state.ui.transform.kx,
  (state: RootState) => state.ui.transform.scaleX,
  (state: RootState) => state.ui.transform.ky,
  (state: RootState) => state.aircraft.togglersState,
  (state: RootState) => state.emptyLegs.emptyLegMap,
  (state: RootState) => state.ui.isDaySeparatorOnTop,
  (
    segmentsVisibility,
    groundTimeType,
    timeLabelDisplayMode,
    mxEventPresenceType,
    statusMap,
    kx,
    scaleX,
    ky,
    togglersState,
    emptyLegMap,
    isDaySeparatorOnTop
  ) => ({
    segmentsVisibility,
    groundTimeType,
    timeLabelDisplayMode,
    mxEventPresenceType,
    statusMap,
    kx,
    scaleX,
    ky,
    togglersState,
    emptyLegMap,
    isDaySeparatorOnTop,
  })
);
const getSecondTen = createSelector(
  (state: RootState) => state.aircraft.showSubCharters,
  (state: RootState) => state.aircraft.showLabels,
  (state: RootState) => state.ui.zoomLvl.value,
  (state: RootState) => state.ui.visibleHoldAcIds,
  (state: RootState) => state.ui.statisticsMode,
  (state: RootState) => state.aircraft.visibleAircraftTypeByOperatingCompanyMap,
  (state: RootState) => state.aircraft.isGroupedByOperator,
  (state: RootState) => state.aircraft.visibleAcIds,
  (state: RootState) => state.aircraft.acTypesPositionMap,
  (
    showSubCharters,
    showLabels,
    zoomLvl,
    visibleHoldAcIds,
    statisticsMode,
    visibleAircraftTypeByOperatingCompanyMap,
    isGroupedByOperator,
    visibleAcIds,
    acTypesPositionMap
  ) => ({
    isGroupedByOperator,
    showSubCharters,
    showLabels,
    zoomLvl,
    visibleHoldAcIds,
    statisticsMode,
    visibleAircraftTypeByOperatingCompanyMap,
    visibleACTypes: uniq(
      flatMap(
        Object.keys(visibleAircraftTypeByOperatingCompanyMap).map(
          key => visibleAircraftTypeByOperatingCompanyMap[key]
        )
      )
    ),
    visibleOperatingCompanies: Object.keys(
      visibleAircraftTypeByOperatingCompanyMap
    ).map(key => +key),
    visibleAcIds,
    acTypesPositionMap,
  })
);
export const getUserPreferences = createSelector(
  getFirstTen,
  getSecondTen,
  (firstTen, secondTen) => {
    const res: UserPreferences = {
      ...firstTen,
      ...secondTen,
    };
    return res;
  }
);

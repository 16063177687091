import MaintenanceItem, { MergedMEL } from '../types/maintenance-item';
import { chain, maxBy } from 'lodash';

export const isMergedMaintenanceItems = (
  m: MaintenanceItem | MergedMEL
): m is MergedMEL => !!(m as MergedMEL).maintenanceItems;

export const getMaintenanceItemsByAircraftId = (
  maintenanceItemsByAircraftId: MaintenanceItem[]
) => {
  return chain(maintenanceItemsByAircraftId)
    .sortBy('start')
    .reduce((acc: (MaintenanceItem | MergedMEL)[], m: MaintenanceItem) => {
      if (acc.length === 0) return [m];
      const lastMEL = acc[acc.length - 1];
      if (m.start < lastMEL.end) {
        if (isMergedMaintenanceItems(lastMEL)) {
          lastMEL.end = maxBy(lastMEL.maintenanceItems, 'end').end;
          lastMEL.maintenanceItems.push(m);
          return acc;
        }
        const mergedMaintenanceItem: MergedMEL = {
          start: lastMEL.start,
          end: Math.max(m.end, lastMEL.end),
          aircraftId: m.aircraftId,
          id: lastMEL.id,
          maintenanceItems: [lastMEL, m],
        };
        acc.splice(-1, 1, mergedMaintenanceItem);
        return acc;
      }
      acc.push(m);
      return acc;
    }, [])
    .value();
};

export const getMergedMaintenanceItems = (
  maintenanceItems: MaintenanceItem[]
): (MaintenanceItem | MergedMEL)[] => {
  return chain(maintenanceItems)
    .groupBy('aircraftId')
    .flatMap(getMaintenanceItemsByAircraftId)
    .value();
};

import './styles.scss';
import { Col } from 'antd';
import { FC } from 'react';

import NotPermittedImage from '../../accets/images/unauthorized_access.png';

import { SUPPORT_URL } from '../../constants/environment';

export const NotPermittedPage: FC<{}> = () => {
  return (
    <div className="error-page-body">
      <Col span={21} className="error-page-image-container">
        <img
          src={NotPermittedImage}
          alt="Not permitted"
          className="error-page-image"
        />
        <div className="error-page-text-container">
          <div className="error-page-text-container-code">403</div>
          <div className="error-page-text-container-inner">
            <div>
              You have no permission to view this page. Required permission:
              AG-Timeline
            </div>
            <a target="_blank" rel="noopener noreferrer" href={SUPPORT_URL}>
              Contact support team
            </a>
          </div>
        </div>
      </Col>
    </div>
  );
};

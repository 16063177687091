import { WsHandoverUpdateBatch } from '../actions';
import { WsUpdateBatch } from '../data-processing/ws-income/ws-update-batch';
import {
  WsIncomeTimelineElement,
  WsIncomeTimelineElementUpdate,
} from '../types/ws-income';

export function isUpdateWsIncome(
  income: WsIncomeTimelineElement
): income is WsIncomeTimelineElementUpdate {
  return !!(income as WsIncomeTimelineElementUpdate).data;
}

export function isGeneralWsUpdateAction(
  action: WsUpdateBatch | WsHandoverUpdateBatch
): action is WsUpdateBatch {
  return !!(action as WsUpdateBatch).data;
}

import { duration, utc } from 'moment';
import { ONE_HOUR_IN_MINUTES } from '../constants';

const DAY_LENGTH = 1000 * 60 * 60 * 24;

export function getTimeSegments(
  days: number[]
): { start: number; end: number }[] {
  let counter = 0;
  let arr = [];
  if (!days.length) {
    return [];
  }
  const beginWithObject = {
    start: days[0],
    end: utc(days[0])
      .endOf('day')
      .valueOf(),
  };
  arr.push(beginWithObject);
  let prevDay = days[0];
  days.forEach(day => {
    const endOfDay = utc(day)
      .endOf('day')
      .valueOf();
    if (day === prevDay || isNextDay(prevDay, day)) {
      arr[counter].end = endOfDay;
    } else {
      counter++;
      const obj = {
        start: day,
        end: endOfDay,
      };
      arr.push(obj);
    }
    prevDay = day;
  });
  return arr;
}

export function isNextDay(gaugeDay: number, testDay: number): boolean {
  return (
    testDay - gaugeDay >= DAY_LENGTH - 1500 &&
    testDay - gaugeDay <= DAY_LENGTH + 1500
  );
}
type formatOptions =
  | 'date'
  | 'fullUtc'
  | 'timeUtc'
  | 'timeLocal'
  | 'dayMonthYear'
  | 'dayMonthYearUIz'
  | 'dayMonthYYtimez'
  | 'timez';
export const universalDateAndTimeFormatter = (
  e: moment.Moment,
  key: formatOptions
) => {
  if (!e) return '';
  const formatOptionByKey: { [keys in formatOptions]: string } = {
    date: 'DD MMM',
    fullUtc: 'DD MMM, HH:mm[Z]',
    timeUtc: 'HH:mm[\u00A0Z]',
    timeLocal: 'HH:mm[\u00A0L]',
    dayMonthYear: "DD MMM'YY",
    dayMonthYearUIz: 'DD MMM’yy HH:mm[z]',
    dayMonthYYtimez: 'DD MMM’YY HH:mm[z]',
    timez: 'HH:mm[z]',
  };
  return e.format(formatOptionByKey[key]);
};

export const getFormattedDuration = (start: number, end: number): string => {
  const durationBased = duration(end - start);
  const durationInHours = Math.floor(durationBased.asHours());
  const durationInMinutes = Math.floor(
    durationBased.subtract(durationInHours, 'hours').asMinutes()
  );
  const minutesString =
    durationInMinutes < 10 ? `0${durationInMinutes}` : `${durationInMinutes}`;
  return `${durationInHours}:${minutesString}`;
};

const formatPrecedingZero = (timeString: string): string => {
  if (typeof timeString !== 'string') {
    throw new Error('String expected');
  }
  return timeString.length < 2 ? `0${timeString}` : timeString;
};

export const getHoursInMinutesFormattedToHHmm = (
  hoursInMinutes: number
): string => {
  if (!hoursInMinutes) return 'N/A';
  const hours = Math.floor(hoursInMinutes / ONE_HOUR_IN_MINUTES).toString();
  const minutes = (hoursInMinutes % ONE_HOUR_IN_MINUTES).toString();
  return `${formatPrecedingZero(hours)}:${formatPrecedingZero(minutes)}`;
};

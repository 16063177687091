import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { getTailsClippedRect } from '../../selectors';

interface ClippedRect {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

interface Props {
  width: number;
  height: number;
  marginTop: number;
  rect: ClippedRect[];
}

class HighlightAreaByTailSearch extends PureComponent<Props> {
  static getFadedAreaSize(w, h, marginTop) {
    return `M 0 ${marginTop} v ${h} h ${w} v -${h} z`;
  }
  static getAircraftHighlightedHoleString(r: ClippedRect) {
    return `M${r.x1} ${r.y1} h ${r.x2 - r.x1} v ${r.y2 - r.y1} h ${r.x1 -
      r.x2}z`;
  }
  render() {
    const { width, height, marginTop, rect } = this.props;
    if (!rect.length) return null;
    let clippedRectPath = HighlightAreaByTailSearch.getFadedAreaSize(
      width,
      height,
      marginTop
    );
    clippedRectPath += rect
      .map(r => HighlightAreaByTailSearch.getAircraftHighlightedHoleString(r))
      .join(' ');
    return (
      <g
        className="highlight-area-clip"
        fill="black"
        fillOpacity={0.3}
        fillRule="evenodd"
      >
        <path d={clippedRectPath} />
      </g>
    );
  }
}
export const HighlightTailBySearchConnected = connect((state: RootState) => ({
  width: state.ui.width,
  height: state.ui.height,
  marginTop: state.ui.marginTop,
  rect: getTailsClippedRect(state),
}))(HighlightAreaByTailSearch);

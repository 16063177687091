export const parseFromUnit8Array = (str: string, key?: string) => {
  const encoded = Uint8Array.from(
    Array.from(JSON.stringify(str)).map(letter => letter.charCodeAt(0))
  );
  const decoder = new TextDecoder('ascii');
  const decoded =
    // escape unicode
    decoder.decode(encoded).replace(/([.?*+^$[\]\\(){}|-])u/gu, '\\$1u');
  const decodedParsed = JSON.parse(decoded);
  if (typeof decodedParsed === 'string') {
    return JSON.parse(decodedParsed, (k, value) => {
      if (k === key) {
        return value.normalize('NFKD');
      }
      return value;
    });
  } else {
    return decoded;
  }
};

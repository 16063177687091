import * as Fuse from 'fuse.js';
import { chain, sortBy } from 'lodash';
import { isHoldAircraft } from '../common/aircraft/aircraft-check-status';
import { HOLD_FLIGHT_HEIGHT, HOLD_LANE_STROKE } from '../constants';
import { getZoomLevelForHoldFlightRender } from '../d3/components/zoom';
import { AircraftTogglerState } from '../reducers/aircraft';
import {
  getElementHeight,
  getElementOffsetWithKoef,
  ReducerShape,
} from '../reducers/ui';
import { FlightWithExtendedAirport, getAircraftRowIndex } from '../selectors';
import Aircraft from '../types/aircraft';
import { CrewRoster } from '../types/crew-roster';
import EventElement from '../types/event-element';
import Flight, {
  HoldFlightsPoolByAircraftIdMap,
  HoldFlightsPoolHoursDelimiter,
  HoursFlight,
} from '../types/flight';
import { SegmentType } from '../types/segment-types';
import {
  getDay,
  getHoldFlightTextLabelLeft,
  getHoldFlightTextLabelWidth,
  getHoldFlightTimeStart,
  getHoursOffsetForHoldFlight,
  getY,
} from './hold-line-flights';

export const getElementVerticalPosition = (
  {
    ky,
    translateY,
    segmentsVisibility,
    rowHeight,
    marginTop,
    togglerState,
    positionMap,
    holdLineHeight,
    holdAircraftCount,
  }: {
    ky: number;
    translateY: number;
    segmentsVisibility: { [st in SegmentType]: boolean };
    rowHeight: number;
    marginTop: number;
    togglerState: AircraftTogglerState;
    positionMap: { [k in SegmentType]: number };
    holdLineHeight: number;
    holdAircraftCount: number;
  },
  el: EventElement,
  aircraftIndexMap: { [id: string]: number },
  segmentType: SegmentType
): number => {
  const rowIndex = getAircraftRowIndex(aircraftIndexMap, el.aircraftId);
  const offset = getElementOffsetWithKoef(
    segmentsVisibility,
    segmentType,
    togglerState,
    positionMap
  );
  const relativeOffset = translateY + marginTop;
  const topOffset =
    holdLineHeight > 0 ? holdLineHeight + relativeOffset : relativeOffset;
  return topOffset + ((rowIndex - holdAircraftCount) * rowHeight + offset) * ky;
};

export function mapFocusWindowRectsWithProps(
  el: EventElement,
  props: {
    ui: ReducerShape;
    indexMap: {
      [aircraftId: number]: number;
    };
    segmentType: SegmentType;
    togglersState: {
      [aircraftId: number]: AircraftTogglerState;
    };
    aircraftById: {
      [aircraftId: number]: Aircraft;
    };
    holdFlightsDelimiter: HoldFlightsPoolHoursDelimiter;
    holdFlightsPool: HoldFlightsPoolByAircraftIdMap;
  }
): {
  x1: number;
  x2: number;
  y1: number;
  y2: number;
} {
  const {
    aircraftById,
    indexMap,
    segmentType,
    togglersState,
    ui,
    holdFlightsDelimiter,
    holdFlightsPool,
  } = props;
  const {
    transform,
    marginTop,
    rowHeight,
    positionMap,
    segmentsVisibility,
    holdAircraftPositionMap,
    holdLineHeight,
    visibleHoldAcIds,
  } = ui;
  const { ky, translateY, scaleX } = transform;
  const holdAircraftCount = visibleHoldAcIds.length;
  const zoomLevel = getZoomLevelForHoldFlightRender(scaleX);
  if (
    segmentType === 'flights' &&
    isHoldAircraft(aircraftById[el.aircraftId])
  ) {
    const { start } = el;
    const flightTimeType = getHoldFlightTimeStart(el as Flight);
    const flightTimeStart =
      zoomLevel === 'fullView'
        ? getHoursOffsetForHoldFlight(flightTimeType)
        : HoursFlight.Midnight;
    const x1 = getHoldFlightTextLabelLeft(
      start,
      zoomLevel,
      transform,
      flightTimeStart
    );
    const width = getHoldFlightTextLabelWidth(zoomLevel, scaleX);
    const x2 = x1 + width;
    const day = getDay(el.start);
    const index = (() => {
      switch (zoomLevel) {
        case 'fullView': {
          return (
            holdFlightsDelimiter[el.aircraftId]
              ?.find(days => days.day === day)
              ?.pool[flightTimeType]?.findIndex(fl => el.id === fl.id) || 0
          );
        }
        case 'weeklyView': {
          const index = holdFlightsPool[el.aircraftId]
            ?.find(days => days.day === day)
            ?.pool.findIndex(fl => el.id === fl.id);
          return index > 9 ? 10 : index;
        }
        case 'weeklyCompressed': {
          return 1;
        }
        default:
          return 0;
      }
    })();
    const y1 =
      getY(el.aircraftId, holdAircraftPositionMap, index) +
      marginTop +
      HOLD_LANE_STROKE;
    return { x1, x2, y1, y2: y1 + HOLD_FLIGHT_HEIGHT };
  }
  const y = getElementVerticalPosition(
    {
      ky,
      translateY,
      segmentsVisibility,
      rowHeight,
      marginTop,
      togglerState: togglersState[el.aircraftId],
      positionMap,
      holdLineHeight,
      holdAircraftCount,
    },
    el,
    indexMap,
    segmentType
  );
  const height =
    getElementHeight(
      segmentsVisibility,
      segmentType,
      togglersState[el.aircraftId],
      positionMap
    ) * ky;
  return {
    x1: scaleX(el.start),
    x2: scaleX(el.end),
    y1: y,
    y2: y + height,
  };
}

export const getCrewRostersForSearch = (
  elements: CrewRoster[],
  query: string
) => {
  if (!query || query == '' || !elements.length) return [];
  const fuse = new Fuse(elements, {
    //@ts-ignore
    keys: ['team.crewMemberCode'],
    tokenize: true,
    matchAllTokens: true,
    threshold: 0,
  });
  return sortBy(fuse.search(query), ['index', 'start']);
};

export const getOptionsForAutocomplete = (
  foundFlights: EventElement[],
  query: string,
  searchType: string
) => {
  if (!query) return [];
  switch (searchType) {
    case 'Airport': {
      return chain(foundFlights)
        .flatMap((flight: FlightWithExtendedAirport) => {
          return [
            flight.departureIcao,
            flight.departureAirportName,
            flight.arrivalIcao,
            flight.arrivalAirportName,
          ].filter(str => str.toLowerCase().includes(query.toLowerCase()));
        })
        .uniq()
        .value();
    }
    case 'Country': {
      return chain(foundFlights)
        .flatMap((flight: FlightWithExtendedAirport) => {
          return [flight.departureCountry, flight.arrivalCountry].filter(str =>
            str.toLowerCase().includes(query.toLowerCase())
          );
        })
        .uniq()
        .value();
    }
    case 'Customer': {
      return chain(foundFlights)
        .map((flight: Flight) => flight.customerName)
        .uniq()
        .value();
    }
    case 'Passenger': {
      return chain(foundFlights)
        .flatMap((flight: Flight) => {
          return flight.passengerNames.filter(str =>
            str.toLowerCase().includes(query.toLowerCase())
          );
        })
        .uniq()
        .value();
    }
    case 'Overflight country': {
      return chain(foundFlights)
        .flatMap((flight: FlightWithExtendedAirport) => {
          return flight.countryPermits.filter(str =>
            str.toLowerCase().includes(query.toLowerCase())
          );
        })
        .uniq()
        .value();
    }
    default:
      return [];
  }
};

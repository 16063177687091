import { PureComponent } from 'react';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';

interface Props {
  controlsBarHeight: number;
  timelineBarHeight: number;
  filterBarHeight: number;
  multipleSearchBarHeight: number;
  planeBlockWidth: number;
}
interface Props {}
interface StateProps {
  isSelecting: boolean;
  startX: number;
  endX: number;
  startY: number;
  endY: number;
}

class Selection extends PureComponent<Props & StateProps> {
  render() {
    const {
      isSelecting,
      startX,
      endX,
      startY,
      endY,
      planeBlockWidth,
      controlsBarHeight,
      timelineBarHeight,
      filterBarHeight,
      multipleSearchBarHeight,
    } = this.props;
    return (
      isSelecting && (
        <rect
          className="selection"
          x={startX - planeBlockWidth}
          y={
            startY -
            controlsBarHeight -
            timelineBarHeight -
            filterBarHeight -
            multipleSearchBarHeight
          }
          width={endX - startX}
          height={endY - startY}
          fill="rgba(24, 144, 255, 0.2)"
          stroke="#40A9FF"
          strokeWidth={1}
        />
      )
    );
  }
}
const mapStateToProps = (state: RootState): StateProps => {
  const selectedCase = state.ui.timelineSelection.isSelecting
    ? state.ui.timelineSelection.isSelecting
    : state.ui.draggingFlightsSelection.isSelecting;
  const area = state.ui.timelineSelection.isSelecting
    ? state.ui.timelineSelection
    : state.ui.draggingFlightsSelection;
  return {
    isSelecting: selectedCase,
    startX: area.start && area.end ? Math.min(area.start.x, area.end.x) : 0,
    endX: area.start && area.end ? Math.max(area.start.x, area.end.x) : 0,
    startY: area.start && area.end ? Math.min(area.start.y, area.end.y) : 0,
    endY: area.start && area.end ? Math.max(area.start.y, area.end.y) : 0,
  };
};
const mapDispatchToProps = dispatch => ({});
export const ConnectedSelection = connect(
  mapStateToProps,
  mapDispatchToProps
)(Selection);

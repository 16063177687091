import { CSSProperties, PureComponent } from 'react';
import Aircraft from '../../types/aircraft';
import './styles.scss';
import * as Color from 'color';
import { openAircraftModule, setDataTestEntityId } from '../../utils';
import { PrimaryInfo } from './content/primary-info';
import SecondaryInfoConnected from './content/secondary-info';
import DarkenedBox from './darkened-box';
import {
  getAircraftIndexMapWithHolding,
  getFoundAircraftBySearch,
} from '../../selectors';
import { RootState } from '../../reducers';
import { connect } from 'react-redux';

interface OwnProps {
  hovered: boolean;
  aircraft: Aircraft;
  height: number;
  index: number;
  planeBlockWidth: number;
  baseCompaniesIds: number[];
  isUnavailableForAdvertisement: boolean;
  hasTailHandover: boolean;
}
interface StatePropsConnected {
  aircraftListBySearch: Aircraft[];
  aircraftIndexMap: { [aircraftId: number]: number };
}
class HoldAircraftComponent extends PureComponent<
  StatePropsConnected & OwnProps
> {
  ref: HTMLDivElement;
  openTailProfile = () => {
    openAircraftModule(this.props.aircraft.id);
  };
  render() {
    const {
      height,
      planeBlockWidth: width,
      hovered,
      aircraft,
      index,
      baseCompaniesIds,
      isUnavailableForAdvertisement,
      aircraftIndexMap,
      aircraftListBySearch,
      hasTailHandover,
    } = this.props;
    const aircraftStyle: CSSProperties = {
      height,
      width,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: hovered ? 'black' : 'transparent',
      position: 'relative',
      boxShadow: 'none',
    };
    const isDarkened =
      aircraftListBySearch.length > 0 &&
      !aircraftListBySearch.some(a => aircraftIndexMap[a.id] === index);
    return (
      <DarkenedBox isDarkened={isDarkened}>
        <div
          className="aircraft"
          {...setDataTestEntityId(`hold-aircraft-item`)}
          style={{
            display: 'flex',
            alignItems: 'stretch',
            width,
            background: Color(
              aircraft.aircraftTypeColour
                ? aircraft.aircraftTypeColour
                : 'orange'
            )
              .alpha(0.4)
              .lighten(index % 2 == 0 ? 0.2 : 0)
              .toString(),
            ...aircraftStyle,
          }}
          ref={ref => (this.ref = ref)}
          onClick={this.openTailProfile}
        >
          <div className="aircraft-content" style={{ marginLeft: '20px' }}>
            <PrimaryInfo
              aircraft={aircraft}
              isUnavailableForAdvertisement={isUnavailableForAdvertisement}
            />
            <SecondaryInfoConnected
              aircraft={aircraft}
              baseCompaniesIds={baseCompaniesIds}
              isUnavailableForAdvertisement={isUnavailableForAdvertisement}
              hasTailHandover={hasTailHandover}
            />
          </div>
        </div>
      </DarkenedBox>
    );
  }
}

export const HoldAircraftComponentConnected = connect((state: RootState) => ({
  aircraftListBySearch: getFoundAircraftBySearch(state),
  aircraftIndexMap: getAircraftIndexMapWithHolding(state),
}))(HoldAircraftComponent);

import { PureComponent } from 'react';
import * as d3Scale from 'd3-scale';
import { utc } from 'moment';
import Flight from '../../../../../types/flight';
import Airport from '../../../../../types/airport';
import {
  flightFerryColorizer,
  accountTypeColorizer,
} from '../../../../../common/flight/flight-colorization';
import { connect } from 'react-redux';
import { RootState } from '../../../../../reducers';
import { getICAOLabelFromAirport } from '../../../../../utils/airport';

interface Props {
  scale: d3Scale.ScaleTime<number, number>;
  y: number;
  flight: Flight;
}
interface StateProps {
  airportsById: { [id: string]: Airport };
}

class FlightPreviewContent extends PureComponent<Props & StateProps> {
  render() {
    const { flight, scale, y, airportsById } = this.props;
    const depICAO = getICAOLabelFromAirport(
      airportsById[flight.departureAirportId]
    );
    const arrICAO = getICAOLabelFromAirport(
      airportsById[flight.arrivalAirportId]
    );
    return (
      <>
        <text x={scale(flight.start) - 89} y={y + 8} width={94} fontSize={12}>
          <tspan opacity={0.5}>{utc(flight.start).format('HH:mm[Z]')}</tspan>
          <tspan dx="5px">{depICAO}</tspan>
        </text>
        <rect
          x={scale(flight.start)}
          y={y}
          width={scale(flight.end) - scale(flight.start)}
          height={3}
          fill={flightFerryColorizer(flight.legBusinessTypeId)}
        />
        <rect
          x={scale(flight.start)}
          y={y + 3}
          width={scale(flight.end) - scale(flight.start)}
          height={3}
          fill={accountTypeColorizer(flight)}
        />
        <text x={scale(flight.end) + 5} y={y + 8} width={94} fontSize={12}>
          <tspan>{arrICAO}</tspan>
          <tspan opacity={0.5} dx="5px">
            {utc(flight.end).format('HH:mm[Z]')}
          </tspan>
        </text>
      </>
    );
  }
}

const ContentConnected = connect<StateProps>((state: RootState) => ({
  airportsById: state.airports.airportsById,
}))(FlightPreviewContent);

export default ContentConnected;

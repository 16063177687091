import { appendUnderlying } from './underlying';
import { appendFlightBorders } from './borders';
import { appendFirstLine } from './first-line';
import { appendSecondLine } from './second-line';
import { appendSlotMismatches } from './slot-mismatch';
import { appendThirdLine } from './third-line';
import { appendUsaFlaggedFlights } from './usa-flagged-flights';

// Items order is important
export default [
  appendUnderlying,
  appendFirstLine,
  appendSecondLine,
  appendThirdLine,
  appendUsaFlaggedFlights,
  appendFlightBorders,
  appendSlotMismatches,
];

import { ColumnProps } from 'antd/lib/table';
import { debounce } from 'lodash';
import { useState } from 'react';
import { ResizeCallbackData } from 'react-resizable';
import { ResizableTableHeader } from './resizable-table-header';

declare type Component<P> =
  | React.ComponentType<P>
  | React.ForwardRefExoticComponent<P>
  | React.FC<P>
  | keyof React.ReactHTML;
declare type CustomizeComponent = Component<any>;
declare type CustomizeScrollBody<RecordType> = (
  data: readonly RecordType[],
  info: {
    scrollbarSize: number;
    ref: React.Ref<{
      scrollLeft: number;
    }>;
    onScroll: (info: {
      currentTarget?: HTMLElement;
      scrollLeft?: number;
    }) => void;
  }
) => React.ReactNode;
interface TableComponents<RecordType> {
  table?: CustomizeComponent;
  header?: {
    wrapper?: CustomizeComponent;
    row?: CustomizeComponent;
    cell?: CustomizeComponent;
  };
  body?:
    | CustomizeScrollBody<RecordType>
    | {
        wrapper?: CustomizeComponent;
        row?: CustomizeComponent;
        cell?: CustomizeComponent;
      };
}

export const useResizeColumns = <T>(
  initialColumns: ColumnProps<T>[]
): {
  components: TableComponents<T>;
  endUpColumns: ColumnProps<T>[];
} => {
  const [columns, setColumns] = useState<ColumnProps<T>[]>(initialColumns);

  const handleResize = (index: number) => (
    _e: React.SyntheticEvent,
    { size }: ResizeCallbackData
  ): void => {
    const nextColumns = [...columns];
    nextColumns[index] = {
      ...nextColumns[index],
      width: +size.width,
    };
    setColumns(nextColumns);
  };

  const components = {
    header: {
      cell: ResizableTableHeader,
    },
  };
  const endUpColumns = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column: ColumnProps<T>) => ({
      width: +column.width,
      onResize: debounce(handleResize(index), 0),
    }),
  }));
  return {
    components,
    endUpColumns,
  };
};

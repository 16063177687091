import { CSSProperties, PureComponent } from 'react';
import { MessageOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { SUPPORT_URL } from '../../constants/environment';

export class ContactSupportTeam extends PureComponent {
  iconBox: CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    marginRight: '4px',
    marginLeft: '12px',
    height: '36px',
    cursor: 'pointer',
  };

  render() {
    return (
      <Tooltip title="Contact support team">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={SUPPORT_URL}
          style={this.iconBox}
        >
          <MessageOutlined style={{ fontSize: '18px', color: 'white' }} />
        </a>
      </Tooltip>
    );
  }
}

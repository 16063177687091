import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import { connect, DispatchProp } from 'react-redux';
import { Popconfirm } from 'antd';
import { hasPermission } from '../../../utils/check-permissions';
import { FC } from 'react';
import { ContextMenuWrapperAsPopper } from '../wrappers';
import { AnyAction } from 'redux';

interface StateProps {
  isOpen: boolean;
  id: number;
  hasEditMxEventPermission: boolean;
  hasDeleteMxEventPermission: boolean;
}
const MxEventsContextMenu: FC<StateProps & DispatchProp<AnyAction>> = ({
  isOpen,
  id,
  dispatch,
  hasEditMxEventPermission,
  hasDeleteMxEventPermission,
}) => {
  const onDelete = () => dispatch(actions.userDeleteMxEvent.started(id));
  const onClose = () => dispatch(actions.userCloseContextMenu());
  const onEdit = () => dispatch(actions.userClickEditMxEvent({ id }));
  const onClone = () => dispatch(actions.userClickCloneMxEvent({ id }));
  if (!isOpen || !hasEditMxEventPermission || !hasDeleteMxEventPermission)
    return null;
  return (
    <ContextMenuWrapperAsPopper
      isOpen={isOpen}
      menuType={'mx-events'}
      onCloseContextMenu={onClose}
      openMenuAction={actions.userOpenContextMenuForMxEvent}
    >
      <ul className="context-menu">
        {hasEditMxEventPermission && (
          <>
            <li onClick={onEdit}>Edit</li>
            <li onClick={onClone}>Clone Event</li>
          </>
        )}
        {hasDeleteMxEventPermission && (
          <Popconfirm
            title="Do you want to delete this Maintenance Event?"
            onConfirm={onDelete}
            onCancel={onClose}
            okText={<span data-stopbubbling="true">Yes</span>}
            cancelText="No"
          >
            <li>Delete Event</li>
          </Popconfirm>
        )}
      </ul>
    </ContextMenuWrapperAsPopper>
  );
};

const mapStateToProps = (state: RootState): StateProps => ({
  isOpen: state.eventElementCreationEdit.isMxContextMenuOpened,
  id: state.eventElementCreationEdit.id,
  hasEditMxEventPermission: hasPermission(state, 'AG-Timeline-Create-MX-Event'),
  hasDeleteMxEventPermission: hasPermission(
    state,
    'AG-Timeline-Delete-MX-Event'
  ),
});

export const MxEventsContextMenuConnected = connect(mapStateToProps)(
  MxEventsContextMenu
);

import { Button } from 'antd';
import { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../../actions';
import {
  HOLD_FLIGHT_HEIGHT,
  MAX_HOLD_FLIGHTS_ON_LESS_THAN_3_DAYS_VIEW,
  SUPPORTED_RESOLUTION_WIDTH,
} from '../../../../constants';
import {
  getZoomLevelForHoldFlightRender,
  ZoomLevelForHoldFlights,
} from '../../../../d3/components/zoom';
import { RootState } from '../../../../reducers';
import { ReducerShape } from '../../../../reducers/ui';
import {
  getFlightsForHoldTails,
  getHoldFlightsPoolDividedByTimeRange,
} from '../../../../selectors';
import {
  HoldFlightsPoolByAircraftIdMap,
  HoldFlightsPoolHoursDelimiter,
  HoursFlight,
  TimeFlightType,
} from '../../../../types/flight';
import { HoldFlightsColumn } from './holdFlightsTextLabels';
import {
  getHoldFlightTextLabelWidth,
  getHoursOffsetForHoldFlight,
  getHoldFlightTextLabelLeft,
} from '../../../../utils/hold-line-flights';

interface StateProps {
  holdAircraftPositionMap: { [id: number]: { y1: number; y2: number } };
  holdFlightsDelimiter: HoldFlightsPoolHoursDelimiter;
  holdFlightsPool: HoldFlightsPoolByAircraftIdMap;
  isScrolling: boolean;
  transform: ReducerShape['transform'];
  visibleHoldAcIds: number[];
  zoomLevel: ZoomLevelForHoldFlights;
}
class HoldFlightsTextLabelsGroup extends PureComponent<
  StateProps & {
    onClickSeeMore: (toDate: number) => void;
  },
  { hoveredId: string }
> {
  constructor(props) {
    super(props);
    this.state = {
      hoveredId: null,
    };
  }
  onHoverButton = (id: string) => {
    this.setState({
      hoveredId: id,
    });
  };
  onMouseLeave = () => {
    this.setState({
      hoveredId: null,
    });
  };
  render() {
    const {
      holdAircraftPositionMap,
      holdFlightsDelimiter,
      holdFlightsPool,
      visibleHoldAcIds,
      zoomLevel,
      isScrolling,
      transform,
    } = this.props;
    const { scaleX } = transform;
    const width = getHoldFlightTextLabelWidth(zoomLevel, scaleX);
    return (
      <>
        {visibleHoldAcIds.map(id => {
          switch (zoomLevel) {
            case 'fullView': {
              if (holdFlightsDelimiter[id] && holdAircraftPositionMap[id]) {
                return holdFlightsDelimiter[id].map(ob => {
                  const flightsByTime = ob.pool;
                  const day = ob.day;
                  return Object.keys(flightsByTime).map(key => {
                    const flights = flightsByTime[key];
                    const time = getHoursOffsetForHoldFlight(
                      key as TimeFlightType
                    );
                    const left = getHoldFlightTextLabelLeft(
                      flights[0]?.start,
                      zoomLevel,
                      transform,
                      time
                    );
                    return (
                      <Fragment key={`${day}_${key}`}>
                        {flights.length > 0 ? (
                          <div className="hold-flights-column">
                            <HoldFlightsColumn
                              flights={flights}
                              aircraftId={id}
                              width={width}
                              left={left}
                            />
                          </div>
                        ) : null}
                      </Fragment>
                    );
                  });
                });
              }
              return null;
            }
            case 'weeklyCompressed':
            case 'weeklyView': {
              if (holdFlightsPool[id] && holdAircraftPositionMap[id]) {
                return holdFlightsPool[id].map(ob => {
                  const allFlights = ob.pool;
                  const flights = allFlights.slice(
                    0,
                    MAX_HOLD_FLIGHTS_ON_LESS_THAN_3_DAYS_VIEW
                  );
                  const restInDay =
                    allFlights.length >
                      MAX_HOLD_FLIGHTS_ON_LESS_THAN_3_DAYS_VIEW &&
                    allFlights.length -
                      MAX_HOLD_FLIGHTS_ON_LESS_THAN_3_DAYS_VIEW;
                  const left = getHoldFlightTextLabelLeft(
                    flights[0]?.start,
                    zoomLevel,
                    transform,
                    HoursFlight.Midnight
                  );
                  return (
                    <div className="hold-flights-column" key={`${ob.day}`}>
                      {zoomLevel === 'weeklyCompressed' ? (
                        <Button
                          style={{
                            transform: `translate(${left}px, ${holdAircraftPositionMap[
                              id
                            ].y1 + 5}px)`,
                            fontSize: '11px',
                            display: !isScrolling ? 'block' : 'none',
                            willChange: 'display',
                          }}
                          type="primary"
                          key={`${ob.day}`}
                          onClick={() => this.props.onClickSeeMore(ob.day)}
                          onMouseLeave={this.onMouseLeave}
                          onMouseEnter={() =>
                            this.onHoverButton(`${id}_${ob.day}`)
                          }
                        >
                          {this.state.hoveredId &&
                          this.state.hoveredId === `${id}_${ob.day}`
                            ? 'zoom'
                            : `+${allFlights.length}`}
                        </Button>
                      ) : (
                        <Fragment key={`${ob.day}`}>
                          <HoldFlightsColumn
                            flights={flights}
                            left={left}
                            aircraftId={id}
                            width={width}
                          />
                          {restInDay > 0 ? (
                            <Button
                              style={{
                                transform: `translate(${left}px, ${holdAircraftPositionMap[
                                  id
                                ].y1 +
                                  MAX_HOLD_FLIGHTS_ON_LESS_THAN_3_DAYS_VIEW +
                                  1}px)`,
                                height: `${HOLD_FLIGHT_HEIGHT}px`,
                                width: `${width}px`,
                                lineHeight: `${HOLD_FLIGHT_HEIGHT - 1}px`,
                                fontSize: '11px',
                                display: !isScrolling ? 'block' : 'none',
                                willChange: 'display',
                                borderRadius: '0px',
                              }}
                              type="primary"
                              onClick={() => this.props.onClickSeeMore(ob.day)}
                              onMouseLeave={this.onMouseLeave}
                              onMouseEnter={() =>
                                this.onHoverButton(`${id}_${ob.day}`)
                              }
                            >
                              {this.state.hoveredId &&
                              this.state.hoveredId === `${id}_${ob.day}`
                                ? 'click to see more'
                                : `+${restInDay}`}
                            </Button>
                          ) : null}
                        </Fragment>
                      )}
                    </div>
                  );
                });
              }
              return null;
            }
            default:
              return null;
          }
        })}
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  isScrolling: state.ui.isScrollingHorizontally,
  visibleHoldAcIds: state.ui.visibleHoldAcIds,
  holdAircraftPositionMap: state.ui.holdAircraftPositionMap,
  holdFlightsDelimiter: getHoldFlightsPoolDividedByTimeRange(state),
  holdFlightsPool: getFlightsForHoldTails(state),
  zoomLevel: getZoomLevelForHoldFlightRender(state.ui.transform.scaleX),
  transform: state.ui.transform,
});

const mapDispatchToProps = dispatch => ({
  onClickSeeMore: (toDate: number) => {
    dispatch(
      actions.userChangeZoomLevel({
        zoomLevel:
          window.screen.availWidth >= SUPPORTED_RESOLUTION_WIDTH ? '3d' : '1d',
        toDate,
      })
    );
  },
});
export const HoldFlightsTextLabelsGroupConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(HoldFlightsTextLabelsGroup);

import { PureComponent } from 'react';
import { DispatchProp, connect } from 'react-redux';
import { RootState } from '../../../reducers';
import { createDelayedForScrollConnect } from '../../../utils/create-delayed-for-scroll-connect';
import { Popover, Button } from 'antd';
import { hasPermission } from '../../../utils/check-permissions';
import { EmptyLegGroupType } from '../../../reducers/empty-legs';
import { SegmentType } from '../../../types/segment-types';
import { OneWayAndEmptyLegContent } from './content';
import { getButtonSize } from '../utils';

interface StateProps {
  emptyLegMap: { [el in EmptyLegGroupType]: boolean };
  segmentsVisibility: { [st in SegmentType]: boolean };
  hasCheckedEmptyLegOffersPermission: boolean;
  hasCheckedOneWayOffersPermission: boolean;
  isVerticalMode: boolean;
}

class FilterByOneWayAndEmptyLeg extends PureComponent<
  StateProps & DispatchProp
> {
  render() {
    const {
      hasCheckedEmptyLegOffersPermission,
      hasCheckedOneWayOffersPermission,
      isVerticalMode,
    } = this.props;

    return (
      <>
        {!hasCheckedEmptyLegOffersPermission &&
        !hasCheckedOneWayOffersPermission ? null : (
          <Popover
            content={<OneWayAndEmptyLegContent {...this.props} />}
            placement="bottom"
          >
            <Button type="dashed" size={getButtonSize(isVerticalMode)}>
              OneWay EmptyLeg
            </Button>
          </Popover>
        )}
      </>
    );
  }
}

const advancedMap = createDelayedForScrollConnect((state: RootState) => ({
  segmentsVisibility: state.ui.segmentsVisibility,
  emptyLegMap: state.emptyLegs.emptyLegMap,
  hasCheckedEmptyLegOffersPermission: hasPermission(
    state,
    'AG-Timeline-View-EL-Offer'
  ),
  hasCheckedOneWayOffersPermission: hasPermission(
    state,
    'AG-Timeline-View-OW-Offer'
  ),
  isVerticalMode: state.ui.isVerticalMode,
}));

export const FilterByOneWayAndEmptyLegConnected = connect(advancedMap)(
  FilterByOneWayAndEmptyLeg
);

import './styles.scss';
import { FC, useEffect, useMemo, useState } from 'react';
import { Select } from 'antd';
import { FieldTitle } from './constants';
import { FieldProps, FormikProps } from 'formik';
import { EmailFormValues } from './interfaces';
import { debounce, isArray } from 'lodash';
import classNames from 'classnames';
import { LabeledValue } from 'antd/es/select';

export const EmailAddressAutoComplete: FC<{
  field: { value: LabeledValue[] };
  filterEmailAddressOptions: string;
  emailAddressOptions: string[];
  title: FieldTitle;
  onUserSearchEmailAddressOptions: (value: string) => void;
  resetEmailAddressOptions: () => void;
} & FormikProps<EmailFormValues> &
  FieldProps<
    EmailFormValues['from' | 'to' | 'cc' | 'bcc']
  >['field']> = props => {
  const {
    field: { value },
    name,
    emailAddressOptions,
    onUserSearchEmailAddressOptions,
    setFieldValue,
    resetEmailAddressOptions,
    filterEmailAddressOptions,
    handleChange,
    errors,
    touched,
  } = props;
  const [options, setOptions] = useState<LabeledValue[]>([]);
  const [searchedValue, setSearchedValue] = useState<string>('');

  useEffect(() => {
    if (
      emailAddressOptions &&
      emailAddressOptions.length &&
      filterEmailAddressOptions === searchedValue
    ) {
      setOptions(
        emailAddressOptions.map((opt: string) => ({
          value: opt,
          label: opt,
          key: opt,
        }))
      );
    }
  }, [emailAddressOptions]);

  const onSearch = useMemo(
    () =>
      debounce((filter: string) => {
        if (filter.length > 2) {
          setSearchedValue(filter);
          handleChange(filter);
          onUserSearchEmailAddressOptions(filter);
        }
      }, 300),
    [onUserSearchEmailAddressOptions, setSearchedValue, handleChange]
  );

  const onSelect = (opt: LabeledValue) => {
    setFieldValue(name, value.concat(opt));
    setSearchedValue('');
    setOptions([]);
    resetEmailAddressOptions();
  };

  const onDeselect = (opt: LabeledValue) => {
    setFieldValue(
      name,
      value.filter(v => v.value !== opt.key)
    );
  };

  const className = classNames({
    'email-form-field-wrapper': true,
    'email-form-field-error-label': errors[name] && touched[name],
  });

  return (
    <div className={className}>
      <label className="email-form-field-wrapper-label">{props.title}</label>
      <div className="email-form-field-wrapper-select">
        <Select
          className="email-form-field-wrapper-input"
          mode="tags"
          labelInValue
          showSearch
          value={value}
          onSearch={onSearch}
          filterOption={false}
          onSelect={onSelect}
          onDeselect={onDeselect}
          options={options}
        />
        {errors[name] && touched[name] ? (
          <div className="email-form-field-error-message">
            {isArray(errors[name])
              ? errors[name].map((er, i) => `${i === 0 ? '' : ' '}${er}`)
              : errors[name]}
          </div>
        ) : null}
      </div>
    </div>
  );
};

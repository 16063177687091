import { FC } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import * as actions from '../../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducers';
import { getUserPreferences } from '../../../selectors/user-preferences';
import { UserPreferences } from '../../../types/user-preferences';

export const SavePreset: FC<{}> = () => {
  const dispatch = useDispatch();
  const preferences = useSelector<RootState, UserPreferences>(
    getUserPreferences
  );
  const savePreset = () => {
    dispatch(actions.userSavePreferences.started(JSON.stringify(preferences)));
  };
  return (
    <Button
      icon={<SaveOutlined />}
      style={{
        background: '#F2F2F2',
        border: 'none',
      }}
      onClick={savePreset}
    >
      Save preset
    </Button>
  );
};

import './styles.scss';
import { FC } from 'react';
import {
  CloseCircleOutlined,
  CloseOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { Button, message, Modal, Tooltip } from 'antd';
import { SUPPORT_URL } from '../../constants/environment';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { doResetErrorHandlingState } from '../../actions';
import { generate } from 'shortid';
import { Dispatch } from 'redux';
import { Action } from 'typescript-fsa';

const CopyButton: FC<{ text: string }> = ({ text }) => (
  <Tooltip placement="bottom" title="Copy error details">
    <Button
      className="error-dialog-content-copy-button"
      onClick={async () => {
        try {
          await navigator.clipboard.writeText(text);
          message.success('Copied to clipboard');
        } catch {
          message.error('Failed to copy error details');
        }
      }}
    >
      <CopyOutlined className="error-dialog-content-copy-button-icon" />
    </Button>
  </Tooltip>
);

interface StateProps {
  content: string;
  title: string;
  visible: boolean;
}
interface DispatchProps {
  onClose?: () => void;
}
export const ErrorDialog: FC<StateProps & DispatchProps> = ({
  content,
  onClose,
  title,
  visible,
}) => {
  const reload = () => window.location.reload();
  return (
    <Modal
      centered
      closable
      closeIcon={<CloseOutlined onClick={onClose} />}
      destroyOnClose
      cancelText={
        <a target="_blank" rel="noopener noreferrer" href={SUPPORT_URL}>
          Contact support team
        </a>
      }
      okText="Reload page"
      onOk={reload}
      title={[
        <div className="error-dialog-warning-title">
          <CloseCircleOutlined />
          <span className="error-dialog-warning-title-text">{title}</span>
        </div>,
      ]}
      open={visible}
      width={550}
      key={generate()}
    >
      <div className="error-dialog-content">{content}</div>
      <CopyButton text={content} />
    </Modal>
  );
};

export const ErrorDialogConnected = connect(
  (state: RootState): StateProps => ({
    content: state.errorState.fullStack,
    title: state.errorState.fullTitle,
    visible: state.errorState.errorCount > 0,
  }),
  (dispatch: Dispatch<Action<void>>): DispatchProps => ({
    onClose: () => dispatch(doResetErrorHandlingState()),
  })
)(ErrorDialog);

import { message } from 'antd';
import * as d3S from 'd3-selection';
import { debounce } from 'lodash';
import { now } from 'moment';
import { ReferenceObject } from 'popper.js';
import { MouseEvent } from 'react';

import {
  AIRCRAFT_PROFILE_URL,
  EMPTY_LEG_WINDOW_HEIGHT,
  EMPTY_LEG_WINDOW_URL,
  EMPTY_LEG_WINDOW_WIDTH,
  ENV_NAME,
  FLIGHT_CENTER_HEIGHT,
  FLIGHT_CENTER_URL,
  FLIGHT_CENTER_WIDTH,
  MX_URL,
  ON_DEMAND_URL,
  ORDER_WINDOW_HEIGHT,
  ORDER_WINDOW_URL,
  ORDER_WINDOW_WIDTH,
} from '../constants/environment';
import { MaintenanceStatus } from '../types/aircraft';
import PeakDay from '../types/peak-day';
import EventElement from '../types/event-element';
import { SegmentType } from '../types/segment-types';
import { MaintenanceStatusForStats } from '../types/ui';
import { isEmpty } from 'lodash';
import { Pool } from '../data-processing/pool-structure';
import Flight, { OperationalStatus } from '../types/flight';
import Maintenance from '../types/maintenance';

export function getBoundingElement(
  element: HTMLDivElement | SVGGElement
): ReferenceObject {
  const event = d3S.event as MouseEvent;
  const clientX = event.clientX;
  const clientY = event.clientY;
  const boundingRect = element.getBoundingClientRect();

  const bottom = Math.min(boundingRect.bottom, clientY + 20);
  const left = Math.max(boundingRect.left, clientX - 20);
  const right = Math.min(boundingRect.right, clientX + 20);
  const top = Math.max(clientY - 20, boundingRect.top);
  const width = right - left;
  const height = bottom - top;

  const ob = {
    bottom,
    left,
    right,
    top,
    width,
    height,
  };
  return {
    clientHeight: height,
    clientWidth: width,
    getBoundingClientRect: () => ob as ClientRect,
  };
}

export function getNoteBoundingElement(
  element: HTMLDivElement
): ReferenceObject {
  const event = d3S.event as MouseEvent;
  const { clientX } = event;
  const boundingRect = element.getBoundingClientRect();
  const { bottom, top } = boundingRect;
  const left = Math.max(boundingRect.left, clientX - 20);
  const right = Math.min(boundingRect.right, clientX + 20);
  const width = right - left;
  const height = bottom - top;
  const ob = {
    bottom,
    left,
    right,
    top,
    width,
    height,
  };
  return {
    clientHeight: height,
    clientWidth: width,
    getBoundingClientRect: () => ob as ClientRect,
  };
}

export const getLoadingTimeKeyNameBySegmentType = (
  segmentType: SegmentType
): string => {
  let name = segmentType as string;
  if (segmentType === 'availabilityNotes' || segmentType === 'generalNotes')
    name = 'notes';
  return `${name}LoadingTime`;
};

export const getNotFetchedDaysRangeList = (days: {
  start: number;
  end: number;
  yesterday: number;
  tomorrow: number;
}): { start: number; end: number }[] => {
  const { start, end, yesterday, tomorrow } = days;
  if (start <= yesterday && tomorrow <= end) return [{ start, end }];
  if (yesterday <= start && end >= tomorrow)
    return [
      {
        start: yesterday,
        end,
      },
    ];
  if (start <= yesterday && yesterday <= end)
    return [
      {
        start,
        end: tomorrow,
      },
    ];
  return [
    {
      start: yesterday,
      end: tomorrow,
    },
    {
      start,
      end,
    },
  ];
};

export const openFlightCenter = (id: number, inNewTab?: boolean) => {
  try {
    if (!id) throw new Error("You can't open Flight Center without flight Id!");
    const leftIndent =
      window.outerWidth / 2 - FLIGHT_CENTER_WIDTH / 2 + window.screenX; // x
    const topIndent = window.screen.height / 2 - FLIGHT_CENTER_HEIGHT / 2; // y
    const url = `${FLIGHT_CENTER_URL}?flightLegId=${id}&timestamp=${now()}`;
    const newWindow = inNewTab
      ? window.open(url, '_blank')
      : window.open(
          url,
          'VJ Flight Center',
          `width=${FLIGHT_CENTER_WIDTH},height=${FLIGHT_CENTER_HEIGHT},menubar=no,location=yes,resizable=yes,status=yes,screenX=${leftIndent},left=${leftIndent},screenY=${topIndent}top=${topIndent}`
        );
    if (window.focus) newWindow.focus();
  } catch (e) {
    console.error(e);
    message.error(e);
  }
};

export const openOnDemand = (flightOrderMspId: string) => {
  try {
    if (!flightOrderMspId)
      throw new Error("You can't open Quote without flight Order MspId!");
    const additionalSign = ON_DEMAND_URL.split('').slice(-1) === '/' ? '' : '/';
    const url = `${ON_DEMAND_URL}${additionalSign}quote/${flightOrderMspId}`;
    const newWindow = window.open(url, '_blank');
    if (window.focus) newWindow.focus();
  } catch (e) {
    console.error(e);
    message.error(e);
  }
};

export interface OrderWindowParameters {
  id?: number;
  aircraftId?: number;
  start?: number;
  afterLeg?: number;
  beforeLeg?: number;
}
export const openOrderWindow = (props: OrderWindowParameters) => {
  try {
    const leftIndent = window.outerWidth / 2 - ORDER_WINDOW_WIDTH / 2;
    const topIndent = window.outerHeight / 2 - ORDER_WINDOW_HEIGHT / 2;
    const { aircraftId, id, start, afterLeg, beforeLeg } = props;
    const url = (() => {
      if (afterLeg) {
        return `${ORDER_WINDOW_URL}/order/${id}/?departureDateTime=${start}&aircraftId=${aircraftId}&afterLeg=${afterLeg}`;
      }
      if (beforeLeg) {
        return `${ORDER_WINDOW_URL}/order/${id}/?departureDateTime=${start}&aircraftId=${aircraftId}&beforeLeg=${beforeLeg}`;
      }
      if (aircraftId && start) {
        return `${ORDER_WINDOW_URL}/?departureDateTime=${start}&aircraftId=${aircraftId}`;
      }
      return `${ORDER_WINDOW_URL}${id ? `/order/${id}` : ''}`;
    })();
    const newWindow = window.open(
      url,
      'VJ Order Window',
      `width=${ORDER_WINDOW_WIDTH},height=${ORDER_WINDOW_HEIGHT},menubar=no,location=yes,resizable=yes,scrollbars=yes,status=yes,screenX=${leftIndent},left=${leftIndent},screenY=${topIndent}top=${topIndent}`
    );
    if (window.focus) newWindow.focus();
  } catch (e) {
    console.error(e);
    message.error(e);
  }
};

export interface EmptyLegWindowParameters {
  id?: number;
  aircraftId?: number;
  start?: number;
  end?: number;
  afterLegId?: number;
  beforeLegId?: number;
  beforeMaintenanceId?: number;
  afterMaintenanceId?: number;
  isValidGap?: boolean;
  ferryLegId?: number;
}

export const openWindowAppEmptyLeg2020 = (props: EmptyLegWindowParameters) => {
  const leftIndent = window.outerWidth / 2 - EMPTY_LEG_WINDOW_WIDTH / 2;
  const topIndent = window.outerHeight / 2 - EMPTY_LEG_WINDOW_HEIGHT / 2;
  const {
    start,
    end,
    id,
    aircraftId,
    beforeLegId,
    afterLegId,
    ferryLegId,
    afterMaintenanceId,
    beforeMaintenanceId,
  } = props;
  const url = (() => {
    if (isEmpty(props) || id) {
      return `${EMPTY_LEG_WINDOW_URL}/emptyLegWindow/id=${id || ''}`;
    }
    if (ferryLegId) {
      return `${EMPTY_LEG_WINDOW_URL}/emptyLegWindow/ferryLegId=${ferryLegId ||
        ''}`;
    }
    const beforeLink =
      beforeLegId || !beforeMaintenanceId
        ? `beforeLegId=${beforeLegId || ''}`
        : `beforeMaintenanceId=${beforeMaintenanceId || ''}`;
    const afterLink =
      afterLegId || !afterMaintenanceId
        ? `afterLegId=${afterLegId || ''}`
        : `afterMaintenanceId=${afterMaintenanceId || ''}`;
    return `${EMPTY_LEG_WINDOW_URL}/emptyLegWindow/startDate=${start ||
      ''}&endDate=${end || ''}&aircraftId=${aircraftId ||
      ''}&${beforeLink}&${afterLink}`;
  })();
  const newWindow = window.open(
    url,
    'VJ Empty Leg Window',
    `width=${EMPTY_LEG_WINDOW_WIDTH},height=${EMPTY_LEG_WINDOW_HEIGHT},menubar=no,location=yes,resizable=yes,scrollbars=yes,status=yes,screenX=${leftIndent},left=${leftIndent},screenY=${topIndent}top=${topIndent}`
  );
  if (window.focus) newWindow.focus();
};

export const leftClickHandlerCreator = <T extends EventElement | PeakDay>(
  onClick: (e?: T) => void,
  onDblClick: (e?: T) => void,
  hasFCPermission: boolean,
  hasOWPermission: boolean
) => {
  let delayedClick: any;
  let clickedOnce: boolean;
  let currentEventElementId: number;
  return (eventElement?: T) => {
    d3S.event.preventDefault();
    d3S.event.stopPropagation();
    if (!delayedClick) {
      delayedClick = debounce(element => {
        clickedOnce = null;
        hasFCPermission
          ? onClick(element)
          : message.warning('You do not have permission to view this window');
      }, 500);
    }
    if (clickedOnce && currentEventElementId === eventElement.id) {
      delayedClick.cancel();
      clickedOnce = false;
      hasOWPermission
        ? onDblClick(eventElement)
        : message.warning('You do not have permission to view this window');
    } else {
      currentEventElementId = eventElement.id;
      clickedOnce = true;
      delayedClick(eventElement);
    }
  };
};

export const getEuclideanDistance = (timelineSelection: {
  start: { x: number; y: number };
  end: { x: number; y: number };
}) =>
  Math.hypot(
    timelineSelection.start.x - timelineSelection.end.x,
    timelineSelection.start.y - timelineSelection.end.y
  );

export const getPositionMap = (
  segmentVisibility: { [st in SegmentType]: boolean }
): { [k in SegmentType]: number } => {
  let availabilityNotes =
    41 +
    (!segmentVisibility.oneWayOffers && !segmentVisibility.emptyLegOffers
      ? 0
      : 12) +
    (!segmentVisibility.maintenances ? 0 : 12);
  const oneWayAndEmptyLegHeight =
    41 + (!segmentVisibility.maintenances ? 0 : 12);
  return {
    flights: 29,
    airportMismatches: 12,
    groundTime: 12,
    fboMismatches: 12,
    maintenances: 41,
    maintenanceItems: 39,
    availabilityNotes,
    generalNotes: 39,
    crewRoster: 12,
    crewAssignment: 12,
    oneWayOffers: oneWayAndEmptyLegHeight,
    emptyLegOffers: oneWayAndEmptyLegHeight,
  };
};

export const capitalize = (str: string): string =>
  str
    .toLowerCase()
    .split(' ')
    .filter(s => s.length > 0)
    .map(word => word[0].toUpperCase() + word.slice(1))
    .join(' ');

export const getMaintenancesStatusType = (type: MaintenanceStatusForStats) => {
  switch (type) {
    case 'AOG': {
      return MaintenanceStatus.AOG;
    }
    case 'SMX': {
      return MaintenanceStatus.SMX;
    }
    case 'UMX': {
      return MaintenanceStatus.UMX;
    }
  }
};

export const setDataTestEntityId = (value, hasId = false) => {
  return ENV_NAME && typeof value !== 'undefined'
    ? {
        [hasId ? 'data-test-entity-id' : 'data-test-entity']: value
          .replace(/\ /g, '-')
          .toLowerCase(),
      }
    : {};
};

export const setDataTestEntityIdD3Elements = value =>
  ENV_NAME && typeof value !== 'undefined' ? value : null;

export const openMaintenanceModule = (aircraftId: number): void => {
  try {
    window.open(`${MX_URL}/aircraftId=${aircraftId}`, '_blank');
  } catch (e) {
    console.error(e);
    message.error(e);
  }
};

export const openAircraftModule = (aircraftId: number): void => {
  try {
    window.open(
      `${AIRCRAFT_PROFILE_URL}/aircraft-details/gv/${aircraftId}`,
      '_blank'
    );
  } catch (e) {
    console.error(e);
    message.error(e);
  }
};

export function shortenStringLength(string: string, max: number): string {
  if (!string) return '';
  if (string.length < max) return string;
  return `${string.slice(0, max)}...`;
}

const getPreviousAndNextLeg = (
  flight: Pool<Flight>,
  aircraftId: number,
  start: number,
  end: number
) =>
  flight.reduce<{
    beforeLeg: Partial<Flight>;
    afterLeg: Partial<Flight>;
  }>(
    (acc, { pool }) =>
      pool
        .filter(
          existingFlight =>
            existingFlight.legOperationalStatusId !==
              OperationalStatus.QUOTED &&
            existingFlight.legOperationalStatusId !==
              OperationalStatus.CANCELLED &&
            existingFlight.legOperationalStatusId !== OperationalStatus.NO_SHOW
        )
        .reduce<{
          beforeLeg: Partial<Flight>;
          afterLeg: Partial<Flight>;
        }>(
          (validFlights, item) => {
            if (
              item.aircraftId === aircraftId &&
              (item.end <= start || item.start <= start) &&
              (!validFlights.beforeLeg.end ||
                item.end > validFlights.beforeLeg.end)
            ) {
              validFlights.beforeLeg = item;
            }
            if (
              item.aircraftId === aircraftId &&
              item.start >= end &&
              (!validFlights.afterLeg.start ||
                item.start < validFlights.afterLeg.start)
            ) {
              validFlights.afterLeg = item;
            }
            return validFlights;
          },
          {
            beforeLeg: acc.beforeLeg,
            afterLeg: acc.afterLeg,
          }
        ),
    {
      beforeLeg: {},
      afterLeg: {},
    }
  );

const getPreviousAndNextMaintenance = (
  maintenances: Pool<Maintenance>,
  aircraftId: number,
  start: number,
  end: number
) =>
  maintenances.reduce<{
    beforeMaintenance: Partial<Maintenance>;
    afterMaintenance: Partial<Maintenance>;
  }>(
    (acc, { pool }) =>
      pool.reduce<{
        beforeMaintenance: Partial<Maintenance>;
        afterMaintenance: Partial<Maintenance>;
      }>(
        (accMaint, item) => {
          if (
            item.aircraftId === aircraftId &&
            (item.end <= start || item.start <= start) &&
            (!accMaint.beforeMaintenance.end ||
              item.end > accMaint.beforeMaintenance.end)
          ) {
            accMaint.beforeMaintenance = item;
          }
          if (
            item.aircraftId === aircraftId &&
            item.start >= end &&
            (!accMaint.afterMaintenance.start ||
              item.start < accMaint.afterMaintenance.start)
          ) {
            accMaint.afterMaintenance = item;
          }
          return accMaint;
        },
        {
          beforeMaintenance: acc.beforeMaintenance,
          afterMaintenance: acc.afterMaintenance,
        }
      ),
    {
      beforeMaintenance: {},
      afterMaintenance: {},
    }
  );

export const getBeforeEventsIds = (
  beforeLegId: number,
  beforeMaintenanceId: number,
  endLeg: number,
  endMaintenance: number
) => {
  if (beforeLegId && beforeMaintenanceId) {
    return endLeg > endMaintenance
      ? { beforeLegId, beforeMaintenanceId: null }
      : { beforeLegId: null, beforeMaintenanceId };
  }
  return { beforeMaintenanceId, beforeLegId };
};

export const getAfterEventsIds = (
  afterLegId: number,
  afterMaintenanceId: number,
  startLeg: number,
  startMaintenance: number
) => {
  if (afterLegId && afterMaintenanceId) {
    return startLeg < startMaintenance
      ? { afterLegId, afterMaintenanceId: null }
      : { afterLegId: null, afterMaintenanceId };
  }
  return { afterMaintenanceId, afterLegId };
};

export const getEmptyLegOfferWindowParams = (
  flights: Pool<Flight>,
  maintenances: Pool<Maintenance>,
  start: number,
  end: number,
  aircraftId: number
) => {
  const { beforeLeg, afterLeg } = getPreviousAndNextLeg(
    flights,
    aircraftId,
    start,
    end
  );
  const { beforeMaintenance, afterMaintenance } = getPreviousAndNextMaintenance(
    maintenances,
    aircraftId,
    start,
    end
  );
  const beforeEvents = getBeforeEventsIds(
    beforeLeg.id || null,
    (beforeMaintenance.airportId && beforeMaintenance.id) || null,
    beforeLeg.end,
    beforeMaintenance.end
  );
  const afterEvents = getAfterEventsIds(
    afterLeg?.id || null,
    (afterMaintenance.airportId && afterMaintenance.id) || null,
    afterLeg.start,
    afterMaintenance.start
  );
  const isValidGap =
    (beforeEvents.beforeLegId && start >= beforeLeg.start) ||
    (beforeEvents.beforeMaintenanceId && start >= beforeMaintenance.start);
  return { ...beforeEvents, ...afterEvents, isValidGap };
};

export const ascending = <T>(a: T, b: T) => {
  if (a < b) return -1;
  if (a > b) return 1;
  else return 0;
};

export const convertHtmlToText = (html: string): string => {
  const element = document.createElement('div');
  element.innerHTML = html;
  return element.textContent || element.innerText || '';
};

import { axiosAuthed as axios } from '../axios-config';
import { transformAirportToFE } from './airport-model';

export const getAirports = () =>
  axios
    .get('/integration/timeline/airports')
    .then(resp => resp.data.map(transformAirportToFE))
    .catch(e => {
      throw e;
    });

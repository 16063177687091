import { store as reduxStore } from '../../../../../root';
import * as d3S from 'd3-selection';
import * as actions from '../../../../../actions';
import MaintenanceItem, {
  MergedMEL,
} from '../../../../../types/maintenance-item';
import {
  getElementOffsetWithKoef,
  getLaneHeightKoef,
} from '../../../../../reducers/ui';
import {
  getBoundingElement,
  setDataTestEntityIdD3Elements,
} from '../../../../../utils';
import { ExtendedEventElement } from '../..';
import {
  buildPropsForComponent,
  getLeftMarginForMergedElement,
} from '../utils';
import {
  LIFT_UP_CASCADER_ELEMENT_HEIGHT,
  THIN_ELEMENT_HEIGHT,
} from '../../../../../constants';
import { isMergedMaintenanceItems } from '../../../../../utils/maintenance-item';

export const getMergedMaintenanceItemsD3creator = d => {
  const state = reduxStore.getState();
  const { transform, segmentsVisibility, positionMap } = state.ui;
  const { togglersState } = state.aircraft;

  const container = d
    .append('div')
    .classed('label-text', true)
    .style('transform', d => `translate(${d.x}px, ${d.y}px)`)
    .style(
      'top',
      el =>
        `${getElementOffsetWithKoef(
          segmentsVisibility,
          'maintenanceItems',
          togglersState[el.aircraftId],
          positionMap
        ) * transform.ky}px`
    )
    .each(function(d) {
      const container = this;
      if (!isMergedMaintenanceItems(d)) {
        d3S
          .select(container)
          .append('div')
          .classed('cascade-single-element-text-label', true)
          .classed('mel-text-label', true)
          .attr(
            'data-test-entity',
            setDataTestEntityIdD3Elements('mel-text-label')
          )
          .classed('merged-mel-text-label', true)
          .attr('data-content', (d.description || []).map(d => d).join(' '))
          .style('position', 'absolute')
          .style(
            'width',
            (d: ExtendedEventElement) =>
              `${d.x < 0 ? Math.max(d.width + d.x, 0) : d.width}px`
          )
          .style(
            'margin-left',
            (d: ExtendedEventElement) =>
              `${-d.x > d.width ? 0 : Math.max(-d.x, 0)}px`
          )
          .style(
            'height',
            (d: ExtendedEventElement) =>
              `${(THIN_ELEMENT_HEIGHT * transform.ky) /
                getLaneHeightKoef(
                  segmentsVisibility,
                  togglersState[d.aircraftId],
                  positionMap
                )}px`
          )
          .on('mouseover', function(d: MaintenanceItem | MergedMEL) {
            d3S.select(this).style('cursor', 'pointer');
            reduxStore.dispatch(
              actions.userHoverSegment([
                d,
                getBoundingElement(this),
                'maintenanceItems',
              ])
            );
          })
          .on('mouseout', d => {
            reduxStore.dispatch(actions.userCloseTooltipSegment());
          })
          .attr('data-test-entity-id', (m: MaintenanceItem) => m.id)
          .text((d: MaintenanceItem) => (d.description || []).join(' '));
      } else {
        d.maintenanceItems.forEach(function(mel, i) {
          const melWithProps = buildPropsForComponent(mel);
          const parentMel = d as MergedMEL & ExtendedEventElement;
          const id = mel.id;
          const aliquotNoteWithProps =
            d.maintenanceItems[i + 4] &&
            buildPropsForComponent(d.maintenanceItems[i + 4]);
          const textWidth = aliquotNoteWithProps
            ? aliquotNoteWithProps.x - melWithProps.x
            : melWithProps.width;
          d3S
            .select(container)
            .append('div')
            .classed('cascade-single-element-text-label', true)
            .style(
              'width',
              `${
                melWithProps.x < 0
                  ? Math.max(melWithProps.width + melWithProps.x, 0)
                  : melWithProps.width
              }px`
            )
            .attr('data-test-entity-id', id)
            .classed('mel-text-label', true)
            .attr(
              'data-test-entity',
              setDataTestEntityIdD3Elements('mel-text-label')
            )
            .classed('merged-mel-text-label', true)
            .attr('data-content', (mel.description || []).map(d => d).join(' '))
            .style(
              'margin-left',
              `${getLeftMarginForMergedElement(parentMel, melWithProps)}px`
            )
            .style('position', 'absolute')
            .style(
              'top',
              `${((LIFT_UP_CASCADER_ELEMENT_HEIGHT * transform.ky) /
                getLaneHeightKoef(
                  segmentsVisibility,
                  togglersState[mel.aircraftId],
                  positionMap
                )) *
                (i % 4)}px`
            )
            .style(
              'height',
              d =>
                `${(THIN_ELEMENT_HEIGHT * transform.ky) /
                  getLaneHeightKoef(
                    segmentsVisibility,
                    togglersState[mel.aircraftId],
                    positionMap
                  )}px`
            )
            .on('mouseover', function(d: MaintenanceItem | MergedMEL) {
              d3S.select(this).style('cursor', 'pointer');
              reduxStore.dispatch(
                actions.userHoverSegment([
                  mel,
                  getBoundingElement(this),
                  'maintenanceItems',
                ])
              );
            })
            .on('mouseout', d => {
              reduxStore.dispatch(actions.userCloseTooltipSegment());
            })
            .append('span')
            .style('display', 'inline-block')
            .style('width', `${textWidth}px`)
            .classed('mel-text-label', true)
            .attr(
              'data-test-entity',
              setDataTestEntityIdD3Elements('mel-text-label')
            )
            .text(() => (melWithProps.description || []).join(' '));
        });
      }
    });

  return container;
};

import { isEmpty } from 'lodash';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { RootState } from '../reducers';
import Airport from '../types/airport';

export const getActiveAirportList = createSelector<
  RootState,
  { [id: number]: Airport },
  Airport[]
>(
  state => state.airports.airportsById,
  airportsById => {
    return Object.values(airportsById).filter(el => el && el.isActive);
  }
);
export const getAirportByIcao = createCachedSelector(
  (state, _icao) => state.airports.airportsByIcao,
  (_state, icao) => icao,
  (airportsByIcao, icao) => {
    if (!airportsByIcao || isEmpty(airportsByIcao)) {
      return null;
    }
    return airportsByIcao[icao];
  }
)((state, icao) => icao);

import * as d3S from 'd3-selection';
import { store } from '../../../../../root';
import { EventGroup } from './../event-group';
import { ExtendedEventElement } from '../';
import { getInitialScale, getLaneHeightKoef } from '../../../../../reducers/ui';
import Note, { MergedNote } from '../../../../../types/note';
import {
  THIN_ELEMENT_HEIGHT,
  LIFT_UP_CASCADER_ELEMENT_HEIGHT,
} from '../../../../../constants';
import appender, { SegmentDatum } from '../appender';
import { isMergeNote } from '../../../../../utils/note';

export class NoteGroup extends EventGroup {
  renderEntered(
    entered: d3S.Selection<
      d3S.EnterElement,
      ExtendedEventElement & (Note | MergedNote),
      SVGGElement,
      {}
    >
  ) {
    const state = store.getState();
    const {
      width,
      planeBlockWidth,
      segmentsVisibility,
      positionMap,
    } = state.ui;
    const getHeight = (d: ExtendedEventElement, coef = THIN_ELEMENT_HEIGHT) =>
      elementName === 'generalNotes'
        ? coef /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          )
        : positionMap[elementName] /
          getLaneHeightKoef(
            segmentsVisibility,
            togglersState[d.aircraftId],
            positionMap
          );
    const { togglersState } = state.aircraft;
    const { elementName } = this.props;
    const scaleX = getInitialScale(width - planeBlockWidth);
    const rectGroup = entered.append('g').classed('event', true);
    const appendedParts = ['body', 'border', 'leftBorder'];
    const basicData = {
      element: 'note',
      color: 'rgba(255,244,157,0.6)',
      borderColor: '#FDD835',
    };
    rectGroup.each(function(d) {
      const container = d3S.select(this);
      if (!isMergeNote(d)) {
        const segmentDatum: SegmentDatum = {
          ...basicData,
          x: scaleX(d.start),
          y: d.y,
          width: scaleX(d.end) - scaleX(d.start),
          height: getHeight(d),
        };
        appendedParts.forEach(part =>
          appender[part].call(null, container, segmentDatum)
        );
      } else {
        d.notes.forEach(function(note, i) {
          const segmentCoordinates: SegmentDatum = {
            ...basicData,
            x: scaleX(note.start),
            y:
              elementName === 'generalNotes'
                ? d.y +
                  getHeight(
                    note as Note & ExtendedEventElement,
                    LIFT_UP_CASCADER_ELEMENT_HEIGHT
                  ) *
                    (i % 4)
                : d.y,
            width: scaleX(note.end) - scaleX(note.start),
            height: getHeight(note as Note & ExtendedEventElement),
          };
          appendedParts.forEach(part =>
            appender[part].call(null, container, segmentCoordinates)
          );
        });
      }
    });
    return rectGroup;
  }
}

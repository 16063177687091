import { utc } from 'moment';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers';
import { getAircraftById } from '../../../selectors';
import Aircraft from '../../../types/aircraft';
import { setDataTestEntityId } from '../../../utils';
import {
  getHoursBeforeMx,
  getHoursFlown,
  getLeftDueDate,
  getLeftHoursFlown,
  getLeftTotalLandings,
  getTotalLandings,
} from './utils';
import './mx-due-tooltip-style.scss';

interface StateProps {
  aircraft: Aircraft;
  today: number;
}

class MxDueTooltipContent extends PureComponent<StateProps> {
  render() {
    const { aircraft, today } = this.props;
    const { landingsBeforeMX = 'N/A', nextDueDateMs } = aircraft;
    const totalLandings = getTotalLandings(aircraft);
    const hoursFlown = getHoursFlown(aircraft);
    const hoursBeforeMx = getHoursBeforeMx(aircraft);
    const leftTotalLandings = getLeftTotalLandings(
      landingsBeforeMX,
      totalLandings
    );
    const leftHoursFlown = getLeftHoursFlown(hoursBeforeMx, hoursFlown);
    const leftDueDate = getLeftDueDate(today, nextDueDateMs);
    return (
      <>
        <table className="mx-due-tooltip-table">
          <tbody>
            <tr>
              <th className="mx-due-tooltip-table-header"></th>
              <th className="mx-due-tooltip-table-header-current">Current</th>
              <th className="mx-due-tooltip-table-header-next">Next</th>
              <th className="mx-due-tooltip-table-header">Rem</th>
            </tr>
            <tr>
              <td className="mx-due-tooltip-table-column">Hours flown</td>
              <td
                {...setDataTestEntityId('tooltip-aircraft-mx-due-hours-flown')}
              >
                {hoursFlown}
              </td>
              <td {...setDataTestEntityId('tooltip-aircraft-mx-due-hours')}>
                {hoursBeforeMx}
              </td>
              <td
                className={
                  parseInt(leftHoursFlown, 10) < 0
                    ? 'mx-due-tooltip-table-color'
                    : ''
                }
              >
                {leftHoursFlown}
              </td>
            </tr>
            <tr>
              <td className="mx-due-tooltip-table-column mx-due-tooltip-table-column-total-landing">
                Total landings
              </td>
              <td
                {...setDataTestEntityId(
                  'tooltip-aircraft-mx-due-total-landings'
                )}
              >
                {totalLandings}
              </td>
              <td {...setDataTestEntityId('tooltip-aircraft-mx-due-landings')}>
                {landingsBeforeMX}
              </td>
              <td
                className={
                  typeof leftTotalLandings === 'number' && leftTotalLandings < 0
                    ? 'mx-due-tooltip-table-color'
                    : ''
                }
              >
                {leftTotalLandings}
              </td>
            </tr>
            <tr>
              <td className="mx-due-tooltip-table-column">Due date</td>
              <td></td>
              <td {...setDataTestEntityId('tooltip-aircraft-mx-due-date')}>
                {nextDueDateMs
                  ? utc(nextDueDateMs).format('DD MMM,’YY')
                  : 'N/A'}
              </td>
              <td
                className={
                  parseInt(leftDueDate, 10) < 0
                    ? 'mx-due-tooltip-table-color'
                    : ''
                }
              >
                {leftDueDate}
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  const aircraft = getAircraftById(state, state.ui.hoveredAircraftId);
  return { aircraft, today: state.time.today };
};
const MxDueTooltipContentConnected = connect(mapStateToProps)(
  MxDueTooltipContent
);

export default MxDueTooltipContentConnected;

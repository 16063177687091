import { PureComponent } from 'react';
import {
  isFerryFlight,
  isFlightOnDemandLike,
  isLiveFlight,
} from '../../common/flight/flight-check-status';
import {
  formatterOrderPrice,
  formatterLegPrice,
} from '../../common/flight/flight-labels';
import Flight, { LegBusinessStatus } from '../../types/flight';
import { setDataTestEntityId } from '../../utils';

interface Props {
  selectedFlight: Flight;
  HAS_PROGRAM_REVENUE_PERMISSION: boolean;
  HAS_OD_REVENUE_PERMISSION: boolean;
}

class PricesRow extends PureComponent<Props> {
  hasRevenuePermissions() {
    const {
      selectedFlight,
      HAS_OD_REVENUE_PERMISSION,
      HAS_PROGRAM_REVENUE_PERMISSION,
    } = this.props;
    return (
      selectedFlight.amounts &&
      ((HAS_PROGRAM_REVENUE_PERMISSION &&
        !isFlightOnDemandLike(selectedFlight)) ||
        (HAS_OD_REVENUE_PERMISSION && isFlightOnDemandLike(selectedFlight)))
    );
  }
  isFerryLeg() {
    return isFerryFlight(this.props.selectedFlight.legBusinessTypeId);
  }
  render() {
    const { selectedFlight } = this.props;
    const isNotInvoicedShown =
      isFlightOnDemandLike(selectedFlight) &&
      isLiveFlight(selectedFlight) &&
      selectedFlight.legBusinessStatusId !== LegBusinessStatus.INVOICE_POSTED;
    return !this.isFerryLeg() && this.hasRevenuePermissions() ? (
      <>
        <div className="ft-box-group">
          <div className="ft-box">
            {isFlightOnDemandLike(selectedFlight) ? (
              <>
                <div className="ft-box-label">Order price</div>
                <div>{formatterOrderPrice(selectedFlight)}</div>
              </>
            ) : null}
          </div>
          <div className="ft-box">
            <div
              className="ft-box-label"
              {...setDataTestEntityId(`tooltip-flight-leg-price`)}
            >
              Leg Price
            </div>
            <div>
              {formatterLegPrice(selectedFlight)}
              {isNotInvoicedShown ? (
                <span className="ft-box-invoiced">{'(not invoiced)'}</span>
              ) : null}
            </div>
          </div>
        </div>
        <div className="ft-line" />
      </>
    ) : null;
  }
}

export default PricesRow;

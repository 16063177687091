import { PureComponent } from 'react';

interface StateProps {
  isDragging: boolean;
  width: number;
  height: number;
}

export class DraggableContent extends PureComponent<StateProps> {
  render() {
    return (
      <div style={{ position: 'relative' }}>
        {this.props.children}
        <DraggableContentCoverElement {...this.props} />
      </div>
    );
  }
}

const DraggableContentCoverElement = (props: StateProps) =>
  props.isDragging && (
    <div
      style={{
        width: `${props.width}px`,
        background: 'white',
        borderLeft: `2px solid grey`,
        height: `${props.height}px`,
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Dragging the element
    </div>
  );

import { FC } from 'react';
import { userCloseDndModal } from '../../../../actions';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { MainContentConnected } from './main-content';
import { RootState } from '../../../../reducers';
import { getAircraftById } from '../../../../selectors';

export const FlightsChangeTailModal: FC = () => {
  const dispatch = useDispatch();
  const onCloseMultipleDnDModal = () => dispatch(userCloseDndModal());
  const isModalVisible = useSelector<RootState, boolean>(
    state =>
      state.ui.isMultipleDnDModalVisible &&
      state.ui.selectedFlights?.length > 0 &&
      !!getAircraftById(state, state.ui.newAircraftId)
  );
  return isModalVisible ? (
    <div className="dnd-multiple-modal-main-container">
      <div className="dnd-multiple-modal-button-container">
        <div className="dnd-multiple-modal-close-button">
          <i className="material-icons" onClick={onCloseMultipleDnDModal}>
            close
          </i>
        </div>
      </div>
      <div className="dnd-multiple-modal-inner-container">
        <MainContentConnected />
      </div>
    </div>
  ) : null;
};

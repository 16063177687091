import { PureComponent } from 'react';
import * as d3Scale from 'd3-scale';
import EventElement from '../../../../../types/event-element';
import Preview from '../wrapper';
import ElementPreviewContent from './content';

interface Props {
  isFocused: boolean;
  onFocus: () => void;
  onBlur: () => void;
  scale: d3Scale.ScaleTime<number, number>;
  y: number;
  element: EventElement;
}

export default class ElementPreview extends PureComponent<Props> {
  render() {
    const { element, scale, y, onFocus, onBlur, isFocused } = this.props;

    return (
      <Preview onBlur={onBlur} onFocus={onFocus} isFocused={isFocused}>
        <ElementPreviewContent element={element} scale={scale} y={y} />
      </Preview>
    );
  }
}

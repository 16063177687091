import Transform from '../../../types/transform';
import * as d3Selection from 'd3-selection';

export default class TimelineElement<Data> {
  protected data: Data;
  protected rootNode: d3Selection.Selection<any, any, any, any>;
  constructor(data: Data) {
    this.data = data;
  }
  render(rootNode: d3Selection.Selection<any, any, any, any>) {
    this.rootNode = rootNode;
  }
  protected renderEntered(
    entered: d3Selection.Selection<any, any, any, any>
  ): d3Selection.Selection<any, any, any, any> {
    return entered;
  }
  reZoom(transform: Transform) {}
  update(data: Data) {
    this.data = data;
  }
  clear() {}
}

/* eslint-disable */
export const logNoop = (message?: any, ...optionalParams: any[]): void => {}; // replace impl. to turn log on
export const logDebug = (message?: any, ...optionalParams: any[]): void =>
  console.debug(message, optionalParams);
export const logWarn = (message?: any, ...optionalParams: any[]): void =>
  console.warn(message, optionalParams);
export const logError = (message?: any, ...optionalParams: any[]): void =>
  console.error(message, optionalParams);
export const logInfo = (message?: any, ...optionalParams: any[]): void =>
  console.info(message, optionalParams);
